import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Divider, Input, Spin } from 'antd'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  DescContainer,
  DescDrawerWrapper,
  DescDrawersFields,
  JobDescriptionsDrawer,
  JobDescriptionsTitle
} from '../style'
import { RichEditor } from '../../../../../../components/RichEditor'
import { useJobsActions } from '../../../../context/JobsContext'
import { matchPath, useHistory, useLocation, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../../constants/constants'

const EditJobDesc = ({ selectedJob, setEditDrawer }) => {
  const actions = useJobsActions()
  const { jobExtId } = useParams()
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const description = selectedJob?.descriptions?.[0]
  const [loader, setLoader] = useState(false)
  const [values, setValues] = useState({
    name: selectedJob?.name,
    title: selectedJob?.title,
    location: selectedJob?.location,
    content: description?.content
  })
  const handleChange = (e) => {
    if (e?.target?.name === undefined) { return setValues({ ...values, content: e }) }
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const handleIdAndLocation = async (edit) => {
    setLoader(true)
    try {
      await actions.updateJob(selectedJob, {
        location: values.location,
        name: values.name
      })
    } catch (e) {
      console.log(e)
    } finally {
      await actions.getOpenJobs(
        {
          countries: [],
          locations: [],
          regions: []
        },
        0,
        10
      )
      edit !== 'all' &&
        setTimeout(() => {
          setLoader(false)
          setEditDrawer(false)
        }, '3000')
    }
  }
  const handleTitleAndDesc = async () => {
    setLoader(true)
    try {
      await actions.updateJobDescription(jobExtId, jobExtId, { ...values })
    } catch (e) {
      console.log(e)
    } finally {
      await actions.getOpenJobs(
        {
          countries: [],
          locations: [],
          regions: []
        },
        0,
        10
      )
      const response = await actions.getJob(jobExtId)
      if (response) {
        setTimeout(() => {
          setLoader(false)
          setEditDrawer(false)
        }, '3000')
      }
    }
  }
  const handleClickSave = async () => {
    if (
      (values.name !== selectedJob?.name &&
        values.content === description?.content) ||
      (values.location !== selectedJob?.location &&
        values.content === description?.content)
    ) {
      handleIdAndLocation()
    } else if (
      (values.name !== selectedJob?.name &&
        values.content !== description?.content) ||
      (values.location !== selectedJob?.location &&
        values.content !== description?.content)
    ) {
      handleIdAndLocation('all')
      handleTitleAndDesc()
    } else {
      handleTitleAndDesc()
    }
  }

  const handleOnClose = () => {
    const urlParts = location.pathname.split('/')
    urlParts.pop()
    history.push(urlParts.join('/'))
  }
  const match = matchPath(location.pathname, {
    path: '/score/:jobExtId/search/:jobPostExtId/applicants/edit',
    exact: true,
    strict: false
  })
  return (
    <Spin spinning={loader}>
      <JobDescriptionsDrawer style={{ padding: '0px 12px 0px 12px' }}>
        <JobDescriptionsTitle>
          <div className="title">
            <span>{t(allConstants.JOB_DESCRIPTION)}</span>
          </div>
          <div className="job-desc-drawer-button">
            <Button
              className="close-button"
              type="text"
              style={{ color: '#00b7c0', fontWeight: '500' }}
              onClick={() => {
                setEditDrawer(false)
                history.push('/score')
              }}
            >
              {t('Close')}
            </Button>
          </div>
        </JobDescriptionsTitle>
        <Divider
          className="desc-divider"
          style={{ border: '0.5px solid #d9d9d9' }}
        />
        <DescDrawerWrapper>
          <div className="sub_header_btn sub_header_btn_save">
            <Button
              type="text"
              icon={<ArrowLeftOutlined />}
              style={{ color: '#00b7c0', fontWeight: '400' }}
              onClick={
                match ? () => handleOnClose() : () => setEditDrawer(false)
              }
            >
              {t(allConstants.back_button)}
            </Button>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={() => handleClickSave()}
              loading={loader}
            >
              {t(allConstants.SAVE)}
            </Button>
          </div>
        </DescDrawerWrapper>
        <div className="fields-container">
          <DescDrawersFields style={{ marginRight: '20px' }}>
            <div className="field">{t(allConstants.JOB_ID)}:</div>
            <div className="value">
              <Input
                className="field-value-input"
                name="name"
                placeholder={t(allConstants.JOB_ID)}
                onChange={(e) => handleChange(e)}
                value={values.name}
              />
            </div>
          </DescDrawersFields>
          <DescDrawersFields>
            <div className="field">{t(allConstants.LOCATION)}:</div>
            <div className="value">
              <Input
                className="field-value-input"
                name="location"
                placeholder={t(allConstants.LOCATION)}
                onChange={(e) => handleChange(e)}
                value={values.location}
              />
            </div>
          </DescDrawersFields>
          <DescDrawersFields style={{ marginRight: '0px' }}>
            <div className="field">{t(allConstants.JOB_TITLE)}:</div>
            <div className="value" style={{ width: '582px' }}>
              <Input
                name="title"
                onChange={(e) => handleChange(e)}
                value={values.title}
              />
            </div>
          </DescDrawersFields>
        </div>
        <DescContainer>
          <RichEditor
            selectedJob={selectedJob}
            name="content"
            onChange={(e) => handleChange(e)}
            defaultValue={description?.content}
          />
        </DescContainer>
      </JobDescriptionsDrawer>
    </Spin>
  )
}
EditJobDesc.propTypes = {
  selectedJob: PropTypes.object,
  setEditDrawer: PropTypes.func
}
export default EditJobDesc
