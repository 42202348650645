
export const QUERY_ACTIONS = {
  SHARE: 'SHARE',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  DUPLICATE: 'DUPLICATE',
  CREATE: 'CREATE',
  EXPORT_CANDIDATES: 'EXPORT_CANDIDATES'
}

export const JOB_PAYLOAD = {
  annotations: {
    _v: 1.1,
    clientRequestId: null,
    mustHave: {
      career_length: null,
      career_count: null,
      location: null,
      postCode: null,
      distance: null
    },
    shouldHave: {
      expertise: [],
      summary: [],
      certifications: [],
      current_company: [],
      current_title: [],
      current_industry: [],
      previous_company: [],
      previous_title: [],
      previous_industry: [],
      school: [],
      degree: [],
      languages: []

    }
  },
  country: '',
  description: 'job Description',
  location: '',
  region: '',
  name: 'Job Title',
  title: '',
  status: 'open',
  externalJobId: 3123123
}
