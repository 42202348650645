import { BASE_URL } from '../axios-config'
import Axios from 'axios'
import qs from 'qs'

export function loginUser ({ email, password }) {
  const data = {
    grant_type: 'password',
    username: email,
    password: password,
    scope: 'email phone profile',
    client_id: 'ai4jobs',
    client_secret: 'D2mk1LgjwyrZqf41t98kA1YG3AJdhp4r'
  }
  return Axios({
    method: 'post',
    url: '/oauth/token',
    data: qs.stringify(data),
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: '*/*',
      Authorization: 'Basic dHdnLXRydXN0ZWQtY2xpZW50OnNlY3JldA=='
    }
  })
}

export function refreshToken () {
  const data = {
    grant_type: 'refresh_token',
    client_id: 'ai4jobs',
    refresh_token: sessionStorage.getItem('refresh_token')
  }

  return Axios(
    {
      method: 'post',
      url: '/oauth/token',
      data: qs.stringify(data),
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: '*/*',
        Authorization: 'Basic dHdnLXRydXN0ZWQtY2xpZW50OnNlY3JldA=='
      }
    })
    .then((res) => {
      sessionStorage.setItem('access_token', res.data.access_token)
      sessionStorage.setItem('expires_in', res.data.expires_in)
      sessionStorage.setItem('refresh_token', res.data.refresh_token)
      sessionStorage.setItem('refresh_expires_in', res.data.refresh_expires_in)
      return res
    })
    .catch((error) => {
      sessionStorage.clear()
      return Promise.reject(error)
    })
}
