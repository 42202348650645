import React from 'react'
import { CustomSelect } from './Style'
import spainFlag from '../../assets/spainFlag.png'
import usaFlag from '../../assets/usaFlag.png'
import franceFlag from '../../assets/franceFlag.png'
import { PropTypes } from 'prop-types'

function LanguageSelection ({ changeLanguage, selectedLanguage }) {
  const selectOptions = SelectLanguageOptions.map(val => ({
    label: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '4px'
        }}
      >
        <span>
          <img
            style={{ width: val.value === 'en' ? '22px' : '16px', height: val.value === 'en' ? '22px' : '16px', paddingBottom: '2px' }}
            src={val.icon}
          />
        </span>
        <span className="language_text" style={{ paddingLeft: '8px', fontSize: '14px', color: '#343434' }}>{val.title}</span>
      </div>
    ),
    value: val.value
  }))
  return (
    <CustomSelect
    style={{ width: '130px' }}
      size="large"
      placeholder="choose language"
      onChange={changeLanguage}
      options={selectOptions}
      value={selectedLanguage}
    />
  )
}
LanguageSelection.propTypes = {
  changeLanguage: PropTypes.func,
  selectedLanguage: PropTypes.string
}
export const SelectLanguageOptions = [{ title: 'English', value: 'en', icon: usaFlag }, { title: 'Spanish', value: 'es', icon: spainFlag }, { title: 'French', value: 'fr', icon: franceFlag }]
export default LanguageSelection
