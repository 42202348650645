/* eslint-disable react/prop-types */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Popover, List, Tooltip, Space, Popconfirm } from 'antd'
import {
  // EyeOutlined,
  DeleteOutlined,
  // FileAddOutlined,
  UserAddOutlined,
  MoreOutlined,
  FileOutlined
} from '@ant-design/icons'
import { useHistory } from 'react-router'
import { useJobsActions, useJobsState } from '../../../context/JobsContext'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'
// import { getAllJobPostings } from '../../../../../api-twg/score/score-controller'

const Actions = ({ job }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const actions = useJobsActions()

  const { search } = useJobsState()

  job?.title?.toLowerCase()?.includes(search.toLowerCase()) ||
      job?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
      job?.location?.toLowerCase()?.includes(search.toLowerCase()) ||
      job?.requestId?.toString()?.toLowerCase()?.includes(search.toLowerCase())
  const data = [
    {
      name: t(allConstants.editViewJob),
      tooltip: t(allConstants.editViewJob),
      icon: <FileOutlined />,
      onClick: () => {
        history.push(`/score/${job.requestId}/descriptions`)
        window.analytics.track('View job', {
          job_id: job.requestId,
          job_title: job?.title?.toLowerCase(),
          location: job?.location?.toLowerCase(),
          applicants: job?.applicantCount,
          attract: job?.isAttractJob ? 'on' : 'off',
          placement: 'Small Menu Window'
        })
      }
    },
    // {
    //   name: 'View Results',
    //   tooltip: 'View Results',
    //   icon: <EyeOutlined />,
    //   onClick: async () => {
    //     const { data } = await getAllJobPostings(job.requestId)
    //     history.push(
    //       `/score/${job.requestId}/search/${data?.[0]?.externalJobPostingId}/applicants`, data
    //     )
    //     window.analytics.track('View Results', {
    //       job_id: job.requestId,
    //       job_title: job?.title?.toLowerCase(),
    //       location: job?.location?.toLowerCase(),
    //       applicants: job?.applicantCount,
    //       attract: job?.isAttractJob ? 'on' : 'off',
    //       placement: 'Small Menu Window'
    //     })
    //   }
    // },
    {
      name: t(allConstants.uploadApplicantResumes),
      tooltip: t(allConstants.uploadApplicantResumes),
      icon: <UserAddOutlined />,
      onClick: () => {
        history.push(`/score/${job.requestId}/upload`)
        window.analytics.track('Upload applicants', {
          job_id: job?.requestId,
          job_title: job?.title?.toLowerCase(),
          location: job?.location?.toLowerCase(),
          applicants: job?.applicantCount
        })
      }
    },
    // {
    //   name: 'Edit Job',
    //   tooltip: 'Edit Job',
    //   icon: <FileAddOutlined />,
    //   onClick: () => {
    //     history.push(`/score/${job.requestId}/edit`)
    //     window.analytics.track('Edit job', {
    //       job_id: job.requestId,
    //       job_title: job?.title?.toLowerCase(),
    //       location: job?.location?.toLowerCase(),
    //       applicants: job?.applicantCount,
    //       attract: job?.isAttractJob ? 'on' : 'off',
    //       placement: 'Small Menu Window'
    //     })
    //   }
    // },
    {
      name: t(allConstants.DeleteJob),
      confirm: t(allConstants.confirmDeleteOperation),
      tooltip: t(allConstants.DeleteJob),
      icon: <DeleteOutlined />,
      style: { color: 'red' },
      onClick: () => {
        actions.closeJob(job.requestId)
        window.analytics.track('Delete job', {
          job_id: job.requestId,
          job_title: job?.title?.toLowerCase(),
          location: job?.location?.toLowerCase(),
          applicants: job?.applicantCount,
          attract: job?.isAttractJob ? 'on' : 'off',
          placement: 'Small Menu Window'
        })
      }
    }
  ]

  return (
    <Popover
      trigger="hover"
      placement="left"
      content={
        <List
          size="small"
          style={{ border: 'none', cursor: 'pointer' }}
          bordered
          split={false}
          dataSource={data}
          renderItem={(item) => item.confirm
            ? (
              <Popconfirm
                placement="left"
                title={item.confirm}
                onConfirm={item.onClick}
                okText={t(allConstants.YES)}
                cancelText={t(allConstants.NO)}
              >
                  <List.Item>
                    <Tooltip title={item.tooltip} placement="right">
                      <Space style={item.style}>
                        {item.icon}
                        {item.name}
                      </Space>
                    </Tooltip>
                  </List.Item>
                </Popconfirm>
              )
            : (
                <List.Item onClick={item.onClick}>
                  <Tooltip title={item.tooltip} placement="right">
                    <Space style={item.style}>
                      {item.icon}
                      {item.name}
                    </Space>
                  </Tooltip>
                </List.Item>
              )
          }
        />
      }
    >
      <MoreOutlined rotate={90} />
    </Popover>
  )
}

Actions.propTypes = {
  job: PropTypes.shape({
    requestId: PropTypes.number
  })
}

export default Actions
