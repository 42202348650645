import React from 'react'
import { Row } from 'antd'
// Local Files
import WriteMessage from './WriteMessage/WriteMessage'
// Styles
import './ZendeskTicketChatBody.css'
import PreviewMessage from './PreviewMessage/PreviewMessage'

function ZendeskTicketChatBody () {
  return (
        <Row className="chat-body" >
            <PreviewMessage/>

            <WriteMessage/>
        </Row>
  )
}

export default ZendeskTicketChatBody
