import { Card } from 'antd'
import styled from 'styled-components'

export const TableContent = styled.table`
    width:700px;
    & thead {
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        color: #343434;
        & tr {
            border: solid 1px #f0f0f0;
            & th{
                height: 44px;
                padding: 8px;
                background-color: #f0f0f0;
                text-align:center;
            }
        }

        & tr {
            & th:last-child {
                border-left:solid 1px #bdbdbd !important;
                padding:0px 24px;
            }
        }
    }

    & tbody {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        color: #343434;
        & tr {
            & td {
                height: 44px;
                padding: 8px 8px 8px 24px;
                border: solid 1px #f0f0f0;
                background-color: #fff;
            }
        }

        & .highlight {
            font-weight: 600;
            border: solid 1px #f0f0f0;
            background-color: #e7f7ff;
        }

        & tr {
            & td:last-child {
                padding-left:0px;
                text-align:center;
            }
        }
        & .total {
            border-top: solid 4px #f0f0f0;
        }
    }
`
export const CustomCard = styled(Card)`
& .ant-card-body{
    padding:12px;
    padding-bottom: 0 !important;
 }
`
