import styled from 'styled-components'
export const TransactionRangeStyle = styled.div`
  padding-left: 10px;
  padding-bottom: 24px;
  .col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 42px;
    gap: 16px;
  }
  .form {
    display: flex;
    justify-content: space-between;
  }
  .row {
    display: flex;
    gap: 24px;
    justify-content: flex-start;
    align-items: center;
  }
  .standard_date {
    margin-top: 12px;
    margin-right: 12px;
  }
  .custom_date {
    margin-top: 12px;
  }
  .export_btn {
    padding-right: 8px;
  }

  
`
