import axios from '../../axios-config'
/**
 * GET
 * /discover/api/v1/workbook
 * getWorkbooks
 */
export const getWorkbooks = () => {
  return axios.get('/discover/api/v1/workbook')
}

// Get Workbooks queries
export const getWorkbookQueries = () => {
  return axios.get('/discover/api/v1/workbook?details=true')
}
/**
 * POST
 * /discover/api/v1/workbook
 * createWorkbook
 */
export const createWorkbook = (payload) => {
  return axios.post('/discover/api/v1/workbook', payload)
}
/**
 * GET
 * /discover/api/v1/workbook/${workbookId}
 * getWorkbook
 */
export const getWorkbook = (workbookId) => {
  return axios.get(`/discover/api/v1/workbook/${workbookId}`)
}
/**
 * PUT
 * /discover/api/v1/workbook/${workbookId}
 * updateWorkbook
 */
export const updateWorkbook = (workbookId) => {
  return axios.PUT(`/discover/api/v1/workbook/${workbookId}`)
}
/**
 * DELETE
 * /discover/api/v1/workbook/${workbookId}
 * deleteWorkbook
 */
export const deleteWorkbook = (workbookId) => {
  return axios.delete(`/discover/api/v1/workbook/${workbookId}`)
}
/**
 * POST
 * /discover/api/v1/workbook/${workbookId}/copy
 * duplicateWorkbook
 */
export const duplicateWorkbook = (workbookId) => {
  return axios.post(`/discover/api/v1/workbook/${workbookId}/copy`)
}
/**
 * POST
 * /discover/api/v1/workbook/${workbookId}/share
 * shareWorkbook
 */
export const shareWorkbook = (workbookId) => {
  return axios.post(`/discover/api/v1/workbook/${workbookId}/share`)
}
