import React from 'react'
import { Layout, Typography, Divider } from 'antd'
import PropTypes from 'prop-types'
// Style Sheet
import './modalBody.css'
import ModalHeader from './ModalHeader'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

const { Text } = Typography

export default function DuplicateModal ({
  values,
  handleInputChange,
  handleSubmit,
  searchName
}) {
  const { t } = useTranslation()
  const Body = () => {
    return (
      <Typography className="modal-body">
        <Text>{`${t(allConstants.confirmDelete)} '${searchName}' ?`} </Text>
      </Typography>
    )
  }

  return (
    <Layout className="modal-layout">
      <ModalHeader title={t(allConstants.delete_search)} />

      <Divider className="modal-divider" />

      <Body />
    </Layout>
  )
}

DuplicateModal.propTypes = {
  values: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateSearch: PropTypes.object.isRequired,
  searchName: PropTypes.object.isRequired
}
