import * as discoverJobController from '../../../api-twg/discover/discover-job-controller'

export const UpdateActiveCandidateData = async (payload, candidatesFilters, api, properties, matchingResults, match) => {
  if (!match) {
    try {
      const annotations = JSON.parse(candidatesFilters.searchJson)
      const updateAnnotations = {
        ...annotations,
        mustHave: { ...annotations.mustHave, ...payload.mustHave },
        shouldHave: { ...annotations.shouldHave, ...payload.shouldHave },

        mustNotHave: { ...annotations.mustNotHave, ...payload.mustNotHave }
      }

      const jobKey = candidatesFilters?.jobKey
      await api.updateJob({
        ...candidatesFilters,
        annotations: updateAnnotations,
        description: candidatesFilters.description || 'Job Description',
        searchJson: undefined
      })
      await api.getSearchedCandidates(jobKey)
      await api.jobPreview(jobKey)
      window.analytics.track('Search Adjusted', {
        ...properties,
        matching_results: matchingResults?.length || 0,
        search_id: jobKey
      })
    } catch (e) {
      console.log(e.message)
    }
  }
}

export const advancedSearchForCandidates = async (
  payload, actions, searchApiObj,
  dispatch, setSearchApiObj, searchApiVal,
  setSearchApiVal, setSearch, setIsLoading
) => {
  const obj = { }
  payload.map(item => {
    if (Object.prototype.hasOwnProperty.call((Object.values(item[1])[0]), 'mustHave')) {
      if (Object.values(item[1])[0].mustHave?.length !== 0) {
        const keys = Object.keys(item[1])
        const values = Object.values(item[1])
        keys.map((ele, ind) => {
          if (ele === 'count') {
            obj.mustHave = {
              ...obj.mustHave,
              career_count: values[ind].mustHave
            }
          } else if (ele === 'years') {
            obj.mustHave = {
              ...obj.mustHave,
              career_length: values[ind].mustHave
            }
          } else {
            obj.mustHave = {
              ...obj.mustHave,
              [ele]: values[ind].mustHave
            }
          }
          return obj
        })
      } else {
        const keys = Object.keys(item[1])
        const values = Object.values(item[1])
        keys.map((ele, ind) => {
          if (!Array.isArray(values[ind].mustHave)) {
            obj.mustHave = {
              ...obj.mustHave,
              [ele]: values[ind].mustHave
            }
          }
          return obj
        })
      }
    }
    return obj
  })
  payload.map(item => {
    if (Object.prototype.hasOwnProperty.call((Object.values(item[1])[0]), 'mustNotHave')) {
      if (Object.values(item[1])[0].mustNotHave?.length !== 0) {
        const keys = Object.keys(item[1])
        const values = Object.values(item[1])
        keys.map((ele, ind) => {
          obj.mustNotHave = {
            ...obj.mustNotHave,
            [ele]: values[ind].mustNotHave
          }
          return obj
        })
      } else if (Object.values(item[1])[1]?.mustNotHave?.length !== 0) {
        const keys = Object.keys(item[1])
        const values = Object.values(item[1])
        keys.map((ele, ind) => {
          obj.mustNotHave = {
            ...obj.mustNotHave,
            [ele]: values[ind].mustNotHave
          }
          return obj
        })
      }
    }
    return obj
  })
  payload.map((item, index) => {
    if (item[0] === 'languages') {
      obj.shouldHave = {
        ...obj.shouldHave,
        languages: item[1].shouldHave
      }
    } else if (Object.prototype.hasOwnProperty.call((Object.values(item[1])[0]), 'shouldHave')) {
      if (Object.values(item[1])[0].shouldHave?.length !== 0) {
        const keys = Object.keys(item[1])
        const values = Object.values(item[1])
        keys.map((ele, ind) => {
          obj.shouldHave = {
            ...obj.shouldHave,
            [ele]: values[ind].shouldHave
          }
          return obj
        })
      } else if (Object.values(item[1])[1]?.shouldHave?.length !== 0) {
        const keys = Object.keys(item[1])
        const values = Object.values(item[1])
        keys.map((ele, ind) => {
          obj.shouldHave = {
            ...obj.shouldHave,
            [ele]: values[ind].shouldHave
          }
          return obj
        })
      }
    }
    dispatch(setSearchApiObj(obj))
    return obj
  })

  if (searchApiVal) {
    dispatch(setIsLoading(true))
    try {
      const { data } = await discoverJobController.getCandidatesFromSearch({
        annotations: {
          ...obj,
          mustHave: { ...obj.mustHave, postCode: obj.mustHave.postCode, distance: +obj.mustHave.distance || 60 }
        }
      })
      dispatch(setSearch(data))
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(setIsLoading(false))
      setSearchApiVal(false)
    }
  }
}
