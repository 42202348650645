import { Select } from 'antd'
import styled from 'styled-components'

export const CustomSelect = styled(Select)`
.ant-select-arrow .anticon {
    margin-left: -4px !important;
}
/

.language_text:hover{
   /* color: #00bc70 !important; */
}

`