import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Input, Form, message } from 'antd'
import { Row, ForgetButton, FullWidthButton, LeftHeader } from './style'
import AuthLayout from '../../../layouts/AuthLayout'
import { useAuthActions } from '../../../contexts/AuthContext'
import { allConstants } from '../../../constants/constants'
import { useTranslation } from 'react-i18next'

const renderRightSideTitle = (t, allConstant) => (
  <>
    <Row>
      <LeftHeader>{t(allConstant.HELLO)}, </LeftHeader>
    </Row>
    <Row>
      <LeftHeader>{t(allConstant.WELCOME)}!</LeftHeader>
    </Row>
  </>
)

const Login = () => {
  const location = useLocation()
  const history = useHistory()
  const actions = useAuthActions()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const onFinish = async (values) => {
    setLoading(true)
    try {
      const data = await actions.loginUser(values)
      window.analytics.identify(data.extuid, {
        name: data.fullName,
        email: data.email,
        userId: data.extuid,
        segments: data.segments
      })

      if (location.pathname.includes('salesforce')) {
        const ref = location.search.split('?url=').join().split("'").join('').split(',').join('')
        const url = `${ref}?auth_token=${data.access_token}&ref=ai4jobs.me/salesforce/login&refresh_token=${data.refresh_token}`
        window.location.assign(url)
      } else {
        history.push('/homepage')
      }
    } catch (e) {
      message.warning(t(allConstants.EMAIL_PASSWORD_ERROR))
    } finally {
      setLoading(false)
    }
  }

  const handleForgetPasswordClick = () => {
    history.push('/forgot-password')
  }

  React.useEffect(() => window.zE('webWidget', 'show'), [])

  return (
    <AuthLayout right={renderRightSideTitle(t, allConstants)}>
      <Form onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t(allConstants.EMAIL_ERROR) }
          ]}
        >
          <Input placeholder={t(allConstants.EMAIL_ADDRESS)} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: t(allConstants.PASSWORD_ERROR) }]}
        >
          <Input.Password placeholder={t(allConstants.PASSWORD)} />
        </Form.Item>

        <Form.Item>
          <FullWidthButton loading={loading} type="primary" htmlType="submit">
            {t(allConstants.LOGIN)}
          </FullWidthButton>
        </Form.Item>
        <Row justify="center">
          <ForgetButton type="link" onClick={handleForgetPasswordClick}>
            {t(allConstants.FORGOT_PASSWORD)}
          </ForgetButton>
        </Row>
      </Form>
    </AuthLayout>
  )
}

export default Login
