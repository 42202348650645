import { BASE_URL } from '../../axios-config'
import Axios from 'axios'
/**
 * POST
 * /twg/password/change
 * doPasswordChange
 */
export const doPasswordChange = (password, clid) => {
  return Axios({
    method: 'post',
    url: '/twg/password/change',
    data: {
      clid,
      password
    },
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*'
    }
  })
}
/**
 * POST
 * /twg/password/reset
 * doPasswordReset
 */
export const doPasswordReset = (email) => {
  return Axios({
    method: 'post',
    url: '/twg/password/reset',
    data: {
      email: email
    },
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*'
    }
  })
}
