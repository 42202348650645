import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
const OptionExtra = ({ items }) => {
  const { t } = useTranslation()
  return <ul>
           {items.map(val => <li key={val}>{t(val)}</li>)}
         </ul>
}

OptionExtra.propTypes = {
  items: PropTypes.array
}

export default OptionExtra
