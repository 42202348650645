import React from 'react'
import PageHeaderLayoutWithHeader from '../../layouts/PageHeaderLayoutWithHeader'
import { useTranslation } from 'react-i18next'
import { WhatsNewWrapper } from './style'
import { allConstants } from '../../constants/constants'
import WhatsNewItem from './components/WhatsNewItem/WhatsNewItem'
import { items } from './data/data'

export const WhatsNew = () => {
  const { t } = useTranslation()
  return (
    <PageHeaderLayoutWithHeader title={t(allConstants.WHAT_NEW)}>
      <WhatsNewWrapper >
        {items.map((val) => (
          <WhatsNewItem key={val.id} itemContent={val} />
        ))}
      </WhatsNewWrapper>
    </PageHeaderLayoutWithHeader>
  )
}
