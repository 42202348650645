import React from 'react'
import { Card, Col, Row, Space } from 'antd'
import ValueCalculatorTable from './Components/ValueCalculatorTable'
import { PropTypes } from 'prop-types'
import { useAuthState } from '../../../contexts/AuthContext'

const ValueCalculator = React.forwardRef((props, ref) => {
  const { prospect, setProspect } = props
  const { user } = useAuthState()
  const { setPdfTableData, pdfTableData } = props
  const [formulas, setFormulas] = React.useState({})

  const [userInputValue, setUserInput] = React.useState({
    numberOfRecruitingPlatforms: 0,
    MonthlyCostOfRecruitngPlatform: 0,
    logInTimeMinutes: 0,
    numberOfJobs: 0,
    lookTimeMinutesPerJob: 0,
    numberOfCandidatesPerJob: 0,
    qualityTimeMinutesPerCandidate: 0,
    hoursPerWeek: 0,
    percentageTimeSpentSourcing: 0 / 100,
    jobPostSimilarityPercentage: 0 / 100,
    twgMonthlyCostOfRecruitngPlatform: 0,
    newHireCost: 0,
    startDate: '',
    endDate: '',
    numberOfDays: 0,
    timeSpentRankQualified: 0,
    startYear: 0
  })
  const [value] = React.useState({
    twgNumberOfRecruitingPlatforms: 8500,
    twgLookTimeMinutesPerJob: 1,
    twgQualityTimeMinutesPerCandidate: 0.1,
    // eslint-disable-next-line no-unused-vars
    twgHoursPerWeek: 0,
    twgParnterNetworkValues: 85000,
    twgCandidateJobLookTime: 0.1
  })

  const {
    numberOfRecruitingPlatforms,
    MonthlyCostOfRecruitngPlatform,
    logInTimeMinutes,
    numberOfJobs,
    lookTimeMinutesPerJob,
    numberOfCandidatesPerJob,
    // eslint-disable-next-line no-unused-vars
    qualityTimeMinutesPerCandidate,
    hoursPerWeek,
    percentageTimeSpentSourcing,
    twgMonthlyCostOfRecruitngPlatform,
    newHireCost,
    timeSpentRankQualified
  } = userInputValue

  const {
    twgQualityTimeMinutesPerCandidate,
    // eslint-disable-next-line no-unused-vars
    twgHoursPerWeek,
    twgParnterNetworkValues,
    twgCandidateJobLookTime
  } = value

  //  Total time to qualify all resumes (Minutes)
  // const candidateJobLookTime = jobPostSimilarityPercentage * numberOfJobs * qualityTimeMinutesPerCandidate * numberOfCandidatesPerJob
  //  Stack Rank Time across all Job Posts (Minutes)
  const candidateRankQualifiedResume = timeSpentRankQualified * numberOfJobs
  // user and twg formula for total time needed hours per week
  // const twgTotalTimeNeededHoursPerWeek = (logInTimeMinutes * numberOfJobs * twgLookTimeMinutesPerJob * numberOfCandidatesPerJob * twgQualityTimeMinutesPerCandidate) / 60
  const twgTotalTimeNeededHoursPerWeek = (logInTimeMinutes + numberOfJobs * (lookTimeMinutesPerJob + numberOfCandidatesPerJob * (twgQualityTimeMinutesPerCandidate + twgCandidateJobLookTime))) / 60
  // const userTotalTimeNeededHoursPerWeek = ((numberOfRecruitingPlatforms * logInTimeMinutes * numberOfJobs * lookTimeMinutesPerJob * numberOfCandidatesPerJob * qualityTimeMinutesPerCandidate) + candidateJobLookTime + candidateRankQualifiedResume) / 60
  // const userTotalTimeNeededHoursPerWeek = parseInt((jobPostSimilarityPercentage * numberOfJobs * timeSpentRankQualified * numberOfCandidatesPerJob).toFixed(2)) + parseInt(timeSpentRankQualified) + parseInt(qualityTimeMinutesPerCandidate * numberOfCandidatesPerJob) + parseInt(numberOfCandidatesPerJob * timeSpentRankQualified)
  const userTotalTimeNeededHoursPerWeek = numberOfRecruitingPlatforms * (logInTimeMinutes + numberOfJobs * (lookTimeMinutesPerJob + numberOfCandidatesPerJob * (qualityTimeMinutesPerCandidate + timeSpentRankQualified))) / 60
  // user and twg formula for hour difference
  const twgHoursDifference = (hoursPerWeek * percentageTimeSpentSourcing) - twgTotalTimeNeededHoursPerWeek
  const userHoursDifference = (hoursPerWeek * percentageTimeSpentSourcing) - userTotalTimeNeededHoursPerWeek

  // const newItem = logInTimeMinutes * (lookTimeMinutesPerJob + numberOfCandidatesPerJob * (qualityTimeMinutesPerCandidate + timeSpentRankQualified ()))

  // user and twg formula for cost to source candidates weekly
  const twgCostToSourceCandidatesWeekly = (newHireCost / 2) * twgTotalTimeNeededHoursPerWeek
  const userCostToSourceCandidatesWeekly = (newHireCost / 2)

  // Sourcing Value Captured formulas
  const equivalentWeeklyValueGainedFormula = userCostToSourceCandidatesWeekly - twgCostToSourceCandidatesWeekly
  const equivalentAnnualValueGainedFormula = equivalentWeeklyValueGainedFormula * 52
  const equivalentMonthValueGainedFormula = equivalentAnnualValueGainedFormula / 12
  // const equivalentDailyValueGainedFormula = equivalentWeeklyValueGainedFormula / 7
  // const valueGainedToDate = equivalentDailyValueGainedFormula * numberOfDays change sto startyear
  // const valueGainedToDate = equivalentDailyValueGainedFormula * startYear
  // const estimateValueGain = equivalentAnnualValueGainedFormula * userInputValue.startYear
  // Platform value captured formulas
  const monthlyPlatformCosts = numberOfRecruitingPlatforms * MonthlyCostOfRecruitngPlatform
  const annualPlatformCosts = monthlyPlatformCosts * 12
  const twgAnnualPlatformCosts = twgMonthlyCostOfRecruitngPlatform * 12
  const twgPortalValueCapturedAnnually = annualPlatformCosts - twgAnnualPlatformCosts
  const equivalentNetworkValue = twgParnterNetworkValues + twgPortalValueCapturedAnnually

  const twgTotalNeededRecruiters = Math.abs(Number(userHoursDifference) / (percentageTimeSpentSourcing * hoursPerWeek))?.toFixed(2)

  React.useEffect(() => {
    setUserInput({ ...userInputValue })
  }, [])
  React.useEffect(() => {
    setFormulas({
      twgTotalTimeNeededHoursPerWeek,
      userTotalTimeNeededHoursPerWeek,
      twgHoursDifference,
      userHoursDifference,
      twgCostToSourceCandidatesWeekly,
      userCostToSourceCandidatesWeekly,
      equivalentWeeklyValueGainedFormula,
      equivalentAnnualValueGainedFormula,
      equivalentMonthValueGainedFormula,
      monthlyPlatformCosts,
      annualPlatformCosts,
      twgAnnualPlatformCosts,
      equivalentNetworkValue,
      candidateRankQualifiedResume,
      twgTotalNeededRecruiters
    })
  }, [userInputValue])

  React.useEffect(() => {
    setTimeout(() => {
      setPdfTableData({ ...pdfTableData, userInputValue, formulas, value, user, prospect })
    }, '1000')
  }, [userInputValue, formulas, value, prospect])

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Card bordered={false} title={''}>
          <Space>
            <ValueCalculatorTable
              prospect={prospect}
              setProspect={setProspect}
              userInputValue={userInputValue}
              value={value}
              setUserInput={setUserInput}
              formulas={formulas}
              ref={ref}
            />
          </Space>
        </Card>
      </Col>
    </Row>
  )
})
ValueCalculator.propTypes = {
  prospect: PropTypes.object,
  setProspect: PropTypes.func,
  setPdfTableData: PropTypes.func,
  pdfTableData: PropTypes.object
}

export default ValueCalculator
