import React from 'react'
import { Divider, Layout, Typography } from 'antd'
// Style Sheet
import './modalBody.css'
import ModalHeader from './ModalHeader'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

export default function ShareModal () {
  const Body = () => {
    const { t } = useTranslation()
    return (
      <Typography className='modal-body' >
        <Text> {t('Select team members you would like to share with')}: </Text>
      </Typography>
    )
  }

  return (
    <Layout className='modal-layout' >
        <ModalHeader title="Share with your Team" />

        <Divider className='modal-divider' />

        <Body />
    </Layout>
  )
}
