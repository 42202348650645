import React from 'react'
import { Row, Typography, Space } from 'antd'
import { useQuerysDispatch, setAccountType } from '../../GreenhouseEstimatorContext'
import FiltersRadio from '../../../Candidates/SearchedCandidate/Filters/component/FiltersRadio'
// Style
import './GreenhouseAccountStyle.css'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

const { Title } = Typography

function AccountSelector () {
  const [value, setValue] = React.useState(null)
  const { t } = useTranslation()
  const dispatch = useQuerysDispatch()

  const onChange = e => {
    const VALUE = e.target.id
    setValue(VALUE)
    dispatch(setAccountType(VALUE))
  }
  return (
    <Row>
      <Space direction="vertical">
        <Title level={5}>{t(allConstants.ESTIMATE_FOR_JOB)}</Title>

        <Row>
          {/* <Radio.Group onChange={onChange} value={value}> */}
            <Space >
            <FiltersRadio
              isOpen={true}
              checked={value === 'singleJob'}
              onChange={onChange}
              dissabled={true}
              label={t(allConstants.SINGLE_JOB)}
              name="job"
              id="singleJob"
              classNames={'job-radio'}
              value={value}
            />
            {/* </Col>
            <Col> */}

             <FiltersRadio
              isOpen={true}
              checked={(value === 'fullAccount')}
              onChange={onChange}
              dissabled={true}
              label={t(allConstants.FULL_ACCOUNT)}
              name="job"
              id="fullAccount"
              classNames={'job-radio'}
              value={value}
            />
            </Space>

            {/* <Radio value={'singleJob'}>Single Job </Radio>
            <Radio value={'fullAccount'}>Full Account</Radio> */}
          {/* </Radio.Group> */}
        </Row>
      </Space>

    </Row>
  )
}

export default AccountSelector
