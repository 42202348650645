/* eslint-disable no-debugger */
// import dayjs from 'dayjs'
import moment from 'moment'
import { allConstants } from '../../constants/constants'
const { DAYS, TRANSACTION_REPORT, TOTAL_USAGE, TODAY, RESUME_REPORTS } = allConstants

export const reportTabs = (t) => [
  {
    tab: t(TRANSACTION_REPORT),
    key: 'transaction-report'
  },
  {
    tab: t(TOTAL_USAGE),
    key: 'usage-report'
  },
  {
    tab: t(RESUME_REPORTS),
    key: 'resume-report'
  }
]

export const defaultDate = (days) => {
  const date = new Date()
  const txDateFrom = moment(date)
    .subtract(days, 'days')
    ?.format()
    .substring(0, 19)
  const txDateTo = moment(date).format().substring(0, 19)

  return { txDateFrom, txDateTo }
}

export const dateOptions = (t) => [
  {
    value: 'today',
    label: t(TODAY)
  },
  {
    value: '7',
    label: `7 ${t(DAYS)}`
  },
  {
    value: '30',
    label: `30 ${t(DAYS)}`
  },
  {
    value: '60',
    label: `60 ${t(DAYS)}`
  }
]

export const getDateBeforeAndAfterRange = (dateVal) => {
  let startDate = moment()
  let endDate = moment()

  if (dateVal === 'today') {
    startDate = moment().startOf('day')
    endDate = moment().endOf('day')
  } else if (!isNaN(dateVal)) {
    startDate = startDate.subtract(Number(dateVal) + 1, 'day')
  } else if (dateVal === 'year') {
    startDate = startDate.subtract(1, 'year')
  }

  return { startDate, endDate }
}
