import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { getJobReq } from '../../../api-twg/discover'
import * as scoreApi from '../../../api-twg/score/score-controller'
import * as scoreApiV2 from '../../../api-twg/score/score-controller-v2'
import * as discoverApi from '../../../api-twg/discover/search-controller'
import { useRequest } from 'ahooks'
import { useAuthState } from '../../../contexts/AuthContext'
import ApplicantPage from '../ApplicantPage'
import { getFeatureFlags } from '../../../api-twg/twg/feature-flag-controller'
import { useJobsState } from '../../jobs/context/JobsContext'

/** action types */
export const CLEAR = 'CLEAR'
export const SET_DIRECT = 'SET_DIRECT'
export const SET_INDIRECT = 'SET_INDIRECT'
export const SET_QUALIFIED = 'SET_QUALIFIED'
export const SET_PASSIVE = 'SET_PASSIVE'
export const SET_FILTER = 'SET_FILTER'
export const SET_OPTIONS = 'SET_OPTIONS'
export const SET_CANDIDATES_IDS = 'SET_CANDIDATES_IDS'
export const DELETE_CANDIDATE = 'DELETE_CANDIDATE'
export const SET_LOADING = 'SET_LOADING'
export const SET_INDIRECT_ONLY = 'SET_INDIRECT_ONLY'
export const SET_V2 = 'SET_V2'
export const SET_PASSIVE_LOADING = 'SET_PASSIVE_LOADING'

/** action creators */
export const clear = () => ({ type: CLEAR })
export const setDirect = ({ list, resultSetId, loading }) => ({ type: SET_DIRECT, list, resultSetId, loading })
export const setIndirect = ({ list, resultSetId, loading }) => ({ type: SET_INDIRECT, list, resultSetId, loading })
export const setQualified = ({ list, resultSetId, loading }) => ({ type: SET_QUALIFIED, list, resultSetId, loading })
export const setPassive = ({ list, resultSetId, loading, stats }) => ({ type: SET_PASSIVE, list, resultSetId, loading, stats })
export const setFilter = (filter) => ({ type: SET_FILTER, filter })
export const setOptions = (options) => ({ type: SET_OPTIONS, options })
export const setSelectedCandidatesIds = (candidatesIds) => ({ type: SET_CANDIDATES_IDS, candidatesIds })
export const deleteCandidate = (id) => ({ type: DELETE_CANDIDATE, id })
export const setLoading = ({ section, loading }) => ({ type: SET_LOADING, section, loading })
export const setIndirectOnly = (indirectOnly) => ({ type: SET_INDIRECT_ONLY, indirectOnly })
export const setV2 = (v2) => ({ type: SET_V2, v2 })
export const setPassiveLoading = (passiveLoading) => ({ type: SET_PASSIVE_LOADING, passiveLoading })

/** initial value */
const initialState = {
  filter: 'direct',
  indirectOnly: false,
  options: { },
  candidatesIds: [],
  direct: {
    list: [],
    loading: false,
    map: {},
    current: {},
    resultSetId: null
  },
  indirect: {
    list: [],
    loading: false,
    map: {},
    current: {},
    resultSetId: null
  },
  qualified: {
    list: [],
    loading: false,
    map: {},
    current: {},
    resultSetId: null
  },
  passive: {
    list: [],
    loading: null,
    map: {},
    current: {},
    resultSetId: null,
    stats: {}
  },
  isV2: false,
  passiveLoading: false
}

const prepareItemWithId = (list) => (map, item, index) => {
  return {
    ...map,
    [item.id]: {
      metadata: {
        index,
        next: list[(index + 1) % list.length]?.id,
        previous: list[(index + list.length - 1) % list.length]?.id
      },
      ...item
    }
  }
}

/** reducer */
export function reducer (state, action) {
  switch (action.type) {
    case SET_DIRECT:
      return {
        ...state,
        direct: {
          list: action.list,
          resultSetId: action.resultSetId,
          loading: action.loading,
          map: action.list.reduce(prepareItemWithId(action.list), {})
        }
      }
    case SET_INDIRECT:
      return {
        ...state,
        indirect: {
          list: action.list,
          resultSetId: action.resultSetId,
          loading: action.loading,
          map: action.list.reduce(prepareItemWithId(action.list), {})
        }
      }
    case SET_QUALIFIED:
      return {
        ...state,
        qualified: {
          list: action.list,
          resultSetId: action.resultSetId,
          loading: action.loading,
          map: action.list.reduce(prepareItemWithId(action.list), {})
        }
      }
    case SET_PASSIVE:
      return {
        ...state,
        passive: {
          list: action.list,
          resultSetId: action.resultSetId,
          loading: action.loading,
          map: action.list?.reduce(prepareItemWithId(action.list), {}),
          stats: action.stats
        }
      }
    case SET_FILTER:
      return {
        ...state,
        filter: action.filter
      }
    case SET_INDIRECT_ONLY:
      return {
        ...state,
        indirectOnly: action.indirectOnly
      }
    case SET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          ...action.options
        }
      }
    case SET_CANDIDATES_IDS:
      return {
        ...state,
        candidatesIds: action.candidatesIds
      }
    case DELETE_CANDIDATE:
      return {
        ...state,
        [state.filter]: {
          ...state[state.filter],
          list: state[state.filter].list.filter(candidate => candidate.id !== action.id),
          map: {
            ...state[state.filter].map,
            [action.id]: null
          }
        }
      }
    case SET_LOADING:
      return {
        ...state,
        [action.section]: {
          ...state[action.section],
          loading: action.loading
        }
      }

    case SET_PASSIVE_LOADING:
      return {
        ...state,
        passiveLoading: action.passiveLoading
      }
    case CLEAR:
      return initialState
    case SET_V2:
      return {
        ...state,
        isV2: action.v2
      }
    default:
      return state
  }
}

const v2FetchDirectIndirectAndQualifiedRequisitions = (dispatch) => async (jobExtId, jobPostExtId) => {
  try {
    dispatch(setDirect({ list: [], loading: true }))
    dispatch(setIndirect({ list: [], loading: true }))
    dispatch(setQualified({ list: [], loading: true }))
    dispatch(setPassive({ list: [], loading: true }))

    const response = await scoreApiV2.searchByJobPosting(jobExtId, jobPostExtId)

    const setList = (field, actionCreator) => {
      const listSortedByScore = response?.data?.[field]?.results
        ?.sort((a, b) => b.score - a.score)
        ?.map((obj, rank) => ({ ...obj, rank, key: rank }))

      dispatch(actionCreator({
        list: listSortedByScore,
        resultSetId: response?.data?.[field]?.resultSetId,
        stats: response?.data?.[field]?.stats,
        loading: false
      }))
    }

    setList('direct', setDirect)
    setList('indirect', setIndirect)
    setList('passive', setPassive)
    setList('qualified', setQualified)
  } catch (error) {
    dispatch(setDirect({ list: [], resultSetId: null, loading: false }))
    dispatch(setIndirect({ list: [], resultSetId: null, loading: false }))
    dispatch(setQualified({ list: [], resultSetId: null, loading: false }))
    dispatch(setPassive({ list: [], resultSetId: null, loading: false }))
    throw error
  } finally {
    dispatch(setLoading({ section: 'direct', loading: false }))
    dispatch(setLoading({ section: 'indirect', loading: false }))
    dispatch(setLoading({ section: 'qualified', loading: false }))
    dispatch(setLoading({ section: 'passive', loading: false }))
  }
}

/** async actions */
const fetchDirectIndirectAndQualifiedRequisitions = (dispatch) => async (jobExtId, jobPostExtId) => {
  const flags = await getFeatureFlags()
  if (flags?.data?.features?.useScoreAlgoV2) {
    dispatch(setV2(true))
    return v2FetchDirectIndirectAndQualifiedRequisitions(dispatch)(jobExtId, jobPostExtId)
  }
  dispatch(setV2(false))
  return v1FetchDirectIndirectAndQualifiedRequisitions(dispatch)(jobExtId, jobPostExtId)
}

/** version 1 */
const v1FetchDirectIndirectAndQualifiedRequisitions = (dispatch) => async (jobExtId, jobPostExtId) => {
  try {
    dispatch(setDirect({ list: [], loading: true }))
    dispatch(setIndirect({ list: [], loading: true }))
    dispatch(setQualified({ list: [], loading: true }))

    /** direct */
    const responseDirect = await scoreApi.searchByJobPosting(jobExtId, jobPostExtId, { indirect: false, experimental: true })
    const directList = responseDirect?.data?.results?.map((obj, rank) => ({ ...obj, isDirect: true, rank }))
    const directListSortedByScore = directList
      .sort((a, b) => b.score - a.score)
      .map((obj, rank) => ({ ...obj, rank, key: rank }))
    dispatch(setDirect({ list: directListSortedByScore, resultSetId: responseDirect?.data?.resultSetId, loading: false }))

    /** indirect */
    const responseIndirect = await scoreApi.searchByJobPosting(jobExtId, jobPostExtId, { indirect: true, experimental: true })
    const indirectList = responseIndirect?.data.results
    const removeDuplicatesFromDirect = indirectList.filter(item => directList.every(obj => obj.id !== item.id))
    const indirectAndDirectListSortedByScore = [...removeDuplicatesFromDirect, ...directList]
      .sort((a, b) => b.score - a.score)
      .map((obj, rank) => ({ ...obj, rank, key: rank }))
    dispatch(setIndirect({ list: indirectAndDirectListSortedByScore, resultSetId: responseIndirect?.data.resultSetId, loading: false }))

    /** qualified */
    const qualifiedAndDirectListSortedByScore = indirectAndDirectListSortedByScore
      .filter(item => item.score >= 60)
    dispatch(setQualified({ list: qualifiedAndDirectListSortedByScore, resultSetId: responseIndirect?.data?.resultSetId, loading: false }))
  } catch (error) {
    dispatch(setDirect({ list: [], resultSetId: null, loading: false }))
    dispatch(setIndirect({ list: [], resultSetId: null, loading: false }))
    dispatch(setQualified({ list: [], resultSetId: null, loading: false }))
    throw error
  } finally {
    dispatch(setLoading({ section: 'direct', loading: false }))
    dispatch(setLoading({ section: 'indirect', loading: false }))
    dispatch(setLoading({ section: 'qualified', loading: false }))
  }
}

const fetchPassiveRequisitions = (dispatch) => async (jobExtId, params) => {
  const flags = await getFeatureFlags()
  if (!flags?.data?.features.useScoreAlgoV2) {
    try {
      if (!jobExtId) return
      dispatch(setPassive({ list: [], loading: true }))
      const { data: externalJobPostingData } = await scoreApi.getAllJobPostings(jobExtId)
      const externalJob = externalJobPostingData[0]
      const form = new FormData()
      form.append('text', externalJob?.content)
      form.append('searchName', externalJob?.title)
      form.append('workbookName', externalJob?.title)
      const { data } = await getJobReq(form, params) || {}
      dispatch(setPassive({ list: data.results, resultSetId: data.resultSetId, loading: false, stats: data.stats }))
    } catch (error) {
      dispatch(setPassive({ list: [], resultSetId: null, loading: false }))
      throw error
    } finally {
      dispatch(setLoading({ section: 'passive', loading: false }))
    }
  }
}

const searchByJobPosting = (dispatch) => async (jobExtId, jobPostExtId) => {
  try {
    dispatch(setPassive({ list: [], loading: true }))
    const response = await discoverApi.searchByJobPosting(jobExtId, jobPostExtId)
    const list = response?.data?.results || []
    const listSortedByScore = list
      .sort((a, b) => b.score - a.score)
      .map((obj, rank) => ({ ...obj, rank, key: rank }))
    dispatch(setPassive({ list: listSortedByScore, resultSetId: response?.data?.resultSetId, loading: false }))
  } catch (error) {
    dispatch(setPassive({ list: [], resultSetId: null, loading: false }))
    throw error
  } finally {
    dispatch(setLoading({ section: 'passive', loading: false }))
  }
}

const getCachedResults = (dispatch) => async (resultSetId, isV2Passive) => {
  try {
    if (!resultSetId) return Promise.reject(new Error('Need to pay again'))
    dispatch(setPassive({ list: [], loading: true }))
    let response
    if (isV2Passive) {
      dispatch(setPassiveLoading(true))
      try {
        response = await discoverApi.getV2PassiveCandidate(resultSetId)
      } catch (e) {
        console.log(e)
      } finally {
        dispatch(setPassiveLoading(false))
      }
    } else {
      response = await discoverApi.getCachedResults(resultSetId)
    }
    const list = response?.data?.results || []
    const listSortedByScore = list
      .sort((a, b) => b.score - a.score)
      .map((obj, rank) => ({ ...obj, rank, key: rank }))
    dispatch(setPassive({ list: listSortedByScore, resultSetId: response?.data?.resultSetId, loading: false }))
  } catch (error) {
    dispatch(setPassive({ list: [], resultSetId: null, loading: false }))
    throw error
  } finally {
    dispatch(setLoading({ section: 'passive', loading: false }))
  }
}

export const removeCandidate = (dispatch) => async (jobExtId, candidateExtId) => {
  await scoreApi.removeCandidate(jobExtId, candidateExtId)
  dispatch(deleteCandidate(candidateExtId))
}
/** contexts */
const JobRequisitionsActionsContext = React.createContext(undefined, undefined)
const JobRequisitionsStateContext = React.createContext(undefined, undefined)
const JobRequisitionsDispatchContext = React.createContext(undefined, undefined)

function ApplicantsContextProvider () {
  const { passive } = useJobsState()
  const { auth } = useAuthState()
  const { jobExtId, jobPostExtId } = useParams()
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [actions] = React.useState({
    fetchDirectIndirectAndQualifiedRequisitions: fetchDirectIndirectAndQualifiedRequisitions(dispatch),
    fetchPassiveRequisitions: fetchPassiveRequisitions(dispatch),
    searchByJobPosting: searchByJobPosting(dispatch),
    removeCandidate: removeCandidate(dispatch),
    getCachedResults: getCachedResults(dispatch)
  })

  useEffect(() => { !auth && dispatch(clear()) }, [auth])

  useEffect(() => { passive && dispatch(setFilter('passive')) }, [])

  useRequest(async () => auth && fetchDirectIndirectAndQualifiedRequisitions(dispatch)(jobExtId, jobPostExtId), { refreshDeps: [jobExtId, jobPostExtId, auth] })
  useRequest(async () => auth && fetchPassiveRequisitions(dispatch)(jobExtId, { preview: true }), { refreshDeps: [jobExtId, auth] })

  return (
    <JobRequisitionsActionsContext.Provider value={actions}>
      <JobRequisitionsStateContext.Provider value={state}>
        <JobRequisitionsDispatchContext.Provider value={dispatch}>
          <ApplicantPage />
        </JobRequisitionsDispatchContext.Provider>
      </JobRequisitionsStateContext.Provider>
    </JobRequisitionsActionsContext.Provider>
  )
}

/** hooks */
function useJobRequisitionsActions () {
  const context = React.useContext(JobRequisitionsActionsContext)
  if (context === undefined) {
    throw new Error('useJobRequisitionsActions must be used within a ApplicantsContextProvider')
  }
  return context
}

function useJobRequisitionsState () {
  const context = React.useContext(JobRequisitionsStateContext)
  if (context === undefined) {
    throw new Error('useJobRequisitionsState must be used within a ApplicantsContextProvider')
  }
  return context
}

function useJobRequisitionsDispatch () {
  const context = React.useContext(JobRequisitionsDispatchContext)
  if (context === undefined) {
    throw new Error('useJobRequisitionsDispatch must be used within a ApplicantsContextProvider')
  }
  return context
}

export { ApplicantsContextProvider, useJobRequisitionsActions, useJobRequisitionsState, useJobRequisitionsDispatch }
