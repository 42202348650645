/* eslint-disable multiline-ternary */
import React from 'react'
import PageHeaderLayoutWithHeader from '../../layouts/PageHeaderLayoutWithHeader'
import { Trans } from '@lingui/react'
import { Card, Col, Row, Spin } from 'antd'
import { FormWrapper } from './style'
import { useAuthState } from '../../contexts/AuthContext'
import NotAuthorized from './road-barrier.svg'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../constants/constants'
// import axios from 'axios'

const CircaNewCustomerPage = () => {
  const { t } = useTranslation()
  // const [inputVal, setInputVal] = React.useState({})
  const { user } = useAuthState()
  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   console.log(inputVal)
  //   axios.post('https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8', { ...inputVal })
  //     .then(data => console.log(data))
  // }
  // const handleOnChange = (e) => {
  //   const { name, value } = e.target
  //   setInputVal({ ...inputVal, [name]: value })
  // }

  return (
    <PageHeaderLayoutWithHeader
      title={
        <Trans id={t(allConstants.create_request)}>
          {t(allConstants.create_request)}
        </Trans>
      }
    >
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Spin tip={`${t(allConstants.LOADING)}...`} spinning={false}>
            <Card bordered={false} style={{ minHeight: 'calc(100vh - 280px)' }}>
              {user?.roles?.includes('deladmin')
                ? (
                <FormWrapper>
                  {/* <form onSubmit={handleSubmit}> */}
                  <form
                    style={{ width: '100%' }}
                    action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                    method="POST"
                  >
                    <input type="hidden" name="orgid" value="00Df4000004jyxP" />
                    {/* <input type='hidden' name="retURL" value="http://thiswayglobal.com"/> */}
                    <input
                      type="hidden"
                      name="retURL"
                      value={'https://thisway.dev-ai4jobs.com/scoreui/homepage'}
                    />

                    {/* <!--  ----------------------------------------------------------------------  -->
                <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
                <!--  these lines if you wish to test in debug mode.                          -->
                <!--  <input type="hidden" name="debug" value=1>                              -->
                <!--  <input type="hidden" name="debugEmail"                                  -->
                <!--  value="victoria@thiswayglobal.com">                                     -->
                <!--  ----------------------------------------------------------------------  --> */}
                    <Row>
                      <Col>
                        <span className="field-name">
                          {t(allConstants.CASE_TYPE)}:
                        </span>
                      </Col>
                      <Col>
                        <select
                          id="00N5G00000V9cli"
                          name="00N5G00000V9cli"
                          title="Case Type"
                        >
                          <option value="">--{t(allConstants.NONE)}--</option>
                          <option value="New Account">
                            {t(allConstants.NEW_ACCOUNT)}
                          </option>
                          <option value="Renewal">
                            {t(allConstants.RENEWAL)}
                          </option>
                          <option value="Upsell">
                            {t(allConstants.UPSELL)}
                          </option>
                        </select>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <span className="field-name">
                          {t(allConstants.company_name)}:
                        </span>
                      </Col>
                      <Col>
                        {' '}
                        <input
                          id="00N5G00000V9cln"
                          maxLength="255"
                          name="00N5G00000V9cln"
                          size="20"
                          type="text"
                          placeholder={t(allConstants.COMPANY_NAME)}
                        />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span className="field-name">
                          {t(allConstants.pricing_model)}:
                        </span>
                      </Col>
                      <Col>
                        <select
                          id="00N5G00000V9cls"
                          name="00N5G00000V9cls"
                          title={t(allConstants.PRICING_MODEL)}
                        >
                          <option value="">--{t(allConstants.NONE)}--</option>
                          <option value="Subscription">{t(allConstants.SUBSCRIPTION)}</option>

                          <option value="Consumption">
                            {t(allConstants.consumption)}
                          </option>
                        </select>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <span className="field-name">
                          {t(allConstants.contract_start_date)}:
                        </span>
                      </Col>
                      <Col>
                        <span className="dateInput dateOnlyInput">
                          <input
                            id="00N5G00000V9clx"
                            name="00N5G00000V9clx"
                            size="12"
                            type="text"
                            placeholder={t(
                              allConstants.ENTER_CONTRACT_START_DATE
                            )}
                          />
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <span className="field-name">
                          {t(allConstants.contract_end_date)}:
                        </span>
                      </Col>
                      <Col>
                        <span className="dateInput dateOnlyInput">
                          <input
                            id="00N5G00000V9cm2"
                            name="00N5G00000V9cm2"
                            size="12"
                            type="text"
                            placeholder={t(
                              allConstants.ENTER_CONTRACT_END_DATE
                            )}
                          />
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        {' '}
                        <span className="field-name">
                          {t(allConstants.discover_credit_amount)}:
                        </span>
                      </Col>
                      <Col>
                        <input
                          id="00N5G00000V9cm7"
                          name="00N5G00000V9cm7"
                          size="20"
                          type="text"
                          placeholder={t(
                            allConstants.ENTER_DISCOVER_CREDIT_AMOUNT
                          )}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <span className="field-name">
                          {t(allConstants.attract_credit_amount)}:
                        </span>
                      </Col>
                      <Col>
                        <input
                          id="00N5G00000V9cmC"
                          name="00N5G00000V9cmC"
                          size="20"
                          type="text"
                          placeholder={t(allConstants.ATTRACT_CREDIT_AMOUNT)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      {' '}
                      <Col>
                        <span className="field-name">
                          {t(allConstants.primary_contact)}:
                        </span>
                      </Col>
                      <Col>
                        <input
                          id="00N5G00000V9cmH"
                          maxLength="255"
                          name="00N5G00000V9cmH"
                          size="20"
                          type="text"
                          placeholder={t(allConstants.enter_primary_contact)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <span className="field-name">
                          {t(allConstants.primary_contact_email)}:
                        </span>
                      </Col>
                      <Col>
                        <input
                          id="00N5G00000V9cmM"
                          maxLength="80"
                          name="00N5G00000V9cmM"
                          size="20"
                          type="text"
                          placeholder={t(allConstants.ENTER_PRIMARY_EMAIL)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <span className="field-name">
                          {t(allConstants.circa_account_manager)}:
                        </span>
                      </Col>
                      <Col>
                        <input
                          id="00N5G00000V9cmR"
                          maxLength="255"
                          name="00N5G00000V9cmR"
                          size="20"
                          type="text"
                          placeholder={t(allConstants.ENTER_CIRCA_MANAGER)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col style={{ width: '475px' }}>
                        <div className="button-wrapper">
                          <button
                            className="submit-button"
                            type="submit"
                            name="submit"
                          >
                            {t(allConstants.SUBMIT)}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </FormWrapper>
                  ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '63vh'
                  }}
                >
                  <div>
                    <img src={NotAuthorized} height={150} width={150} />
                  </div>
                  <div>
                    <span
                      style={{
                        fontSize: '15px',
                        fontWeight: 500,
                        color: '#000'
                      }}
                    >
                      {t(allConstants.not_authorized)}
                    </span>
                  </div>
                </div>
                  )}
            </Card>
          </Spin>
        </Col>
      </Row>
    </PageHeaderLayoutWithHeader>
  )
}

export default CircaNewCustomerPage
