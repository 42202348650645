import { DatePicker, Radio, Row, Select, Col, Button, Form } from 'antd'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { useReportActions, useReportState } from '../../context/ReportContext'
import { dateOptions, defaultDate } from '../../utils'
import { handleCsvDownload } from '../../../../helper'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

const { RangePicker } = DatePicker
const { SUBMIT, EXPORT, RESET, STANDARD_DATE, CUSTOM_DATE, DAYS, START_DATE, END_DATE } = allConstants

function TransactionRange () {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [dateRange, setDateRange] = useState(defaultDate(30))
  const [dateSelector, setDateSelector] = useState('standard')
  const actions = useReportActions()
  const DownloadRef = useRef()
  const { transactionReport } = useReportState()
  const handleChange = (days) => {
    const date = new Date()
    const txDateFrom = moment(date)
      .subtract(days, 'days')
      ?.format()
      .substring(0, 19)
    const txDateTo = moment(date).format().substring(0, 19)

    setDateRange({ txDateFrom, txDateTo })
  }

  const customDateHandler = (val) => {
    const txDateFrom = val[0].format().substring(0, 19)
    const txDateTo = val[1].format().substring(0, 19)
    setDateRange({ txDateFrom, txDateTo })
  }

  const handleDateSelector = (val) => {
    setDateSelector(val)
  }
  const handleReset = () => {
    form.resetFields()
    actions.getTransactionReport(defaultDate(30))
  }
  const exportData = async () => {
    handleCsvDownload(
      transactionReport,
      DownloadRef,
      'Transaction Report.csv'
    )
  }
  const onFinish = () => {
    actions.getTransactionReport(dateRange)
  }
  return (
    <div style={{ paddingLeft: '10px' }}>
      <a href="#" ref={DownloadRef} />
      <Form
        onFinish={onFinish}
        form={form}
        initialValues={{ standard: `30 ${t(DAYS)}` }}
      >
        <Row gutter={[16, 8]}>
          <Col>
            <label style={{ cursor: 'pointer' }}>
              <Radio
                checked={dateSelector === 'standard'}
                value="standard"
                onChange={(e) => {
                  handleDateSelector(e.target.value)
                }}
              />{' '}
              {t(STANDARD_DATE)}
            </label>
            <div style={{ marginTop: '5px' }}>
              <Form.Item name="standard">
                <Select
                  size="large"
                  style={{
                    width: 255
                  }}
                  onChange={handleChange}
                  options={dateOptions(t)}
                  disabled={dateSelector === 'custom'}
                />
              </Form.Item>
            </div>
          </Col>
          <Col>
            <label style={{ cursor: 'pointer' }}>
              <Radio
                checked={dateSelector === 'custom'}
                value="custom"
                onChange={(e) => {
                  handleDateSelector(e.target.value)
                }}
              />
              {t(CUSTOM_DATE)}
            </label>
            <div style={{ marginTop: '5px' }}>
              <Form.Item name="custom">
                <RangePicker
                  size="large"
                  onChange={customDateHandler}
                  disabled={dateSelector === 'standard'}
                  placeholder={[t(START_DATE), t(END_DATE)]}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: '24px' }} gutter={8}>
          <Col>
            <Button htmlType="submit" type="primary">
              {t(SUBMIT)}
            </Button>
          </Col>
          <Col>
            <Button type="default" onClick={handleReset}>
              {t(RESET)}
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={exportData}>
              {t(EXPORT)}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default TransactionRange
