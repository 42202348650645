/* eslint-disable react/prop-types */
import { useLocalStorageState, useRequest } from 'ahooks'
import { Button, Modal } from 'antd'
import React from 'react'
import { useParams } from 'react-router'
import { useJobRequisitionsActions, useJobRequisitionsState } from '../context/ApplicantsContextProvider'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import './DeleteProfileLink.css'

const { confirm } = Modal

export default function DeleteProfileLink ({ profile, onAfterClick }) {
  const { jobExtId } = useParams()
  const actions = useJobRequisitionsActions()
  const state = useJobRequisitionsState()
  const [messagingPreference] = useLocalStorageState('messaging', true)

  const { filter } = state
  const { map } = state[filter]

  const { run } = useRequest(() => actions.removeCandidate(jobExtId, map[profile].externalId), { manual: true })

  const showConfirmDeleteModal = async () => {
    if (messagingPreference) {
      confirm({
        title: 'Are you sure you want to delete this candidate?',
        content: 'Once deleted, this candidate will no longer be available to match to any job. The deletion will take up to a minute.',
        okText: 'Yes',
        cancelText: 'No',
        icon: <ExclamationCircleOutlined />,
        onOk: async () => {
          await run()
          onAfterClick?.()
        },
        onCancel: () => {}
      })
    } else {
      await run()
    }
  }
  return (
    <Button className='deleteButton' size="small" onClick={showConfirmDeleteModal}><DeleteOutlined/></Button>
  )
}
