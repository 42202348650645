/* eslint-disable react/prop-types */
import React from 'react'
import { Popover, List, Tooltip, Space, Popconfirm } from 'antd'
import { DeleteOutlined, MoreOutlined, FileOutlined } from '@ant-design/icons'
import {
  useJobsActions,
  useJobsDispatch,
  useJobsState,
  setIsLoadingJobs
} from '../../../../jobs/context/JobsContext'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'

const JobDescActions = () => {
  const [pageAndSize] = React.useState({
    page: 1,
    size: 10,
    pageSizeOptions: ['10', '20', '30']
  })
  const { t } = useTranslation()
  const filters = {
    countries: [],
    locations: [],
    regions: []
  }
  const history = useHistory()
  const { jobExtId } = useParams()
  const actions = useJobsActions()
  const dispatch = useJobsDispatch()
  const { selectedJob, jobs } = useJobsState()

  const data = [
    {
      name: t(allConstants.EDIT),
      tooltip: t(allConstants.EDIT),
      icon: <FileOutlined />,
      onClick: () => {
        window.analytics.track('Edit job', {
          job_id: selectedJob?.requestId,
          job_title: selectedJob?.title?.toLowerCase(),
          location: selectedJob?.location?.toLowerCase(),
          applicants: selectedJob?.applicantCount,
          placement: 'Results page'
        })
        history.push(`/score/${jobExtId}/search/${jobExtId}/applicants/edit`)
      }
    },
    {
      name: t(allConstants.DeleteJob),
      confirm: t(allConstants.confirmDeleteOperation),
      tooltip: t(allConstants.DeleteJob),
      icon: <DeleteOutlined />,
      style: { color: 'red' },
      onClick: async () => {
        try {
          await actions.closeJob(jobExtId)
          window.analytics.track('Delete job', {
            job_id: jobs.requestId,
            job_title: jobs?.title?.toLowerCase(),
            location: jobs?.location?.toLowerCase(),
            applicants: jobs?.applicantCount,
            attract: jobs?.isAttractJob ? 'on' : 'off',
            placement: 'Small Menu Window'
          })
        } catch (e) {
          console.log(e)
        } finally {
          dispatch(setIsLoadingJobs(true))
          setTimeout(() => {
            actions.getOpenJobs({ ...filters }, pageAndSize.page - 1, 10)
            dispatch(setIsLoadingJobs(false))
            history.push('/score')
          }, '9000')
        }
      }
    }
  ]

  return (
    <Popover
      trigger="hover"
      placement="left"
      content={
        <List
          size="small"
          style={{ border: 'none', cursor: 'pointer' }}
          bordered
          split={false}
          dataSource={data}
          renderItem={(item) => item.confirm
            ? (
              <Popconfirm
                placement="left"
                title={item.confirm}
                onConfirm={item.onClick}
                okText={t(allConstants.YES)}
                cancelText={t(allConstants.NO)}
              >
                  <List.Item>
                    <Tooltip title={item.tooltip} placement="right">
                      <Space style={item.style}>
                        {item.icon}
                        {item.name}
                      </Space>
                    </Tooltip>
                  </List.Item>
                </Popconfirm>
              )
            : (
                <List.Item onClick={item.onClick}>
                  <Tooltip title={item.tooltip} placement="right">
                    <Space style={item.style}>
                      {item.icon}
                      {item.name}
                    </Space>
                  </Tooltip>
                </List.Item>
              )
          }
        />
      }
    >
      <MoreOutlined />
    </Popover>
  )
}

// JobDescActions.propTypes = {
//   job: PropTypes.shape({
//     requestId: PropTypes.number
//   })
// }

export default JobDescActions
