
import React from 'react'
// import { Row, Typography, Tooltip } from 'antd'
import PropTypes from 'prop-types'
// Style
import './ZendeskCards.css'
import { useTranslation } from 'react-i18next'
import { OptionContainer } from './style'
import { RightArrowSvg } from '../../../../../customIcons'

function ZendeskCard ({ title, description, onClick }) {
  const { t } = useTranslation()
  return (
          <OptionContainer onClick={onClick}>
            <div >
           <span className='card-title text-overflow' > {t(title)} </span>
           <span className='card_description text-overflow '> {t(description)} </span>
          </div>
          <div className='arrow'><RightArrowSvg/></div>
          </OptionContainer>
  )
}

ZendeskCard.propTypes = {
  styles: PropTypes.object,
  icons: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func
}

export default ZendeskCard
