import {
  Divider, Tooltip
} from 'antd'
import React from 'react'
import PropTypes from 'prop-types'
import {
  DescContainer, DescDrawersFields, JobDescriptionsDrawer
} from '../style'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../../constants/constants'

const JobDesc = ({ selectedJob, setEditDrawer, editDrawer }) => {
  const description = selectedJob?.descriptions?.[0]
  React.useEffect(() => { }, [selectedJob])
  const { t } = useTranslation()

  return (
    <JobDescriptionsDrawer style={{ padding: '0px 12px 0px 12px' }}>
        <div className='fields-container' style={!editDrawer ? { marginTop: '-16px' } : {}}>
        <DescDrawersFields style={{ marginRight: '35px' }}>
            <div className='field'>
            {t(allConstants.JOB_ID)}:
            </div>
            <div className={selectedJob?.name?.length >= 40 ? 'value id-ellipsis' : 'value'}>
            {selectedJob?.name?.length >= 75
              ? <Tooltip placement="topLeft" overlayInnerStyle={{ width: '400px' }} title={selectedJob?.name}>
                      {selectedJob?.name}
                    </Tooltip>
              : selectedJob?.name
                }
            </div>
        </DescDrawersFields>
        <DescDrawersFields>
            <div className='field'>
            {t(allConstants.JOB_LOCATION)}:
            </div>
            <div className='value'>
                {selectedJob?.location || t(allConstants.REMOTE)}
            </div>
        </DescDrawersFields>
        </div>
        <DescContainer>
            <div className='job-title'>
                <div className={selectedJob?.title.length >= 75 ? 'title-ellipsis' : ''}>
                  {selectedJob?.title.length >= 75
                    ? <Tooltip placement="topLeft" overlayInnerStyle={{ width: '400px' }} title={selectedJob?.title}>
                      {selectedJob?.title}
                    </Tooltip>
                    : selectedJob?.title
                }
                </div>
            </div>
            <Divider className='desc-divider'/>
            <div
              style={{ padding: '0px 16px', height: 'calc(100vh - 250px)', overflowY: 'auto' }}
              dangerouslySetInnerHTML={{ __html: description?.content }}
            />
        </DescContainer>
      </JobDescriptionsDrawer>
  )
}
JobDesc.propTypes = {
  editDrawer: PropTypes.bool,
  selectedJob: PropTypes.object,
  setEditDrawer: PropTypes.func
}
export default JobDesc