import React from 'react'
import { Layout, Typography, Form, Input } from 'antd'
import PropTypes from 'prop-types'
// Style Sheet
import './modalBody.css'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

const { Text } = Typography

export default function CreateModal ({ values, handleInputChange, handleSubmit }) {
  const { t } = useTranslation()
  return (
    <Layout className='edit-modal-layout' >

      <Form.Item>
        <div className='search-label-icon' >
          <label className='name-label' >
            <Typography>
              <Text>{t(allConstants.nameYourSearch)}</Text>
            </Typography>
          </label>
        </div>
        <Input
          type="text"
          // className="tag-input"
          name='name'
          value={values.name}
          onChange={handleInputChange}
          onPressEnter={() => handleSubmit()}
        />
      </Form.Item>

      </Layout>
  )
}

CreateModal.propTypes = {
  values: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}
