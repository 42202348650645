import { Card, Divider, Tooltip } from 'antd'
import React from 'react'
import { useJobsState } from '../../../jobs/context/JobsContext'
import JobDescActions from './Component/JobDescActions'
import './JobDescription.css'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

const JobDescription = () => {
  const [desc, setDesc] = React.useState(null)
  const [jobDetails, setJobDetails] = React.useState(null)
  const { selectedJob } = useJobsState()
  const { t } = useTranslation()
  const hanldeJobDesc = async () => {
    setDesc(selectedJob?.descriptions?.[0])
    setJobDetails(selectedJob)
  }
  React.useEffect(() => {
    hanldeJobDesc()
  }, [selectedJob])

  return (
    <Card className='job-desc-container'>
        <div className='job-desc-id-location-container'>
            <div className='job-desc-id-location'>
                <div className='job-id'>
                  <div className='job-desc-fields' >{t(allConstants.JOB_ID)}:</div>
                  {jobDetails?.name?.length > 20
                    ? <Tooltip title={jobDetails?.name}>
                        <div className='job-id-value'>{jobDetails?.name || '-'}</div>
                      </Tooltip>
                    : <div className='job-id-value'>{jobDetails?.name || '-'}</div>
                  }
                </div>
                <div className='job-id'>
                    <div className='job-desc-fields'>{t(allConstants.LOCATION)}:</div>
                    <div className='job-id-value'>{jobDetails?.location || t(allConstants.REMOTE)}</div>
                </div>
            </div>
            <JobDescActions/>
        </div>
        <Divider className='job-desc-divider'/>
        <div className='job-desc'>
            <span className='job-desc-fields'>{t(allConstants.JOB_DESCRIPTION)}:</span>
            <div className='job-desc-detail'>
            <div
              dangerouslySetInnerHTML={{ __html: desc?.content }}
            />
            </div>
        </div>
    </Card>
  )
}

export default JobDescription