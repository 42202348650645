import React from 'react'
import { useHistory } from 'react-router'
import { Typography } from 'antd'
import moment from 'moment'

// Context
import {
  useQuerysDispatch,
  setFields
} from '../CandidateContext/SearchQueryContext'
// Local Files
import { DateFormat } from '../../../helper/'
import ActionsDropdown from './ActionsDropdown'
// Style Sheet
import './SearchCandidates.css'
import { TitleDiv } from '../../jobs/components/JobRequisitionsSection/JobsTable/styles'
import { allConstants } from '../../../constants/constants'

const { Title } = Typography

export default function Queries (queries, t) {
  const handleLocationSorting = (a, b) => {
    if (!a || !b) return
    const cellDataA = JSON.parse(a?.searchJson)
    const cellDataB = JSON.parse(b?.searchJson)
    // if (!cellDataA?.mustHave?.location || !cellDataB?.mustHave?.location) return
    return cellDataA?.mustHave?.location?.localeCompare(cellDataB?.mustHave?.location)
  }
  const columns = [
    {
      title: t(allConstants.searchTitle),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      render: function NameAndDate (cell, row) {
        const history = useHistory()
        const dispatch = useQuerysDispatch()
        function goToSearchedCandidateScreen () {
          history.push(`/candidates/${row.jobKey}`)
          dispatch(setFields({ SelectedCandidate: row, SearchCandidateScreenToggle: false }))
        }

        return (
        <Typography >
          <TitleDiv title={cell} className='query-title-ellipsis'onClick={goToSearchedCandidateScreen}>
              {cell}
          </TitleDiv>
        </Typography>
        )
      }
    },
    {
      title: t(allConstants.dateCreated),
      dataIndex: 'createdAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['ascend', 'descend'],
      render: function dateCoulmn (date) {
        return <time dateTime={date} >{ DateFormat(date) }</time>
      }
    },
    // {
    //   title: 'Location',
    //   dataIndex: 'searchJson',
    //   key: 'searchJson',
    //   sorter: (a, b) => a.searchJson.localeCompare(b.searchJson),
    //   sortDirections: ['ascend', 'descend'],
    //   render: function locationColumn (cell) {
    //     if (!cell) return
    //     const cellData = JSON.parse(cell)
    //     if (!cellData?.mustHave?.location) return
    //     return (
    //     <Typography>
    //       <Title level={5} id='location' > { cellData?.mustHave?.location } </Title>
    //     </Typography>
    //     )
    //   }
    // },
    {
      title: t(allConstants.LOCATION),
      dataIndex: 'searchJson',
      key: 'searchJson',
      sorter: (a, b) => handleLocationSorting(a, b),
      sortDirections: ['ascend', 'descend'],
      render: function locationColumn (cell) {
        if (!cell) return
        const cellData = JSON.parse(cell)
        // if (!cellData?.mustHave?.location) return
        return (
        <Typography>
          <Title level={5} id='location' > { cellData?.mustHave?.location || t(allConstants.Remote)} </Title>
        </Typography>
        )
      }
    },
    {
      title: t(allConstants.manageSearch),
      dataIndex: 'actions',
      key: 'action',
      align: 'center',
      render: function RenderFunction (cell, row) {
        return (
          <ActionsDropdown row={row} />
        )
      }
    }
  ]

  const DataResource = () => {
    return queries
  }

  return {
    DataResource: DataResource(),
    columns
  }
}
