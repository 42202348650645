/* eslint-disable no-debugger */
import { Button, Card, Col, Row } from 'antd'
import React, { useState } from 'react'
import LanguageSelection from '../../../components/LanguageSelection/SelectLanguage'
import { languageFormatFunc } from '../../../helper'
import { allConstants } from '../../../constants/constants'
import { useTranslation } from 'react-i18next'
import { changeLanguage, useHomeDispatch, useHomeState } from '../../HomePage/context/HomeContext'

function ChangeLanguagePage () {
  const dispatch = useHomeDispatch()
  const homeState = useHomeState()
  const [isLanguageChange, setIsLanguageChange] = useState(false)
  const [language, setLanguage] = useState(homeState.language)
  const [loading, setLoading] = useState(false)
  const handleLanguageChange = (val) => {
    setLanguage(val)
  }
  const { t } = useTranslation()
  const handleSave = async () => {
    setLoading(true)
    const response = await changeLanguage(language, dispatch)
    if (response) {
      setIsLanguageChange(false)
    }
    setLoading(false)
  }
  const getLanguage = languageFormatFunc(homeState.language).language
  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Card title={t('Change Language')} bordered={false}>
          <Row gutter={32} align="middle">

            <Col >
              {isLanguageChange
                ? (
                <LanguageSelection
                  changeLanguage={handleLanguageChange}
                  selectedLanguage={language}
                />
                  )
                : (
                <h1 style={{ fontSize: '14px' }}>{getLanguage}</h1>
                  )}
            </Col>

            <Col>
              {isLanguageChange
                ? (
                <Row gutter={8}>
                  <Col>
                    {' '}
                    <Button
                      onClick={() => {
                        handleSave()
                      }}
                      style={{
                        borderColor: '#00b7c0',
                        color: 'white',
                        backgroundColor: '#00bc70',
                        borderRadius: '4px'
                      }}
                      loading={loading}
                    >
                      {t(allConstants.SAVE)}
                    </Button>
                  </Col>
                  <Col>
                    {' '}
                    <Button
                      onClick={() => {
                        setIsLanguageChange(false)
                        handleLanguageChange(homeState.language)
                      }}
                    >
                      {t(allConstants.CANCEL)}
                    </Button>
                  </Col>
                </Row>
                  )
                : (
                <Button
                  onClick={() => {
                    setIsLanguageChange(!isLanguageChange)
                  }}
                >
                  {t(allConstants.CHANGE_LANGUAGE)}
                </Button>
                  )}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}

export default ChangeLanguagePage
