import { Button, Row as AntRow } from 'antd'
import styled from 'styled-components'

export const Row = styled(AntRow)`
  padding-bottom: 1rem;
`
export const LeftHeader = styled.span`
  font-size: 3rem;
  font-weight: 600;
`
export const BackLoginPageButton = styled(Button)`
  border: none;
`
export const FullWidthButton = styled(Button)`
  width: 100%;
  background: #009faf;
  color: white;
  font-weight: bold;
  border-radius: 4px;
`
