import styled from 'styled-components'

export const ResumeReportStyle = styled.div`
padding:0;
background-color: white;
margin-top: 16px;
border-radius: 4px;



.ant-tabs-top > .ant-tabs-nav {
    margin: 0 !important;
    margin-top:-19px !important;
    padding: 16px;
    padding-top: 16px !important;
    padding-bottom: 0 !important;
  }
 
  & .ant-tabs-tab-btn {
    font-size: 16px;
    font-weight: 600;
    line-height: 2.5;
    color: rgb(162, 162, 162);
  }
  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.primary_color};
    font-weight: 600;
  }
  & .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.primary_color};
  }
  & .ant-tabs-top > .ant-tabs-nav::before{
   line-height: 46px;
    border: 0;
    border-bottom: 1px dotted #f0f0f0;
    box-shadow: none;
  }
`