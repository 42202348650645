/* eslint-disable no-debugger */
import React from 'react'
import { PropTypes } from 'prop-types'
import * as ReportApi from '../../../api-twg/score/report-controller'
import * as bankingControllerApi from '../../../api-twg/score/banking-controller'
export const REPORT_STATS_TYPE = 'REPORT_STATS_TYPE'
export const REPORT_STATS = 'REPORT_STATS'
export const STATS_FIELD = 'STATS_FIELD'
export const REPORT_LOADING = 'REPORT_LOADING'
export const TRANSACTION_REPORT = 'TRANSACTION_REPORT'
export const RESUME_REPORT = 'RESUME_REPORT'

/** action creators */
export const setReportStatsType = (reportStatsType) => ({
  type: REPORT_STATS_TYPE,
  reportStatsType
})
export const setReportStats = (reportStats) => ({
  type: REPORT_STATS,
  reportStats
})
export const setResumeReport = (reportData) => ({
  type: RESUME_REPORT,
  reportData
})
export const setStatsField = (statsField) => ({
  type: STATS_FIELD,
  statsField
})
export const setReportLoading = (reportLoading) => ({
  type: REPORT_LOADING,
  reportLoading
})

export const setTransactionReport = (transactionReport) => ({
  type: TRANSACTION_REPORT,
  transactionReport
})
/** async actions */

const getReportStatsType = (dispatch) => async () => {
  try {
    const { data } = await ReportApi.getReportStatsType()
    dispatch(setReportStatsType(data))
    if (data) {
      const array = []
      data.map((item) => array.push(item.code))
      dispatch(setStatsField(array))
    }
    return data
  } catch (e) {
    console.log(e)
  }
}

const getReportStats = (dispatch) => async (val) => {
  dispatch(setReportLoading(true))
  const obj = {
    filter: val
  }
  try {
    const { data } = await ReportApi.getReportStats(obj)
    dispatch(setReportStats(data))
    return data
  } catch (e) {
    console.log(e)
  } finally {
    setTimeout(() => {
      dispatch(setReportLoading(false))
    }, '1000')
  }
}
const getResumeReport = (dispatch) => async (payload) => {
  dispatch(setReportLoading(true))
  try {
    const { data } = await ReportApi.getResumeReport(payload)

    dispatch(setResumeReport(data.results))
    return data
  } catch (e) {
    console.log(e)
  } finally {
    setTimeout(() => {
      dispatch(setReportLoading(false))
    }, '1000')
  }
}
const getTransactionReport = (dispatch) => async (payload) => {
  try {
    dispatch(setReportLoading(true))
    const { data } = await bankingControllerApi.getTransactionUsageReport(
      payload
    )
    dispatch(setTransactionReport(data))
    return data
  } catch (e) {
    console.log(e)
  } finally {
    dispatch(setReportLoading(false))
  }
}
const initialState = {
  reportStatsType: null,
  reportStats: null,
  statsField: [],
  reportLoading: false,
  transactionReport: [],
  resumeReport: []
}

export function reducer (state, action) {
  switch (action.type) {
    case REPORT_STATS_TYPE:
      return {
        ...state,
        reportStatsType: action.reportStatsType
      }
    case REPORT_STATS:
      return {
        ...state,
        reportStats: action.reportStats
      }
    case RESUME_REPORT:
      return {
        ...state,
        resumeReport: action.reportData
      }
    case STATS_FIELD:
      return {
        ...state,
        statsField: action.statsField
      }

    case REPORT_LOADING:
      return {
        ...state,
        reportLoading: action.reportLoading
      }
    case TRANSACTION_REPORT:
      return {
        ...state,
        transactionReport: action.transactionReport
      }
    default:
      return state
  }
}

const ReportActionsContext = React.createContext(undefined, undefined)
const ReportStateContext = React.createContext(undefined, undefined)
const ReportDispatchContext = React.createContext(undefined, undefined)

function ReportContextProvider ({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [actions] = React.useState({
    getReportStatsType: getReportStatsType(dispatch),
    getReportStats: getReportStats(dispatch),
    getTransactionReport: getTransactionReport(dispatch),
    getResumeReport: getResumeReport(dispatch)
  })

  return (
    <ReportActionsContext.Provider value={actions}>
      <ReportStateContext.Provider value={state}>
        <ReportDispatchContext.Provider value={dispatch}>
          {children}
        </ReportDispatchContext.Provider>
      </ReportStateContext.Provider>
    </ReportActionsContext.Provider>
  )
}

function useReportActions () {
  const context = React.useContext(ReportActionsContext)
  if (context === undefined) {
    throw new Error(
      'useReportActions must be used within a ReportContextProvider'
    )
  }
  return context
}

function useReportState () {
  const context = React.useContext(ReportStateContext)
  if (context === undefined) {
    throw new Error(
      'useReportState must be used within a ReportContextProvider'
    )
  }
  return context
}

function useReportDispatch () {
  const context = React.useContext(ReportDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useReportDispatch must be used within a ReportContextProvider'
    )
  }
  return context
}

ReportContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export {
  ReportContextProvider,
  useReportActions,
  useReportState,
  useReportDispatch
}
