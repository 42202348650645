// import { useRequest } from 'ahooks'
import { Button } from 'antd'
import React from 'react'
import { useHistory } from 'react-router'
import { PropTypes } from 'prop-types'
import { useJobsDispatch, setPassive } from '../../../../context/JobsContext'
import { useRequest } from 'ahooks'
import { getAllJobPostings } from '../../../../../../api-twg/score/score-controller'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../../constants/constants'

const PassiveCandidateViewButton = ({ job }) => {
  const history = useHistory()
  const dispatch = useJobsDispatch()
  const { run } = useRequest(() => getAllJobPostings(job.requestId), { manual: true })
  const { t } = useTranslation()
  const handleViewResultClick = async () => {
    const { data } = await run()
    history.push(`/score/${job.requestId}/search/${data?.[0].externalJobPostingId}/applicants`)
    return dispatch(setPassive(true))
  }
  return <Button type="link" onClick={handleViewResultClick}>{t(allConstants.VIEW_RESULTS)}</Button>
}

PassiveCandidateViewButton.propTypes = {
  job: PropTypes.object
}

export default PassiveCandidateViewButton