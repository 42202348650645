import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import HeaderLayout from '../../layouts/HeaderLayout'
import JobRequisitionsSection from './components/JobRequisitionsSection/JobRequisitionsSection'
import RecentActivitySection from './components/RecentActivitySection/RecentActivitySection'
import CreateJobModal from './components/CreateJobModal/CreateJobModal'
import JobDrawer from './components/JobDrawer'
import { matchPath, Redirect, useHistory, useParams } from 'react-router'
import { PlusOutlined } from '@ant-design/icons'
import { ButtonsWrapper } from '../../components/theme-components'
import { useHomeActions } from '../HomePage/context/HomeContext'
import { allConstants } from '../../constants/constants'
import { useTranslation } from 'react-i18next'
// import { useJobsState } from './context/JobsContext'

const JobsPage = () => {
  const { jobExtId } = useParams()
  const { t } = useTranslation()
  // const { jobs } = useJobsState()

  const history = useHistory()
  const homeActions = useHomeActions()
  React.useEffect(() => {
    window.zE('webWidget', 'helpCenter:setSuggestions', { search: 'ticket' })
  }, [])
  useEffect(() => {
    homeActions.getShowPopupRun()
  }, [])
  // analytics.page('Active Jobs Page')

  const matchNew = matchPath(location.pathname, {
    path: '/score/:jobExtId',
    exact: true,
    strict: false
  })
  if (matchNew && jobExtId !== 'new') return <Redirect to={`/score/${jobExtId}/descriptions`} />

  function renderJobRequisitionsSection () {
    return (
      <Row>
        <Col span={24} flex="auto">
          <JobRequisitionsSection />
        </Col>
      </Row>
    )
  }

  function renderRecentActivitySection () {
    return (
      <Row>
        <Col span={24} flex="auto">
          <RecentActivitySection />
        </Col>
      </Row>
    )
  }

  const renderJobDrawer = () => <JobDrawer />

  const renderNewJobModal = () => <CreateJobModal />

  function renderCreateJobButton () {
    const handleCreateNewJobClick = () => {
      history.push('/score/new')
      window.analytics.track('Create New Job')
    }
    return (
      <ButtonsWrapper
        icon={<PlusOutlined />}
        onClick={handleCreateNewJobClick}
      >
        {t(allConstants.CREATE_NEW_JOB)}
      </ButtonsWrapper>
    )
  }

  return (
    <HeaderLayout title={t(allConstants.ACTIVE_JOBS)} extra={renderCreateJobButton()}>
      {/* <Button
        onClick={() => history.push('/score/export/download/51eb4872-f1df-4256-94d2-8552d133869c') }
      >Go to download      </Button> */}
      {renderJobRequisitionsSection()}
      {renderRecentActivitySection()}
      {renderJobDrawer()}
      {renderNewJobModal()}
    </HeaderLayout>
  )
}

export default JobsPage
