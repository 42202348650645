import React from 'react'
import { Row, Space, Spin } from 'antd'
import { useHistory } from 'react-router-dom'
import ZendeskCard from './ZendeskCard'
import { useZendeskActions, useZendesksState } from '../../../ZendeskContext'

function ZendeskCards () {
  const [loader, setLoader] = React.useState(true)
  const actions = useZendeskActions()
  const { categories } = useZendesksState()
  const history = useHistory()

  const Spinner = () => {
    return (
      <Space size="middle">
        <Spin size="large" />
      </Space>
    )
  }

  const settingCategory = (url, name) => {
    history.push(`/categories/${name}/${url}`)
  }

  React.useEffect(() => {
    try {
      actions.getZendeskAllCategories()
    } catch (e) {
      console.log(e)
    }
  }, [loader, actions])

  React.useEffect(() => {
    categories?.length !== 0 && setLoader(false)
  }, [categories])

  return (
    <>
      <Row className="zendesk-card-body">
        {loader
          ? (
          <Spinner />
            )
          : (
          <div
            className={`zendesk-card-container ${
              categories?.length > 4 ? 'categories_grow' : 'categories_default'
            }`}
          >
            {categories?.map((item, index) => {
              return (
                <ZendeskCard
                  key={item.id}
                  title={item.name}
                  description={item.description}
                  url={item.sectionId}
                  onClick={() => settingCategory(item.sectionId, item.name)}
                />
              )
            })}
          </div>
            )}
      </Row>
    </>
  )
}

export default ZendeskCards
