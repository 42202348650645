import { normalizeAxiosResponse, sortByName } from '../../utils'
import { job, jobRequisition } from './models'
import n from 'normalize-data'
// import {
//   normalizeJobFromApi, getJobFromApi, getJobPostingFromApi, normalizeJobPostingFromApi
// } from './normalize'

export const normalizeDoExport = (response) => response

export const normalizeCreateJob = (response) => {
  return normalizeAxiosResponse(response)(job)
}
export const normalizeGetJob = (response) => {
  return normalizeAxiosResponse(response)(job)
}
export const normalizeUpdateJob = (response) => {
  return normalizeAxiosResponse(response)(job)
}
export const normalizeCloseJob = (response) => {
  return normalizeAxiosResponse(response)(job)
}
export const normalizeUpdateCandidateWithCv = (response) => {
  const normalizeCandidateWithCv = (data) => {
    return n.normalize(data, [
      ['_t', '_t'], // "string",
      ['status', 'status'], // "string",
      ['trackingId', 'trackingId'], // "string",
      ['trackingIds', 'trackingIds'] // ["string"]
    ])
  }
  return normalizeAxiosResponse(response)(normalizeCandidateWithCv)
}
export const normalizeCreateCandidatesWithCv = (response) => {
  const normalizeCandidateWithCv = (data) => {
    return n.normalize(data, [
      ['_t', '_t'], // "string",
      ['status', 'status'], // "string",
      ['trackingId', 'trackingId'], // "string",
      ['trackingIds', 'trackingIds'] // ["string"]
    ])
  }
  return normalizeAxiosResponse(response)(normalizeCandidateWithCv)
}

const filterAndSort = (data) => {
  const ifNull = string => string
  return data.filter(ifNull).sort(sortByName)
}
export const normalizeGetAllCountries = (response) => {
  return normalizeAxiosResponse(response)(filterAndSort)
}
export const normalizeGetAllCountriesForRegions = (response) => {
  return normalizeAxiosResponse(response)(filterAndSort)
}
export const normalizeGetAllLocations = (response) => {
  return normalizeAxiosResponse(response)(filterAndSort)
}
export const normalizeGetAllLocationsForRegionsAndCountries = (response) => {
  return normalizeAxiosResponse(response)(filterAndSort)
}
export const normalizeGetAllRegions = (response) => {
  return normalizeAxiosResponse(response)(filterAndSort)
}
const normalizeSearch = (data) => {
  return n.normalize(data, [
    ['results', 'results', array => array.map(jobRequisition)] // [jobRequisition],
  ], true)
}
export const normalizeSearchByJobDescription = (response) => {
  return normalizeAxiosResponse(response)(normalizeSearch)
}
export const normalizeSearchByQueryRequest = (response) => {
  return normalizeAxiosResponse(response)(normalizeSearch)
}
export const normalizeSearchByJobPosting = (response) => {
  return normalizeAxiosResponse(response)(normalizeSearch)
}
export const normalizeSearchByQueryRequestNoPosting = (response) => {
  return normalizeAxiosResponse(response)(normalizeSearch)
}
export const normalizeSearchByQueryRequestNoJobNoPosting = (response) => {
  return normalizeAxiosResponse(response)(normalizeSearch)
}
