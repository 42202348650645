import React, { useState } from 'react'
import { WhatsNewItemWrapper } from './style'
import { PropTypes } from 'prop-types'
import FirstTimeUser from '../../../jobs/components/JobRequisitionsSection/JobsTable/components/FirstTimeUserModals'
function WhatsNewItem ({ itemContent }) {
  const [popUpValue, setPopupValue] = useState(false)
  const {
    title,
    content,
    thumbnail,
    newPost,
    WhatsNewSlidesitem
  } = itemContent

  return (
    <>
      <WhatsNewItemWrapper>
        {thumbnail && (
          <div onClick={() => setPopupValue(true)} className="video">
            <img src={thumbnail} />
          </div>
        )}
        <div onClick={() => setPopupValue(true)}>
          <h2 className="title">
            {title}
            <span className={`${newPost ? 'show_new' : 'hide_new'}`}>New</span>
          </h2>
          <p className="content">{content}</p>
        </div>
      </WhatsNewItemWrapper>
      <FirstTimeUser
        popUpValue={popUpValue}
        setPopUpValue={setPopupValue}
        closable={true}
        otherSlidesItems={WhatsNewSlidesitem}
      />
    </>
  )
}

WhatsNewItem.propTypes = {
  itemContent: PropTypes.object
}
export default WhatsNewItem
