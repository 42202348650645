/* eslint-disable no-debugger */
import React, { useEffect } from 'react'
import { TabWrapperStyle } from '../style'
import {
  useReportActions,
  useReportState
} from '../../../context/ReportContext'
import { Spin, Table } from 'antd'
import { ingestReportColumn } from '../../table-utils/column'
import RangeComponent from '../../RangeComponent'
import { defaultDate } from '../../../utils'
import { useTranslation } from 'react-i18next'

function IngestReport () {
  const action = useReportActions()
  const { resumeReport, reportLoading } = useReportState()
  const { txDateFrom: startDate, txDateTo: endDate } = defaultDate('1')
  const { t } = useTranslation()
  useEffect(() => {
    action.getResumeReport({
      startDate,
      endDate,
      sortColumn: 'NAME',
      sortDirection: 'ASC'
    })
  }, [])
  return (
    <TabWrapperStyle>
      <RangeComponent exportFileName="Resume Ingest Report" />
      <Spin spinning={reportLoading}>
        <Table
          rowKey="resumeFilename"
          columns={ingestReportColumn(t)}
          dataSource={resumeReport.filter(val => !val.parseFailure)}
        />
      </Spin>
    </TabWrapperStyle>
  )
}

export default IngestReport
