import React from 'react'
import { Form, Input, Col, Row, Radio, Tooltip } from 'antd'
import './index.css'
import { RichEditor } from '../../../../components/RichEditor'
import { InfoCircleOutlined } from '@ant-design/icons'
import LightBulb from '../../../../assets/lightbulb.png'
import { allConstants } from '../../../../constants/constants'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line react/prop-types

const CreateJobForm = () => {
  const [radioValue, setRadioValue] = React.useState('On Site')
  const { t } = useTranslation()
  return (
    <>
      <Row gutter={[16, 4]}>
        <Col span={12}>
          <Form.Item label={t(allConstants.JOB_TITLE)} name="title" rules={[{ required: true }]}>
            <Input size="large" placeholder={t(allConstants.software_engineer)} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t(allConstants.JOB_ID)} name="name" >
            <Input size="large" placeholder={`${t(allConstants.TYPE_HERE)}...`} />
          </Form.Item>
        </Col>

        <Col span={18}>
          <Form.Item
          style={{ marginBottom: '10px' }}
          requiredMark={false}
          label={t(allConstants.JOB_LOCATION)}
          rules={[{ required: true }]}>
            <Radio
              value='On Site'
              checked={radioValue === 'On Site'}
              onChange={(e) => setRadioValue(e.target.value)}
            >

              {t(allConstants.ON_SITE)}
            </Radio>
            <Radio
              value='Remote Jobs'
              checked={radioValue === 'Remote Jobs'}
              onChange={(e) => setRadioValue(e.target.value)}
            >

              {t(allConstants.REMOTE)}
              <Tooltip
              overlayInnerStyle={{ width: '300px' }}
              title={t(allConstants.SORT_NOT_WORK_WARNING)}>
                <InfoCircleOutlined
                style={{ marginLeft: '5px', color: ' rgba(0, 0, 0, 0.85)' }}/>
              </Tooltip>
            </Radio>
          </Form.Item>
        </Col>

        {radioValue === 'On Site' &&
        <>
          <Col span={9}>
            <Form.Item label={t(allConstants.CITY)} name="city" rules={[{ required: true }]}>
              <Input size="large" placeholder="San Diego" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t(allConstants.STATE)} name="state" rules={[{ required: true }]}>
              <Input size="large" placeholder="CA" maxLength="2" />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item label={t(allConstants.COUNTRY)} name="country" rules={[{ required: true }]}>
              <Input size="large" placeholder="United States" />
            </Form.Item>
          </Col>
        </>
        }

        <Col span={24}>
          <Form.Item label={t(allConstants.JOB_DESCRIPTION)} name="content" rules={[{ required: true }]}
            help={t(allConstants.JOB_DESCRIPTION_TO_RANK)}
          >
             <RichEditor />
          </Form.Item>
        </Col>
      </Row>

      <Row className={'create-job-warning'} gutter={4}>
        <Col span={2}>
          <img src={LightBulb} className={'create-job-warning-img'}/>
        </Col>
        <Col span={22}>
          <span className={'create-job-warning-text'}>{t(allConstants.CREATING_JOB_ATS_SYSTEM_MESSAGE)}</span>
        </Col>
      </Row>
    </>
  )
}

export default CreateJobForm
