import React from 'react'
import { ModalForAboutUs } from './style'
import { Button } from 'antd'
import { useTheme } from 'styled-components'
import {
  setAboutUsModalVal,
  useAuthActions,
  useAuthDispatch,
  useAuthState
} from '../../contexts/AuthContext'
import moment from 'moment'
import { allConstants } from '../../constants/constants'
import { useTranslation } from 'react-i18next'

const AboutUsModal = () => {
  const theme = useTheme()
  const [tillDate] = React.useState(new Date())
  const { aboutUsModalVal, releaseData, auth } = useAuthState()
  const dispatch = useAuthDispatch()
  const action = useAuthActions()
  const { t } = useTranslation()

  React.useEffect(() => {
    auth && action.getReleaseData()
  }, [auth]); // eslint-disable-line

  return (
    <ModalForAboutUs
      visible={aboutUsModalVal}
      closable={false}
      onCancel={() => dispatch(setAboutUsModalVal(false))}
      footer={
        <Button key={1} onClick={() => dispatch(setAboutUsModalVal(false))}>
          {t(allConstants.CLOSE)}
        </Button>
      }
    >
      <div>
        <img
          src={theme.logo || 'https://score.ai4jobs.info/logo.png'}
          style={{ width: 200, height: 51, objectFit: 'contain' }}
          alt="logo"
        />
      </div>
      <div>
        <p>{t(allConstants.ABOUT_US_MODAL)}</p>
      </div>
      <div className="version-container">
        <div className="version-details">
          <div>
            <span> {t(allConstants.VERSION)}: </span>
            <span className="value">{releaseData?.versionId?.slice(0, 5)}</span>
          </div>
          <div style={{ marginTop: '8px' }}>
            <span> {t(allConstants.RELEASE_DATE)}: </span>
            <span className="value">
              {moment(releaseData?.versionId?.slice(6, 14)).format(
                'MMMM DD, YYYY'
              ) || '-'}
            </span>
          </div>
        </div>
      </div>
      <div>
        <p className="footer-text">
          © 2013 - {tillDate.getFullYear()} ThisWay Global Inc.{' '}
          {t(allConstants.RIGHTS_RESERVED)}
        </p>
      </div>
    </ModalForAboutUs>
  )
}

export default AboutUsModal
