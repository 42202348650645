export const getPreviewFiles = fileList => {
  const previewFiles = []
  for (let index = 0; index < fileList.length; index++) {
    const element = fileList[index]
    let preview
    if ((element.type === 'image/jpeg') || (element.type === 'image/png') || (element.type === 'image/svg+xml')) {
      preview = URL.createObjectURL(element)
    } else {
      preview = { name: element.name }
    }
    previewFiles.push(preview)
  }
  return previewFiles
}
