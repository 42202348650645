import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router'
import { BrowserRouter, Redirect } from 'react-router-dom'
import AppProviders from './contexts/AppProviders'
import LoginPage from './pages/auth/login/LoginPage'
import ResetPasswordPage from './pages/auth/reset-password/ResetPasswordPage'
import ForgotPasswordPage from './pages/auth/forgot-password/ForgotPasswordPage'
import PrivateRoute from './components/PrivateRoute'
import { ThemeContainer } from './components/theme-components'
import { JobsContextProvider } from './pages/jobs/context/JobsContext'
import KeycloakErrorPage from './pages/auth/token/error/KeycloakErrorPage'
import KeycloakSuccessPage from './pages/auth/token/sucess/KeycloakSuccessPage'
import ExportDownloadPage from './pages/ExportDownloadPage/ExportDownloadPage/ExportDownloadPage'
import SettingsPage from './pages/settings/SettingsPage'
import { CandidatesContextProvider } from './pages/Candidates/CandidateContext/SearchQueryContext'
import { GreenhouseEstimatorContextProvider } from './pages/GreenhouseEstimator/GreenhouseEstimatorContext'
import { ZendseskContextProvider } from './pages/Zendesk/ZendeskContext'
import { Report } from './pages/ReportsPage/reports'
import { WhatsNew } from './pages/whats-new/whatsNewPage'
import { ValueCalculatorPage } from './pages/ValueCalculatorPage/valueCalculatorPage'
import { HomeContextProvider } from './pages/HomePage/context/HomeContext'
import { ReportContextProvider } from './pages/ReportsPage/context/ReportContext'
import CircaNewCustomerPage from './pages/CircaNewCustomerPage/CircaNewCustomerPage'
import AboutUsModal from './components/AboutUsModal/AboutUsModal'

const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    window.analytics.page(location.pathname)
  }, [location])
}

function App () {
  usePageTracking()
  return (
    <HomeContextProvider>
        <AppProviders>
          <ReportContextProvider>
            <ThemeContainer>
              <Switch>
                <Route
                  exact
                  path={['/', '/salesforce/login', '/index.html']}
                  component={LoginPage}
                  strict={false}
                />
                <Route
                  exact
                  path="/token/error"
                  component={KeycloakErrorPage}
                  strict={false}
                />
                <Route
                  exact
                  path="/token/success/:token"
                  component={KeycloakSuccessPage}
                  strict={false}
                />
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPasswordPage}
                  strict={false}
                />
                <Route
                  exact
                  path="/twg/password/reset/:clid"
                  component={ResetPasswordPage}
                  strict={false}
                />
                <Route
                  exact
                  path="/score/export/download/:ticketId?"
                  component={ExportDownloadPage}
                  strict={false}
                />
                <PrivateRoute
                  path="/settings"
                  component={SettingsPage}
                  strict={false}
                />
                <PrivateRoute
                  path={['/reports', '/reports/transaction-report', '/reports/usage-report', '/reports/resume-report']}
                  component={Report}
                  strict={false}
                />
                <PrivateRoute
                  path="/newcircacustomer"
                  component={CircaNewCustomerPage}
                  strict={false}
                />
                <PrivateRoute
                  path="/whats-new"
                  component={WhatsNew}
                  strict={false}
                />
                <PrivateRoute
                  path="/valuecalculator"
                  component={ValueCalculatorPage}
                  strict={false}
                />
                <PrivateRoute
                  exact
                  path={[
                    '/homepage',
                    '/score/:jobExtId?/:descriptions?/:descriptionId?/:edit?',
                    '/score/:jobExtId/search/:jobPostExtId/applicants/:applicantId?',
                    '/score/:jobExtId/search/:jobPostExtId/applicants/new/descriptions',
                    '/score/:jobExtId/search/:jobPostExtId/applicants/:descriptionId/edit'
                  ]}
                  strict={false}
                  component={JobsContextProvider}
                />
                <PrivateRoute
                  exact
                  path={[
                    '/candidates',
                    '/candidates/:jobkey',
                    '/simplesearch/:title/:skills/:location'
                  ]}
                  strict={false}
                  component={CandidatesContextProvider}
                />
                <PrivateRoute
                  exact
                  path={['/greenhouseEstimator', '/thirtyMinutes']}
                  strict={false}
                  component={GreenhouseEstimatorContextProvider}
                />
                <PrivateRoute
                  exact
                  path={['/greenhouseEstimator', '/thirtyMinutes']}
                  strict={false}
                  component={GreenhouseEstimatorContextProvider}
                />
                <PrivateRoute
                  exact
                  path={[
                    '/help-center',
                    '/price-plan',
                    '/security-privacy',
                    '/tickets',
                    '/ticket-preview/:id',
                    '/categories/:name/:id',
                    '/:name/article/:id',
                    '/article/search/:id'
                  ]}
                  strict={false}
                  component={ZendseskContextProvider}
                />
                <Redirect to={'/'} />
              </Switch>
              <AboutUsModal />
            </ThemeContainer>
          </ReportContextProvider>
        </AppProviders>
    </HomeContextProvider>
  )
}

// eslint-disable-next-line react/display-name
export default () => (
  <BrowserRouter basename="/scoreui">
    <App />
  </BrowserRouter>
)
