import React from 'react'
import { Col, Row, Typography, Image } from 'antd'
import { useZendesksState } from '../../../ZendeskContext'
import { useAuthState } from '../../../../../contexts/AuthContext'
import { DateTime } from '../../../../../helper'
import FileIcon from '../../../Assets/FileIcon.png'
// Styles
import './PreviewMessage.css'
import { DownloadOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

function PreviewMessage () {
  const { ticketComments, isLoading } = useZendesksState()
  const { user } = useAuthState()

  if (isLoading) return <p>Loading...</p>
  const nameparts = user?.fullName?.split(' ')

  const downloadImage = async (e, imgUrl) => {
    const originalImage = `${imgUrl}`
    const image = await fetch(`${imgUrl}`)

    const nameSplit = originalImage.split('/')
    const duplicateName = nameSplit.pop().split('=')[1]

    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
    const link = document.createElement('a')
    link.href = imageURL
    link.download = '' + duplicateName + ''
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  return (
        <Row className='preview-message-wrapper' >
            {ticketComments?.length > 0 &&
                ticketComments.map((comment) => {
                  return (
                        <Row className='preview-message-body' key={comment.id} >
                            <Text className='user-icon' >
                                {nameparts && nameparts.map((name, index) => {
                                  return (
                                        <span key={name}>
                                            {name.charAt(0)}
                                        </span>
                                  )
                                })}
                            </Text>

                            <Col className='message-body-content' >
                                <Row className='preview-message-header' >
                                    <Title className='preview-message-user' >{user?.fullName}</Title>
                                    <Text className='preview-message-date'> {DateTime(comment.created_at)}  </Text>
                                </Row>

                                <Row className='comment-body'>
                                    <Text >{comment?.body}  </Text>

                                    <Row className='preview-attachement' >
                                        {comment?.attachments?.length > 0 &&
                                            comment?.attachments.map((attachment) => {
                                              const fileType = attachment?.fileName?.split('.')[attachment?.fileName?.split('.').length - 1]
                                              const isNotPreview = (fileType !== 'jpg') && (fileType !== 'png')
                                              return isNotPreview
                                                ? (
                                                        <Row key={attachment?.id} className='preview-image-container' style={{ width: 'auto' }} >
                                                            <Row className='general-file' >
                                                                <div className='img-download-container'>
                                                                    <div>
                                                                        <Image
                                                                            onClick={() => window.open(attachment.contentUrl)}
                                                                            key={attachment?.id}
                                                                            src={FileIcon}
                                                                            className='preview-image'
                                                                            preview={false}
                                                                        />
                                                                    </div>
                                                                    <a
                                                                        className='img-downloader'
                                                                        onClick={(e) => downloadImage(e, attachment.contentUrl)}
                                                                        >
                                                                        <DownloadOutlined />
                                                                    </a>
                                                                </div>
                                                                <div className='file-container'>
                                                                    <div><span className='file-name' > {attachment.fileName} </span></div>
                                                                </div>
                                                            </Row>
                                                        </Row>

                                                  )
                                                : (
                                                        <div key={attachment?.id} className='img-download-container'>
                                                            <div>
                                                            <Image
                                                                key={attachment?.id}
                                                                src={attachment.contentUrl}
                                                                className='preview-image'
                                                            />
                                                            </div>
                                                            <a
                                                                className='img-downloader'
                                                                onClick={(e) => downloadImage(e, attachment.contentUrl)}
                                                            >
                                                                <DownloadOutlined />
                                                            </a>
                                                        </div>
                                                  )
                                            })
                                        }
                                    </Row>
                                </Row>
                            </Col>
                        </Row>
                  )
                })
            }
        </Row >
  )
}

export default PreviewMessage
