
import axios from '../../axios-config'

/**
 * GET
 * /twg/api/v1/feature
 * getFeatureFlags
 */
export const getFeatureFlags = () => {
  return axios.get('/twg/api/v1/feature')
}
