import React, { useState } from 'react'
import { Col, Input, Form, Layout, Row, Card, Button, Spin } from 'antd'
import HeaderLayout from '../../../layouts/HeaderLayout'
// Context
import { useQueryActions } from '../GreenhouseEstimatorContext'
import { useThemeActions, setGreenhouseLoading, useThemeState, useThemeDispatch } from '../../../contexts/WhiteLabelContext'
// Style
import './thirtyMinutes.css'
import { t } from 'i18next'
import { allConstants } from '../../../constants/constants'

// const { Title } = Typography

function ThirtyMinutes () {
  const [values, setValues] = useState({
    atsApiKey: '',
    atsOnBehalfOf: '',
    jobId: '',
    name: 'GHIO',
    atsType: 'GHIO'

  })

  // const { isLoading } = useQuerysState()
  const globalActions = useThemeActions()
  const actions = useQueryActions()
  const globalDispatch = useThemeDispatch()
  const { greenhouseLoading } = useThemeState()

  const onChange = e => setValues({ ...values, [e.target.name]: e.target.value })

  const handleSubmit = async () => {
    try {
      globalDispatch(setGreenhouseLoading(true))
      const res = await actions.startSingleJobBackFill(values)
      const { status: { clientKey, trackingId } } = res
      if (!res.status) throw Error('Api Failed')
      const minutesToAdd = 24
      const currentDate = new Date()
      const futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000)
      await globalActions.getGreenhousePollResult(clientKey, trackingId, futureDate)
    } catch (err) {
      console.log(err.message)
      globalDispatch(setGreenhouseLoading(false))
    }
  }

  const thirtyMinutesForm = () => (
        <Layout id='saved-searches-layout' >
            <Row justify='center'>
                <Col span={16} flex="auto">
                    <Card className='tmFormCard' >
                        <Form>
                            <Input
                            onChange={onChange}
                            name='atsApiKey'
                            value={values.atsApiKey}
                            className='tmInput'
                            placeholder={t(allConstants.GREENHOUSE_API_KEY)} />

                            <Input
                            onChange={onChange}
                            name='jobId'
                            value={values.jobId}
                            className='tmInput'
                            placeholder={t(allConstants.GREENHOUSE_JOB_ID)} />

                            <Input
                            onChange={onChange}
                            name='atsOnBehalfOf'
                            value={values.atsOnBehalfOf}
                            className='tmInput'
                            placeholder={t(allConstants.ONBEHALF_ID)} />

                            <Button
                            disabled={(Object.values(values).some(elem => !elem))}
                            className='tmFormBtn'
                            type='primary'
                            onClick={handleSubmit} >{t(allConstants.CREATE)}</Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Layout>
  )
  return (
        <HeaderLayout title={t(allConstants.GREENHOUSE_DEMO)} >
            {greenhouseLoading
              ? <Spin tip={`${t(allConstants.COLLECTING_GREENHOUSE_DATA)}...`} size='large' style={{ fontSize: 26, color: '#A3A3A3' }}>
                {thirtyMinutesForm()}
                  </Spin>
              : thirtyMinutesForm()}
        </HeaderLayout>
  )
}

export default ThirtyMinutes
