
import dayjs from 'dayjs'
import { allConstants } from '../../../../constants/constants'
const { FILE_NAME, FIRST_NAME, LAST_NAME, INGEST_REPORT_DATE, DATE_OF_ATTEMPT, ASSOCIATION, TRANSACTIONS, CREDIT_SPENT, CURRNECY_NAME, CURRENCY_CODE, DATE_USED, COMPANY_TEXT } = allConstants

export const ingestReportColumn = (t) => [
  {
    title: t(FILE_NAME),
    dataIndex: 'resumeFilename',
    key: 'resumeFilename',
    sortDirections: ['ascend', 'descend', 'ascend'],
    showSorterTooltip: {
      title: 'click to sort',
      placement: 'topLeft',
      arrowPointAtCenter: false,
      autoAdjustOverflow: true,
      align: { offset: [80, 0] }
    },
    sorter: (a, b) => a.resumeFilename.localeCompare(b.resumeFilename)
  },
  {
    title: t(FIRST_NAME),
    dataIndex: 'firstName',
    width: 200,
    sortDirections: ['ascend', 'descend', 'ascend'],
    key: 'firstName',
    sorter: (a, b) => a.resumeFilename.localeCompare(b.resumeFilename),
    render: (val) => {
      return val || '-'
    },
    showSorterTooltip: {
      title: 'click to sort',
      placement: 'topLeft',
      arrowPointAtCenter: false,
      autoAdjustOverflow: true,
      align: { offset: [80, 0] }
    }
  },
  {
    title: t(LAST_NAME),
    dataIndex: 'lastName',
    width: 200,
    key: 'lastName',
    render: (val) => {
      return val || '-'
    }
  },
  {
    title: t(INGEST_REPORT_DATE),
    dataIndex: 'ingestedAt',
    key: 'ingestedAt',
    width: 400,
    sortDirections: ['ascend', 'descend', 'ascend'],
    sorter: (a, b) => dayjs(a.ingestedAt).unix() - dayjs(b.ingestedAt).unix(),
    render: (val) => {
      return dayjs(val).format('YYYY-MM-DD')
    },
    showSorterTooltip: {
      title: 'click to sort',
      placement: 'topLeft',
      arrowPointAtCenter: false,
      autoAdjustOverflow: true,
      align: { offset: [140, 0] }
    }
  }
]

export const failureReportColumn = (t) => [
  {
    title: t(FILE_NAME),
    dataIndex: 'resumeFilename',
    key: 'resumeFilename',
    sorter: (a, b) => a.resumeFilename.localeCompare(b.resumeFilename),
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 470,
    showSorterTooltip: {
      title: 'click to sort',
      placement: 'topLeft',
      arrowPointAtCenter: false,
      autoAdjustOverflow: true,
      align: { offset: [80, 0] }
    }
  },
  {
    title: t(DATE_OF_ATTEMPT),
    dataIndex: 'ingestedAt',
    key: 'ingestedAt',
    sorter: (a, b) => dayjs(a.ingestedAt).unix() - dayjs(b.ingestedAt).unix(),
    showSorterTooltip: {
      title: 'click to sort',
      placement: 'topLeft',
      align: { offset: [120, 0] },
      arrowPointAtCenter: false,
      autoAdjustOverflow: true
    },
    align: 'left',
    sortDirections: ['ascend', 'descend', 'ascend'],
    render: (val) => {
      return dayjs(val).format('YYYY-MM-DD')
    }
  }
]

export const transactionColumn = (t) => {
  return [
    { title: t(ASSOCIATION), dataIndex: 'associationName', key: 'association' },
    { title: t(COMPANY_TEXT), dataIndex: 'companyName', key: 'company' },
    { title: t(DATE_USED), dataIndex: 'transaction_date', key: 'usedDate' },
    { title: t(CURRENCY_CODE), dataIndex: 'currencyCode', key: 'currency' },
    { title: t(CURRNECY_NAME), dataIndex: 'currencyName', key: 'currency' },
    { title: t(CREDIT_SPENT), dataIndex: 'amount', key: 'transactionId', align: 'center' },
    { title: t(TRANSACTIONS), dataIndex: 'count', key: 'transactionId', align: 'center' }
  ]
}
