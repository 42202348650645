import React from 'react'
// import ZendeskArticle from './ZendeskArticles/ZendeskArticle'
import ZendeskArticleListing from './ZendeskArticleListing/ZendeskArticleListing'
// styles
import './ZendeskCategory.css'

function ZendeskCategory () {
  return (
    <ZendeskArticleListing />
  )
}

export default ZendeskCategory
