import React from 'react'
import { PropTypes } from 'prop-types'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useSessionStorageState } from 'ahooks'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated] = useSessionStorageState('access_token', sessionStorage.getItem('access_token'))
  const location = useLocation()
  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated
        ? (
            <Component {...props} />
          )
        : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location }
              }}
            />
          ))}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired
}

export default PrivateRoute
