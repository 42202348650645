import styled from 'styled-components'

export const Container = styled.div`
  // display: grid;
  grid-template-areas:
    "cards"
    "content";
  grid-template-rows: 152px;
  // height: 100vh;
  flex: 1;
  align-items: flex-start;
  gap: 8px;
`
export const SnackBar = styled.div`
  height: 48px;
  border: solid 1px #94d6fd;
  background-color: #e7f7ff;
  display: flex;
  align-items: center;
  margin-top:5px;
  margin-bottom: 10px;

  img {
    width: 20px;
    height: 22px;
    margin: 0 5px;
  }
  span {
    margin-top:2px;
    font-size: 16px;
    color: #000;
    font-weight: 600;
  }
  .click-here {
    text-decoration: underline;
    color: #00b7c0;

    &:hover {
      cursor:pointer;
    }
  }
`
export const ButtonContainer = styled.div`
.ant-btn { 
  background: white;
  width: 218px;
  border-radius: 4px;
  border: solid 1.5px ${({ theme }) => theme.primary_color || '#00b7c0'};
  height: 42px;
}
.ant-btn > span {
  color:${({ theme }) => theme.primary_color || '#00b7c0'}
}
`