import styled from 'styled-components'
import { Modal, Tag as AntdTag } from 'antd'

export const Container = styled.div`

  .ant-table-filter-trigger {
     display:flex;
     justify-content:center;
     align-items:center;
     width: 12vw !important;
  }

`

export const Tag = styled(AntdTag)`
  text-align: center;
  display: inline-block;
  color: #979797;
  padding: 5px 10px;
  width: 100%;
  border-radius: 4px;
  background: rgba(56,177,117,.12);
  border: none;
  border-left: 6px solid #56b983;
`
export const SnackBar = styled.div`
  height: 48px;
  border: solid 1px #94d6fd;
  background-color: #e7f7ff;
  display: flex;
  align-items: center;
  margin-top:5px;
  margin-bottom: 10px;

  img {
    width: 20px;
    height: 22px;
    margin: 0 5px;
  }
  span {
    margin-top:2px;
    font-size: 16px;
    color: #000;
    font-weight: 600;
  }
  .click-here {
    text-decoration: underline;
    color: #00b7c0;

    &:hover {
      cursor:pointer;
    }
  }
`

export const PageTitle = styled.div`
  height: 73px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:space-between;
  
  & .table-title {
    font-size: 18px;
    font-weight: 600;
  }
  
  .ant-btn {
    width: 250px;
    height: 42px;
    border-radius: 4px;
  }

  .ant-btn > span {
    font-size: 14px;
    font-weight: 600;
  }
`
export const QualifiedModal = styled(Modal)`
  & .ant-modal-body {
      padding: 16px;
  }
  & .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: #343434;
    margin-bottom: 13px;
    padding-bottom:10px;
    border-bottom: 1px solid  #f0f0f0;
  }

  & ul {
    margin-left: -15px;
    & li {
      font-size: 16px;
      line-height: 1.5;
      color: #343434;
    }
    & li:first-child {
      margin-bottom: 16px;
    }
  }
  & .title-info {
    margin-right: 11.5px;
  }
  /* & p {
    font-size: 16px;
    line-height: 1.38;
    color: #343434;
    margin-bottom: 12px;
  } */

`