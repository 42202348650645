import React from 'react'
import { Drawer, Row, Col, Typography, Layout, Divider, Checkbox, Skeleton, Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
// Local Files
import TagGroup from '../../components/TagGroup'
import FiltersLayout from '../Filters/component/FiltersLayout'
import { ThemeContainer } from '../../../../components/theme-components'
// Context
import {
  useQuerysState,
  useQuerysDispatch,
  setFields
  // useQueryActions
} from '../../CandidateContext/SearchQueryContext'
// Style Sheet
import './ProfileView.css'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

const { Text, Title } = Typography
const { Content } = Layout

export default function ProfileView ({ handletoggle, isOpen, payload, profileIds, selectedProfileId, profileView }) {
  const candidateByIndex = React.useMemo(() => profileView?.results.findIndex(candidate => candidate.id === selectedProfileId), [profileView])
  const [activeProfile, setActiveProfile] = React.useState(candidateByIndex)
  const dispatch = useQuerysDispatch()
  const { SelectedCandidates } = useQuerysState()
  const { t } = useTranslation()
  const changeCandidate = React.useCallback((index) => {
    if (index < 0) return
    try {
      const getCandidateIndex = profileView?.results.indexOf(profileView?.results[index])
      setActiveProfile(getCandidateIndex)
    } catch (err) {
      console.log(err.message)
    }
  }, [payload])

  const showCandidate = React.useMemo(() => profileView?.results[activeProfile], [activeProfile, profileView])

  const selectCandidates = (candidate) => {
    const NEW_LIST = SelectedCandidates.includes(candidate) ? SelectedCandidates.filter((v) => v !== candidate) : [...SelectedCandidates, candidate]

    dispatch(setFields({ SelectedCandidates: NEW_LIST }))
  }

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={handletoggle}
      visible={isOpen}
      width={528}
      className="profile-drawer"
    >
      <ThemeContainer>
      {!showCandidate
        ? <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>

        : <>
          <Row className='profile-view-header' >
            <Col span={12}>
            {activeProfile !== 0 && <Button
                type="link"
                icon={<LeftOutlined />}
                onClick={() => {
                  window.analytics.track('previous candidate')
                  changeCandidate(activeProfile - 1)
                }}
              >
                {t(allConstants.PREVIOUS_CANDIDATE)}
              </Button>}
            </Col>
            <Col span={12} justify="end" className='right-align' >
              <Button type='link'
                onClick={() => {
                  window.analytics.track('next candidate')
                  changeCandidate(activeProfile + 1)
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row-reverse',
                  gap: '8px',
                  float: 'right'
                }}
                icon={<RightOutlined />}
              >
                {t(allConstants.NEXT_CANDIDATE)}
              </Button>

            </Col>
          </Row>

          <Content className='profile-view-body' >
            <Layout justify='center' align='middle' >
              <Title level={5} >
                <span style={{ fontSize: '14px' }} >
                  {showCandidate.firstName.substr(0, 1)}
                </span>
                <span style={{ filter: 'blur(4px)' }} >
                  {showCandidate?.firstName?.substr(1)}
                </span>
              </Title>
              <Title level={5} > {showCandidate.currentTitle || '-'} </Title>

              <Row justify='center' align='middle' className='select-candidate'>
                <Col >
                  {/* <Space size={24} > */}
                  <Text className='score-checkbox' >{t(allConstants.SCORE)}: <Text> {Math.floor(showCandidate.score)} %</Text> </Text>
                  <Checkbox
                    checked={SelectedCandidates.includes(showCandidate.id)}
                    onChange={() => selectCandidates(showCandidate.id)}>
                    {t(allConstants.SELECT_CANDIDATE)}
                  </Checkbox>
                </Col>
              </Row>
            </Layout>

            <Divider />
            {/* Personal Info  */}
            <Layout justify='center' align='middle' gutter={10} >
              <FiltersLayout title={t(allConstants.PERSONAL_INFO)} RightSideIcon={<div />} isAlwaysOpen={true} classNames={'profile-cards'}>
                <Row>
                  <Col span={12} >
                    <Text className='pofile-labels' >{t(allConstants.FULL_NAME)}</Text>
                    <Text >
                      <span style={{ fontSize: '14px' }} >
                        {showCandidate.firstName.substr(0, 1)}
                      </span>
                      <span style={{ filter: 'blur(4px)' }} >
                        {showCandidate?.firstName?.substr(1)}
                      </span></Text>
                  </Col>
                  <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.LOCATION)}</Text>
                    <Text> {showCandidate.location || '-'} </Text>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.EMAIL)}</Text>
                    <Text className='redacted-data'>{showCandidate.emails || '-'}</Text>
                  </Col>
                  <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.PHONE)}</Text>
                    <Text className='redacted-data' >{showCandidate.phoneNumbers || '-'}</Text>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Text className='pofile-labels'>{t(allConstants.DEGREE)}</Text>
                    <Text>
                      {showCandidate.degree
                        ? <TagGroup
                          tags={showCandidate.degree?.split(',')}
                          className="profile-tags"
                          HideCloseIcon={true}
                        />
                        : '-'
                      }
                    </Text>
                  </Col>
                </Row>
              </FiltersLayout>
            </Layout>

            {/* Expertise  */}
            <Layout align='middle' gutter={10} >
              <FiltersLayout title={t(allConstants.EXPERTISE)} RightSideIcon={<div />} isAlwaysOpen={true} classNames={'profile-cards'}>
                <Row className="profile-tags" >
                  {showCandidate.expertise
                    ? <TagGroup
                      tags={showCandidate.expertise?.split(',').map(item => item.trim()).sort((a, b) => a.localeCompare(b))}
                      className="profile-tags"
                      HideCloseIcon={true}
                    />
                    : '-'
                  }
                </Row>
              </FiltersLayout>
            </Layout>

            {/* Work History  */}
            <Layout align='middle' gutter={10} >
              <FiltersLayout title={t(allConstants.WORK_HISTORY)} RightSideIcon={<div />} isAlwaysOpen={true} classNames={'profile-cards'}>
                <Row>
                  <Col span={12} >
                    <Text className='pofile-labels' >{t(allConstants.CURRENT_INDUSTRY)}</Text>
                    <Text > {showCandidate.currentIndustry || '-'} </Text>
                  </Col>

                </Row>

                <Row>
                  <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.CURRENT_COMPANY)}</Text>
                    <Text > {showCandidate.currentCompany || '-'} </Text>
                  </Col>
                  <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.CURRENT_TITLE)}</Text>
                    <Text > {showCandidate.currentTitle || '-'} </Text>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.PREVIOUS_COMPANY)}</Text>
                    <Text >{showCandidate.previousCompany || '-'}</Text>
                  </Col>
                  <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.PREVIOUS_TITLE)}</Text>
                    <Text > {showCandidate.previousTitle || '-'} </Text>
                  </Col>
                </Row>
              </FiltersLayout>
            </Layout>

          </Content>
        </>
      }
      </ThemeContainer>
    </Drawer>
  )
}

ProfileView.propTypes = {
  handletoggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  payload: PropTypes.object.isRequired,
  profileIds: PropTypes.array.isRequired,
  selectedProfileId: PropTypes.string,
  profileView: PropTypes.object.isRequired
}
