import React from 'react'
import AuthLayout from '../../../layouts/AuthLayout'
import { LeftHeader, Row } from './style'
import { Form, Input, message } from 'antd'
import { ForgetButton, FullWidthButton } from '../login/style'
import { useHistory, useParams } from 'react-router'
import * as passwordApi from '../../../api-twg/twg/password-change-controller'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../constants/constants'

const ResetPasswordPage = () => {
  const { clid } = useParams()
  const history = useHistory()
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onFinish = (values) => {
    passwordApi.doPasswordChange(values.password, clid)
      .then((res) => {
        if (res.data) {
          message.success(t(allConstants.RESET_PASSWORD_ERROR))
        } else {
          message.error(t(allConstants.RESET_PASSWORD_AGAIN_ERROR))
        }
        history.push('/')
      })
      .catch(() => message.error(t(allConstants.RESET_PASSWORD_AGAIN_ERROR)))
  }

  const renderRightSideTitle = () => (
    <>
      <Row>
        <LeftHeader>{t(allConstants.HELLO)},</LeftHeader>
      </Row>
      <Row>
        <LeftHeader>{t(allConstants.RESET_YOUR_PASSWORD)}</LeftHeader>
      </Row>
    </>
  )

  const handleGoBackToLoginButtonClick = () => {
    history.push('/')
  }

  return (
    <AuthLayout right={renderRightSideTitle()}>
      <Form form={form} onFinish={onFinish} >
        <Form.Item
          name="password"
          rules={[
            { required: true, message: t(allConstants.PASSWORD_ERROR) }
          ]}
          dependencies={['password']}
        >
          <Input.Password placeholder={t(allConstants.PASSWORD)} />
        </Form.Item>

        <Form.Item
          name="confirm password"
          rules={[
            { required: true, message: t(allConstants.CONFIRM_NEW_PASSWORD) },
            ({ getFieldValue }) => ({
              validator (rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error(t(allConstants.PASSWORD_NOT_MATCH)))
              }
            })
          ]}
        >
          <Input.Password placeholder={t(allConstants.REPEAT_PASSWORD)} />
        </Form.Item>

        <Form.Item>
          <FullWidthButton type="primary" htmlType="submit">
            {t(allConstants.CONFIRM_NEW_PASSWORD)}
          </FullWidthButton>
        </Form.Item>
        <Row justify="center" >
          <ForgetButton onClick={handleGoBackToLoginButtonClick}>
          {t(allConstants.GO_TO_LOGIN)}
          </ForgetButton>
        </Row>
      </Form>
    </AuthLayout>
  )
}

export default ResetPasswordPage
