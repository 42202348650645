import { i18n } from '@lingui/core'
import { en, cs, fr } from 'make-plural/plurals'
import messagesEn from './locales/en/messages'
import messagesCs from './locales/cs/messages'
import messagesFr from './locales/fr/messages'
import i18nNext from 'i18next'
import { initReactI18next } from 'react-i18next'
import spanish from './language/spanish.json'
import french from './language/french.json'
import english from './language/english.json'

export const locales = {
  en: 'English',
  cs: 'Česky',
  fr: 'France'
}
export const defaultLocale = 'en'

i18n.loadLocaleData({
  en: { plurals: en },
  cs: { plurals: cs },
  fr: { plurals: fr }
})

/**
* We do a dynamic import of just the catalog that we need
* @param locale any locale string
*/

export async function dynamicActivate (locale) {
  switch (locale) {
    case 'en': i18n.load(locale, messagesEn?.messages || {}); break
    case 'fr': i18n.load(locale, messagesFr?.messages || {}); break
    case 'cs': i18n.load(locale, messagesCs?.messages || {}); break

    default:
      break
  }
  i18n.activate(locale)
}

const resources = {
  en: {
    translation: english
  },
  fr: {
    translation: french
  },
  es: {
    translation: spanish
  }
}

i18nNext.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
})

export default i18nNext
