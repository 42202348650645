import React from 'react'
import { matchPath, useHistory, useLocation } from 'react-router'
import Modal from 'antd/lib/modal/Modal'
import CreateJobForm from './CreateJobForm'
import './modal.css'
import { useJobsActions } from '../../context/JobsContext'
import { Form } from 'antd'
import { useRequest } from 'ahooks'
import { allConstants } from '../../../../constants/constants'
import { useTranslation } from 'react-i18next'

const CreateJobModal = () => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const actions = useJobsActions()
  const location = useLocation()
  const history = useHistory()

  const { loading, run } = useRequest(values => actions.createJobAndPost(values), {
    manual: true,
    onSuccess: (response) => {
      history.push(`/score/${response.data.externalJobId}/descriptions`)
    }
  })

  const match = matchPath(location.pathname, {
    path: '/score/new',
    exact: true,
    strict: false
  })

  const handleOkClick = async () => {
    await form.submit()
  }
  const handleCancelClick = () => {
    history.push('/score')
  }

  const onFinish = async (values) => {
    run(values)
  }

  return (
    <Modal
      title={t(allConstants.CREATE_NEW_JOB)}
      destroyOnClose
      centered
      visible={!!match}
      onOk={handleOkClick}
      onCancel={handleCancelClick}
      okText={t(allConstants.SUBMIT)}
      cancelText={t(allConstants.CANCEL)}
      confirmLoading={loading}
      preserve={false}
      width={700}
    >
      <Form
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{}}
        onFinish={onFinish}
        requiredMark="optional"
        preserve={false}
      >
        <CreateJobForm />
      </Form>
    </Modal>
  )
}

export default CreateJobModal
