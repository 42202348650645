import React from 'react'
import { Tabs } from 'antd'
import PropTypes from 'prop-types'
import { CustomPageHeader } from './PageHeaderLayoutWithHeader/style'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
function PageSubHeader ({ onTabChange, subHeaderTabs }) {
  const path = useLocation()
  const { t } = useTranslation()
  const defaultKey = path.pathname.replace('/reports/', '')
  return (
       <div >
      <CustomPageHeader>
        <Tabs onChange={onTabChange} activeKey={defaultKey.includes('resume-report') ? 'resume-report' : defaultKey.includes('/reports') ? 'transaction-report' : defaultKey} defaultActiveKey={defaultKey}>
          {subHeaderTabs(t).map((val) => (
            <Tabs.TabPane tab={val.tab} key={val.key}></Tabs.TabPane>
          ))}
        </Tabs>
      </CustomPageHeader>
    </div>
  )
}

export default PageSubHeader
PageSubHeader.propTypes = {
  subHeaderTabs: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired
}
