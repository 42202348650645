/* eslint-disable react/prop-types */
import React from 'react'
import { Modal, Button, message, notification } from 'antd'
import * as scoreExportApi from '../../../api-twg/score/score-controller'
import * as discoverExportApi from '../../../api-twg/discover/export-controller'
import { DollarCircleTwoTone, ExclamationCircleOutlined, ExportOutlined } from '@ant-design/icons'
import fileDownload from 'js-file-download'
import { useJobRequisitionsState } from '../context/ApplicantsContextProvider'
import { useRequest, useToggle } from 'ahooks'
import { useAuthState } from '../../../contexts/AuthContext'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../constants/constants'
import { useJobsState } from '../../jobs/context/JobsContext'
import { fileDownloadDate } from '../../../helper'

const { confirm } = Modal
export default function ExportButton ({ selectedIds, resultSetId }) {
  const { t } = useTranslation()
  const { filter } = useJobRequisitionsState()
  const { selectedJob } = useJobsState()
  const { title } = selectedJob || {}
  const [loading, { toggle }] = useToggle(false)
  const { user } = useAuthState()

  const { run: runScoreExport } = useRequest((params, data) => scoreExportApi.doExport(params, data), { manual: true })
  const { run: runDiscoverExport } = useRequest((params, data) => discoverExportApi.doExport(params, data), { manual: true })

  const exportingFromActiveJobTable = async (data) => {
    if (filter === 'passive') {
      try {
        toggle()
        notification.warning({
          message: t(allConstants.EXPORTING_CANDIDATES),
          description: t(allConstants.EXPORT_REQUEST_PROCESSED),
          placement: 'topRight'
        })
        const response = await runDiscoverExport({}, data)
        fileDownload(response.data, `passive-candidates-exports-for-${title.toLowerCase().replace(/\s/g, '_')}-${fileDownloadDate()}.csv`)
      } finally {
        toggle()
      }
    } else {
      confirm({
        title: t(allConstants.INCLUDE_RESUME),
        okText: t(allConstants.YES),
        cancelText: t(allConstants.NO),
        icon: <ExclamationCircleOutlined />,
        onOk: async () => {
          await runScoreExport({ withcv: true }, data)
          message.success(t(allConstants.RECIEVE_EMAIL_LINK))
        },
        onCancel: async () => {
          const response = await runScoreExport({ withcv: false }, data)
          fileDownload(response.data, `direct-applicants-exports-for-${title.toLowerCase().replace(/\s/g, '_')}-${fileDownloadDate()}.csv`)
        }
      })
    }
  }
  const handleExportClick = async () => {
    const data = { resultIds: selectedIds, resultSetId: resultSetId }
    if (filter === 'passive' && user?.showConsumptionPopups) {
      confirm({
        width: '600px',
        title: t(allConstants.EXPORT_SELECTED_CANDIDATES),
        okText: t(allConstants.YES),
        cancelText: t(allConstants.NO),
        icon: <DollarCircleTwoTone />,
        content: t(allConstants.REQUIRE_CREDIT),
        onOk: () => exportingFromActiveJobTable(data),
        onCancel: () => { }
      })
    } else {
      exportingFromActiveJobTable(data)
    }
  }

  return (
    <Button
      type="primary"
      icon={<ExportOutlined />}
      disabled={!(selectedIds?.length) || loading}
      onClick={handleExportClick}
      loading={loading}
    >
      {t(allConstants.EXPORT)}
    </Button>
  )
}
