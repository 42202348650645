import styled from 'styled-components'
import { Carousel, Modal } from 'antd'

export const ModalWrapper = styled(Modal)`
  .ant-modal-footer {
    text-align: center;
    border-top: none;
    margin-top: -6px;
    padding: 0px 16px 24px 16px;
  }

  & .first-slide-button {
    margin: -25px 0px 10px 0px;
  }

  & .slide-buttons {
    display: flex;
    margin: -25px 0px 10px 26px;
  }

  .ant-btn:hover:not(.ant-btn-primary),
  .ant-btn:focus:not(.ant-btn-primary) {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    background: #fff;
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
  }
  .ant-btn-primary:enabled:not(.ant-btn-dangerous) {
    background: ${({ theme }) => theme.primary_color || '#00b7c0'};
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
  }
  .ant-btn-dashed:enabled {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
  }
  .ant-btn-link:enabled {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
  }

  .popup-buttons {
    width: 83px;
    height: 39px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.28px;
    box-shadow: none;
  }
`

export const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 2px solid #c7c7c7;
  }
  > .slick-dots li.slick-active button {
    height: 10px !important;
    border-radius: 100%;
    background: rgba(0, 183, 192, 0.4);
    border: 0;
  }

  .slick-dots-bottom {
    bottom: 32px;
  }
  .slick-dots-false {
    bottom: 32px;
    left: -351px;
  }

  .slick-dots li.slick-active {
    width: 18px;
  }
 
  .image-container {
    width: 397px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: rgba(0, 183, 192, 0.2);
    padding: 6px 0 0 5px;
    object-fit: contain;
    margin-bottom: 20px;
  }

  .pages-img {
    width: 392px;
    height: 223px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    object-fit: fill;
  }

  .heading {
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: #000;
    margin: 28px 0px 23px 0px;
  }

  .second-heading {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    color: #343434;
    margin-top: 20px;
  }
  div > p {
    width: 387px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
    text-align: left;
    color: #343434;
    margin-bottom: 0px;
    margin-top: 10px;
    word-break: break-word;
  }
  .second-span {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.81;
    letter-spacing: normal;
    text-align: left;
    color: #343434;
    // margin-bottom:0px;
  }
`
export const FirstSlide = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 495px;

  & .heading {
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.69;
    letter-spacing: normal;
    color: #1a324a;
  }

  & div > img {
    object-fit: contain;
    margin: 24px 0px;
    width: 230px;
    height: 70px;
  }

  & div > span {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #000;
  }
`

export const OtherSlides = styled.div`
  padding-left: 34px;
  height: 495px;
`
export const NoJobContainer = styled.div`
  & .ant-card {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 380px);

    & .ant-card-body {
      padding: 0px !important;
    }
  }
`
