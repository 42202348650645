import styled from 'styled-components'
import { rgba } from 'polished'

export const ZendeskBannerBody = styled.div`
    background: linear-gradient(
        270deg,
        ${({ theme }) => theme.barColor?.[0] || '#76e1e6'},
        ${({ theme }) => theme.barColor?.[1] || '#00b7c0'}
      );
   
`
export const SearchButton = styled.div`
    background-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    width: 82px;
    height: 33px;
    color: #fff;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    display:flex;
    justify-content:center;
    align-items: center;

    &:hover {
        cursor:pointer;
    }
`
export const ArticleName = styled.div`
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
`
export const ZendeskText = styled.span`
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
`
export const UploadImage = styled.div`
    border-radius: 2px;
    border: dashed 2px ${({ theme }) => theme.primary_color || '#00b7c0'};
    padding: 26px 14px 25px;
    text-shadow: 0 3px 12px rgba(0, 0, 0, 0.16);
    font-size: 16px;
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
`
export const BrowseImage = styled.span`
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    font-weight: 600;
    margin-left: 3px;
`
export const ZendeskModalBody = styled.div`
    padding: 21px;
    margin: 0px;
    display: block;
    .ant-input{
        
    }
    .ant-input:focus, .ant-input-focused {
        border-color: ${({ theme }) => theme.primary_color || '#00b7c0'} ;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: 0 0 0 2px ${({ theme }) => rgba(theme.primary_color || '#00b7c0', 0.2)};
      } 
      .ant-input:hover {
        border-color:  ${({ theme }) => theme.primary_color || '#00b7c0'} ;
      }
`
export const ZendeskWriteMessage = styled.div`
    border: solid 1px #e3e3e3;
    border-radius: 4px;
    padding: 10px 10px 10px;
    background-color: #fff;
    
    .ant-btn {
        color:  ${({ theme }) => theme.primary_color || '#00b7c0'} ;
        border: 1px solid ${({ theme }) => theme.primary_color || '#00b7c0'};
        border-radius:16px;
        display:flex;
        justify-content:center;
        align-items:center;

    }

    .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
        color: rgba(0, 0, 0, 0.25);
        border: 1px solid #f5f5f5;
        border-radius:16px;
        background:none;
        border-color: #d9d9d9;
        text-shadow: none;
        box-shadow: none;
    }
    .ant-btn:hover:not(.ant-btn-primary), .ant-btn:focus:not(.ant-btn-primary) {
        color:  ${({ theme }) => theme.primary_color || '#00b7c0'} ;
        background: #fff;
        border-color:  ${({ theme }) => theme.primary_color || '#00b7c0'} ;
    }
    .ant-btn-primary:enabled:not(.ant-btn-dangerous) {
      background: ${({ theme }) => theme.primary_color || '#00b7c0'} ;
      border-color: ${({ theme }) => theme.primary_color || '#00b7c0'}       
    }
    .ant-btn-dashed:enabled {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'};
      border-color: ${({ theme }) => theme.primary_color || '#00b7c0'}
    }
    .ant-btn-link:enabled {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    }
`