import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'
// const { } = allConstants
const ComponentToPrint = ({ pdfTableData }) => {
  const styles = StyleSheet.create({
    table: {
      margin: 10,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#D9D9D9',
      borderBottomWidth: 0
    },
    footer: {
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10,
      flexGrow: 1,
      alignItems: 'center',
      text: {
        fontSize: 12,
        color: '#506266',
        fontWeight: 'bold'
      }
    },
    text: {
      container: {
        marginTop: 20,
        marginLeft: 10
      },
      color: '#313131',
      fontSize: 10,
      fontWeight: 700
    },
    thead: {
      flexDirection: 'row',
      borderStyle: 'solid',
      borderBottomWidth: 1,
      borderColor: '#D9D9D9',
      backgroundColor: '#f0f0f0'
    },
    headerText: {
      container: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 10,
        fontSize: 10,
        fontWeight: 600,
        color: '#313131'
      },
      middleBox: {
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: '#D9D9D9'
      },
      width: 181
    },
    tbody: {
      flexDirection: 'column',
      highlightWrapper: {
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: '#d2e4fe'
      },
      dimmedContainer: {
        flexDirection: 'row'
      },
      container: {
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderColor: '#D9D9D9',
        flexDirection: 'row'
      },
      highlightContainer: {
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: '#d2e4fe',
        flexDirection: 'row',
        backgroundColor: '#ebf4ff'
      }
    },
    onlyHeading: {
      container: {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 10,
        fontSize: 10,
        fontWeight: 'bold',
        color: '#313131',
        backgroundColor: '#fafafa'
      },
      middleBox: {
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: '#D9D9D9'
      },
      width: 181
    },
    data: {
      dimmedWrapper: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#D9D9D9'
      },
      dimmedHighlightWrapper: {
        borderStyle: 'solid',
        borderBottomWidth: 2,
        borderColor: '#d2e4fe',
        backgroundColor: '#ebf4ff'
      },
      highlightContainer: {
        color: '#313131',
        fontSize: 9,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 10
      },
      highlightMiddleBox: {
        borderStyle: 'solid',
        borderLeftWidth: 2,
        borderRightWidth: 2,
        borderColor: '#d2e4fe'
      },
      container: {
        color: '#313131',
        fontSize: 9,
        paddingLeft: 10,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
      },
      middleBox: {
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: '#D9D9D9'
      },
      dimmed: {
        backgroundColor: '#F5F5F5'
      },
      dimmedBorderAdjustment: {
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: '#D9D9D9'
      },
      dimmedColor: {
        backgroundColor: '#F5F5F5'
      },
      width: 181
    }
  })
  const { t } = useTranslation()

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.text.container}>
          <Text style={styles.text}>{`${t(allConstants.PREPARED_FOR)} ${
            pdfTableData?.prospect?.name || ''
          }, ${t(allConstants.FROM)} ${
            pdfTableData?.prospect?.company || ''
          }`}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.thead}>
            <View style={{ ...styles.headerText.container }}>
              <Text style={styles.headerText}>
                {t(allConstants.PLATFORM_USAGE)}
              </Text>
            </View>
            <View
              style={[
                styles.headerText.container,
                styles.headerText.middleBox,
                { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
              ]}
            >
              <Text style={styles.headerText}>{'Current Tool(s)'}</Text>
            </View>
            <View
              style={[
                styles.headerText.container,
                { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
              ]}
            >
              <Text style={styles.headerText}>
                {t(allConstants.AI4J_VALUES_CALCULATE)}
              </Text>
            </View>
          </View>
          <View style={styles.tbody}>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={styles.data}>
                  {t(allConstants.RECRUITING_PLATFORM_COUNT)}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.numberOfRecruitingPlatforms}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>8500 Networks</Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={styles.data}>
                  {t(allConstants.MONTHLY_RECRUITING_COST)}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  $
                  {pdfTableData?.userInputValue?.MonthlyCostOfRecruitngPlatform}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  $
                  {
                    pdfTableData?.userInputValue
                      ?.twgMonthlyCostOfRecruitngPlatform
                  }
                </Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={styles.data}>{t(allConstants.LOG_TIME)}</Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.logInTimeMinutes}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.logInTimeMinutes}
                </Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={styles.data}>{t(allConstants.NO_OF_JOBS)}</Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.numberOfJobs}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.numberOfJobs}
                </Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={styles.data}>
                  {/* {t(allConstants.LOOK_TIME)} */}
                  {t('Job Look Up Time (Minutes/Job)')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.lookTimeMinutesPerJob}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.value?.twgLookTimeMinutesPerJob}
                </Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={{ ...styles.data, paddingRight: 3 }}>
                  {t(allConstants.NUMBER_OF_CANDIDATES)}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.numberOfCandidatesPerJob}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.numberOfCandidatesPerJob}
                </Text>
              </View>
            </View>
            <View style={[styles.tbody.container]}>
              <View style={styles.data.container}>
                <Text style={styles.data}>
                  {/* {t(allConstants.QUALIFY_TIME)} */}
                  {t('Review Resume Time (Minutes/Candidate)')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.qualityTimeMinutesPerCandidate}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.value?.twgQualityTimeMinutesPerCandidate}
                </Text>
              </View>
            </View>
            {/* new added */}
            <View style={[styles.tbody.container]}>
              <View style={styles.data.container}>
                <Text style={{ ...styles.data, paddingRight: 3 }}>
                  {t('Time Spent to Stack Rank Qualified Resumes (Minutes)')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.timeSpentRankQualified}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>{0.1}</Text>
              </View>
            </View>
            {/* new note */}
            <View style={[styles.tbody.container, { borderBottomWidth: 0 }]}>
              <View style={styles.data.container}>
                <Text style={styles.data}>
                  {t('Note about resume ranking')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>Often subjective</Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>Algorithmically based</Text>
              </View>
            </View>
            <View style={{ ...styles.tbody.container, borderTopWidth: 1 }}>
              <View style={styles.data.container}>
                <Text style={{ ...styles.data, paddingRight: 5 }}>
                  {/* {t(allConstants.TOTAL_TIME_NEEDED)} */}
                  {t('Total Time Needed To complete Sourcing (Hours/Week)')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' },
                  styles.data.dimmed
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.formulas?.userTotalTimeNeededHoursPerWeek?.toFixed(
                    2
                  )}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.formulas?.twgTotalTimeNeededHoursPerWeek?.toFixed(
                    2
                  )}
                </Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={styles.data}>
                  {/* {t(allConstants.HRS_WEEK)} */}
                  {t('Normal Work Hours/Week')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.hoursPerWeek}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.hoursPerWeek}
                </Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={styles.data}>
                  {/* {t(allConstants.PERCENT_TIME_SPENT)} */}
                  {t('Percent Time of Work week Spent Sourcing')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.percentageTimeSpentSourcing *
                    100}
                  %
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.userInputValue?.percentageTimeSpentSourcing *
                    100}
                  %
                </Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={styles.data}>
                  {t('Total Time Currently Spent Sourcing (Hours)')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {(
                    pdfTableData?.userInputValue?.percentageTimeSpentSourcing *
                    pdfTableData?.userInputValue?.hoursPerWeek
                  ).toFixed(2)}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {(
                    pdfTableData?.userInputValue?.percentageTimeSpentSourcing *
                    pdfTableData?.userInputValue?.hoursPerWeek
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
            <View
              style={{
                ...styles.tbody.container,
                ...styles.data.dimmed,
                height: 21
              }}
            >
              <View style={styles.data.container}>
                <Text style={styles.data}></Text>
              </View>
              <View>
                <Text style={styles.data}></Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}></Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={styles.data}>
                  {/* {`${t(allConstants.HOURS_DIFFERENCE)} (+/-)`} */}
                  {t('Workload Delta (+/-) Hours   (+/-) ')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.formulas?.userHoursDifference?.toFixed(2)}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.formulas?.twgHoursDifference?.toFixed(2)}
                </Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={{ ...styles.data, paddingRight: '18' }}>
                  {/* {t(allConstants.TIME_ASSESSMENT)} */}
                  {t('Weekly Workload Assessment (Surplus/Deficit)')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.formulas?.userHoursDifference
                    ? pdfTableData?.formulas?.userHoursDifference > 0
                      ? 'Surplus'
                      : 'Deficit'
                    : ''}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {pdfTableData?.formulas?.twgHoursDifference
                    ? pdfTableData?.formulas?.twgHoursDifference > 0
                      ? 'Surplus'
                      : 'Deficit'
                    : ''}
                </Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={{ ...styles.data, paddingRight: 3 }}>
                  {t('Total Time Spent Sourcing using Ai4J (Hours)')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {Number(
                    pdfTableData?.formulas?.userTotalTimeNeededHoursPerWeek?.toFixed(
                      2
                    )
                  )}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {Number(
                    pdfTableData?.formulas?.twgTotalTimeNeededHoursPerWeek?.toFixed(
                      2
                    )
                  )}
                </Text>
              </View>
            </View>
            <View style={[styles.tbody.container, { borderBottomWidth: 0 }]}>
              <View style={styles.data.container}>
                <Text style={styles.data}>
                  {t('Weekly Time Savings using Ai4J (Hours)')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}></Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {(
                    Number(
                      pdfTableData?.formulas?.userTotalTimeNeededHoursPerWeek?.toFixed(
                        2
                      )
                    ) -
                    Number(
                      pdfTableData?.formulas?.twgTotalTimeNeededHoursPerWeek?.toFixed(
                        2
                      )
                    )
                  ).toFixed(2)}
                </Text>
              </View>
            </View>
            <View style={styles.tbody.highlightContainer}>
              <View style={styles.data.highlightContainer}>
                <Text style={{ width: 179 }}>{t('Summary')}</Text>
              </View>
              <View
                style={[
                  styles.data.highlightContainer,
                  styles.data.highlightMiddleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={{ width: 179 }}></Text>
              </View>
              <View
                style={[
                  styles.data.highlightContainer,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={{ width: 179 }}></Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={{ ...styles.data, paddingRight: 3 }}>
                  {t('Average Annual Salary for a Recruiter ($K/Yr)')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  ${pdfTableData?.userInputValue?.newHireCost}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  ${pdfTableData?.userInputValue?.newHireCost}
                </Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={{ ...styles.data, paddingRight: 5 }}>
                  {t(
                    'Weekly Labor Cost Savings to Source Candidates using Ai4J'
                  )}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' },
                  styles.data.dimmed
                ]}
              >
                <Text style={styles.data}></Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  $
                  {(
                    (pdfTableData?.userInputValue?.newHireCost / 2) *
                    (Number(
                      pdfTableData?.formulas?.userTotalTimeNeededHoursPerWeek
                    ) -
                      Number(
                        pdfTableData?.formulas?.twgTotalTimeNeededHoursPerWeek
                      ))
                  ).toFixed(0)}
                </Text>
              </View>
            </View>
            <View style={styles.tbody.container}>
              <View style={styles.data.container}>
                <Text style={styles.data}>
                  {t('Needed recruiters to complete workload')}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {!isNaN(pdfTableData?.formulas?.twgTotalNeededRecruiters)
                    ? pdfTableData?.formulas?.twgTotalNeededRecruiters
                    : 0}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' }
                ]}
              >
                <Text style={styles.data}>
                  {!isNaN(pdfTableData?.formulas?.twgTotalNeededRecruiters)
                    ? pdfTableData?.formulas?.twgTotalNeededRecruiters
                    : 0}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View break style={{ ...styles.table, marginTop: '26px' }}>
          <View style={styles.tbody}>
            <View style={[styles.tbody.dimmedContainer]}>
              <View
                style={[
                  styles.data.container,
                  styles.data.dimmedBorderAdjustment
                ]}
              >
                <Text style={styles.data}>
                  {t(allConstants.WEEKLY_VALUE_GAINED)}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' },
                  styles.data.dimmed
                ]}
              >
                <Text style={styles.data}></Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.dimmedBorderAdjustment,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={{ width: 179 }}>
                  $
                  {(
                    (pdfTableData?.userInputValue?.newHireCost / 2) *
                    (Number(
                      pdfTableData?.formulas?.userTotalTimeNeededHoursPerWeek
                    ) -
                      Number(
                        pdfTableData?.formulas?.twgTotalTimeNeededHoursPerWeek
                      ))
                  ).toFixed(0)}
                </Text>
              </View>
            </View>
            <View style={styles.tbody.dimmedContainer}>
              <View
                style={[
                  styles.data.container,
                  styles.data.dimmedBorderAdjustment
                ]}
              >
                <Text style={styles.data}>
                  {t(allConstants.MONTHLY_VALUE_GAINED)}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' },
                  styles.data.dimmed
                ]}
              >
                <Text style={styles.data}></Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.dimmedBorderAdjustment,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' }
                ]}
              >
                <Text style={{ width: 179 }}>
                  $
                  {(
                    4.3333 *
                    (pdfTableData?.userInputValue?.newHireCost / 2) *
                    (Number(
                      pdfTableData?.formulas?.userTotalTimeNeededHoursPerWeek
                    ) -
                      Number(
                        pdfTableData?.formulas?.twgTotalTimeNeededHoursPerWeek
                      ))
                  ).toFixed(0)}
                </Text>
              </View>
            </View>
            <View style={[styles.tbody.dimmedContainer]}>
              <View
                style={[
                  styles.data.container,
                  styles.data.dimmedBorderAdjustment
                ]}
              >
                <Text style={styles.data}>
                  {t(allConstants.ANNUAL_VALUE_GAINED)}
                </Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  styles.data.middleBox,
                  { paddingLeft: 0, paddingRight: 10, textAlign: 'center' },
                  styles.data.dimmed,
                  styles.data.dimmedBorderAdjustment
                ]}
              >
                <Text style={styles.data}></Text>
              </View>
              <View
                style={[
                  styles.data.container,
                  { paddingLeft: 0, paddingRight: 8, textAlign: 'center' },
                  styles.data.dimmedBorderAdjustment
                ]}
              >
                <Text style={{ width: 179 }}>
                  $
                  {(
                    52 *
                    (pdfTableData?.userInputValue?.newHireCost / 2) *
                    (Number(
                      pdfTableData?.formulas?.userTotalTimeNeededHoursPerWeek
                    ) -
                      Number(
                        pdfTableData?.formulas?.twgTotalTimeNeededHoursPerWeek
                      ))
                  ).toFixed(0)}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.footer}>
          <View>
            <Text style={styles.footer.text}>
              Spend your time looking at the right ones and not for the right
              ones!
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}
ComponentToPrint.propTypes = {
  setPdfTableData: PropTypes.func,
  pdfTableData: PropTypes.object
}
export default ComponentToPrint
