import React, { useEffect, useState } from 'react'
import HeaderLayout from '../../layouts/HeaderLayout'
import FirstTimeUser from '../jobs/components/JobRequisitionsSection/JobsTable/components/FirstTimeUserModals'
import Options from './components/Options'
import { useHomeState } from './context/HomeContext'
import img1 from './components/img1.svg'
import hoverImg1 from './components/hoverImg1.svg'
import hoverImg2 from './components/hoverImg2.svg'
import img2 from './components/img2.svg'
import rocket from './components/rocket.svg'
import { useHistory } from 'react-router'
import { CustomAlert, CustomCard, JourneyHeading } from './style'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../constants/constants'
import dayjs from 'dayjs'
import { useAuthState } from '../../contexts/AuthContext'
import { InfoCircleOutlined } from '@ant-design/icons'
import OptionExtra from './OptionExtra'
import { demoSlidesItems } from '../whats-new/data/data'
import { getLogoAndText } from '../../helper/index'

const { JOB_POSTING, SEE_JOB_POSTING, SEARCH_CANDIDATE, SOURCE_FUNNEL_CANDIDATE, SEARCH_CANDIDATE_IN_DATABASE, FILTER_SKILLS, EXPORT_CANDIDATE, WELCOME_TO, START_JOURNEY, VIEW_APPLICANT_RESUME, VIEW_CANDIDATE_PROFILE, LAUNCH_JOB_POST, RENEWAL_NEEDED, ACCOUNT_EXPIRED } = allConstants
const HomePage = () => {
  const theme = useTheme()
  const history = useHistory()
  const { t } = useTranslation()
  const [popUpValue, setPopUpValue] = useState(false)
  const [remainingDaysLeft, setRemainingDaysLeft] = useState(null)

  const { firstTimeLoginValue } = useHomeState()
  const { user } = useAuthState()
  const { firstLogin } = firstTimeLoginValue || {}
  const { clientActiveTo, clientActiveFrom } = user || {}

  useEffect(() => {
    firstLogin === 'true' && setPopUpValue(true)
  }, [firstLogin])

  useEffect(() => {
    const daysLeft = () => {
      const days = dayjs(clientActiveTo).diff(dayjs(), 'day')
      if (days <= 60) setRemainingDaysLeft(days)
    }
    daysLeft()
  }, [clientActiveTo, clientActiveFrom])
  return (
    <HeaderLayout
      title={
        <span>
            {t(WELCOME_TO)} {theme.logo ? 'Circa' : 'ThisWay'} {getLogoAndText(theme.logo)?.text || ''}
        </span>
      }
    >
      <FirstTimeUser
        popUpValue={popUpValue}
        setPopUpValue={setPopUpValue}
        closable={false}
        otherSlidesItem={demoSlidesItems}
      />

      <CustomCard bordered={false}>
        {!!remainingDaysLeft && (
          <CustomAlert
            message={
              remainingDaysLeft > 0
                ? t(RENEWAL_NEEDED, { remainingDaysLeft: remainingDaysLeft })
                : t(ACCOUNT_EXPIRED)
            }
            showIcon
            type="warning"
            icon={<InfoCircleOutlined className="info_icon" />}
          />
        )}

        <JourneyHeading>
          <span>{t(START_JOURNEY)}</span>
          <img src={rocket} />
        </JourneyHeading>
        <Options
          onClick={() => history.push('/score')}
          src={img1}
          hoverSrc={hoverImg1}
          heading={t(JOB_POSTING)}
          para={t(SEE_JOB_POSTING)}
          extra={
            <OptionExtra
              items={[
                VIEW_APPLICANT_RESUME,
                VIEW_CANDIDATE_PROFILE,
                LAUNCH_JOB_POST
              ]}
            />
          }
        />
        <Options
          onClick={() => history.push('/candidates')}
          src={img2}
          hoverSrc={hoverImg2}
          heading={t(SEARCH_CANDIDATE)}
          para={t(SOURCE_FUNNEL_CANDIDATE)}
          extra={
            <OptionExtra
              items={[
                SEARCH_CANDIDATE_IN_DATABASE,
                FILTER_SKILLS,
                EXPORT_CANDIDATE
              ]}
            />
          }
        />
      </CustomCard>
    </HeaderLayout>
  )
}

export default HomePage
