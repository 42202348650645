import axios from 'axios'
/**
 * GET
 * /twg/api/v1/theme/${themeExtId}
 * getTheme
 */
export const getTheme = (themeExtId) => {
  return axios.get(`/twg/api/v1/theme/${themeExtId}`, {
    baseURL: process.env.REACT_APP_BASE_API_URL,
    headers: {
      'Content-type': 'application/json; charset=utf-8',
      Accept: 'application/json; charset=utf-8'
    }
  })
}
