import React from 'react'
import HeaderLayout from '../../../layouts/HeaderLayout'
import { Card, Col, Layout, Row, Spin } from 'antd'
import AccountSelector from './Components/AccountSelector'
import GreenHouseAccountInfoForm from './Components/GreenHouseAccountInfoForm'
import EstimatorResult from '../Estimatorresult'

// import WelcomeGreenHouseHeader from './Components/WelcomeGreenHouseHeader'
// context
import { useQuerysState } from '../GreenhouseEstimatorContext'
import { allConstants } from '../../../constants/constants'
import { useTranslation } from 'react-i18next'

export default function WelcomeGreenhouse () {
  const { isLoading, isGrnhouseResult, accountType } = useQuerysState()
  const { t } = useTranslation()
  const EstimatorForms = () => (
    <Layout id='saved-searches-layout' >
      <Row justify='center'>
        <Col span={16} flex="auto">
            {isGrnhouseResult
              ? <Card>
                <EstimatorResult/>
              </Card>
              : <Card >
              <AccountSelector />

              {accountType && <GreenHouseAccountInfoForm />}
            </Card>}
        </Col>
      </Row>
    </Layout>
  )

  return (
    <HeaderLayout title={t(allConstants.WELCOME_GREENHOUSE_ESTIMATOR)} >
      {isLoading
        ? <Spin tip={`${t(allConstants.COLLECTING_GREENHOUSE_DATA)}...`} size='large' style={{ fontSize: 26, color: '#A3A3A3' }}>
        {EstimatorForms()}
      </Spin>
        : EstimatorForms()}

    </HeaderLayout>

  )
}
