import axios from '../../axios-config'

/**
 * GET
 * /score/api/v1/reporting/stats/types
 * getReportStatsType
 */
export const getReportStatsType = () => {
  return axios.get('/score/api/v1/reporting/stats/types')
}

/**
 * POST
 * /score/api/v1/score/api/v1/reporting/stats
 * getReportStatsType
 */
export const getReportStats = (data) => {
  return axios.post('/score/api/v1/reporting/stats', data)
}

export const getResumeReport = (data) => {
  return axios.post('/score/api/v1/reporting/resume/report', data)
}