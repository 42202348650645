import { Spin, Table } from 'antd'
import React, { useMemo } from 'react'
import { transactionColumn } from '../table-utils/column'
import TransactionRange from '../RangeComponent/TransactionRange'
import { useReportState } from '../../context/ReportContext'
import { useTranslation } from 'react-i18next'
function TransactionReport () {
  const { reportLoading, transactionReport } = useReportState()
  const { t } = useTranslation()
  const groupTransaction = (transactions) => {
    const groups = transactions.reduce((acc, transaction) => {
      const {
        associationId,
        companyId,
        currencyCode,
        transaction_date: transactionDate,
        amount
      } = transaction
      const key = `${associationId}+${companyId}+${currencyCode}+${transactionDate}`

      const result = ({
        ...acc,
        [key]: {
          ...transaction,
          count: (acc[key]?.count || 0) + 1,
          amount: (acc[key]?.amount || 0) + amount
        }
      })
      return result
    }, [])
    return Object.entries(groups)
      .map(([key, value]) => value)
      .sort((transactionA, transactionB) => {
        if (
          transactionA.associationName.localeCompare(
            transactionB.associationName
          ) !== 0
        ) {
          return transactionA.associationName.localeCompare(
            transactionB.associationName
          )
        }
        if (
          transactionA.companyName.localeCompare(transactionB.companyName) !== 0
        ) {
          return transactionA.companyName.localeCompare(
            transactionB.companyName
          )
        }
        if (
          transactionA.transaction_date.localeCompare(
            transactionB.transaction_date
          ) !== 0
        ) {
          return transactionA.transaction_date.localeCompare(
            transactionB.transaction_date
          )
        }
        return transactionA.currencyCode.localeCompare(
          transactionB.currencyCode
        )
      })
  }

  const dataSource = useMemo(() => {
    if (transactionReport) {
      return groupTransaction(transactionReport)
    }
  }, [transactionReport])

  return (
    <div style={{ marginTop: '22px' }}>
      <TransactionRange />
      <Spin spinning={reportLoading}>
        <Table
          rowKey="transactionId"
          columns={transactionColumn(t)}
          dataSource={dataSource}
        />
      </Spin>
    </div>
  )
}

export default TransactionReport
