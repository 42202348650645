
import n from 'normalize-data'

// createJob
export const createJobToApi = (data) => n.normalize(data, [
  ['location', ['city', 'state'], (city, state) => city && state && `${city}, ${state}`],
  ['country', 'country'],
  ['externalJobId', 'externalJobId'],
  ['name', 'name'],
  ['region', 'region']
])
