/* eslint-disable no-debugger */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from 'react'
import {
  Button,
  Card,
  Dropdown,
  Menu,
  Space,
  Tag,
  Table,
  Spin,
  Input
} from 'antd'
import { columns } from './columns'

import {
  setFilters,
  // setSearch,
  useJobsDispatch,
  useJobsState,
  useJobsActions,
  setPageSize,
  setPage
} from '../../../context/JobsContext'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import { TableWrapper } from './styles'
import { ThemeContainer } from '../../../../../components/theme-components'
import { useDebounce } from 'ahooks'
import './JobsTable.css'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'
import { searchText } from '../utils'
// import NoJob from './components/NoJob'
// import { job } from '../../../../../api-twg/score/score-controller/models'

const JobsTable = () => {
  const [pageAndSize, setPageAndSize] = useState({
    page: 1,
    size: 10,
    pageSizeOptions: ['10', '20', '30']
  })
  const { t } = useTranslation()
  const [countriesTag, setCountriesTag] = useState([])
  const [locationsTag, setLocationsTag] = useState([])
  const [sorterObj, setSorterObj] = useState({})
  const [search, setSearch] = useState(null)
  const debouncedValue = useDebounce(search, { wait: 500 })
  const {
    jobs,
    jobsData,
    locations,
    countries,
    isLoading,
    filters
  } = useJobsState()
  const actions = useJobsActions()
  const dispatch = useJobsDispatch()

  // const containSearch = (job) => {
  //   return (
  //     job?.title?.toLowerCase()?.includes(search.toLowerCase()) ||
  //     job?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
  //     job?.location?.toLowerCase()?.includes(search.toLowerCase()) ||
  //     job?.requestId?.toString()?.toLowerCase()?.includes(search.toLowerCase())
  //   )
  // }

  // const jobsFiltered = jobs?.filter(containSearch)

  const renderTitle = useMemo(() => {
    if (!jobsData) return
    let from = jobsData?.page * jobsData.pageSize
    if (!pageAndSize?.pageSizeOptions?.includes(jobsData.pageSize)) {
      from = jobsData.page * parseInt(pageAndSize.size)
      // console.log(from, Math.floor(jobsData.totalRecords / jobsData.pageSize), jobsData)
    }
    return `${t(allConstants.JOB_REQUISITIONS)} (${t(allConstants.SHOWING)} ${
      from + 1
    } - ${from + jobsData.pageSize} ${t('of')} ${jobsData?.totalRecords} ${t(
      'Results'
    )})`
    // return `Your Job Requisitions (Showing ${jobsData.pageSize} of ${jobsData?.totalRecords} Results)`
  }, [jobsData])

  const handlingSorting = (sorterData) => {
    const sortObj = {}
    if (sorterData.columnKey !== undefined) {
      sortObj.sortColumn =
        sorterData.columnKey === 'isAttractJob'
          ? 'ATTRACT_DAYS'
          : sorterData.columnKey === 'id'
            ? 'NAME'
            : sorterData.columnKey.toUpperCase()
      sortObj.sortDirection = sorterData.order === 'ascend' ? 'ASC' : 'DESC'
      return sortObj
    } else {
      return sortObj
    }
  }
  const locationCollection = (selectedLocations) => {
    if (debouncedValue && !searchText(debouncedValue)) {
      return [...selectedLocations, 'remote']
    } else {
      return selectedLocations
    }
  }
  const handleSearchChange = async () => {
    const searchTextVal = searchText(debouncedValue)

    dispatch(setFilters({
      ...filters,
      locations: locationCollection(locationsTag),
      filterText: searchTextVal
    }))
    setPageAndSize({
      page: 1,
      size: pageAndSize.size,
      pageSizeOptions: pageAndSize.pageSizeOptions
    })

    window.analytics.track('Search', {
      query: debouncedValue
    })
  }
  useEffect(() => {
    if (debouncedValue || debouncedValue === '') handleSearchChange()
  }, [debouncedValue])
  useEffect(() => {
    dispatch(setFilters({ ...filters, filterText: '' }))
    dispatch(setPage(false))
  }, [])

  const handlePageAndSizeChange = async (e, sorter, extra) => {
    setSorterObj(extra)
    await actions.getOpenJobs(
      {
        ...filters,
        filterText: debouncedValue,
        ...handlingSorting(extra)
      },
      e.current - 1,
      e.pageSize
    )
    dispatch(
      setPageSize({
        page: e.current,
        size: e.pageSize,
        pageSizeOptions: e.pageSizeOptions
      })
    )
    setPageAndSize({
      page: e.current,
      size: e.pageSize,
      pageSizeOptions: e.pageSizeOptions
    })
  }

  const handleCountriesTagClose = () => {
    const countriesArr = countriesTag
    countriesArr.pop()
    dispatch(
      setFilters({
        ...filters,
        filterText: searchText(debouncedValue),
        ['Countries'.toLowerCase()]: countriesTag
      })
    )
  }

  const handleLocationsTagClose = (e, index) => {
    e.preventDefault()
    const searchTextVal = searchText(debouncedValue)
    let popingOutLocationTag = locationsTag.filter(
      (ele, ind) => ind !== index
    )

    setLocationsTag(popingOutLocationTag)
    if (debouncedValue && !searchTextVal) {
      popingOutLocationTag = new Set([...popingOutLocationTag, 'remote'])
    }
    dispatch(
      setFilters({
        ...filters,
        filterText: searchTextVal,
        locations: [...popingOutLocationTag]
      })
    )
  }

  const handleClearButton = () => {
    setSearch('')
    setCountriesTag([])
    setLocationsTag([])
    dispatch(
      setFilters({
        ...filters,
        filterText: debouncedValue,
        countries: [],
        locations: []
      })
    )
  }
  // console.log(jobsData)
  return (
    <>
      {/* {jobs.length !== 0 */}
      {/*  ? */}
      <Spin tip={`${t('loadingJobs')}...`} spinning={isLoading}>
        <Card bordered={false}>
          <Space
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              marginTop: '16px'
            }}
          >
            <div style={{ width: '470px !important' }}>
              <Input
                style={{
                  width: '470px',
                  height: '40px',
                  border: '1px dotted #d9d9d9',
                  borderRadius: '5px'
                }}
                allowClear={search !== '' && true}
                suffix={
                  search === '' && <SearchOutlined style={{ fontSize: 16 }} />
                }
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={t(allConstants.SEARCH_BY_ATTRIBUTES)}
              />
            </div>
            <OverlayVisible
              name={allConstants.Locations}
              list={['Remote', ...locations]}
              debouncedValue={debouncedValue}
              filters={filters}
              setTags={setLocationsTag}
              Tags={locationsTag}
              setPageAndSize={setPageAndSize}
              pageAndSize={pageAndSize}
              handlingSorting={handlingSorting}
              sortObj={sorterObj}
              search={search}
            />
          </Space>
          <div
            className={
              countriesTag.length || locationsTag.length
                ? 'tag-div'
                : 'empty-tag-div'
            }
          >
            {countriesTag.length !== 0 && (
              <Tag closable onClose={() => handleCountriesTagClose()}>
                Country: <span className="tag-item">{countriesTag[0]}</span>
              </Tag>
            )}
            {locationsTag.map((item, index) => (
              <Tag
                key={index}
                onClose={(e) => handleLocationsTagClose(e, index)}
                closable
              >
                {t(allConstants.LOCATION)} : <span className="tag-item">{item}</span>
              </Tag>
            ))}
            {(countriesTag.length !== 0 || locationsTag.length !== 0) && (
              <span
                onClick={() => handleClearButton()}
                className="clear-button"
              >
                {t(allConstants.CLEAR)}
              </span>
            )}
          </div>
        </Card>
        <Card bordered={false}>
          <TableWrapper>
            <div className="table-title">{renderTitle}</div>
            <Table
              dataSource={jobs}
              columns={columns(locations, countries, t)}
              rowKey={'requestId'}
              onChange={(e, sorter, extra) =>
                handlePageAndSizeChange(e, sorter, extra)
              }
              pagination={{

                defaultPageSize: 10,
                current: pageAndSize.page,
                defaultCurrent: 1,
                showSizeChanger: true,
                pageSizeOptions: pageAndSize.pageSizeOptions,
                position: ['bottomRight', 'topRight'],
                total: jobsData?.totalRecords
              }}
              // loading={isLoading}
            />
          </TableWrapper>
        </Card>
      </Spin>

    </>
  )
}

function OverlayVisible ({
  name,
  list,
  debouncedValue,
  filters,
  handlingSorting,
  sortObj,
  setTags,
  Tags,
  setPageAndSize,
  pageAndSize
}) {
  const [visible, setVisible] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchedArray, setSearchedArray] = useState([])
  const dispatch = useJobsDispatch()
  const { t } = useTranslation()
  const handleMenuClick = (e) => {
    const searchTextVal = searchText(debouncedValue)
    if (Tags.includes(e.key)) {
      let newCheckeds = Tags.filter((item) => item !== e.key)
      setTags(newCheckeds)
      if (!searchTextVal) {
        newCheckeds = [...newCheckeds, 'remote']
      }
      dispatch(
        setFilters({
          ...filters,
          filterText: searchTextVal,
          ...handlingSorting(sortObj),
          [name.toLowerCase()]: newCheckeds
        })
      )
      setPageAndSize({ ...pageAndSize, page: 1 })
      window.analytics.track('Filter - Locations', {
        [name.toLowerCase()]: newCheckeds
      })
    } else {
      let newCheckeds = name === 'Countries' ? [e.key] : [...Tags, e.key]
      setTags(newCheckeds)
      setPageAndSize({ ...pageAndSize, page: 1 })
      if (!searchTextVal) {
        newCheckeds = [...newCheckeds, 'remote']
      }
      dispatch(
        setFilters({
          ...filters,
          filterText: searchTextVal,
          ...handlingSorting(sortObj),
          [name.toLowerCase()]: newCheckeds
        })
      )
      window.analytics.track('Filter - Locations', {
        [name.toLowerCase()]: newCheckeds
      })
    }
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag)
    !flag && setSearchValue('')
    setSearchedArray([])
  }
  const handleDropDownSearchChange = (e, array) => {
    const searchItem = e.target.value
    setSearchValue(searchItem)
    const filteredArray = array.filter((name) =>
      name.toLowerCase().includes(searchItem.toLowerCase())
    )
    setSearchedArray(filteredArray)
  }
  const menu = (
    <ThemeContainer>
      <Menu selectedKeys={Tags}>
        <Menu.Item>
          <Input
            placeholder={t(allConstants.SEARCH)}
            value={searchValue}
            onChange={(e) => handleDropDownSearchChange(e, list)}
          />
        </Menu.Item>

        <Menu
          selectedKeys={Tags}
          onClick={handleMenuClick}
          style={{ overflow: 'scroll', maxHeight: 200 }}
        >
          {searchedArray.length === 0
            ? Array.isArray(list) &&
              list.map((item) => <Menu.Item key={item}>{item}</Menu.Item>)
            : Array.isArray(searchedArray) &&
              searchedArray.map((item) => (
                <Menu.Item key={item}>{item}</Menu.Item>
              ))}
        </Menu>

        {/* <ThemeContainer>
        <Space style={{ padding: 5 }}>
          <Button type="primary" size="small" onClick={() => { setVisible(false) }} >Ok</Button>
          <Button danger type="primary" size="small" onClick={() => {
            setVisible(false)
            setTags([])
            dispatch(setFilters({ ...filters, filterText: debouncedValue, [name.toLowerCase()]: [] }))
          }}>
            Reset
          </Button>
        </Space>
      </ThemeContainer> */}
      </Menu>
    </ThemeContainer>
  )
  return (
    <ThemeContainer>
      <Dropdown
        overlay={menu}
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        <Button
          style={{
            width: '250px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px dotted #d9d9d9',
            borderRadius: '5px'
          }}
          type="link"
          onClick={(e) => e.preventDefault()}
        >
          {t(name)}
          {Tags.length ? ` (${Tags.length})` : ''}
          <DownOutlined />
        </Button>
      </Dropdown>
    </ThemeContainer>
  )
}

export default JobsTable
