import React from 'react'
import { OptionsContainer, UserOption } from '../style'
import { PropTypes } from 'prop-types'
import { RightOutlined } from '@ant-design/icons'

const Options = ({ src, heading, para, extra, onClick, hoverSrc }) => {
  const [imageHover, setImageTrigger] = React.useState(false)
  return (
        <OptionsContainer
          onMouseOver={() => setImageTrigger(true)}
          onMouseLeave={() => setImageTrigger(false)}
          onClick={onClick}
        >
          <UserOption className='hover-user-options'>
              <div>
                  <img src={imageHover ? hoverSrc : src}/>
              </div>
              <div className='text-container'>
                  <div className='text-heading'>{heading}</div>
                  <div className='text-para'>{para}</div>
                  <div className='text-para'>{extra}</div>
              </div>
              <div className='arrow'>
                  <RightOutlined/>
              </div>
          </UserOption>
        </OptionsContainer>
  )
}
Options.propTypes = {
  src: PropTypes.string,
  hoverSrc: PropTypes.string,
  heading: PropTypes.string,
  para: PropTypes.string,
  extra: PropTypes.object,
  onClick: PropTypes.func
}
export default Options