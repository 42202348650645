export default function StringFilter (string) {
  if (string) {
    if (Array.isArray(string) && string.length < 1) return
    let str = string
    const mapObj = {
      GTE: '',
      LTE: ''
    }
    str = str.replace(/GTE|LTE/gi, function (matched) {
      return mapObj[matched]
    })
    str = str.replace(/\s/g, '')
    str = str.split(':')
    return {
      GTE: str[0],
      LTE: str[1]
    }
  }
}
