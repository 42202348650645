import React from 'react'
import { Input, Row } from 'antd'
import PropTypes from 'prop-types'
// Local Files
import FiltersLayout from '../component/FiltersLayout'
import TagGroup from '../../../components/TagGroup'
// Api helper
import { UpdateActiveCandidateData } from '../../../helpers/QueryHelpers'
import HandleInputTags from '../../../helpers/HandleInputTagsHelpers'
import isAnyField from '../helpers/isAnyField'
import { setIsOpen } from '../helpers/actionHelpers'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'
// import { setSearchApiObj, useQuerysDispatch, useQuerysState } from '../../../CandidateContext/SearchQueryContext'

export default function Language ({
  contextData, existedLanguage, setExistedFilters,
  match, existedFilters, setSearchThroughAdvanceFilter,
  setSearchApiVal
}) {
  const [values, setValues] = React.useState({
    language: [],
    languageValue: '',
    isOpen: false
  })
  const { t } = useTranslation()
  // const dispatch = useQuerysDispatch()
  // const { searchApiObj } = useQuerysState()
  const [advancedSearchFilter, setAdvancedSearchFilter] = React.useState(false)

  const { candidatesFilters, api, matchingResults } = contextData

  const handleInputChange = e => setValues({ ...values, [e.target.name]: e.target.value })

  const handleInpurConfirm = async (activeInputValue, activeInput) => {
    setAdvancedSearchFilter(true)
    const tags = HandleInputTags(activeInputValue, activeInput, values, setValues)
    if (tags.length > 0 && values[activeInputValue]) {
      console.log('check')
      setValues({
        ...values,
        [activeInput]: tags,
        [activeInputValue]: ''
      })
      updateJobApi(activeInput, tags)
    }
  }

  const handleDelete = async (tags, activeTagsType) => {
    const filteredTags = { ...values, [activeTagsType]: tags }
    setValues(filteredTags)
    if (match) {
      setAdvancedSearchFilter(true)
    }
    updateJobApi(activeTagsType, tags)
  }

  const updateJobApi = React.useCallback((activeInput, tags) => {
    UpdateActiveCandidateData({
      shouldHave: { languages: activeInput === 'language' ? tags : values.language }
    },
    candidatesFilters,
    api,
    {
      languages: activeInput === 'language' ? tags : values.language
    },
    matchingResults,
    match
    )
  }, [values])

  React.useEffect(() => {
    setValues({ ...values, language: existedLanguage?.shouldHave || [] })
  }, [existedLanguage])

  React.useEffect(() => {
    if (match && advancedSearchFilter) {
      setExistedFilters({
        ...existedFilters,
        languages: {
          ...existedFilters.languages,
          mustHave: [],
          mustNotHave: [],
          shouldHave: values?.language || []
        }
      })
      setSearchThroughAdvanceFilter(true)
      setAdvancedSearchFilter(false)
      setSearchApiVal(true)
    }
  }, [advancedSearchFilter, values])

  const clearAllFilters = () => {
    if (match) {
      // dispatch(setSearchApiObj(
      //   {
      //     ...searchApiObj,
      //     shouldHave: { ...searchApiObj.shouldHave, languages: [] }

      //   }
      // ))
      setValues({
        ...values,
        language: []
      })
      setAdvancedSearchFilter(true)
    }
    UpdateActiveCandidateData({
      shouldHave: { languages: [] }
    },
    candidatesFilters,
    api,
    {
      language: []
    },
    matchingResults,
    match
    )
  }

  return (
    <FiltersLayout
    title={t(allConstants.LANGUAGE_CAPITAL)}
    activeFilters={ isAnyField(values) }
    clearAllFilters={clearAllFilters}
    setIsOpen={() => setIsOpen(setValues, values)}
    isOpen={values.isOpen}

    >
      <Row className='filters-tags' style={values.language.length > 0 ? { marginTop: '16px' } : undefined} >
        <TagGroup
        tags={values.language}
        onDelete={handleDelete}
        activeTagsType="language"
        />
      </Row>
     {values.isOpen && <Row className='search-skill-row'>
        <Input
        name={'languageValue'}
        placeholder={t(allConstants.ADD_LANGUAGE)}
        value={values.languageValue}
        onChange={handleInputChange}
        onBlur={!match ? () => handleInpurConfirm('languageValue', 'language') : null}
        onPressEnter={() => handleInpurConfirm('languageValue', 'language')}
        />
      </Row>}
    </FiltersLayout>
  )
}

Language.propTypes = {
  contextData: PropTypes.object.isRequired,
  existedLanguage: PropTypes.object,
  setExistedFilters: PropTypes.func,
  match: PropTypes.object,
  existedFilters: PropTypes.object,
  setSearchThroughAdvanceFilter: PropTypes.func,
  setSearchApiVal: PropTypes.func
}
