import React from 'react'
import { Typography } from 'antd'
import { useHistory } from 'react-router-dom'
// style
import './ZendeskBanner.css'
import SearchBar from '../../SearchBar/SearchBar'
// functions
import { handleOnChange, handleSearchClick, handleKeyEnter } from '../../SearchBar/utils'
import { ZendeskBannerBody } from './ZendeskBannerStyles'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'

const { Title } = Typography

function ZendeskBanner () {
  const history = useHistory()
  const [inputValue, setInputValue] = React.useState('')
  const [searchValue, setSearchValue] = React.useState('')
  const { t } = useTranslation()
  return (
        <ZendeskBannerBody className='banner-body' >
                <Title className='banner-title' >{t(allConstants.WELCOME_TWG)}</Title>

                <SearchBar
                  placeholder={t(allConstants.QUERY_SEARCH)}
                  onChange={(e) => handleOnChange(e, setInputValue, setSearchValue)}
                   onClick={() => handleSearchClick(inputValue, history)}
                   onKeyPress={(e) => handleKeyEnter(e, inputValue, history)}
                   value={searchValue}
                />
        </ZendeskBannerBody>
  )
}

export default ZendeskBanner
