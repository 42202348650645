import React, { useEffect, useState } from 'react'
import { Row, Table } from 'antd'
import ZendeskTicketPageWrapper from '../ZendeskTicketPage/ZendeskTicketPageWrapper/ZendeskTicketPageWrapper'
import Columns from './Columns'
import { useZendeskActions } from '../../ZendeskContext'
import { useAuthState } from '../../../../contexts/AuthContext'

// Style
import './ZendeskTickets.css'
import { CreateTicket } from './style'
import CreateModal from '../../Component/CreateTicket/CreateModal/CreateModal'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

function ZendeskTickets () {
  const [filterState, setFilterState] = useState('All Tickets')
  const [filteredInfo, setFilteredInfo] = useState(null)
  const [tickets, setTickets] = useState(null)
  const [IsModal, setIsModal] = useState(false)
  const { t } = useTranslation()
  // Context
  const { user } = useAuthState()
  const actions = useZendeskActions()

  const getUserTickets = async () => {
    if (!user?.email) return
    try {
      const res = await actions.getTicketSummaryForUser(user?.email)
      if (res.length < 1) return
      setTickets(res)
    } catch (err) {
      return console.log(err.message)
    }
  }

  const headerTitle = () => {
    return (
      <Row className='preview-title' >

        {t(allConstants.MY_SUPPORT_TICKET)}
      </Row>
    )
  }
  const handleFilterState = (filters) => {
    console.log(filters)
    if (filters?.status?.[0] === 'NEW') return setFilterState('New')
    else if (filters?.status?.[0] === 'OPEN') return setFilterState('Open')
    else if (filters?.status?.[0] === 'PENDING') return setFilterState('Pending')
    else if (filters?.status?.[0] === 'SOLVED') return setFilterState('Solved')
    else if (filters?.status?.[0] === 'CLOSED') return setFilterState('Closed')
    else return setFilterState('All Tickets')
  }

  const createTicketButton = () => {
    return (
      <CreateTicket onClick={() => setIsModal(true)}>
       {t(allConstants.CREATE_TICKET)}
      </CreateTicket>
    )
  }
  useEffect(() => {
    getUserTickets()
  }, [user])
  return (
        <ZendeskTicketPageWrapper headerTitle={headerTitle()} extra={createTicketButton()} withFooter={false} >
            <CreateModal IsModal={IsModal} setIsModal={setIsModal}/>
            <Table
              className='ticket-table'
              dataSource={tickets}
              columns={Columns(filterState, filteredInfo, t).columns}
              rowKey='id'
              showSorterTooltip={true}
              onChange={(pagination, filters) => { setFilteredInfo(filters); handleFilterState(filters) }}
            />;
        </ZendeskTicketPageWrapper>
  )
}

export default ZendeskTickets
