import React from 'react'
import {
  Button,
  Descriptions,
  Divider,
  Drawer,
  // message,
  notification,
  Popconfirm,
  Space
} from 'antd'
import {
  matchPath,
  Route,
  useHistory,
  useLocation,
  useParams
} from 'react-router'
import { useRequest, useToggle } from 'ahooks'
// import JobInfo from './JobInfo'
// import JobDescriptions from './JobDescriptions'
import UploadCandidates from '../../../../components/UploadCandidates'
import CreateNewJobDescription from './CreateNewJobDescription'
import { useJobsActions, useJobsState } from '../../context/JobsContext'
// import JobEditForm from '../../../../components/JobEditForm'
import {
  DeleteFilled,
  EditOutlined,
  // CloseOutlined,
  // DeleteFilled,
  // EditOutlined,
  LeftOutlined
} from '@ant-design/icons'
// import MoreActions from './MoreActions'
import NewJobDescriptionDrawer from './NewJobDescriptionDrawer/NewJobDescriptionDrawer'
import {
  DescDrawerWrapper,
  JobDescriptionsTitle
} from './NewJobDescriptionDrawer/style'
import { SnackBar } from '../../../applicant-results/components/ApplicantsTable/style'
import LightBulb from '../../../../assets/lightbulb.png'
import { allConstants } from '../../../../constants/constants'
import { useTranslation } from 'react-i18next'

const JobDrawer = () => {
  const [fullScreen, { toggle: toggleFullScreen }] = useToggle(true)
  const [editDrawer, setEditDrawer] = React.useState(false)
  const location = useLocation()
  const history = useHistory()
  const { jobExtId } = useParams()
  const actions = useJobsActions()
  const { selectedJob } = useJobsState()
  const description = selectedJob?.descriptions?.[0]
  const { t } = useTranslation()
  useRequest(async () => jobExtId !== 'new' && actions.getJob(jobExtId), {
    refreshDeps: [jobExtId]
  })

  const matchNew = matchPath(location.pathname, {
    path: '/score/new',
    exact: true,
    strict: false
  })

  const match = matchPath(location.pathname, {
    path: '/score/:jobExtId',
    exact: false,
    strict: false
  })

  const matchWithApplicantseditJob = matchPath(location.pathname, {
    path: '/score/:jobExtId/search/:jobPostExtId/applicants/edit',
    exact: false,
    strict: false
  })
  const matchWithApplicants = matchPath(location.pathname, {
    path: '/score/:jobExtId/search/:jobPostExtId/applicants',
    exact: false,
    strict: false
  })

  const matchWithJobDescription = matchPath(location.pathname, {
    path: '/score/:jobExtId/descriptions',
    exact: false,
    strict: false
  })
  // const onFinish = async (values) => {
  //   await actions.updateJob(selectedJob, values)
  //   message.success({ content: 'Job updated', placement: 'leftTop' })
  //   history.goBack()
  // }

  const onFinishUploadCandidate = async ({ upload, Source }) => {
    history.push(`/score/${selectedJob.externalJobId}/descriptions`)
    notification.success({
      message: 'Candidates are being created',
      description:
        'Candidates will appear shortly after upload, depending on the number of candidates this process may take awhile',
      placement: 'topLeft'
    })
  }

  const handleDeleteJobDescription = (description) => () => {
    try {
      actions.deleteJobPosting(description)
    } catch (e) {
      console.log(e)
    } finally {
      setTimeout(() => {
        actions.getOpenJobs(
          {
            countries: [],
            locations: [],
            regions: []
          },
          0,
          10
        )
      }, '10000')
      history.push('/score')
    }
  }

  // const formEditJobInfo = () => (
  //   <JobEditForm
  //     onCancelClick={() => history.goBack()}
  //     onFinish={onFinish}
  //   />
  // )
  return (
    <Drawer
      placement="right"
      destroyOnClose={true}
      closable={!matchWithJobDescription}
      onClose={
        matchWithJobDescription
          ? () => {
              setEditDrawer(false)
              history.push('/score')
            }
          : () => history.push('/score')
      }
      width={fullScreen ? 720 : '100%'}
      visible={
        (!!match && !matchNew && !matchWithApplicants) ||
        matchWithApplicantseditJob
      }
      bodyStyle={
        matchWithJobDescription ? { paddingTop: 0 } : { paddingBottom: 80 }
      }
    >
      <>
        {/* <Route
            exact
            path="/score/:jobExtId/edit"
            render={() => (
              <>
                {formEditJobInfo()}
              </>
            )}
            strict={false}
          /> */}

        <Route
          exact
          path="/score/:jobExtId/descriptions"
          render={() => (
            <Space direction="vertical" size="middle">
              {!editDrawer && (
                <DescDrawerWrapper>
                  <JobDescriptionsTitle>
                    <div className="title">
                      <span>{t(allConstants.JOB_DESCRIPTION)}</span>
                    </div>
                    <div >
                      <Button
                        className="close-button"
                        type="text"
                        onClick={() => {
                          setEditDrawer(false)
                          history.push('/score')
                        }}
                      >
                        {t('Close')}
                      </Button>
                    </div>
                  </JobDescriptionsTitle>
                  <Divider className="desc-divider" />

                 <div className='sub_header_btn sub_header_btn_edit'>
                 <Popconfirm
                   placement="left"
                   title={t(allConstants.CONFIRM_DELETE)}
                   onConfirm={handleDeleteJobDescription(description)}
                   okText={t(allConstants.YES)}
                   cancelText={t(allConstants.NO)}
                 >
                   <Button
                   icon={<DeleteFilled />}
                   style={{ color: 'red' }}
                    type="text">
                     {t(allConstants.DELETE_JOB)}
                   </Button>
                 </Popconfirm>
                 <Button
                   type="primary"
                   icon={<EditOutlined />}
                   onClick={() => setEditDrawer(true)}
                 >
                   {t(allConstants.EDIT)}
                 </Button>
               </div>
               </DescDrawerWrapper>
              )}
              <NewJobDescriptionDrawer
                editDrawer={editDrawer}
                setEditDrawer={setEditDrawer}
              />
            </Space>
          )}
          strict={false}
        />
        <Route
          exact
          path="/score/:jobExtId/upload"
          render={() => (
            <>
              <Button
                type="text"
                icon={<LeftOutlined />}
                shape="circle"
                onClick={() => history.goBack()}
              />
              <SnackBar>
                <img src={LightBulb} />
                <span>{t(allConstants.REVEAL_MATCHING_SCORE)}</span>
              </SnackBar>
              <Descriptions title={t(allConstants.UPLOAD_CV_TO_SCORE)}>
                <Descriptions.Item>
                  <UploadCandidates onFinish={onFinishUploadCandidate} />
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
          strict={false}
        />
        <Route
          exact
          path="/score/:jobExtId/descriptions/new"
          render={() => (
            <>
              <Button
                type="text"
                icon={<LeftOutlined />}
                shape="circle"
                onClick={() => history.goBack()}
              />
              <Descriptions title={t(allConstants.CREATE_JOB_DESCRIPTION)}>
                <Descriptions.Item>
                  <CreateNewJobDescription
                    toggleFullScreen={toggleFullScreen}
                  />
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
          strict={false}
        />
        <Route
          exact
          path="/score/:jobExtId/descriptions/:descriptionId/edit"
          render={() => (
            <>
              <Divider orientation="left">
                Edit Job Description
                {t(allConstants.EDIT_)}
              </Divider>
              <CreateNewJobDescription toggleFullScreen={toggleFullScreen} />
            </>
          )}
          strict={false}
        />
      </>
    </Drawer>
  )
}

export default JobDrawer
