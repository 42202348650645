import React from 'react'
import { Button } from 'antd'
import { useParams } from 'react-router-dom'
// Local Files
import ProfileView from './ProfileView'
// Style Sheet
import './FilteredData.css'
import { TWGProgress } from '../../../../components/theme-components'
import BlurField from '../../../../components/BlurField/BlurField'
import { allConstants } from '../../../../constants/constants'
export default function FilteredDataColumns (filteredData, selectCandidate, t) {
  const { jobkey } = useParams()
  const ids = []

  const columns = [
    {
      key: 'firstName',
      title: t(allConstants.candidateName),
      dataIndex: 'firstName',
      align: 'center',
      render: function RenderName (text) {
        return (
          <BlurField text={text} name={true}/>
        )
      }
    },
    {
      key: 'score',
      title: t(allConstants.SCORE),
      dataIndex: 'score',
      align: 'center',
      render: function RenderScore (score) {
        return (
          <TWGProgress
          percent={Math.floor(score)}
          type='dashboard'
          width={70}
        />
        )
      }
    },
    // {
    //   key: 'careerLength',
    //   title: 'Career Length',
    //   dataIndex: 'careerLength',
    //   align: 'center'
    // },
    // {
    //   key: 'careerCount',
    //   title: 'Job Count',
    //   dataIndex: 'careerCount',
    //   align: 'center'
    // },
    // {
    //   key: 'source',
    //   title: 'Source',
    //   dataIndex: 'source',
    //   align: 'center',
    //   render: function RenderScore (source) {
    //     return (
    //         <Row className='source' > <Text>{source}</Text> </Row>
    //     )
    //   }
    // },
    {
      key: 'id',
      title: '',
      dataIndex: 'id',
      className: 'discover-table',
      render: function RenderProfile (_id, row) {
        const [values, setValues] = React.useState({
          isOpen: false,
          profileId: ''
        })

        const handletoggle = id => {
          window.analytics.track('View Profile', {
            score: row.score,
            source: row.source,
            careerLength: row.careerLength,
            job_count: row.careerCount,
            search_id: jobkey
          })
          setValues({ isOpen: !values.isOpen, profileId: id })
        }

        React.useEffect(() => ids.push(_id), [_id])

        return (
            <>
              <Button onClick={() => handletoggle(_id)} type='link'>
              {t(allConstants.VIEW_PROFILE)}
              </Button>
                { values.isOpen &&
                <ProfileView
                handletoggle={handletoggle}
                isOpen={values.isOpen}
                payload={{ resultIds: ids, resultSetId: filteredData?.resultSetId }}
                profileIds={ids}
                selectedProfileId= {_id}
                profileView={filteredData}
                /> }
            </>
        )
      }
    }
  ]

  return {
    columns
  }
}
