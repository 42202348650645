import axios from '../../axios-config'
/**
 * POST
 * /discover/api/v1/jobreq
 * uploadJobReq
 */
export const uploadJobR = () => {
  return axios.post('/discover/api/v1/jobreq')
}
/**
 * GET
 * /discover/api/v1/preview/${workbookId}/query/${queryId}
 * getPreview
 */
export const getPreview = (workbookId, queryId) => {
  return axios.get(`/discover/api/v1/preview/${workbookId}/query/${queryId}`)
}
/**
 * POST
 * /discover/api/v1/search
 * postSearch
 */
export const postSearch = () => {
  return axios.post('/discover/api/v1/search')
}
/**
 * GET
 * /discover/api/v1/search/${workbookId}/query/${queryId}
 * getSearch
 */
export const getSearch = (workbookId, queryId) => {
  return axios.get(`/discover/api/v1/search/${workbookId}/query/${queryId}`)
}

export const getDiscoverSearch = (jobKey) => {
  return axios.get(`/discover/api/v1/search/discoverjob/${jobKey}`)
}

/**
 * GET
 * /discover/api/v1/search/cache/${resultSetId}
 * getCachedResults
 */
export const getCachedResults = (resultSetId) => {
  return axios.get(`/discover/api/v1/search/cache/${resultSetId}`)
}

export const getV2PassiveCandidate = (resultSetId) => {
  return axios.get(`/score/api/v2/search/job/result/${resultSetId}`)
}
/**
 * POST
 * /discover/api/v1/search/job/${jobExtId}/posting/${jobPostExtId}/search
 * searchByJobPosting
 */
export const searchByJobPosting = (jobExtId, jobPostExtId) => {
  return axios.post(`/discover/api/v1/search/job/${jobExtId}/posting/${jobPostExtId}/search`)
}
