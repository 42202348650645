import React from 'react'
import { useJobRequisitionsState } from '../../../context/ApplicantsContextProvider'
import FilterCard from '../FilterCard/FilterCard'

export default function QualifiedCard () {
  const { qualified: { list, loading }, passiveLoading } = useJobRequisitionsState()
  return (
    <FilterCard
      name="qualified"
      title="Applicants"
      trackcard="Qualified"
      value={list?.length || 0}
      tooltip="These are the applicants that are best suited for this role"
      loading={loading || passiveLoading}
    />
  )
}
