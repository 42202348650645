import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { AuthProvider } from './AuthContext'
import { UseRequestProvider } from 'ahooks'
import axios from 'axios'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as TWGThemeProvider, ThemeStateContext } from './WhiteLabelContext'
import { notification } from 'antd'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { messages } from '../locales/en/messages.js'
import { defaultLocale, dynamicActivate } from '../i18n'

i18n.load('en', messages)
i18n.activate('en')

const AppProviders = ({ children }) => {
  useEffect(() => {
    // With this method we dynamically load the catalogs
    dynamicActivate(defaultLocale)
  }, [])
  return (
    <I18nProvider i18n={i18n}>
      <UseRequestProvider
        value={{
          refreshOnWindowFocus: false,
          requestMethod: (param) => axios(param),
          onError: (error) => {
            if (error?.isAxiosError) {
              console.log(error?.response?.status)
              switch (error?.response?.status) {
                case 402: notification.error({ placement: 'topLeft', message: 'You do not have any credits remaining. Please contact your Account Manager.' }); break
                case 404: notification.error({ placement: 'topLeft', message: '404 - resource not found' }); break
                case 408: notification.error({ placement: 'topLeft', message: '408 - request timed out' }); break
                case 403: notification.error({ placement: 'topLeft', message: '403 - resource forbidden' }); break
                case 500: notification.error({ placement: 'topLeft', message: '500 - unexpected error' }); break // its me not you
                case 503: notification.error({ placement: 'topLeft', message: '503 - Service Unavailable' }); break
                default: notification.error({ placement: 'topLeft', message: 'Server could not process your request' }); break
              }
              console.log(error?.response?.data)
            } else {
              console.log(error)
              notification.error({ placement: 'topLeft', message: 'Some error happened. Please refresh the page.' })
            }
          }
        }}
      >
        <TWGThemeProvider>
          <ThemeStateContext.Consumer>
            {theme => {
              return (
                <ThemeProvider theme={theme}>
                  <AuthProvider>
                    {children}
                  </AuthProvider>
                </ThemeProvider>
              )
            }}
          </ThemeStateContext.Consumer>
        </TWGThemeProvider>
      </UseRequestProvider>

    </I18nProvider>
  )
}

AppProviders.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppProviders
