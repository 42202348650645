import axios from '../axios-config'
// import Axios from 'axios'

const ZENDESK_BASE_URL = '/twg/api/v1/zendesk/'

/**
 * POST
 * /twg/api/v1/zendesk/ticket/create
 */
export const postCreateTicket = (body) => {
  return axios
    .post(`${ZENDESK_BASE_URL}ticket/create`, body)
}

/**
 * POST
 * /twg/api/v1/zendesk/attachment
 */
export const postCreateTicketAttachment = (body) => {
  return axios
    .post(`${ZENDESK_BASE_URL}attachment`, body)
}

/**
 * GET
 * /twg/api/v1/zendesk/categories
 */
export const getZendeskAllCategories = () => {
  return axios
    .get(`${ZENDESK_BASE_URL}categories`)
}

/**
 * GET
 * /twg/api/v1/zendesk/articles/section/{sections}
 */
export const getZendeskArticlesPerSection = (sectionId, body) => {
  return axios
    .post(`${ZENDESK_BASE_URL}articles/section/${sectionId}`, body)
}

/**
 * GET
 * /twg/api/v1/zendesk/articles
 */
export const getZendeskAllArticles = () => {
  return axios
    .get(`${ZENDESK_BASE_URL}articles`)
}

/**
 * GET
 * /twg/api/v1/zendesk/article/{article_id}
 */

export const getZenedeskArticle = (articleId) => {
  return axios
    .get(`${ZENDESK_BASE_URL}article/${articleId}`)
}

/**
 * POST
 * /twg/api/v1/zendesk/article/search
 * */
export const searchingArticle = (body) => {
  return axios
    .post(`${ZENDESK_BASE_URL}article/search`, body)
}

/**
 * GET
 * /twg/api/v1/zendesk/users
 */
export const getZendeskAllUsers = () => {
  return axios
    .get(`${ZENDESK_BASE_URL}users`)
}

/**
 * GET
 * /twg/api/v1/zendesk/user/{email}
 */
export const getZendeskUser = (email) => {
  return axios
    .get(`${ZENDESK_BASE_URL}user/${email}`)
}

/**
 * GET
 * /twg/api/v1/zendesk/tickets/{email}
 */
export const getTicketSummaryForUser = (email) => {
  return axios
    .get(`${ZENDESK_BASE_URL}tickets`)
}

/**
 * GET
 * /twg/api/v1/zendesk/ticket/{ticket_id} */
export const getZendeskTicket = (id) => {
  return axios
    .get(`${ZENDESK_BASE_URL}ticket/${id}`)
}

/**
 * POST
 * /twg/api/v1/zendesk/ticket/{ticket_id}/comment/add */
export const postCreateComment = async (id, payload) => {
  return axios
    .post(`${ZENDESK_BASE_URL}ticket/${id}/comment/add`, payload)
}

/**
 * GET
 * /twg/api/v1/zendesk/ticket/${id}/comments */
export const getComments = (id) => {
  return axios
    .get(`${ZENDESK_BASE_URL}ticket/${id}/comments`)
}
