import { Modal } from 'antd'
import styled from 'styled-components'

export const ModalForAboutUs = styled(Modal)`
  & .ant-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 599px;
    border-radius: 4px;
  }

  & .ant-modal-body {
    margin: 24px 0px 0px 0px;
    padding: 0px;
  }

  & .ant-modal-footer {
    text-align: right;
    width: 95%;
    padding: 16px 0px 24px 0px;
    border-top: 1px solid #f0f0f0;

    & .ant-btn {
      width: 66px;
      height: 32px;
      padding: 5px 16px;
      border-radius: 2px;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
      border: solid 1px #00b7c0;
      background-color: #fff;
      color: #00b7c0;
    }
  }

  & img {
    margin-bottom: 24px;
  }

  & p {
    width: 510px;
    font-size: 16px;
    line-height: 1.5;
    color: #343434;
    margin-bottom: 16px;
  }

  & div {
    text-align: center;
  }

  & .version-details {
    padding: 16px;
    border-radius: 4px;
    background-color: #f9f9f9;

    & span {
      font-size: 16px;
      line-height: 1.5;
      color: #343434;
    }

    & .value {
      font-weight: 600;
    }
  }

  & .version-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }

  & .link-container {
    margin-bottom: 24px;
    & span:first-child {
      margin-right: 16px;
    }
  }

  & .footer-text {
    text-align: center;
    font-size: 14px;
    line-height: 1.71;
    color: #343434;
  }

  /* & .button-container {
    text-align: right;

    & .ant-btn {
      width: 66px;
      height: 32px;
      padding: 5px 16px;
      border-radius: 2px;
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
      border: solid 1px #00b7c0;
      background-color: #fff;
      color: #00b7c0;
    }
  } */
`
export const Link = styled.span`
  flex-grow: 0;
  font-size: 14px;
  line-height: 1.57;
  color: #00b7c0;
  text-decoration: underline;
  cursor: pointer;
`
