/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Upload, Input, Space, Form, Button } from 'antd'
import { createCandidatesWithCv } from '../api-twg/score/score-controller'
import { useJobsState } from '../pages/jobs/context/JobsContext'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../constants/constants'

function UploadCandidates ({ onFinish }) {
  const [uploading, setUploading] = useState(false)
  const { selectedJob } = useJobsState()
  const { t } = useTranslation()

  const onFinishUploadCandidate = async (values) => {
    window.analytics.track('Upload applicants', {
      job_id: selectedJob?.requestId,
      job_title: selectedJob?.title?.toLowerCase(),
      location: selectedJob?.location?.toLowerCase(),
      applicants: selectedJob?.applicantCount
    })

    const { upload, Source } = values
    const { fileList } = upload
    const form = new FormData()
    const object = { source: Source }

    fileList.forEach((file) => form.append('files', file.originFileObj))
    form.append('request', JSON.stringify(object))
    setUploading(true)
    try {
      await createCandidatesWithCv(selectedJob.externalJobId, form)
      onFinish && onFinish({ upload, Source })
    } catch (e) {
      console.log(e)
    } finally {
      setUploading(false)
    }

    window.analytics.track('Candidates Uploaded', {
      source: Source
    })
    upload.fileList = []
  }

  const MyUpload = ({ onChange, value, ...props }) => (
    <Upload.Dragger
      accept=".doc,.docx,.html,.pdf,.rtf"
      onChange={onChange}
      multiple beforeUpload={() => false} {...value} {...props}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{t(allConstants.FILE_LIMIT_MESSAGE)}</p>
    </Upload.Dragger>
  )
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <p style={{ fontSize: '16px', margin: '-8px 0px 5px 0px' }}>{t(allConstants.LOADED_APPLICANT_MATCH_MESSAGE)}</p>
      <Form
        onFinish={onFinishUploadCandidate}
        layout="vertical"
        requiredMark={true}
      >
        <Form.Item
          name="upload"
          required rules={[{ required: true, message: t(allConstants.FILE_VALIDATION) }]}
          help={t(allConstants.ALLOWED_FILE_EXTENSIONS)}
        >
          <MyUpload />
        </Form.Item>
        <p style={{ marginTop: '15px' }}>{t(allConstants.LABEL_ORIGINATING_SOURCE)}</p>
        <Form.Item
          style={{ marginTop: '-15px' }}
          name="Source"
          label={t(allConstants.SOURCE_LABEL)}
        // help="Add Applicant's Source (Indeed, ZipRecruiter, etc.)"
        >
          <Input placeholder={t(allConstants.TASKS_EXAMPLES)} />
        </Form.Item>
        <Form.Item style={{ marginTop: '-10px' }}>
          <Button
            // style={{ marginTop: 15 }}
            type="primary"
            htmlType="submit"
            loading={uploading}
          >
            {uploading ? t(allConstants.UPLOADING) : t(allConstants.UPLOAD)}
          </Button>
        </Form.Item>
      </Form>
      <div style={{ marginTop: '-25px', color: 'rgba(0, 0, 0, 0.85)', fontSize: '15px' }}>
        <InfoCircleOutlined /> <span>{t(allConstants.MATCH_PROCESSING_TIME)}</span>
      </div>
    </Space>
  )
}

export default UploadCandidates
