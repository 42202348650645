import React from 'react'
import { Row, Form, Input, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
// Local Files
import FiltersLayout from '../component/FiltersLayout'
import { setIsOpen } from '../helpers/actionHelpers'
import FiltersCount from '../component/FiltersCount'
import FiltersRadio from '../component/FiltersRadio'
import TagGroup from '../../../components/TagGroup'
// Api helper
import { UpdateActiveCandidateData } from '../../../helpers/QueryHelpers'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'
export default function Location ({
  contextData, existedLocation, setExistedFilters,
  match, existedFilters, setSearchThroughAdvanceFilter,
  setSearchApiVal
}) {
  const [values, setValues] = React.useState({
    cityPostCode: null,
    postCode: null,
    distance: 60,
    cityPostCodenValue: null,
    postCodeValue: null,
    distanceValue: null,
    isOpen: false,
    isCity: false,
    isStopBlur: false
  })
  const [advancedSearchFilter, setAdvancedSearchFilter] = React.useState(false)

  const { candidatesFilters, api, matchingResults } = contextData
  const { t } = useTranslation()

  const handleInputChange = e => setValues({ ...values, [e.target.name]: e.target.value })

  const handleInpurConfirm = async (activeInputValue, activeInput) => {
    setAdvancedSearchFilter(true)
    if (!values[activeInput] && !existedLocation[activeInput]?.mustHave) return
    updateJobApi(values[activeInput])
  }
  const updateJobApi = React.useCallback(async (isDistance) => {
    try {
      await UpdateActiveCandidateData({
        mustHave: {
          location: values.location,
          postCode: values.postCode,
          distance: ((values.location || values.postCode || isDistance) && !values.distance) ? '60' : values.distance
        }
      },
      candidatesFilters,
      api,
      {
        location: values.location || null,
        postCode: values.postCode || null,
        distance: values.distance || 60
      },
      matchingResults,
      match
      )
    } catch (e) {
      console.log(e.message)
    }
  }, [values])

  React.useEffect(() => {
    setValues({
      ...values,
      remote: !existedLocation?.location?.mustHave && !existedLocation?.postCode?.mustHave,
      location: existedLocation?.location?.mustHave,
      postCode: existedLocation?.postCode?.mustHave || null,
      isCity: !!existedLocation?.location?.mustHave || ((values.isCity && !values.remote) && true),
      // distance: existedLocation?.distance?.mustHave
      distance: existedLocation?.distance?.mustHave || values.distance
    })
  }, [existedLocation])

  React.useEffect(() => {
    if (match && advancedSearchFilter) {
      setExistedFilters({
        ...existedFilters,
        location: {
          ...existedFilters.location,
          distance: {
            mustHave: values?.distance || '',
            mustNotHave: []
          },
          location: {
            mustHave: values?.location || '',
            mustNotHave: []
          },
          postCode: {
            mustHave: values?.postCode || null,
            mustNotHave: []
          }
        }
      })
      setSearchThroughAdvanceFilter(true)
      setAdvancedSearchFilter(false)
      setSearchApiVal(true)
    }
  }, [advancedSearchFilter, values])

  const emptyInput = async (inputType) => {
    if (!match) {
      const payload = (({ cityPostCodenValue, postCodeValue, distanceValue, isOpen, isCity, cityPostCode, isStopBlur, ...item }) => item)(values)
      await UpdateActiveCandidateData({
        mustHave: {
          ...payload,
          [inputType]: null,
          distance: 60
        }
      },
      candidatesFilters,
      api,
      {
        ...payload,
        [inputType]: null,
        distance: null
      },
      matchingResults,
      match
      )
    } else {
      setAdvancedSearchFilter(true)
    }
    setValues({ ...values, [inputType]: '', distance: 60 })
  }

  const handleCityPostCodeSwitch = async isCity => {
    if (!match) {
      await UpdateActiveCandidateData(
        { mustHave: { distance: values.distance, postCode: null, location: null } },
        candidatesFilters,
        api,
        { postCode: [], location: [], matchingResults },
        null,
        match
      )
    }
    setValues({ ...values, postCode: null, location: null, isCity, distance: values.distance, remote: false })
  }

  const handleRemote = async () => {
    if (!match) {
      await UpdateActiveCandidateData(
        { mustHave: { distance: null, postCode: null, location: null } },
        candidatesFilters,
        api,
        { postCode: [], location: [], matchingResults },
        null,
        match
      )
    } else { setAdvancedSearchFilter(true) }
    setValues({ ...values, postCode: null, location: null, isCity: false, remote: true })
  }
  const clearField = field => (
    <span className='location-close-icon' onClick={() => emptyInput(field)}>
      x
    </span>
  )
  return (
    <FiltersLayout
      setIsOpen={() => setIsOpen(setValues, values)}
      isOpen={values.isOpen}
      title={t('LOCATION')}
      islocationToolTip={true}
    >
      <Row className={values.isOpen ? 'city-postCode-container' : undefined}>
        <Row className={values.isOpen ? 'margin-top-row' : undefined} style={{ width: '100%' }}>
          <Form className='block-level-form'>
          {(values.isOpen || values.remote) &&
          <FiltersCount
            title={t(allConstants.REMOTE)}
            classNames={'filters-sub-heading'}
          >
            <FiltersRadio
              isOpen={values.isOpen }
              checked={values.remote}
              onChange={() => handleRemote()}
              dissabled={true}
              label={t(allConstants.REMOTE)}
              name="location"
              id="isCity"
            />
          </FiltersCount>
          }
          </Form>
        </Row>
        {/* Zip / PostCode section */}
        <Row className={values.isOpen ? 'margin-top-row' : undefined} style={{ width: '100%' }} >
          <Form className='block-level-form' style={values.isOpen ? { marginTop: '-20px' } : {}}>
            {(values.isOpen || values.postCode) &&
              <FiltersCount
                ExcludeField={[]}
                clearExcludeFilters={() => UpdateActiveCandidateData({ mustHave: { postCode: '' } }, candidatesFilters, api, { postCode: [], matchingResults, match })}
                // title={'Zip / Postcode:'}
                title={`${t(allConstants.ZIP)} / ${t(allConstants.POST_CODE)}: `}
                classNames={'filters-sub-heading'}
              >
                <FiltersRadio
                  isOpen={values.isOpen}
                  checked={!values.isCity && !values.remote}
                  onChange={() => handleCityPostCodeSwitch(false)}
                  dissabled={true}
                  label={`${t(allConstants.ZIP)} / ${t(allConstants.POST_CODE)}`}
                  name="location"
                  id="isCity"
                />
              </FiltersCount>
            }
            {values.postCode && !values.isOpen &&
              <Row className='tags-label filters-tags' >
                <TagGroup
                  tags={[values.postCode || []]}
                  onDelete={() => emptyInput('postCode')}
                />
              </Row>}
            {
              values.isOpen &&
              <Form.Item>
                <Input
                  placeholder={t(allConstants.ADD_POSTAL_CODE)}
                  value={values.postCode}
                  name="postCode"
                  onChange={handleInputChange}
                  onPressEnter={() => handleInpurConfirm('postCodeValue', 'postCode')}
                  onBlur={!match ? () => handleInpurConfirm('postCodeValue', 'postCode') : null}
                  className='single-select-input'
                  disabled={values.isCity || values.remote}
                />

                {(values.postCode) && clearField('postCode')}
              </Form.Item>
            }
          </Form>
        </Row>

        {/* Location section */}
        <Row className={values.isOpen ? 'margin-top-row' : undefined} style={{ width: '100%' }} >
          <Form className='block-level-form' >
            {(values.isOpen || values.location) &&
              <FiltersCount
                ExcludeField={[]}
                clearExcludeFilters={() => UpdateActiveCandidateData({ mustHave: { location: '' } }, candidatesFilters, api, { location: [], matchingResults, match })}
                classNames={'filters-sub-heading'}
              >
                <FiltersRadio
                  isOpen={values.isOpen}
                  checked={values.isCity && !values.remote}
                  onChange={() => handleCityPostCodeSwitch(true)}
                  label={`${t(allConstants.CITY)} , ${t(allConstants.STATE)}`}
                  name="location"
                  id="isCity"
                />
              </FiltersCount>
            }
            {values.location && !values.isOpen &&
              <Row className='tags-label filters-tags' >
                <TagGroup
                  tags={[values.location || []]}
                  onDelete={() => emptyInput('location')}
                />
              </Row>}
            {values.isOpen &&
              <Form.Item>

                <Row className='tags-label filters-tags'> </Row>

                <Input
                  placeholder={`${t(allConstants.ADD)} ${t(allConstants.CITY)}, ST `}
                  value={values.location}
                  name="location"
                  onChange={handleInputChange}
                  onPressEnter={() => handleInpurConfirm('locationValue', 'location')}
                  onBlur={!match ? () => handleInpurConfirm('locationValue', 'location') : null}
                  className='single-select-input'
                  disabled={!values.isCity || values.remote}
                />
                {(values.location) && clearField('location')}
              </Form.Item>}
          </Form>
        </Row>

        {/* Distance section */}
        <Row className={values.isOpen ? 'margin-top-row' : undefined} style={{ width: '100%' }} >
          <Form className='block-level-form' >
            {(values.isOpen || (values.distance && !values.remote)) &&
              <FiltersCount
                ExcludeField={[]}
                clearExcludeFilters={() => UpdateActiveCandidateData({ mustHave: { distance: '' } }, candidatesFilters, api, { distance: [], matchingResults, match })}
                classNames={'filters-sub-heading'}
              >
                {(values.isOpen || (values.distance && !values.remote)) && <div>
                  <span style={{ marginRight: '5px' }} >{t(allConstants.DISTANCE_UNIT)}:</span>
                  <Tooltip placement="topLeft" title={t(allConstants.LOCATION_TEXT)}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>}
              </FiltersCount>
            }
            {values.distance && !values.isOpen && !values.remote &&
              <Row className='tags-label filters-tags' >
                <TagGroup
                  tags={[values.distance] || []}
                  onDelete={() => emptyInput('distance')}
                />
              </Row>}
            {values.isOpen &&
              <Form.Item>

                <Row className='tags-label filters-tags'> </Row>
                <Input
                  placeholder={t(allConstants.ADD_DISTANCE)}
                  value={values.distance ?? ((values.location || values.postCode) && '60')}
                  name="distance"
                  onChange={handleInputChange}
                  onPressEnter={() => handleInpurConfirm('distanceValue', 'distance')}
                  onBlur={!match ? () => handleInpurConfirm('distanceValue', 'distance') : null}
                  className='single-select-input'
                  disabled={values.remote}
                />
                {(values.distance) && clearField('distance')}
              </Form.Item>}
          </Form>
        </Row>
      </Row>
    </FiltersLayout>
  )
}

Location.propTypes = {
  contextData: PropTypes.object.isRequired,
  existedLocation: PropTypes.object,
  setExistedFilters: PropTypes.func,
  match: PropTypes.object,
  existedFilters: PropTypes.object,
  setSearchThroughAdvanceFilter: PropTypes.func,
  setSearchApiVal: PropTypes.func
}
