import React from 'react'
import { PropTypes } from 'prop-types'
import { Layout, Divider, Tag } from 'antd'
import { useQuerysDispatch, useQuerysState, setSearchBarValue, setSearchApiObj, useQueryActions } from '../../CandidateContext/SearchQueryContext'
import { AdvancedSearchContainer, FilterContainer, FilterHeading, LocationHeading, TagsWrapper } from './style'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

const { Sider } = Layout

const SimpleSearchFilter = ({ setAdvanceSearchVal }) => {
  const [refresh, setRefresh] = React.useState(false)
  const { t } = useTranslation()
  const { isLoading, searchApiObj, searchedCandidates } = useQuerysState()
  const { title, skills, location } = useParams()
  const skillsArray = decodeURIComponent(skills).split(',')
  const dispatch = useQuerysDispatch()
  const action = useQueryActions()
  React.useEffect(() => {
    dispatch(setSearchApiObj(
      {
        ...searchApiObj,
        mustNotHave: { ...searchApiObj.mustNotHave },
        shouldHave: { ...searchApiObj.shouldHave, expertise: [...skillsArray], current_title: title === 'no_title' ? [] : [decodeURIComponent(title)] },
        mustHave: { ...searchApiObj.mustHave, location: location === 'no_location' ? '' : location }
      }
    ))
    dispatch(setSearchBarValue({ title, location, skills: skillsArray }))
    if (!searchedCandidates) setRefresh(true)
  }, [])

  React.useEffect(() => {
    if (refresh) {
      action.getCandidatesFromSearch({ annotations: searchApiObj })
    }
  }, [refresh])
  return (
    <Sider
      className='Filters-body'
      style={isLoading
        ? { pointerEvents: 'none' }
        : undefined}
    >
      <FilterContainer>
        <FilterHeading>{t(allConstants.SEARCH_BY_JOB_TITLE)}</FilterHeading>
        {title !== 'no_title' && <TagsWrapper> <Tag>{decodeURIComponent(title)}</Tag></TagsWrapper>}
        <Divider/>
        <FilterHeading>{t(allConstants.SKILLS)}</FilterHeading>
        <TagsWrapper>{skillsArray.map((item, ind) => <Tag className='skills-tag' key={ind}>{decodeURIComponent(item)}</Tag>)}</TagsWrapper>
        <Divider/>
        <FilterHeading>{t(allConstants.LOCATION)}</FilterHeading>
        {location !== 'no_location' && <LocationHeading>{t(allConstants.CITY)}, {t(allConstants.STATE)}</LocationHeading>}
        <TagsWrapper><Tag>{location === 'no_location' ? t(allConstants.REMOTE) : location}</Tag></TagsWrapper>
        <Divider/>
        <AdvancedSearchContainer>
            <div>
              <span className={isLoading ? 'disable-link-style' : 'link-style'} onClick={() => setAdvanceSearchVal(true)}>
              {t(allConstants.CLICK_HERE_CAPITAL)},
              </span>
            <span style={{ marginLeft: '3px' }}>{t(allConstants.ADVANCED_SEARCH)}</span>
            </div>
        </AdvancedSearchContainer>
      </FilterContainer>
    </Sider>
  )
}
SimpleSearchFilter.propTypes = {
  setAdvanceSearchVal: PropTypes.func
}

export default SimpleSearchFilter