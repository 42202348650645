import React, { useState, useEffect } from 'react'
// import { useHistory } from 'react-router'
import { Typography, Form, Input, Select, Button, notification } from 'antd'
// context
import { useQuerysState, useQueryActions, useQuerysDispatch, setIsLoading, setGreenhouseResult } from '../../GreenhouseEstimatorContext'
// Styling
import './GreenhouseAccountStyle.css'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'
const { Title } = Typography
const { Option } = Select

function GreenHouseAccountInfoForm () {
  const [values, setValues] = useState({
    atsApiKey: null,
    JobId: null,
    sinceDate: -1
  })
  // const history = useHistory()
  const { t } = useTranslation()
  const { accountType } = useQuerysState()
  const actions = useQueryActions()
  const dispatch = useQuerysDispatch()

  const onChange = e => setValues({ ...values, [e.target.name]: e.target.value })

  // following since date method taken help from https://stackoverflow.com/questions/1648392/get-a-date-object-six-months-prior-from-another-date-object
  const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate()

  const getSinceDate = (months) => {
    const date = new Date()
    // date.setDate(1)
    date.setMonth(date.getMonth() + months)
    date.setDate(Math.min(date.getDate(), getDaysInMonth(date.getFullYear(), date.getMonth() + 1)))
    console.log(date)
    return date.toISOString()
  }

  const handleSubmit = async () => {
    try {
      const date = getSinceDate(parseInt(values.sinceDate) || -1)
      if (!date) return
      const payload = values
      payload.sinceDate = date
      payload.atsType = 'GHIO'

      dispatch(setIsLoading(true))
      let res
      if (accountType === 'fullAccount') res = await actions.getEstimate(payload)
      else res = await actions.getEstimateForJob(payload)
      if (!res) {
        function greenhouseErrorAlert (type) {
          notification[type]({
            message: 'ERROR',
            description: 'Something went wrong, contact engineering for further details',
            duration: 0
          })
        }
        greenhouseErrorAlert('error')
        return
      }
      function greenhouseAlert (type) {
        notification[type]({
          message: 'Success',
          description: 'Greenhouse Integration was successful',
          duration: 0

        })
      }
      greenhouseAlert('success')

      dispatch(setGreenhouseResult(true))
      return
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (accountType === 'fullAccount') {
      setValues({
        atsApiKey: null,
        sinceDate: '-1'
      })
    } else {
      setValues({
        atsApiKey: null,
        JobId: null,
        sinceDate: '-1'
      })
    }
  }, [accountType])

  return (
    < >
      <Title level={5} >{t(allConstants.FILL_INFO_REGARDING_API_KEY)}</Title>

      <Form className='singleAccountForm' >
        <Input placeholder={t(allConstants.GREENHOUSE_API_KEY)} value={values.atsApiKey} onChange={onChange} name='atsApiKey' />
        {(accountType !== 'fullAccount') && <Input placeholder={t(allConstants.GREENHOUSE_JOB_ID)} value={values.JobId} onChange={onChange} name='JobId' />}

        <Title className='days-title' level={5}>{t(allConstants.DAYS_BACK_ESTIMATE)}</Title>
        <Select defaultValue="-1" value={values.sinceDate} className='select-job' onChange={(value) => setValues({ ...values, sinceDate: value })} >
          <Option value="-1">30 {t(allConstants.DAYS)}</Option>
          <Option value="-2">60 {t(allConstants.DAYS)}</Option>
          <Option value="-3">90 {t(allConstants.DAYS)}</Option>
          <Option value="-6">6 {t(allConstants.MONTHS)}</Option>
          <Option value="-9">9 {t(allConstants.MONTHS)}</Option>
          <Option value="-12">1 {t(allConstants.YEAR)}</Option>
        </Select>

        <Button className='job-form-button' disabled={Object.values(values).some(elem => !elem)} type='primary' block onClick={handleSubmit} > {t(allConstants.ESTIMATE)}  </Button>
      </Form>
    </>
  )
}

export default GreenHouseAccountInfoForm
