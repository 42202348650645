import React, { useEffect, useMemo } from 'react'
import { PropTypes } from 'prop-types'
import * as userApi from '../api-twg/twg/user-controller'
import * as oauthApi from '../api-twg/oauth'
import * as circaRedirectApi from '../api-twg/twg/circa-redirect-controller'
import * as VersionApi from '../api-twg/score/version-controller/index'
import { clear } from '../pages/jobs/context/JobsContext'
import { useRequest } from 'ahooks'
// style
import './zendesk.css'

/** action types */
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'
export const SET_FLAG = 'SET_FLAG'
export const SET_USER = 'SET_USER'
export const SET_ABOUT_US_MODAL_VAL = 'SET_ABOUT_US_MODAL_VAL'
export const SET_RELEASE_DATA = 'SET_RELEASE_DATA'

/** action creators */
export const loginSuccess = (data) => ({ type: LOGIN_SUCCESS, data })
export const logout = () => ({ type: LOGOUT })
export const setUser = (user) => ({ type: SET_USER, user })
export const setFlag = (flag) => ({ type: SET_FLAG, flag })
export const setAboutUsModalVal = (aboutUsModalVal) => ({ type: SET_ABOUT_US_MODAL_VAL, aboutUsModalVal })
export const setReleaseData = (releaseData) => ({ type: SET_RELEASE_DATA, releaseData })

/** async actions */
const getUser = (dispatch) => async (token) => {
  const { data } = await userApi.getMe(token) || {}
  const flag = await userApi.getFeatureFlag(token)
  dispatch(setUser(data))
  dispatch(setFlag(flag?.data?.features))
  if (data) {
    // window.zE('webWidget', 'hide') // hide temporary
    // window.zE('webWidget', 'prefill', {
    //   email: {
    //     value: data.email,
    //     readOnly: true // optional
    //   },
    //   name: {
    //     value: data.fullName,
    //     hide: true,
    //     readOnly: true // optional
    //   }
    // })
  }
  return data
}

const loginUser = (dispatch) => async ({ email, password }) => {
  const { data } = await oauthApi.loginUser({ email, password })
  const user = await getUser(dispatch)(data.access_token)
  sessionStorage.setItem('access_token', data.access_token)
  sessionStorage.setItem('expires_in', data.expires_in)
  sessionStorage.setItem('refresh_token', data.refresh_token)
  sessionStorage.setItem('refresh_expires_in', data.refresh_expires_in)
  dispatch(loginSuccess({
    access_token: data.access_token,
    expires_in: data.expires_in,
    refresh_token: data.refresh_token,
    refresh_expires_in: data.refresh_expires_in
  }))
  return {
    ...data,
    ...user
  }
}

const getOidcToken = (dispatch) => async (token) => {
  const { data } = await circaRedirectApi.getOidcToken(token)
  if (data.access_token === 'error') {
    return Promise.reject(new Error('Error'))
  }
  await getUser(dispatch)(data.access_token)
  sessionStorage.setItem('access_token', data.access_token)
  sessionStorage.setItem('expires_in', data.expires_in)
  sessionStorage.setItem('refresh_token', data.refresh_token)
  sessionStorage.setItem('refresh_expires_in', data.refresh_expires_in)
  dispatch(loginSuccess({
    access_token: data.access_token,
    expires_in: data.expires_in,
    refresh_token: data.refresh_token,
    refresh_expires_in: data.refresh_expires_in
  }))
}

const logoutUser = (dispatch) => () => {
  sessionStorage.clear()
  dispatch(logout())
  window.analytics.track('logout')
}

const getReleaseData = (dispatch) => async () => {
  try {
    const { data } = await VersionApi.getVersionId()
    dispatch(setReleaseData(data))
  } catch (error) {
    console.log(error)
  }
}

/** initial value */
const initialState = {
  user: null,
  flag: null,
  auth: !!sessionStorage.access_token,
  access_token: sessionStorage.access_token,
  expires_in: sessionStorage.expires_in,
  refresh_token: sessionStorage.refresh_token,
  refresh_expires_in: sessionStorage.refresh_expires_in,
  aboutUsModalVal: false,
  releaseData: { }
}

/** reducer */
export function reducer (state, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        auth: !!sessionStorage.access_token,
        access_token: action.data.access_token,
        expires_in: action.data.expires_in,
        refresh_token: action.data.refresh_token,
        refresh_expires_in: action.data.refresh_expires_in
      }
    case LOGOUT:
      return initialState
    case SET_USER:
      return { ...state, user: action.user }
    case SET_FLAG:
      return { ...state, flag: action.flag }
    case SET_ABOUT_US_MODAL_VAL:
      return { ...state, aboutUsModalVal: action.aboutUsModalVal }
    case SET_RELEASE_DATA:
      return { ...state, releaseData: action.releaseData }
    default:
      return state
  }
}

/** contexts */
const AuthActionsContext = React.createContext(undefined, undefined)
const AuthStateContext = React.createContext(undefined, undefined)
const AuthDispatchContext = React.createContext(undefined, undefined)

function AuthProvider ({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [actions] = React.useState({
    getUser: getUser(dispatch),
    loginUser: loginUser(dispatch),
    logoutUser: logoutUser(dispatch),
    getOidcToken: getOidcToken(dispatch),
    getReleaseData: getReleaseData(dispatch)
  })

  const { loading: isLoadingUser } = useRequest(() => getUser(dispatch)(sessionStorage.access_token), { manual: true })

  useEffect(() => {
    sessionStorage.access_token && getUser(dispatch)(sessionStorage.access_token)
  }, [])

  useEffect(() => { !state.auth && dispatch(clear()) }, [state.auth])

  const value = useMemo(() => ({ ...state, isLoadingUser }), [isLoadingUser, state])

  return (
    <AuthActionsContext.Provider value={actions}>
      <AuthStateContext.Provider value={value}>
        <AuthDispatchContext.Provider value={dispatch}>
          {children}
        </AuthDispatchContext.Provider>
      </AuthStateContext.Provider>
    </AuthActionsContext.Provider>
  )
}

/** hooks */
function useAuthActions () {
  const context = React.useContext(AuthActionsContext)
  if (context === undefined) {
    throw new Error('useAuthActions must be used within a AuthProvider')
  }
  return context
}

function useAuthState () {
  const context = React.useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider')
  }
  return context
}

function useAuthDispatch () {
  const context = React.useContext(AuthDispatchContext)
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider')
  }
  return context
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { AuthProvider, useAuthActions, useAuthState, useAuthDispatch }
