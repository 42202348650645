import { Card } from 'antd'
import styled from 'styled-components'

export const CardContent = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  justify-content: center;
`
export const SpinContainer = styled.div`
 width: 100% !important;
  height: 100% !important;
  .ant-spin-nested-loading {
    width: 100% !important;
    height: 100% !important;
    .ant-spin-container {
      width: 100% !important;
      height: 100% !important;
    }
  }
`
export const SelectableCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  height: 100% !important;
  background-color: ${({ theme, selected }) => selected ? theme.primary_color || 'rgba(7, 227, 233, 0.8)' : 'white'};
  /* color: ${({ selected }) => selected ? 'white' : 'black'}; */
  h1, .anticon-info-circle {
    color: ${({ selected }) => selected ? 'white' : 'black'};
    margin: 0
  }
  span {
    margin: 0
  }
  box-shadow: ${({ selected }) => selected ? '0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);' : ''};
  .ant-divider {
    border-top : ${({ selected }) => selected ? '1px solid white' : '1px solid rgba(0, 0, 0, 0.06)'};
  }
  .ant-card-body {
    padding: 30px 
  }
`

export const Title = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
`
export const Value = styled.h1`
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0px;
  text-align: center;
`
export const Description = styled.span`  
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #A1A1A1;
`
