import { Spin } from 'antd'
import React from 'react'
import { useJobsState } from '../../../context/JobsContext'
import EditJobDesc from './components/EditJobDesc'
import JobDesc from './components/JobDesc'
import { SpinContainer } from './style'
import PropTypes from 'prop-types'

const NewJobDescriptionDrawer = ({ editDrawer, setEditDrawer }) => {
  const { selectedJob } = useJobsState()

  if (!selectedJob && !editDrawer) return <SpinContainer><Spin /></SpinContainer>

  return (
    <>
     {!editDrawer
       ? <JobDesc selectedJob={selectedJob} setEditDrawer={setEditDrawer}/>
       : <EditJobDesc selectedJob={selectedJob} setEditDrawer={setEditDrawer} editDrawer={editDrawer}/>
     }
     </>
  )
}

NewJobDescriptionDrawer.propTypes = {
  editDrawer: PropTypes.bool,
  setEditDrawer: PropTypes.func
}
export default NewJobDescriptionDrawer