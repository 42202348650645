export const sortByName = (a, b) => a.localeCompare(b)

export const normalizeAxiosResponse = response => normalizeAxiosResponseData => {
  if (!response) return response
  if (!normalizeAxiosResponseData) return response
  return ({
    ...response,
    data: normalizeAxiosResponseData(response.data)
  })
}
