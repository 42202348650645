import React from 'react'
import ReactQuill from 'react-quill'
import RichEditorToolbar, { modules, formats } from './RichEditorToolbar'
import { PropTypes } from 'prop-types'

export default function RichEditor (props) {
  return (
    <div>
      <RichEditorToolbar />
      <ReactQuill
        style={ props.selectedJob ? { height: 'calc(100vh - 310px)', overflowY: 'auto' } : {}}
        theme="snow"
        modules={modules}
        formats={formats}
        {...props}
      />
    </div>
  )
}
RichEditor.propTypes = {
  selectedJob: PropTypes.object
}
