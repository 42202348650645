import { Button } from 'antd'
import styled from 'styled-components'

export const CreateTicket = styled(Button)`
    /* width: 149px; */
    height: 32px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1.5px ${({ theme }) => theme.primary_color || '#00b7c0'} ;
    background-color: #fff;
    color: ${({ theme }) => theme.primary_color || '#00b7c0'} ;
    font-size: 14px;
`