import styled from 'styled-components'
import { Menu, Row } from 'antd'

export const Content = styled(Row)`
  grid-area: content;
  padding: 8px;
`

export const Dot = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${({ theme }) =>
    theme.primary_color || '#00b7c0'} !important;
`
export const CustomMenuItemStyle = styled(Menu.Item)`
  background-color: white !important;
  height: 85px;
  border-top: 1px solid #f0f0f0;
`
export const AboutUsDetails = styled.div`
  background-color: white;
  & .item {
    height: 16px;
    margin-bottom: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #000000a6;
  }
`

export const MenuWrapper = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  & .main_menu {
    display: flex;
    justify-content: flex-start;
    & .menu_items {
      margin: 0 !important;
      margin-left: 40px !important;
    }
  }
  & .logo_menu {
    margin: 0 !important;
    width: 143.28px !important;
    width:${({ theme }) => theme.logo ? '143px !important' : 'auto !important'};
    margin-left: ${({ theme }) => theme.logo ? '0 !important' : '10px !important'};
    img{
      width:100%
    }
  }
  .env_text {
    border-radius: 2px;
    background: #e9f5f3;
    padding: 4px 8px;
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 10px;
    color: #343434;
    text-transform: capitalize;
  }
 
  & .custom_menu_item {
    border-bottom: 0 !important;
    cursor: default;
    width: 56px !important;
  }
  & .menu-right {
    display: flex;
    justify-content: flex-end;
    width: 25% !important;
    max-width: 332px;
    gap: 24px;
    & .ant-menu-submenu {
      margin: 0 !important;
      border-bottom: 0 !important;
    }
    & .question_submenu {
      width: 16px !important;
    }
    & .user_submenu {
      width: 145px !important;
    }
  }
`

export const GreenhouseWrapper = styled.span`
  color: #000000a6;
  &:hover {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'} !important;
  }
  &:hover + svg {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'} !important;
  }
  margin-left: ${(props) => (props.marginLeft ? '14px' : '0px')};
  &.item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
`
export const ActiveJobsLabel = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
`
export const JobTitle = styled.div`
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  & span {
    color: white !important;
  }
`
export const JobPageHeader = styled.div`
  padding: 0px 24px;
  background: linear-gradient(
    270deg,
    ${({ theme }) => theme.barColor?.[0] || '#76e1e6'},
    ${({ theme }) => theme.barColor?.[1] || '#00b7c0'}
  );
  border-radius: 5px;
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: space-between;
`
export const RenewalTitle = styled.div`
  color: black;

  &:hover {
    color: black !important;
  }
`

// export const CustomMenuItem = styled(Menu.Item)`
//  position:absolute;
//  right:0;
//  margin-right: -3rem;
//  /* border:1px solid red; */
// `
export const CustomMenuItem = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 2rem;
  margin-right: 5rem;
  font-size: 12px;
`
