import React from 'react'
import { useHistory } from 'react-router-dom'
import { Menu, Dropdown, Typography, message } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
// Local Files
import ModalLayout from '../components/ModalLayout'
// Separator
import {
  useQueryActions,
  useQuerysDispatch,
  // setFields,
  setIsLoading,
  useQuerysState
} from '../CandidateContext/SearchQueryContext'
// Style Sheet
import './SearchCandidates.css'
import { QUERY_ACTIONS } from '../components/Constants'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../constants/constants'

// const { Title } = Typography
export default function ActionsDropdown ({ row }) {
  const [openModal, setOpenModal] = React.useState(false)
  const [activeAction, setActiveAction] = React.useState(undefined)
  const { t } = useTranslation()
  const history = useHistory()

  // Context
  const dispatch = useQuerysDispatch()
  const actions = useQueryActions()
  const { isLoading } = useQuerysState()

  const deleteJob = async () => {
    dispatch(setIsLoading(true))
    try {
      await actions.deleteJob(row)
      await actions.getDiscoverJobs()
      dispatch(setIsLoading(false))
    } catch (e) {
      console.log(e.message)
      dispatch(setIsLoading(false))
    }
  }

  const updateJob = async (name, description) => {
    try {
      let annotations
      if (!row?.searchJson) return message.error('search json is missing')
      if (row.searchJson) annotations = JSON.parse(row.searchJson)
      const reqHeader = row
      reqHeader.name = name
      reqHeader.description = description || 'Description'
      reqHeader.annotations = annotations || null
      await actions.updateJob(reqHeader)
      await actions.getDiscoverJobs()
      setOpenModal(false)
      dispatch(setIsLoading(false))
    } catch (e) {
      dispatch(setIsLoading(false))
      console.log(e.message)
    }
  }

  const duplicateJob = async (name) => {
    dispatch(setIsLoading(true))

    const job = await actions.jobPreview(row.jobKey)
    if (job) {
      const parseJob = JSON.parse(job.searchJson)
      const payload = {
        // ...JOB_PAYLOAD,
        annotations: parseJob,
        name: name
      }
      await actions.createJob(payload)
      await actions.getDiscoverJobs() // actually we are creating new JOB instead of duplicate that's why I'm using create job api
      dispatch(setIsLoading(false))
      setOpenModal(false)
    }
  }

  const handleOk = (name, description, action) => {
    switch (action) {
      case QUERY_ACTIONS.DELETE:
        deleteJob()
        break
      case QUERY_ACTIONS.EDIT:
        updateJob(name, description)
        break
      case QUERY_ACTIONS.CREATE:
        duplicateJob(name)
        break
      default:
    }
  }

  const SearchedScreenToggle = () => {
    history.push(`/candidates/${row?.jobKey}`)
  }

  const handleOpenModal = (action) => {
    setOpenModal(!openModal)
    setActiveAction(action)
  }

  const handleDuplicate = () => {
    handleOpenModal(QUERY_ACTIONS.CREATE)
  }

  const menu = (
    <Menu id='tooltip-menu-body' >
      <Menu.Item key="0" onClick={SearchedScreenToggle} id='tooltip-menu-item'>
        <Typography className='tooltip-item'> {t(allConstants.VIEW)} / {t(allConstants.EDIT)} </Typography>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item key="1" onClick={handleDuplicate} id='tooltip-menu-item'>
        <Typography className='tooltip-item'> {t(allConstants.DUPLICATE)} </Typography>
      </Menu.Item>

      {/* <Menu.Divider /> */}

      {/* <Menu.Item
        key="2"
        id='tooltip-menu-item'
        onClick={() => handleOpenModal(QUERY_ACTIONS.EDIT)}
      >
        <Typography className='tooltip-item'> Edit </Typography>
      </Menu.Item> */}

      <Menu.Divider />

      <Menu.Item key="3" id='tooltip-menu-item' onClick={() => handleOpenModal(QUERY_ACTIONS.DELETE)}>
        <div className='tooltip-item'>{t(allConstants.DELETE)}</div>
      </Menu.Item>
      {openModal &&
        <ModalLayout
          isLoading={isLoading}
          setIsopen={setOpenModal}
          isOpen={true}
          updateSearch={row}
          handleOk={handleOk}
          modalBody={activeAction} />}
    </Menu>

  )

  return (
    <Dropdown
      overlay={menu}
      onClick={(e) => { e.stopPropagation() }}
      trigger={['click']}
      placement="bottomRight"
      id='dropdown-body'
    >
      <span id='more-icon'>
        <EllipsisOutlined />
      </span>
    </Dropdown>
  )
}

ActionsDropdown.propTypes = {
  row: PropTypes.object.isRequired
}
