import React from 'react'
import PropTypes from 'prop-types'
import HeaderLayout from '../HeaderLayout'
import { Menu, PageHeader, Tabs, Tag } from 'antd'
import Layout, { Content } from 'antd/lib/layout/layout'
import Sider from 'antd/lib/layout/Sider'
import SubMenu from 'antd/lib/menu/SubMenu'
// import PageSubHeader from '../PageSubHeader'

const PageHeaderLayoutWithHeader = ({
  children,
  title,
  subtitle,
  tags,
  extra,
  avatar,
  tabs,
  menus,
  back,
  onTabChange,
  defaultActiveKey,
  subHeaderTabs
}) => {
  return (
    <HeaderLayout>
      <PageHeader
        avatar={avatar}
        ghost={false}
        onBack={back}
        title={title}
        subTitle={subtitle}
        tags={tags?.map(({ tag, color }) => <Tag key={tag} color={color}>{tag}</Tag>)}
        extra={extra}
        footer={tabs &&
          <Tabs defaultActiveKey={defaultActiveKey} onChange={onTabChange}>
            {tabs?.map(({ tab, key }) => (<Tabs.TabPane tab={tab} key={key} />))}
          </Tabs>
        }
      />
      {menus && (
      <Layout className="site-layout-background" style={{ padding: '8px 0' }}>
        <Sider className="site-layout-background" width={200}>
          <Menu active defaultSelectedKeys={[defaultActiveKey]} mode="inline" style={{ height: '100%' }}>
            {menus.map(({ tab, key, icon, menus }) => menus
              ? (
                  <SubMenu active={true} key={key} icon={icon} title={tab}>
                    {menus.map(submenu => (
                      <Menu.Item
                        key={submenu.key}
                        icon={submenu.icon}
                        onClick={() => onTabChange(submenu.key)}
                      >{submenu.tab}</Menu.Item>
                    ))}
                  </SubMenu>
                )
              : (
                <Menu.Item active={true} key={key} onClick={() => onTabChange(key)} icon={icon} >
                  {!tab.menus && tab}
                </Menu.Item>
                ))}
          </Menu>
        </Sider>
        <Content style={{ padding: '0 0 0 8px', minHeight: 280 }}>
          {children}
        </Content>
      </Layout>
      )}
        {/* { subHeaderTabs
          ? <PageSubHeader onTabChange={onTabChange} subHeaderTabs={subHeaderTabs} />
          : ''} */}
      {!menus && (
        <Layout style={{ padding: '8px 0' }}>
          <Content style={{ minHeight: 280 }}>

            {children}
          </Content>
        </Layout>
      )}

    </HeaderLayout>
  )
}

PageHeaderLayoutWithHeader.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.any,
  back: PropTypes.func,
  actions: PropTypes.node,
  subtitle: PropTypes.string,
  avatar: PropTypes.string,
  extra: PropTypes.node,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    tab: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired
  })),
  menus: PropTypes.arrayOf(PropTypes.shape({
    tab: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired
  })),
  tags: PropTypes.arrayOf(PropTypes.shape({
    tag: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired
  })),
  defaultActiveKey: PropTypes.string,
  onTabChange: PropTypes.func,
  subHeaderTabs: PropTypes.array
}

export default PageHeaderLayoutWithHeader
