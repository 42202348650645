import React from 'react'
import { Table, Layout, Typography, Row, Col, Card, Modal, Button, message } from 'antd'
import {
  useHistory,
  useParams
} from 'react-router'
import { PropTypes } from 'prop-types'

// Context
import {
  useQuerysState,
  useQuerysDispatch,
  setFields,
  setIsLoading,
  useQueryActions,
  setSavedSearchVal,
  setSearchApiObj
} from '../../CandidateContext/SearchQueryContext'
// Table Columns
import FilteredDataColumns from './FilteredDataColumns'
import CreateModal from '../../components/modalBodyComponent/CreateModal'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

const { Content } = Layout
const { Title } = Typography
// const { Link } = Anchor

export default function FilteredData ({ extra, advancedSearchVal }) {
  const { jobkey } = useParams()
  const history = useHistory()
  const [urlVal, setUrlVal] = React.useState(false)
  const [jobVal, setJobVal] = React.useState(null)
  const [values, setValues] = React.useState({
    name: ''
  })
  // Context
  const { searchedCandidates, isLoading, SelectedCandidates, savedSearchVal, searchApiObj } = useQuerysState()
  const dispatch = useQuerysDispatch()
  const actions = useQueryActions()
  const { t } = useTranslation()

  const { columns } = FilteredDataColumns(searchedCandidates, '', t)
  const SearchedCandidatesTable = () => {
    const rowSelection = {
      selectedRowKeys: SelectedCandidates,
      selection: SelectedCandidates,
      onSelect: (candidate) => {
        const checkCandidate = () => {
          if (SelectedCandidates.includes(candidate.id)) {
            return SelectedCandidates.filter(id => id !== candidate.id)
          }
          return [...(SelectedCandidates || []), candidate.id]
        }
        dispatch(setFields({ SelectedCandidates: checkCandidate() }))
      },
      onSelectAll: () => {
        if (SelectedCandidates.length === searchedCandidates?.results.length) {
          dispatch(setFields({ SelectedCandidates: [] }))
        } else {
          dispatch(setFields({ SelectedCandidates: searchedCandidates?.results.map(item => item.id) }))
        }
      }
    }

    const savedMessage = () => (
      message.success(t(allConstants.SAVED_SEARCH))
    )
    const createDiscoverJob = async () => {
      console.log(searchApiObj)
      dispatch(setIsLoading(true))
      try {
        const obj = {
          annotations: {
            ...searchApiObj,
            mustHave: {
              ...searchApiObj.mustHave,
              distance: +searchApiObj.mustHave.distance,
              postCode: null
            },
            shouldHave: { ...searchApiObj.shouldHave },
            mustNotHave: { ...searchApiObj.mustNotHave }
          },
          name: values.name,
          location: searchApiObj.mustHave.location,
          title: '',
          country: '',
          description: 'job Description',
          region: '',
          status: 'open'
        }

        const job = await actions.createJob(obj)
        if (!job) return
        setJobVal(job)
        savedMessage()
        console.log(obj.annotations)
        dispatch(setSearchApiObj({ ...obj.annotations }))
        // history.push(`/candidates/${job?.jobKey}`)
        dispatch(setSavedSearchVal(false))
        // setIsopen(!isOpen)
      } catch (e) {
        dispatch(setIsLoading(false))
        console.log(e.message)
      } finally {
        dispatch(setSearchApiObj({ ...searchApiObj, mustHave: { ...searchApiObj.mustHave, career_count: '', career_length: '', distance: 60 } }))
        dispatch(setIsLoading(false))
        setUrlVal(true)
      }
    }

    function handleOk () {
      createDiscoverJob()
    }

    const handleInputChange = e => {
      setValues({ ...values, [e.target.name]: e.target.value })
    }

    React.useEffect(() => {
      if (urlVal) {
        history.push(`/candidates/${jobVal?.jobKey}`)
      }
    }, [urlVal])

    return (
          <Col className='table-parent-col' >
            <Card style={{ minHeight: 'calc(100vh - 243px)' }}>
              <div className='passive-table-header'>
                <div>
                  <span>{t(allConstants.PASSIVE_CANDIDATES)}</span>
                </div>
                <div>{extra}</div>
              </div>
              <Table
                rowSelection={{
                  ...rowSelection
                }}
                rowKey={'id'}
                columns={columns}
                dataSource={searchedCandidates?.results || []}
                className="filtered-data-table"
                loading={isLoading}
                scroll={{ y: 'calc(100vh - 343px)' }}
                onChange={(pagination) => {
                  window.analytics.track('Page change', {
                    page: pagination?.current,
                    search_id: jobkey
                  })
                }}
              />
            </Card>
            <Row justify='space-between' align='center' className='best-matches' >
              <Typography>
                <Title level={5} > {searchedCandidates?.results?.length || 0} {t(allConstants.MATCHING_RESULT)}</Title>
              </Typography>
            </Row>
            <Modal
              className='discover-modal'
              centered
              closable={false}
              visible={savedSearchVal}
              footer={[
                <Button disabled={isLoading} key="cancel" onClick={() => dispatch(setSavedSearchVal(false))}>
                  {t(allConstants.CANCEL)}
                </Button>,
                <Button loading={isLoading} type='primary' key="Save" onClick={() => handleOk()}>
              {t(allConstants.SAVE)}
                </Button>
              ]}
            >
              <CreateModal values={values} handleSubmit={handleOk} handleInputChange={handleInputChange}/>
            </Modal>
          </Col>
    )
  }
  React.useEffect(() => dispatch(setFields({ SelectedCandidates: [] })), [])
  return (
    <Content style={{ height: 'calc(100vh - 200px)' }} >

      {SearchedCandidatesTable()}

    </Content>
  )
}
FilteredData.propTypes = {
  extra: PropTypes.object,
  advancedSearchVal: PropTypes.bool
}
