export default (values) => {
  const count = 0
  for (const key in values) {
    if (Array.isArray(values[key])) {
      if (values[key].length > 0) {
        return count + values[key].length
      }
    }
  }
  return 0
}
