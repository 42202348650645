import { PropTypes } from 'prop-types'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import {
  Button, Col, Divider, Drawer, Layout,
  message,
  Row,
  Space
} from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import React from 'react'
import { matchPath, useHistory, useLocation, useParams } from 'react-router'
import { getDocument } from '../../api-twg/score/document-controller'
import { setSelectedCandidatesIds, useJobRequisitionsDispatch, useJobRequisitionsState } from './context/ApplicantsContextProvider'
import { ThemeContainer } from '../../components/theme-components'
import DeleteProfileLink from './components/DeleteProfileLink'
// import isRedacted from './utils/isRedacted'
// import BlurField from '../../components/BlurField/BlurField'
import { Content } from 'antd/lib/layout/layout'
import Title from 'antd/lib/skeleton/Title'
import Text from 'antd/lib/typography/Text'
import FiltersLayout from '../Candidates/SearchedCandidate/Filters/component/FiltersLayout'
import TagGroup from '../Candidates/components/TagGroup'
import { allConstants } from '../../constants/constants'
import { useTranslation } from 'react-i18next'

function NewDrawerApplicantProfile () {
  const history = useHistory()
  const location = useLocation()
  const { jobExtId, jobPostExtId, applicantId: applicantIdNeedReplace } = useParams()
  const state = useJobRequisitionsState()
  const dispatch = useJobRequisitionsDispatch()
  const { t } = useTranslation()
  const applicantId = applicantIdNeedReplace?.split('@')?.join('/')

  const { candidatesIds, filter } = state
  const { map } = state[filter]

  const applicant = map?.[applicantId]

  const handlePreviousCandidate = () => {
    window.analytics.track('previous candidate')
    const previous = applicant?.metadata?.previous
    history.push(
      `/score/${jobExtId}/search/${jobPostExtId}/applicants/${previous?.split('/')?.join('@')}`
    )
  }

  const handleNextCandidate = () => {
    window.analytics.track('next candidate')
    const next = applicant?.metadata?.next
    history.push(
      `/score/${jobExtId}/search/${jobPostExtId}/applicants/${next?.split('/')?.join('@')}`
    )
  }

  const matchUpload = matchPath(location.pathname, {
    path: '/score/:jobExtId/search/:jobPostExtId/applicants/upload',
    exact: true,
    strict: false
  })
  const matchEditJob = matchPath(location.pathname, {
    path: '/score/:jobExtId/search/:jobPostExtId/applicants/edit',
    exact: true,
    strict: false
  })
  const match = matchPath(location.pathname, {
    path: '/score/:jobExtId/search/:jobPostExtId/applicants/:applicantId',
    exact: true,
    strict: false
  })

  const handleDownloadResumeClick = () => {
    getDocument(applicant?.documentId)
      .then((response) => {
        console.log({ applicant })
        window.analytics.track('Download Resume', {
          careerCount: applicant.careerCount,
          careerLength: applicant.careerLength,
          currentCompany: applicant.currentCompany,
          currentIndustry: applicant.currentIndustry,
          currentTitle: applicant.currentTitle,
          degree: applicant.degree,
          documentId: applicant.documentId,
          expertise: applicant.expertise,
          externalId: applicant.externalId,
          id: applicant.id,
          indirect: applicant.indirect,
          languages: applicant.languages,
          licenseStatus: applicant.licenseStatus,
          licenseType: applicant.licenseType,
          likelihood: applicant.likelihood,
          location: applicant.location,
          notes: applicant.notes,
          originTabCode: applicant.originTabCode,
          preferredEmail: applicant.preferredEmail,
          preferredPhoneNumber: applicant.preferredPhoneNumber,
          previousCompany: applicant.previousCompany,
          previousIndustry: applicant.previousIndustry,
          previousTitle: applicant.previousTitle,
          rank: applicant.rank,
          relevancy: applicant.relevancy,
          resumeFilename: applicant.resumeFilename,
          school: applicant.school,
          score: applicant.score,
          segment: applicant.segment,
          source: applicant.source,
          stat: applicant.state
        })

        const filename = response?.headers?.['content-disposition']?.split('"')?.[1] || 'file'
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch(() => {
        message.error('The document expired')
      })
  }

  return (

    <Drawer
      className="profile-drawer"
      placement="right"
      closable={false}
      width={720}
      onClose={() => {
        const urlParts = location.pathname.split('/')
        urlParts.pop()
        history.push(urlParts.join('/'))
      }}
      visible={match && !matchEditJob && !matchUpload}
    >
      <ThemeContainer>
      <Row className='profile-view-header'>
        <Col span={12}>
          {applicant?.key !== 0 && <Button
            type="link"
            icon={<LeftOutlined />}
            onClick={handlePreviousCandidate}
          >
            {t(allConstants.PreviousCandidate)}
          </Button>}
        </Col>
        <Col span={12} justify="end" className='right-align'>
          <Button
            type="link"
            icon={<RightOutlined />}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row-reverse',
              gap: '8px',
              float: 'right'
            }}
            onClick={handleNextCandidate}
          >

            {t(allConstants.NEXT_CANDIDATE)}
          </Button>
        </Col>
      </Row>
      <Content className='profile-view-body' >
        <Layout justify='center' align='middle' >
            <Row style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Text className='applicant-name'>
                <span style={{ fontSize: '14px' }} >
                  {applicant?.firstName.substr(0, 1)}
                </span>
                <span style={{ filter: 'blur(4px)' }} >
                  {applicant?.firstName?.substr(1)}
                </span>
                </Text>
                <Text className='applicant-name'>
                <span style={{ fontSize: '14px' }} >
                  {applicant?.lastName.substr(0, 1)}
                </span>
                <span style={{ filter: 'blur(4px)' }} >
                  {applicant?.lastName?.substr(1)}
                </span>
                </Text>
            </Row >
                <Text > {applicant?.currentTitle || '-'} </Text>
            <Space>
              { filter !== 'passive' && <Button
                type="primary"
                size="small"
                onClick={handleDownloadResumeClick}
              >
                {t(allConstants.DOWNLOAD_RESUME)}
              </Button> }
              {(applicant?.isDirect || applicant?.originTabCode === 'DIRECT') && (
                <DeleteProfileLink
                  profile={applicantId}
                  onAfterClick={() => {
                    console.log({ applicant })
                    window.analytics.track('Delete Resume', {
                      careerCount: applicant.careerCount,
                      careerLength: applicant.careerLength,
                      currentCompany: applicant.currentCompany,
                      currentIndustry: applicant.currentIndustry,
                      currentTitle: applicant.currentTitle,
                      degree: applicant.degree,
                      documentId: applicant.documentId,
                      expertise: applicant.expertise,
                      externalId: applicant.externalId,
                      id: applicant.id,
                      indirect: applicant.indirect,
                      languages: applicant.languages,
                      licenseStatus: applicant.licenseStatus,
                      licenseType: applicant.licenseType,
                      likelihood: applicant.likelihood,
                      location: applicant.location,
                      notes: applicant.notes,
                      originTabCode: applicant.originTabCode,
                      preferredEmail: applicant.preferredEmail,
                      preferredPhoneNumber: applicant.preferredPhoneNumber,
                      previousCompany: applicant.previousCompany,
                      previousIndustry: applicant.previousIndustry,
                      previousTitle: applicant.previousTitle,
                      rank: applicant.rank,
                      relevancy: applicant.relevancy,
                      resumeFilename: applicant.resumeFilename,
                      school: applicant.school,
                      score: applicant.score,
                      segment: applicant.segment,
                      source: applicant.source,
                      stat: applicant.state
                    })
                  }}
                />
              )}
            </Space>
            </Row>

            <Title level={5} > {applicant?.currentTitle || '-'} </Title>
            <Row justify='center' align='middle' className='select-candidate'>
                <Col >
                  <Text className='score-checkbox' >{t(allConstants.Score)}: <Text>{Math.floor(applicant?.score)}%</Text> </Text>
                  <Checkbox
                    checked={candidatesIds.includes(applicant?.id)}
                    onChange={() => {
                      if (candidatesIds.includes(applicant?.id)) {
                        dispatch(setSelectedCandidatesIds(candidatesIds.filter(id => id !== applicant?.id)))
                      } else {
                        dispatch(setSelectedCandidatesIds([...candidatesIds, applicant?.id]))
                      }
                    }}
                   >{t(allConstants.SELECT_CANDIDATE)}
                   </Checkbox>
                </Col>
              </Row>
        </Layout>
        <Divider />

        {/* Personal Info  */}
        <Layout justify='center' align='middle' gutter={10} >
        <FiltersLayout title={t(allConstants.PERSONAL_INFO)} RightSideIcon={<div />} isAlwaysOpen={true} classNames={'profile-cards'}>
            <Row>
            <Col span={12} >
                <Text className='pofile-labels' >{t(allConstants.FULL_NAME)}</Text>
                <Row style={{ display: 'flex', marginLeft: '-3px' }}>
                <div>
                    <span style={{ fontSize: '14px', fontWeight: '600' }} >
                        {applicant?.firstName.substr(0, 1)}
                    </span>
                    <span style={{ filter: 'blur(4px)' }} >
                        {applicant?.firstName?.substr(1)}
                    </span>
                </div>
                <div>
                    <span style={{ fontSize: '14px', fontWeight: '600' }} >
                        {applicant?.lastName.substr(0, 1)}
                    </span>
                    <span style={{ filter: 'blur(4px)' }} >
                        {applicant?.lastName?.substr(1)}
                    </span>
                </div>
                </Row>
            </Col>
            <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.LocationSmText)}</Text>
                    <Text className='redacted-data'> {applicant?.location || '-'} </Text>
                  </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.EMAIl)}</Text>
                    <Text className='redacted-data'>{applicant?.emails || '-'}</Text>
                </Col>
                <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.PHONE)}</Text>
                    <Text className='redacted-data' >{applicant?.phoneNumbers || '-'}</Text>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.DEGREE)}</Text>
                    <Text>
                        {applicant?.degree
                          ? <TagGroup
                            tags={applicant?.degree?.split(',')}
                            className="profile-tags"
                            HideCloseIcon={true}
                        />
                          : '-'
                        }
                    </Text>
                </Col>
            </Row>
        </FiltersLayout>
        </Layout>
        {/* Expertise  */}
        <Layout align='middle' gutter={10} >
              <FiltersLayout title={t(allConstants.EXPERTISE)} RightSideIcon={<div />} isAlwaysOpen={true} classNames={'profile-cards'}>
                <Row className="profile-tags" >
                  {applicant?.expertise
                    ? <TagGroup
                      tags={applicant?.expertise?.split(',').map(item => item.trim()).sort((a, b) => a.localeCompare(b))}
                      className="profile-tags"
                      HideCloseIcon={true}
                    />
                    : '-'
                  }
                </Row>
              </FiltersLayout>
            </Layout>
            <Layout align='middle' gutter={10} >
              <FiltersLayout title={t(allConstants.WORK_HISTORY)} RightSideIcon={<div />} isAlwaysOpen={true} classNames={'profile-cards'}>
                <Row>
                  <Col span={12} >
                    <Text className='pofile-labels' >{t(allConstants.CURRENT_INDUSTRY)}</Text>
                    <Text > {applicant?.currentIndustry || '-'} </Text>
                  </Col>

                </Row>

                <Row>
                  <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.CURRENT_COMPANY)}</Text>
                    <Text > {applicant?.currentCompany || '-'} </Text>
                  </Col>
                  <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.CURRENT_TITLE)}</Text>
                    <Text > {applicant?.currentTitle || '-'} </Text>
                  </Col>
                </Row>

                <Row>
                  <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.PREVIOUS_COMPANY)}</Text>
                    <Text >{applicant?.previousCompany || '-'}</Text>
                  </Col>
                  <Col span={12}>
                    <Text className='pofile-labels'>{t(allConstants.PREVIOUS_TITLE)}</Text>
                    <Text > {applicant?.previousTitle || '-'} </Text>
                  </Col>
                </Row>
              </FiltersLayout>
            </Layout>
      </Content>

    </ThemeContainer>
    </Drawer>
  )
}

NewDrawerApplicantProfile.propTypes = {
  visible: PropTypes.bool
}

export default NewDrawerApplicantProfile
