import axios from '../../axios-config'

/**
 * GET
 * /twg/api/v1/user/me
 * getMe
 */
export const getMe = (token) => {
  return axios.get('/twg/api/v1/user/me',
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
}

/**
 * GET
 * /thisway/api/v1/feature
 * getMe
 */
export const getFeatureFlag = (token) => {
  return axios.get('/twg/api/v1/feature',
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
}

/**
 * GET
 * /twg/api/v1/user/meta
 * getUserMetadata
 */
export const getUserMetadata = () => {
  return axios.get('/twg/api/v1/user/meta')
}
/**
 * PUT
 * /twg/api/v1/user/meta
 * updateUserMetadata
 */
export const updateUserMetadata = (payload) => {
  return axios.put('/twg/api/v1/user/meta', payload)
}
/**
 * POST
 * /twg/api/v1/user/meta
 * createUserMetadata
 */
export const createUserMetadata = (payload) => {
  return axios.post('/twg/api/v1/user/meta', payload)
}
/**
 * DELETE
 * /twg/api/v1/user/meta
 * deleteUserMetadata
 */
export const deleteUserMetadata = () => {
  return axios.delete('/twg/api/v1/user/meta')
}
/**
 * GET
 * /twg/api/v1/user/preferences
 * getPreferences
 */
export const getPreferences = () => {
  return axios.get('/twg/api/v1/user/preferences')
}
/**
 * PUT
 * /twg/api/v1/user/preferences
 * updatePreferences
 */
export const updatePreferences = () => {
  return axios.put('/twg/api/v1/user/preferences')
}
/**
 * POST
 * /twg/api/v1/user/preferences
 * savePreferences
 */
export const savePreferences = () => {
  return axios.post('/twg/api/v1/user/preferences')
}
/**
 * PATCH
 * /twg/api/v1/user/preferences
 * patchPreferences
 */
export const patchPreferences = () => {
  return axios.patch('/twg/api/v1/user/preferences')
}
/**
 * GET
 * /twg/api/v1/user/team
 * getTeamMembers
 */
export const getTeamMembers = () => {
  return axios.get('/twg/api/v1/user/team')
}
