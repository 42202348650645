import React from 'react'
import { Typography } from 'antd'
import PropTypes from 'prop-types'

const { Title } = Typography

const ModalHeader = ({ title }) => (
    <Typography className='modal-title' >
        <Title level={5} > {title} </Title>
    </Typography>
)

ModalHeader.propTypes = {
  title: PropTypes.string

}

export default ModalHeader
