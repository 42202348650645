import styled from 'styled-components'

export const TableContainerWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    width:calc(100vw - 100px);
  

    & .platform-value-captured-container {
        margin-left: 20px;
    }

    & .prospect-name-company {
        width:200px;
        height:25px;
        margin:0 5px 10px 10px;
    }
`

export const TableContainer = styled.table`
    // width:800px;
    .text-center{
                 text-align: center;
                }
    & .platform-value-captured-body {
        & tr {
            & td {
                padding: 16px 24px 16.5px 24px;
                
            }
        }
    }

    & thead {
        & tr {     
            background-color: #f0f0f0;
            & th {
                padding:10px 10px;
                border-radius: 4px;
                border: 1px solid #D9D9D9;
                text-align:center;
                color: rgba(0, 0, 0, 0.85);
            }

            // & .logo-column {
            //     background-image: url('https://score.ai4jobs.info/logo.png');
            //     background-repeat: no-repeat;
            //     background-size: 150px 50px;
            //     background-position: center;
            // }
        }
    }

    & tbody {

        & .top{
            border-bottom:none !important;
            border-top: solid 2px #d2e4fe !important;
        }
        & .bottom{
            border-top:none !important;
            border-bottom: solid 2px #d2e4fe !important;
        }

        & .dimmed {
            background-color:rgba(240, 240, 240, 0.5);
            //border:none !important;
        }

        & .dark {
          background-color:#000000;
          border:none !important;
          & td {
            border: none;
          }
          & hr {
            color: #000000;
          }
        }      

        & .highlight {
            background-color: #ebf4ff;
            & td {
                border: solid 2px #d2e4fe;
            }
        }

        & tr {
            & .data-value {
                padding:16px 24px;
            }
            & .second-column-value {
                text-align:center !important;
            }
            & th {
                border-radius: 4px;
                border: 1px solid #D9D9D9;
                background-color: #fafafa;
                padding: 10px 0px 10px 24px;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: rgba(0, 0, 0, 0.85);
            }

            & td {
                border-radius: 4px;
                border: 1px solid #D9D9D9;
                padding: 10px 24px 10px 24px;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: rgba(0, 0, 0, 0.85);
            }
            & td:last-child {
                padding: 10px 0px;
                text-align:center;
            }
            & .ant-input-number {
                width: 173px;
                height: 32px;
                padding: 0 0 0 5px;
                border-radius: 2px;
                border: solid 1px #d9d9d9;
                background-color: #fff;
            }
            
            & .ant-input-number:focus, .ant-input-number-focused, ant-input-number:hover {
                outline: none !important;
                box-shadow: none !important;
            }
            
            & .ant-input-number-handler-wrap {
                border-left: 1px solid #d9d9d9;
                opacity:1;
            }
            & .ant-input {
                width: 150px;
                height: 32px;
                padding: 0 0 0 10px;
                border-radius: 2px;
                border: solid 1px #d9d9d9;
                background-color: #fff;
            }
            & .ant-input-group {
                width:auto;
            }
            & .ant-input-group-addon {
                border-radius: 2px;
                border: 1px solid #d9d9d9;
                padding: 0px 4px;
            }

            & .user-value {
                // padding-left:10px;
                // text-align:center;
                // border:1px solid red;
            }

            & .ant-input::-webkit-outer-spin-button,.ant-input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
            }
            & .ant-input:focus, .ant-input-focused, .ant-input:hover {
                outline: none !important;
                box-shadow: none !important;
                border-color: #d9d9d9 !important;
            }

            & .ant-picker {
                width: 170px;
                height: 32px;
                padding: 0 0 0 10px;
                border-radius: 2px;
                border: solid 1px #d9d9d9;
                background-color: #fff;

                & .ant-picker-input {
                    & span {
                        padding-right:5px;
                    }
                }
            }
            & .ant-picker:hover, .ant-picker-focused{
                outline: none !important;
                box-shadow: none !important;
            }
            & .field-bold {
                font-size: 14px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: rgba(0, 0, 0, 0.85);
            }
        }
    }
`
