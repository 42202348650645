import React from 'react'
import { Input, Row, Col } from 'antd'
import PropTypes from 'prop-types'
// Helpers
import { UpdateActiveCandidateData } from '../../../helpers/QueryHelpers'
import HandleInputTags from '../../../helpers/HandleInputTagsHelpers'
// Component
import TagGroup from '../../../components/TagGroup'
import AddMore from '../component/AddMore'
import FiltersLayout from '../component/FiltersLayout'
import { setIsOpen } from '../helpers/actionHelpers'
import isAnyField from '../helpers/isAnyField'
import FiltersCount from '../component/FiltersCount'
import { setSearchApiObj, useQuerysDispatch, useQuerysState } from '../../../CandidateContext/SearchQueryContext'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'

export default function SearchByTitle ({
  contextData, existedTitles, setExistedFilters,
  match, existedFilters, setSearchThroughAdvanceFilter,
  setSearchApiVal
}) {
  const [values, setValues] = React.useState({
    titleValue: '',
    title: [],
    excludeTitleValue: '',
    excludeTitle: [],
    isExcludeTitle: false,
    isOpen: false
  })
  const dispatch = useQuerysDispatch()
  const { searchApiObj } = useQuerysState()
  const [advancedSearchFilter, setAdvancedSearchFilter] = React.useState(false)
  const { t } = useTranslation()
  // Props
  const { api, candidatesFilters, matchingResults } = contextData
  const handleInputChange = (e) => setValues({ ...values, [e.target.name]: e.target.value })

  const handleInpurConfirm = React.useCallback((activeInputValue, activeInput) => {
    setAdvancedSearchFilter(true)
    const tags = HandleInputTags(activeInputValue, activeInput, values)
    if (tags.length > 0 && values[activeInputValue]) {
      setValues({
        ...values,
        [activeInput]: tags,
        [activeInputValue]: '',
        isExcludeTitle: false
      })
      updateJobApi(activeInput, tags)
    }
  }, [api, setValues, values, candidatesFilters])

  // const updateWithTitle = async () => {
  //   setValues({ ...values, title: [...values.title, values.titleValue] })
  //   await UpdateActiveCandidateData('jobTitles', values.title, candidatesFilters, api)
  // }

  React.useEffect(() => {
    setValues({ ...values, title: existedTitles?.current_title?.shouldHave || [], excludeTitle: existedTitles?.current_title?.mustNotHave || [] })
  }, [existedTitles])
  // React.useEffect(() => { console.log(values) }, [values])
  React.useEffect(() => {
    if (match && advancedSearchFilter) {
      setExistedFilters({
        ...existedFilters,
        title: {
          ...existedFilters.title,
          current_title: {
            mustHave: [],
            mustNotHave: values?.excludeTitle || [],
            shouldHave: values?.title || []
          }

        }
      })
      setSearchThroughAdvanceFilter(true)
      setAdvancedSearchFilter(false)
      setSearchApiVal(true)
    }
  }, [advancedSearchFilter, values])

  const handleDelete = async (tags, activeTagsType) => {
    const filteredTags = { ...values, [activeTagsType]: tags }
    setValues(filteredTags)
    if (match) {
      setAdvancedSearchFilter(true)
    }
    updateJobApi(activeTagsType, tags)
  }

  const updateJobApi = React.useCallback((activeInput, tags) => {
    try {
      const title = activeInput === 'title' ? tags : values.title
      UpdateActiveCandidateData({
        shouldHave: { current_title: title },
        mustNotHave: { current_title: activeInput === 'excludeTitle' ? tags : values.excludeTitle }
      },
      candidatesFilters,
      api,
      { job_title: title },
      matchingResults,
      match
      )
    } catch (err) {
      console.log(err.message)
    }
  }, [values])

  const clearIncludeFilters = () => {
    if (match) {
      dispatch(setSearchApiObj(
        {
          ...searchApiObj,
          shouldHave: { ...searchApiObj.shouldHave, current_title: [] }
        }
      ))
      setValues({
        ...values,
        title: []
      })
      setAdvancedSearchFilter(true)
    }

    UpdateActiveCandidateData({
      shouldHave: { current_title: [] }
    },
    candidatesFilters,
    api,
    { job_title: [] },
    matchingResults
    )
  }

  const clearExcludeFilters = () => {
    if (match) {
      dispatch(setSearchApiObj(
        {
          ...searchApiObj,
          mustNotHave: { ...searchApiObj.mustNotHave, current_title: [] }
        }
      ))
      setValues({
        ...values,
        excludeTitle: []
      })
      setAdvancedSearchFilter(true)
    }
    UpdateActiveCandidateData({
      mustNotHave: { current_title: [] }
    }, candidatesFilters, api, { title_exclude: [] }, matchingResults)
  }

  return (
    <FiltersLayout
    activeFilters={isAnyField({ title: values.title })}
    clearAllFilters={clearIncludeFilters}
    title={t(allConstants.TITLE)}
    setIsOpen={() => setIsOpen(setValues, values)}
    isOpen={values.isOpen}
    >
      <Row className='filters-tags' style={values.title.length ? { marginTop: '16px' } : undefined} >
        <TagGroup
          tags={values.title}
          onDelete={handleDelete}
          activeTagsType="title" />
      </Row>
      {values.isOpen && <Input.Search
        name="titleValue"
        onChange={handleInputChange}
        value={values.titleValue}
        placeholder={t(allConstants.ADD_TITLE)}
        onSearch={(e) => handleInpurConfirm('titleValue', 'title')}
        onBlur={!match ? (e) => handleInpurConfirm('titleValue', 'title') : null}
        onPressEnter={(e) => handleInpurConfirm('titleValue', 'title')}
      />}

      <Col className={values.excludeTitle.length || values.isOpen ? 'exclude-tags-area' : ''} span={24}>
        {(values.excludeTitle.length > 0 || values.isExcludeTitle) &&
          <FiltersCount
          ExcludeField={values.excludeTitle}
          clearExcludeFilters={clearExcludeFilters}
          title={t(allConstants.EXCLUDE_TITLE)}
          />}

        <Row className='filters-tags' >
          <TagGroup
            tags={values.excludeTitle}
            isExlude={values.excludeTitle && true}
            onDelete={handleDelete}
            activeTagsType="excludeTitle" />
        </Row>

        {values.isExcludeTitle &&
          <Row>
            <Input
              name='excludeTitleValue'
              placeholder={t(allConstants.EXCLUDE_SKILLS)}
              value={values.excludeTitleValue}
              onChange={handleInputChange}
              onBlur={!match ? (e) => handleInpurConfirm('excludeTitleValue', 'excludeTitle') : null}
              onPressEnter={(e) => handleInpurConfirm('excludeTitleValue', 'excludeTitle')}
            />
          </Row>}
      </Col>

      {!values.isExcludeTitle && values.isOpen &&
        <Row className="excl-typo-row" onClick={() => setValues({ ...values, isExcludeTitle: !values.isExcludeTitle })}>
          <AddMore
            title={t(allConstants.EXCLUDE_TITLE)}
          />
        </Row>}
      </FiltersLayout >
  )
}

SearchByTitle.propTypes = {
  contextData: PropTypes.object,
  existedTitles: PropTypes.object,
  setExistedFilters: PropTypes.func,
  match: PropTypes.object,
  existedFilters: PropTypes.object,
  setSearchThroughAdvanceFilter: PropTypes.func,
  setSearchApiVal: PropTypes.func
}
