import { Card, Col, Row, Spin } from 'antd'
import React from 'react'
import ReportsTable from '../table-utils/ReportTable'
import { useTranslation } from 'react-i18next'
import { useReportActions, useReportState } from '../../context/ReportContext'

function UsageReport () {
  const { t } = useTranslation()
  const { reportLoading, statsField } = useReportState()
  const action = useReportActions()

  React.useEffect(() => {
    action.getReportStatsType()
  }, [])

  React.useEffect(() => {
    if (statsField) action.getReportStats(statsField)
  }, [statsField])
  return (
    <Row className='report' style={{ marginTop: '20px' }} gutter={[8, 8]}>
      <Col span={24}>
        <Spin tip={`${t('Loading')}...`} spinning={reportLoading}>
          <Card bordered={false} >
            <ReportsTable />
          </Card>
        </Spin>
      </Col>
    </Row>
  )
}

export default UsageReport
