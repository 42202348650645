/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import { Space, Tag as AntdTag, Button } from 'antd'
import { useHistory, useParams } from 'react-router'
import { TWGProgress } from '../../../../components/theme-components'
import dayjs from 'dayjs'
import moment from 'moment'
import DeleteProfileLink from '../DeleteProfileLink'
import {
  useJobsDispatch,
  setViewProfileDrawer
} from '../../../jobs/context/JobsContext'
// import { RedactedField } from '../../../../components/RedactedField'
import BlurField from '../../../../components/BlurField/BlurField'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

function ViewProfileLink ({ profile, record }) {
  const dispatch = useJobsDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { jobExtId, jobPostExtId } = useParams()
  const handleViewProfileClick = async () => {
    window.analytics.track('View Profile', {
      careerCount: record.careerCount,
      careerLength: record.careerLength,
      currentCompany: record.currentCompany,
      currentIndustry: record.currentIndustry,
      currentTitle: record.currentTitle,
      degree: record.degree,
      documentId: record.documentId,
      expertise: record.expertise,
      externalId: record.externalId,
      id: record.id,
      indirect: record.indirect,
      languages: record.languages,
      licenseStatus: record.licenseStatus,
      licenseType: record.licenseType,
      likelihood: record.likelihood,
      location: record.location,
      notes: record.notes,
      originTabCode: record.originTabCode,
      preferredEmail: record.preferredEmail,
      preferredPhoneNumber: record.preferredPhoneNumber,
      previousCompany: record.previousCompany,
      previousIndustry: record.previousIndustry,
      previousTitle: record.previousTitle,
      rank: record.rank,
      relevancy: record.relevancy,
      resumeFilename: record.resumeFilename,
      school: record.school,
      score: record.score,
      segment: record.segment,
      source: record.source,
      stat: record.state
    })
    dispatch(setViewProfileDrawer(true))
    history.push(
      `/score/${jobExtId}/search/${jobPostExtId}/applicants/${profile
        ?.split('/')
        ?.join('@')}`
    )
  }

  return (
    <Button type="link" size="small" onClick={handleViewProfileClick}>
      {t(allConstants.VIEW_PROFILE)}
    </Button>
  )
}

export const columns = (filterState, filteredInfo, filter, t) => [
  {
    title: t('Rank'),
    dataIndex: 'rank',
    key: 'rank',
    align: 'center',
    width: '80px',
    sorter: (a, b) => b.rank - a.rank,
    render: (rank) => {
      return (
        <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
          {rank + 1}
        </span>
      )
    }
  },
  {
    title: filter === 'passive' ? t('Candidate') : t('Applicant'),
    key: 'ApplicantName',
    dataIndex: 'id',
    width: '30%',
    filters: filter !== 'passive' && [
      {
        text: t('Direct Applicants'),
        value: 'Direct Applicants'
      },
      {
        text: t('Indirect Applicants'),
        value: 'Indirect Applicants'
      },
      {
        text: t('Qualified Applicants'),
        value: 'Qualified Applicants'
      }
    ],
    filterMultiple: false,
    filteredValue: filterState,
    filterIcon: (filtered) => <span>{`${t('Showing')}: ${t(filterState[0])}`}</span>,
    render: (id, record) => {
      return (
        <Space direction="vertical">
          {(record?.isDirect || record?.originTabCode === 'DIRECT') && (
            <Space>
              <AntdTag style={{ marginLeft: '0px' }}>{t('Direct Applicant')}</AntdTag>
            </Space>
          )}
          {record?.originTabCode === 'INDIRECT' && (
            <AntdTag style={{ marginLeft: '0px' }}>{t('Indirect Applicant')}</AntdTag>
          )}
          <Space>
            <BlurField text={record?.firstName} name={true} />
            <BlurField text={record?.lastName} name={true} />
          </Space>
        </Space>
      )
    }
  },
  {
    title: t('Score'),
    dataIndex: 'score',
    key: 'score',
    width: '100px',
    align: 'center',
    sorter: (a, b) => a.score - b.score,
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend', 'descend'],
    render: (score) => {
      return (
        <TWGProgress percent={Math.floor(score)} type="dashboard" width={70} />
      )
    }
  },
  {
    title: t('Source'),
    key: 'Source',
    width: '12.5%',
    align: 'center',
    dataIndex: 'source',
    sorter: (a, b) => a.source.localeCompare(b.source),
    render: (source) => {
      if (source) {
        return source.toLowerCase() === 'thisway'
          ? (
            <AntdTag color="#16a1b1">{source}</AntdTag>
            )
          : (
            <AntdTag>{source}</AntdTag>
            )
      } else {
        return '-'
      }
    }
  },
  filter !== 'passive'
    ? {
        title: t('Date Added'),
        dataIndex: 'addedAt',
        key: 'addedAt',
        align: 'center',
        sorter: (a, b) => moment(a.addedAt).unix() - moment(b.addedAt).unix(),
        render: (addedAt) =>
          addedAt ? dayjs(addedAt).format('MMM DD, YYYY') : '-'
      }
    : {},
  // {
  //   title: 'Career Length',
  //   dataIndex: 'careerLength',
  //   key: 'careerLength',
  //   align: 'center',
  //   sorter: (a, b) => b.careerLength - a.careerLength
  // },
  // {
  //   title: 'Job Count',
  //   dataIndex: 'careerCount',
  //   key: 'careerCount',
  //   align: 'center',
  //   sorter: (a, b) => b.careerCount - a.careerCount
  // },
  // {
  //   title: 'Match',
  //   dataIndex: 'segment',
  //   key: 'segment',
  //   render: segment => {
  //     switch (segment) {
  //       case 1: return <AntdTag color="green">Excellent</AntdTag>
  //       case 2: return <AntdTag color="blue">Good</AntdTag>
  //       case 3: return <AntdTag color="gold">Okay</AntdTag>
  //       case 4: return <AntdTag color="purple">Low</AntdTag>
  //       default: return <AntdTag color="magenta">Unranked</AntdTag>
  //     }
  //   }
  // },
  {
    title: '',
    key: 'actions',
    render: (value, record) => (
      <Space>
        <ViewProfileLink profile={record.id} record={record} />
        {(record.isDirect || record?.originTabCode === 'DIRECT') && (
          <DeleteProfileLink
            profile={record.id}
            onAfterClick={() => {
              window.analytics.track('Delete', {
                score: record.score
              })
            }}
          />
        )}
      </Space>
    )
  }
]
