import { Row } from 'antd'
import React from 'react'
import styled from 'styled-components'
// import DirectApplicantsCard from './cards/DirectApplicantsCard'
// import IndirectApplicantsCard from './cards/IndirectApplicantsCard'
import PassiveCandidatesCard from './cards/PassiveCandidatesCard'
import QualifiedCard from './cards/QualifiedCard'

export default function FilterCards () {
  return (
    <Container>
      <Row gutter={8} style={{ gridArea: 'cards' }}>
        <QualifiedCard />
        {/* <DirectApplicantsCard /> */}
        {/* <IndirectApplicantsCard /> */}
        <PassiveCandidatesCard />
      </Row>
    </Container>
  )
}

const Container = styled.div`
  grid-area: cards;
  display:none;
`
