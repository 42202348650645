/* eslint-disable no-debugger */
import React, { useEffect, useMemo } from 'react'
import * as scoreApi from '../../../api-twg/score/score-controller'
import { useRequest } from 'ahooks'
import { useAuthState } from '../../../contexts/AuthContext'
import JobsPage from '../JobsPage'
import { ApplicantsContextProvider } from '../../applicant-results/context/ApplicantsContextProvider'
import PrivateRoute from '../../../components/PrivateRoute'
import { Switch } from 'react-router'
import HomePage from '../../HomePage/HomePage'

/** action types */
export const IS_LOADING_JOBS = 'IS_LOADING_JOBS'
export const CLEAR = 'CLEAR'
export const SET_JOBS = 'SET_JOBS'
export const ADD_JOB = 'ADD_JOB'
export const UPDATE_JOB = 'UPDATE_JOB'
export const REMOVE_JOB = 'REMOVE_JOB'
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const ADD_COUNTRY = 'ADD_COUNTRY'
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const ADD_LOCATION = 'ADD_LOCATION'
export const SET_FILTERS = 'SET_FILTERS'
export const SET_SEARCH = 'SET_SEARCH'
export const SET_SELECTED_JOB = 'SET_SELECTED_JOB'
export const ADD_SELECTED_JOB_DESCRIPTION = 'ADD_SELECTED_JOB_DESCRIPTION'
export const REMOVE_SELECTED_JOB_DESCRIPTION = 'REMOVE_SELECTED_JOB_DESCRIPTION'
export const UPDATE_SELECTED_JOB_DESCRIPTION = 'UPDATE_SELECTED_JOB_DESCRIPTION'
export const SET_PAGE = 'SET_PAGE'
export const SET_VIEW_PROFILE_DRAWER = 'SET_VIEW_PROFILE_DRAWER'
export const PAGE_AND_SIZE = 'PAGE_AND_SIZE'
export const SET_PASSIVE = 'SET_PASSIVE'

/** action creators */
export const setIsLoadingJobs = (isLoadingJobs) => ({ type: 'IS_LOADING_JOBS', isLoadingJobs })
export const clear = () => ({ type: CLEAR })
export const setJobs = (jobs) => ({ type: SET_JOBS, jobs })
export const addJobs = (job) => ({ type: ADD_JOB, job })
export const refreshJob = (job) => ({ type: UPDATE_JOB, job })
export const removeJob = (id) => ({ type: REMOVE_JOB, id })
export const setCountries = (countries) => ({ type: SET_COUNTRIES, countries })
export const setLocations = (locations) => ({ type: SET_LOCATIONS, locations })
export const addLocation = (location) => ({ type: ADD_LOCATION, location })
export const addCountry = (country) => ({ type: ADD_COUNTRY, country })
export const setFilters = (filters) => ({ type: SET_FILTERS, filters })
export const setSearch = (search) => ({ type: SET_SEARCH, search })
export const setSelectedJob = (job) => ({ type: SET_SELECTED_JOB, job })
export const addSelectedJobDescription = (jobPosting) => ({ type: ADD_SELECTED_JOB_DESCRIPTION, jobPosting })
export const removeSelectedJobDescription = (externalJobPostingId) => ({ type: REMOVE_SELECTED_JOB_DESCRIPTION, externalJobPostingId })
export const updateSelectedJobDescription = (jobPosting) => ({ type: UPDATE_SELECTED_JOB_DESCRIPTION, jobPosting })
export const setPage = (page) => ({ type: SET_PAGE, page })
export const setViewProfileDrawer = (viewProfileDrawer) => ({ type: SET_VIEW_PROFILE_DRAWER, viewProfileDrawer })
export const setPageSize = (pageSize) => ({ type: PAGE_AND_SIZE, pageSize })
export const setPassive = (passive) => ({ type: SET_PASSIVE, passive })

/** async actions */

const getJob = (dispatch) => async (jobExtId) => {
  dispatch(setSelectedJob(null))
  try {
    if (!jobExtId) return
    const jobResponse = await scoreApi.getJob(jobExtId)
    const jobPostingsResponse = await scoreApi.getAllJobPostings(jobExtId)
    const { data: job } = jobResponse
    const { data: jobPostings } = jobPostingsResponse
    job.descriptions = jobPostings
    dispatch(setSelectedJob(job))
    return job
  } catch (e) {
    console.log(e)
  }
}

const createJob = (dispatch) => async (data) => {
  const jobResponse = await scoreApi.createJob(data)
  const job = jobResponse.data
  dispatch(addJobs(job))

  const { name, title, externalJobId, location } = job
  const object = {
    name: name,
    title: title || data.title,
    location,
    content: data.description,
    active: true
  }
  const response = await scoreApi.createJobPosting(externalJobId, object)
  dispatch(addLocation(response?.data?.location))
  dispatch(addCountry(data.country))
  return response
}

const updateJob = (dispatch) => async (job, data) => {
  dispatch(setIsLoadingJobs(true))
  try {
    const { externalJobId } = job
    const jobResponse = await scoreApi.updateJob(externalJobId, { ...job, ...data })
    const updatedJob = jobResponse?.data
    dispatch(refreshJob({ ...job, ...data, externalJobId }))
    dispatch(addLocation(updatedJob?.location))
    dispatch(addCountry(updatedJob?.country))
  } finally {
    dispatch(setIsLoadingJobs(false))
  }
  // catch (err) {
  //   console.log(err.message)
  //   return undefined
  // }
}

const createJobDescription = (dispatch) => async (job, data) => {
  const { externalJobId, location } = job
  const object = {
    title: data.title,
    name: data.name,
    location,
    content: data.content,
    active: false
  }
  const response = await scoreApi.createJobPosting(externalJobId, object)
  dispatch(addSelectedJobDescription(response.data))
  return response
}

const createJobAndPost = (dispatch) => async (data) => {
  const { city, state, ...rest } = data

  const response = await scoreApi.createJobAndPost({
    ...rest,
    region: 'North America',
    location: (city && state) && `${city}, ${state}`
  })
  const job = response?.data
  job && dispatch(addJobs({
    requestId: job.externalJobId,
    ...job
  }))
  getOpenJobs(dispatch)({}, 0, 10)
  dispatch(addLocation(job?.location))
  dispatch(addCountry(job?.country))
  return response
}

const updateJobDescription = (dispatch) => async (externalJobId, externalJobPostingId, data) => {
  // const { externalJobId } = oldData
  // const object = {
  //   title: newData.title,
  //   content: newData.content
  // }
  // const response = await scoreApi.updateJobPosting(externalJobId, externalJobPostingId, object)
  const response = await scoreApi.updateJobPosting(externalJobId, externalJobPostingId, data)
  dispatch(updateSelectedJobDescription(response.data))
  return response
}

const closeJob = (dispatch) => async (id) => {
  const response = await scoreApi.closeJob(id)
  dispatch(removeJob(id))
  return response
}

const deleteJobPosting = (dispatch) => async (jobPosting) => {
  dispatch(setIsLoadingJobs(true))
  try {
    const { externalJobId, externalJobPostingId } = jobPosting
    await scoreApi.deactivateJobPosting(externalJobId, externalJobPostingId)
    dispatch(removeSelectedJobDescription(externalJobPostingId))
  } catch (e) {
    console.log(e)
  } finally {
    setTimeout(() => {
      dispatch(setIsLoadingJobs(false))
    }, '10000')
  }
}

export const getAllCountries = (dispatch) => async () => {
  const response = await scoreApi.getAllCountries()
  const { data } = response || {}
  dispatch(setCountries(data?.filter((value) => value)))
}
export const getAllLocations = (dispatch) => async () => {
  const response = await scoreApi.getAllLocations()
  const { data } = response || {}
  dispatch(setLocations(data.filter((value) => value)))
}
export const getOpenJobs = (dispatch) => async (filters, page, size) => {
  dispatch(setIsLoadingJobs(true))
  try {
    const response = await scoreApi.getOpenJobs(filters, page, size)
    const { data } = response || {}
    dispatch(setJobs(data))
    return data
  } catch (e) {
    console.log(e.message)
  } finally {
    dispatch(setIsLoadingJobs(false))
  }
}

const closeAttractJob = (dispatch) => async (attractJobExtId) => {
  await scoreApi.closeAttractJob(attractJobExtId)
}

const prepareItemWithId = (list) => (map, item, index) => {
  return {
    ...map,
    [item.requestId]: {
      metadata: {
        index,
        next: list[(index + 1) % list.length]?.requestId,
        previous: list[(index + list.length - 1) % list.length]?.requestId
      },
      ...item
    }
  }
}

/** initial value */
const initialState = {
  jobs: [],
  map: {},
  countries: [],
  locations: [],
  regions: [],
  filters: {
    countries: [],
    locations: [],
    regions: []
  },
  search: '',
  selectedJob: null,
  jobsData: undefined,
  isLoading: false,
  page: false,
  viewProfileDrawer: false,
  pageSize: {
    page: 1,
    size: 10,
    pageSizeOptions: ['10', '20', '30']
  },
  passive: false
}

/** reducer */
export function reducer (state, action) {
  switch (action.type) {
    case IS_LOADING_JOBS:
      return {
        ...state,
        isLoading: action.isLoadingJobs
      }
    case SET_JOBS:
      return {
        ...state,
        jobsData: action.jobs,
        jobs: action.jobs?.items,
        map: action.jobs?.items?.reduce(prepareItemWithId(action.jobs), {})
      }
    case ADD_JOB:
      return {
        ...state,
        jobs: [
          action.job,
          ...state.jobs
        ],
        map: {
          ...state.map,
          [action.job.requestId]: action.job
        }
      }
    case UPDATE_JOB:
      return {
        ...state,
        jobs: state.jobs.map(job => {
          if (job.requestId === action.job.externalJobId) {
            return {
              ...job,
              ...action.job,
              location: action.job.location,
              title: action.job.title
            }
          }
          return job
        }),
        map: {
          ...state.map,
          [action.job.externalJobId]: {
            ...state.map[action.job.externalJobId],
            location: action.job.location,
            title: action.job.title
          }
        },
        selectedJob: {
          ...state.selectedJob,
          ...action.job,
          location: action.job.location,
          title: action.job.title
        }
      }
    case REMOVE_JOB:
      return {
        ...state,
        jobs: state.jobs.filter(job => job.requestId !== action.id)
      }
    case SET_COUNTRIES:
      return { ...state, countries: action.countries }
    case ADD_COUNTRY:
      return {
        ...state,
        countries: state.countries.includes(action.country)
          ? state.countries
          : [action.country, ...state.countries].sort()
      }
    case SET_LOCATIONS:
      return { ...state, locations: action.locations }
    case ADD_LOCATION:
      return {
        ...state,
        locations: state.locations.includes(action.location)
          ? state.locations
          : [action.location, ...state.locations].sort()
      }
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        }
      }
    case SET_SEARCH:
      return { ...state, search: action.search }
    case SET_SELECTED_JOB:
      return { ...state, selectedJob: action.job }
    case ADD_SELECTED_JOB_DESCRIPTION:
      return {
        ...state,
        selectedJob: {
          ...state.selectedJob,
          descriptions: [...state.selectedJob.descriptions, action.jobPosting]
        }
      }
    case UPDATE_SELECTED_JOB_DESCRIPTION:
      return {
        ...state,
        selectedJob: {
          ...state?.selectedJob,
          descriptions: state?.selectedJob?.descriptions?.map(description => {
            if (description.externalJobPostingId === action.jobPosting.externalJobPostingId) {
              return {
                ...description,
                ...action.jobPosting
              }
            }
            return description
          })
        }
      }
    case REMOVE_SELECTED_JOB_DESCRIPTION: {
      return {
        ...state,
        selectedJob: {
          ...state.selectedJob,
          descriptions: state?.selectedJob?.descriptions?.filter(item => item.externalJobPostingId !== action.externalJobPostingId)
        }
      }
    }
    case SET_PASSIVE: {
      return {
        ...state,
        passive: action.passive
      }
    }
    case SET_PAGE:
      return {
        ...state,
        page: action.page
      }
    case SET_VIEW_PROFILE_DRAWER:
      return {
        ...state,
        viewProfileDrawer: action.viewProfileDrawer
      }
    case CLEAR:
      return initialState
    case PAGE_AND_SIZE: {
      return {
        ...state,
        pageSize: {
          page: action.page,
          size: action.size,
          pageSizeOptions: action.pageSizeOptions
        }
      }
    }
    default:
      return state
  }
}

/** contexts */
const JobsActionsContext = React.createContext(undefined, undefined)
const JobsStateContext = React.createContext(undefined, undefined)
const JobsDispatchContext = React.createContext(undefined, undefined)

function JobsContextProvider () {
  const { auth } = useAuthState()
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [actions] = React.useState({
    getJob: getJob(dispatch),
    createJob: createJob(dispatch),
    updateJob: updateJob(dispatch),
    createJobDescription: createJobDescription(dispatch),
    updateJobDescription: updateJobDescription(dispatch),
    closeJob: closeJob(dispatch),
    closeAttractJob: closeAttractJob(dispatch),
    deleteJobPosting: deleteJobPosting(dispatch),
    getAllCountries: getAllCountries(dispatch),
    getAllLocations: getAllLocations(dispatch),
    getOpenJobs: getOpenJobs(dispatch),
    createJobAndPost: createJobAndPost(dispatch)
  })

  useEffect(() => { !auth && dispatch(clear()) }, [auth])

  const { loading: isLoadingAllCountries } = useRequest(async () => auth && getAllCountries(dispatch)(), { refreshDeps: [auth] })
  const { loading: isLoadingAllLocations } = useRequest(async () => auth && getAllLocations(dispatch)(), { refreshDeps: [auth] })
  const { loading: isLoadingJobs } = useRequest(async () => auth && getOpenJobs(dispatch)(state.filters), { refreshDeps: [state.filters, auth] })

  const value = useMemo(() => (
    { ...state, isLoadingJobs: isLoadingJobs || isLoadingAllCountries || isLoadingAllLocations }
  ), [state, isLoadingJobs, isLoadingAllCountries, isLoadingAllLocations])
  return (
    <JobsActionsContext.Provider value={actions}>
      <JobsStateContext.Provider value={value}>
        <JobsDispatchContext.Provider value={dispatch}>
          <Switch>
            <PrivateRoute
              path='/homepage'
              component={HomePage}
              strict={false}
            />
            <PrivateRoute
              path="/score/:jobExtId/search/:jobPostExtId/applicants/:applicantId?"
              component={ApplicantsContextProvider}
              strict={false}
            />
            <PrivateRoute
              path="/score/:jobExtId?/:descriptions?/:descriptionId?/:edit?"
              component={JobsPage}
              strict={false}
            />
             <PrivateRoute
              path='/score/:jobExtId/search/:jobPostExtId/applicants/:descriptionId/edit'
              component={ApplicantsContextProvider}
              strict={false}
            />
            <PrivateRoute
              path='/score/:jobExtId/search/:jobPostExtId/applicants/new/descriptions'
              component={ApplicantsContextProvider}
              strict={false}
            />

          </Switch>

        </JobsDispatchContext.Provider>
      </JobsStateContext.Provider>
    </JobsActionsContext.Provider>
  )
}

/** hooks */
function useJobsActions () {
  const context = React.useContext(JobsActionsContext)
  if (context === undefined) {
    throw new Error('useJobsActions must be used within a JobsContextProvider')
  }
  return context
}

function useJobsState () {
  const context = React.useContext(JobsStateContext)
  if (context === undefined) {
    throw new Error('useJobsState must be used within a JobsContextProvider')
  }
  return context
}

function useJobsDispatch () {
  const context = React.useContext(JobsDispatchContext)
  if (context === undefined) {
    throw new Error('useJobsDispatch must be used within a JobsContextProvider')
  }
  return context
}

export { JobsContextProvider, useJobsActions, useJobsState, useJobsDispatch }
