import React, { useState } from 'react'
import { useReportState } from '../../context/ReportContext'
import { TableContent } from '../../style'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

const ReportsTable = () => {
  const [reportObject, setReportObject] = useState({})
  const { reportStats } = useReportState()
  const { t } = useTranslation()

  React.useEffect(() => {
    let obj = {}
    if (reportStats) {
      reportStats.map(item => {
        obj = { ...obj, [item.code]: item.value }
        return obj
      })
      setReportObject(obj)
    }
  }, [reportStats])
  React.useEffect(() => { }, [reportObject])
  return (
    <TableContent style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <thead>
            <tr>
                <th style={{ textAlign: 'left', paddingLeft: '24px' }}>{t(allConstants.OPEN_JOBS)}</th>
                <th>{t(allConstants.QUANTITY)}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{t(allConstants.TOTAL_OPEN)}</td>
                <td>{reportObject?.TOTAL_OPEN_REVEAL_JOBS ? reportObject?.TOTAL_OPEN_REVEAL_JOBS : 0}</td>
            </tr>
            <tr>
                <td>{t(allConstants.TOTAL_JOBS_CLOSED)}</td>
                <td>{ reportObject?.TOTAL_ACTIVE_REVEAL_JOBS ? reportObject?.TOTAL_REVEAL_JOBS - reportObject?.TOTAL_OPEN_REVEAL_JOBS : 0}</td>
            </tr>
            <tr>
                <td>{t(allConstants.TOTAL_JOB_PROCESSED)}</td>
                <td>{reportObject?.TOTAL_REVEAL_JOBS ? reportObject?.TOTAL_REVEAL_JOBS : 0}</td>
            </tr>
            <tr>
                <td className='highlight'>{t(allConstants.attractCampaignJobs)}</td>
                <td className='highlight'></td>
            </tr>
            <tr>
                <td>{t(allConstants.TOTAL_ATTRACT_JOBS)}</td>
                <td>{reportObject?.TOTAL_ATTRACT_JOBS ? reportObject?.TOTAL_ATTRACT_JOBS : 0}</td>
            </tr>
            <tr>
                <td>{t(allConstants.TOTAL_OPEN_ATTRACT_JOBS)}</td>
                <td>{reportObject?.TOTAL_OPEN_ATTRACT_JOBS ? reportObject?.TOTAL_OPEN_ATTRACT_JOBS : 0}</td>
            </tr>
            <tr>
                <td className='highlight'>{t(allConstants.APPLICANT_STATISTICS)}</td>
                <td className='highlight'></td>
            </tr>
            <tr >
                <td className='total'>{t(allConstants.TOTAL_REVEAL_CANDIDATES)}</td>
                <td className='total'>{reportObject?.TOTAL_REVEAL_CANDIDATES ? reportObject?.TOTAL_REVEAL_CANDIDATES : 0}</td>
            </tr>
            <tr>
                <td>{t(allConstants.ACTIVE_REVEAL_CANDIDATES)}</td>
                <td>{reportObject?.TOTAL_ACTIVE_REVEAL_CANDIDATES ? reportObject?.TOTAL_ACTIVE_REVEAL_CANDIDATES : 0}</td>
            </tr>
        </tbody>
    </TableContent>
  )
}

export default ReportsTable