export const handleOnChange = (e, setInputValue, setSearchValue) => {
  setInputValue(e.target.value)
  setSearchValue(e.target.value)
}

export const handleSearchClick = (inputValue, history) => {
  inputValue !== '' && history.push(`/article/search/${inputValue}`)
}

export const handleKeyEnter = (e, inputValue, history) => {
  e.key === 'Enter' && handleSearchClick(inputValue, history)
}
