
import React from 'react'

export const RightArrowSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.663 9.438 5.6 1.578a.178.178 0 0 0-.287.141v1.726c0 .11.05.214.136.281L13.484 10 5.45 16.276a.354.354 0 0 0-.136.281v1.726c0 .15.171.232.287.14l10.063-7.859a.711.711 0 0 0 0-1.125z"
      fill=""
    />
  </svg>
)
