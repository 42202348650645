import React from 'react'
import { notification } from 'antd'
// import { message } from 'antd'
// import { useRequest } from 'ahooks'
import { Switch } from 'react-router'
// APi
import * as salesController from '../../../api-twg/score/sales-support-controller'
// Routes
import PrivateRoute from '../../../components/PrivateRoute'
import ThirtyMinutes from '../ThirtMinutes'
// Local Files
import WelcomeGreenhouse from '../WelcomeGreenhousePage'
// Controller

// Context Api instance
const QueryActionsContext = React.createContext(undefined, undefined)
const QueryStateContext = React.createContext(undefined, undefined)
const QueryDispatchContext = React.createContext(undefined, undefined)

// Actions Types
export const IS_LOADING = 'IS_LOADING'
export const ACCOUNT_TYPE = 'ACCOUNT_TYPE'
export const IS_GRENHOUSE_RESULT = 'IS_GRENHOUSE_RESULT'
export const GREENHOUSE_ESTIMATOR = 'GREENHOUSE_ESTIMATOR'

// Actions Creators
export const setIsLoading = (bool) => ({ type: IS_LOADING, bool })
export const setAccountType = (accountType) => ({ type: ACCOUNT_TYPE, accountType })
export const setGreenhouseResult = (isResult) => ({ type: IS_GRENHOUSE_RESULT, isResult })
export const setGreenhouseEstimator = (estimate) => ({ type: GREENHOUSE_ESTIMATOR, estimate })

const initialState = {
  // Commons
  isLoading: false,
  accountType: '',
  isGrnhouseResult: false,
  greenhouseEstimate: undefined
}

export function reducer (state, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.bool
      }
    case ACCOUNT_TYPE:
      return {
        ...state,
        accountType: action.accountType
      }
    case IS_GRENHOUSE_RESULT:
      return {
        ...state,
        isGrnhouseResult: action.isResult
      }
    case GREENHOUSE_ESTIMATOR:
      return {
        ...state,
        greenhouseEstimate: action.estimate
      }
    default:
      return state
  }
}

export const startAllJobBackFill = (dispatch) => async (payload) => {
  try {
    const response = await salesController.startAllJobBackFill({
      atsType: 'GHIO',
      sinceDate: '2021-10-17T15:47:37.973Z'
    })
    const { data } = response
    dispatch(setGreenhouseEstimator(data))
    // const data = 'res'
    return response
    // return
  } catch (e) {
    const greenhouseErrorAlert = type => {
      notification[type]({
        message: 'ERROR',
        description: 'Something went wrong, contact engineering for further details',
        duration: 0

      })
    }
    greenhouseErrorAlert('error')
    console.log(e.message)
  }
}

export const getEstimate = (dispatch) => async (payload) => {
  dispatch(setIsLoading(true))
  try {
    const response = await salesController.getEstimate(payload, {
      atsType: payload.atsType,
      sinceDate: payload?.sinceDate
    })
    const { data } = response
    dispatch(setGreenhouseEstimator(data))
    // const data = 'res'
    return response
    // return
  } catch (e) {
    // message.error('Something went wrong with create query')
    console.log(e.message)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const getEstimateForJob = (dispatch) => async (payload) => {
  dispatch(setIsLoading(true))
  try {
    const response = await salesController.getEstimateForJob(payload, {
      atsType: payload.atsType,
      sinceDate: payload.sinceDate
    })
    const { data } = response
    dispatch(setGreenhouseEstimator(data))
    return response
  } catch (e) {
    // message.error('Something went wrong with create query')
    console.log(e.message)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const startSingleJobBackFill = (dispatch) => async (payload) => {
  dispatch(setIsLoading(true))
  try {
    const response = await salesController.startSingleJobBackFill(payload)
    const { data } = response
    dispatch(setGreenhouseEstimator(data))
    // const data = 'res'
    return data
    // return
  } catch (e) {
    const greenhouseErrorAlert = type => {
      notification[type]({
        message: 'ERROR',
        description: 'Something went wrong, contact engineering for further details',
        duration: 0

      })
    }
    greenhouseErrorAlert('error')
    return 'error'
  } finally {
    dispatch(setIsLoading(false))
  }
}

function GreenhouseEstimatorContextProvider () {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [actions] = React.useState({
    // Commons
    getEstimate: getEstimate(dispatch),
    getEstimateForJob: getEstimateForJob(dispatch),
    startSingleJobBackFill: startSingleJobBackFill(dispatch),
    startAllJobBackFill: startAllJobBackFill(dispatch)
  })

  // useRequest(async () => actions.getDiscoverJobs())

  return (
        <QueryActionsContext.Provider value={actions}>
            <QueryStateContext.Provider value={state}>
                <QueryDispatchContext.Provider value={dispatch}>
                   <Switch>
                        <PrivateRoute
                        path={'/greenhouseEstimator'}
                        strict={false}
                        component={WelcomeGreenhouse}
                        />
                         <PrivateRoute
                        path={'/thirtyMinutes'}
                        strict={false}
                        component={ThirtyMinutes}
                        />
                    </Switch>
                </QueryDispatchContext.Provider>
            </QueryStateContext.Provider>
        </QueryActionsContext.Provider>
  )
}

// Hooks
function useQueryActions () {
  const context = React.useContext(QueryActionsContext)
  if (context === undefined) {
    throw new Error('useQueryActions must be used within a QueryContextProvider')
  }
  return context
}

function useQuerysState () {
  const context = React.useContext(QueryStateContext)
  if (context === undefined) {
    throw new Error('useQueryState must be used within a QueryContextProvider')
  }
  return context
}

function useQuerysDispatch () {
  const context = React.useContext(QueryDispatchContext)
  if (context === undefined) {
    throw new Error('useQueryDispatch must be used within a QueryContextProvider')
  }
  return context
}

export { GreenhouseEstimatorContextProvider, useQueryActions, useQuerysState, useQuerysDispatch }
