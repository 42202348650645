/* eslint-disable no-debugger */
import moment from 'moment'
import { notification } from 'antd'
import dayjs from 'dayjs'
import ThiswayDemo from '../assets/thisway-demo.png'
import ThiswayDev from '../assets/thisway-score.png'
import Thisway from '../assets/popup_logo.png'

export const languageFormatFunc = (lang) => {
  const languageCode = lang.slice(0, 2)
  switch (languageCode) {
    case 'en':
      return { language: 'English', code: languageCode, standardCode: lang }
    case 'es':
      return { language: 'Spanish', code: languageCode, standardCode: lang }
    case 'fr':
      return { language: 'French', code: languageCode, standardCode: lang }
    default:
      return { language: 'English', code: 'en', standardCode: 'en_US' }
  }
}

export const handleCsvDownload = async (data, DownloadRef, fileName) => {
  try {
    const jsonData = JSON.stringify(data)
    const blob = new Blob([jsonData], { type: 'text/csv;charset=utf-8' })
    const url = window.URL.createObjectURL(blob)
    DownloadRef.current.href = url
    DownloadRef.current.download = fileName
    await DownloadRef.current.click()
    return true
  } catch (e) {
    notification.error({
      placement: 'topLeft',
      message: 'Something went wrong'
    })
    return false
  }
}

export const fileDownloadDate = () => {
  return dayjs().format('YYMMDD')
}

export function DateFormat (dateStr) {
  return moment(dateStr).format('MM/DD/YYYY')
}

export function DateTime (date) {
  const dateObj = new Date(date)
  const datetime =
      dateObj.toLocaleString('default', { month: 'short' }) + ' ' +
      (dateObj.getDate()) + ', ' +
      dateObj.getFullYear() + ', ' +
      dateObj.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  return datetime
}

export const getLogoAndText = (logo) => {
  if (logo) {
    return { logo }
  }
  if (window.location.href.includes('dev')) {
    return { logo: ThiswayDev, text: '- Dev' }
  }
  if (window.location.href.includes('demo')) {
    return { logo: ThiswayDemo, text: ' - Demo' }
  }
  return { logo: Thisway }
}

export default {
  handleCsvDownload
}
