import React from 'react'
import { Col, Table, Card, Typography, Input, Spin, Modal } from 'antd'
import { useParams } from 'react-router'
import { PropTypes } from 'prop-types'

// Local Files
import Queries from './columns'
// import SearchFields from './SearchFields'
// import HeaderLayout from '../../../layouts/HeaderLayout'
// Context
import {
  useQueryActions,
  useQuerysState
} from '../CandidateContext/SearchQueryContext'
// Styles
import './SearchCandidates.css'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../constants/constants'

const { Title } = Typography

export default function Candidates ({ setSavedSearch, savedSearch }) {
  const [search, setSearch] = React.useState(null)
  const [searchedArray, setSearchedArray] = React.useState([])
  const { jobkey } = useParams()
  const { t } = useTranslation()
  // Context
  const { queries, isLoading } = useQuerysState()
  const actions = useQueryActions()

  React.useEffect(() => {
    window.zE('webWidget', 'helpCenter:setSuggestions', { search: 'ticket' })
    actions.getDiscoverJobs()
  }, [])

  React.useEffect(() => {
    setSearchedArray(queries)
  }, [queries])
  const {
    columns
    // DataResource
  } = React.useMemo(() => Queries(queries, t), [queries])

  const handleSavedSearch = (e) => {
    const searchItem = e?.target?.value
    setSearch(searchItem)
    const filteredArray = queries.filter(item => item?.name.toLowerCase().includes(searchItem?.toLowerCase()))
    setSearchedArray(filteredArray)
  }
  const getAllQueries = () => (
      <Spin spinning={isLoading}>
        {/* <Layout id='saved-searches-layout' > */}
          {/* <Row justify='center'> */}
            <Card >
            <Col span={24} flex="auto">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                <Typography>
                  <Title level={4} id='saved-searches' >{t(allConstants.SAVED_SEARCHES)}</Title>
                </Typography>
                </div>
                <div>
                  <CloseOutlined onClick={() => setSavedSearch(false)}/>
                </div>
              </div>
              <div style={{ width: '55%' }}>
                <Input
                  style={{
                    height: '40px',
                    border: '1px dotted #d9d9d9',
                    borderRadius: '5px'
                  }}
                  allowClear={ true}
                  suffix={<SearchOutlined style={{ fontSize: 16 }} />}
                  value={search}
                  onChange={(e) => handleSavedSearch(e)}
                  placeholder={t(allConstants.FIND_SEARCH_BY_TITLE)}
                />
              </div>
            </Col>
            </Card>
          {/* </Row> */}
          {/* <Row justify='center' > */}
            <Col span={24} flex="auto">
              <Card >
                <Table
                style={{ minHeight: '50vh' }}
                // dataSource={DataResource}
                dataSource={searchedArray}
                columns={columns}
                rowKey={'jobKey'}
                scroll={{ y: 'calc(100vh - 400px)' }}
                onRow={ record => {
                  return {
                    // onClick: e => goToSearchedCandidateScreen(record)
                  }
                } }
                loading={isLoading }
                rowClassName="query-table-row"
                onChange={(pagination) => {
                  window.analytics.track('Page change', {
                    page: pagination?.current,
                    search_id: jobkey
                  })
                }}
                />
              </Card>
            </Col>
          {/* </Row> */}
        {/* </Layout> */}
      </Spin>
  )

  return (
  <Modal
    centered
    closable={false}
    width={1000}
    visible={savedSearch}
    footer={null}
  >
      <div className="search-candidates-dFlex-column" >
        {/* {SearchFields()} */}
        {getAllQueries()}
      </div>
  </Modal>
  )
}

Candidates.propTypes = {
  setSavedSearch: PropTypes.func,
  savedSearch: PropTypes.bool
}