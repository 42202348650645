import styled from 'styled-components'

export const OptionContainer = styled.div`
  margin-top: 12px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 4px 4px 0px;
  background: #fff;
  border: solid 1px #f0f0f0;
  border-left: 4px solid #00b7c0 !important;
  width: 271px;
  padding: 18px 24px;
  height: 85px;
  @media screen and (max-width: 1320px) {
    width: 260px;
    padding: 14px;
  }
 
  @media screen and (max-width: 1280px) {
    width: 240px;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  & svg {
    width: 45px;
    height: 38px;
    fill: #bdbdbd;
  }

  & .arrow {
    width: 32px;
    height: 32px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    & svg {
      width: 20px;
      height: 20px;
    }
  }
  &:hover {
    cursor: pointer;
    border: solid 1px #00b7c0;
    background-color: rgba(0, 183, 192, 0.08);
    border-radius: 0px 4px 4px 0px;
    border-top: 1px solid #00b7c0;

    border-right: 1px solid #00b7c0;

    border-bottom: 1px solid #00b7c0;

    background: #ddeef1;

    & svg {
      fill: "#00b7c0";
    }

    & .arrow {
      border-radius: 20px;
      background-color: #00b7c0;
      & svg {
        fill: #fff;
      }
    }
  }
`
