import React, { useRef } from 'react'
import { PropTypes } from 'prop-types'
// eslint-disable-next-line no-unused-vars
import {
  CarouselWrapper,
  FirstSlide,
  ModalWrapper,
  OtherSlides
} from './style'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../../constants/constants'
import FirstTimeModalFooter from './FirstTimeModalFooter'

const FirstTimeUser = ({
  popUpValue,
  setPopUpValue,
  closable,
  otherSlidesItems
}) => {
  const { t } = useTranslation()
  const [slideNumber, setSlideNumber] = React.useState(0)
  const theme = useTheme()
  const slider = useRef()

  const onChange = (currentSlide) => {
    setSlideNumber(currentSlide)
  }
  const footerProps = {
    setPopUpValue,
    slideNumber,
    slider,
    setSlideNumber,
    totalSlides: otherSlidesItems?.length
  }
  return (
    <ModalWrapper
      visible={popUpValue}
      closable={closable}
      destroyOnClose={true}
      onCancel={() => {
        if (closable) {
          setSlideNumber(0)
          setPopUpValue(false)
        }
      }}
      footer={<FirstTimeModalFooter {...footerProps} />}
    >
      <CarouselWrapper
        style={{ height: '495px' }}
        dotPosition={slideNumber === 0 && 'bottom'}
        dots={true}
        ref={(ref) => {
          slider.current = ref
        }}
        afterChange={onChange}
      >
        <FirstSlide>
          <div className="heading">{t(allConstants.WELCOME_TO)}</div>
          <div className="welcome_logo">
            <img src={theme.logo || 'https://score.ai4jobs.info/logo.png'} />
          </div>
          <div>
            <span>{t(allConstants.PRODUCT_OVERVIEW)}</span>
          </div>
        </FirstSlide>
        {otherSlidesItems?.map((val) => (
          <OtherSlides key={val.title}>
            <div className="heading">{t(val.title)}</div>
            <div className="image-container">
              {val?.video
                ? <video className="pages-img" controls>
                  <source src={val.slide} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                : <img className="pages-img" src={val.slide} />
                  }
            </div>

            <div>
              <p>{t(val.description)}</p>
            </div>
          </OtherSlides>
        ))}
      </CarouselWrapper>
    </ModalWrapper>
  )
}

FirstTimeUser.propTypes = {
  popUpValue: PropTypes.bool,
  setPopUpValue: PropTypes.func,
  closable: PropTypes.bool,
  otherSlidesItems: PropTypes.array
}

export default FirstTimeUser
