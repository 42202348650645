import axios from '../axios-config'
import { normalizeGetJobReq } from './normalize'

/**
 * POST
 * /discover/api/v1/jobreq
 */
export const getJobReq = (data = null, params = {}) => {
  return axios.post('/discover/api/v1/jobreq', data, {
    headers: {
      'Content-Type': 'multipart-formdata',
      Accept: 'application/json'
    },
    params: {
      preview: true,
      ...(params || {})
    }
  })
    .then(normalizeGetJobReq)
}
