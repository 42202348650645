import React from 'react'
import { PropTypes } from 'prop-types'
import * as themeApi from '../api-twg/twg/theme-controller'
import * as greenhosueDemo from '../api-twg/score/sales-support-controller'
import { useRequest } from 'ahooks'
import { notification } from 'antd'

/** action types */
export const SET_THEME = 'SET_USER'
export const GREENHOUSE_LOADING = 'GREENHOUSE_LOADING'

/** action creators */
export const setTheme = (theme) => ({ type: SET_THEME, theme })
export const setGreenhouseLoading = (bool) => ({ type: GREENHOUSE_LOADING, bool })

/** async actions */
const getTheme = (dispatch) => async () => {
  const response = await themeApi.getTheme(encodeURI(window.location.host))
  const { data } = response || {}
  const { theme } = data || {}
  dispatch(setTheme(JSON.parse(theme || '{}')))
  if (theme) {
    const color = JSON.parse(theme)
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        color: {
          theme: color.primary_color || '#00b7c0',
          button: color.primary_color || '#00b7c0'
        }

      }
    })
  }
}

const getGreenhousePollResult = (dispatch) => async (clientKey, trackingId, futureDate) => {
  dispatch(setGreenhouseLoading(true))
  try {
    const response = await greenhosueDemo.startAllJobBackFill(clientKey, trackingId)
    const { data } = response || {}
    const dt = new Date()
    const isTimeExceed = dt > futureDate
    if (!data.status.completed) {
      if (isTimeExceed) {
        dispatch(setGreenhouseLoading(false))
        const greenhouseErrorAlert = type => {
          notification[type]({
            message: 'ERROR',
            description: 'Something went wrong, contact engineering for further details',
            duration: 0

          })
        }
        greenhouseErrorAlert('error')
        return
      }
      setTimeout(function () {
        getGreenhousePollResult(dispatch)(clientKey, trackingId, futureDate)
      }, 10000)
      window.addEventListener('beforeunload', function (e) {
        /* eslint-disable */
        const confirmationMessage = '\o/';

        (e || window.event).returnValue = confirmationMessage // Gecko + IE
        return confirmationMessage // Webkit, Safari, Chrome
      })
    } else {
      dispatch(setGreenhouseLoading(false))
      const greenhouseAlert = type => {
        notification[type]({
          message: 'Success',
          description: 'Greenhouse Integration was successful',
          duration: 0
    
        })
      }
      greenhouseAlert('success')
      return data
    }

    console.log(data)
  } catch (err) {
    console.log(err.message)
  }
}

/** initial value */
const initialState = {
  primary_color: '#00b7c0',
  logo: '',
  barColor: ['#00b7c0', '#00b7c0'],
  greenhouseLoading: false
}

/** reducer */
export function reducer (state, action) {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        ...action.theme
      }
      case GREENHOUSE_LOADING:
        return {
          ...state,
          greenhouseLoading: action.bool
        }
      default:
      return state
  }
}

/** contexts */
const ThemeActionsContext = React.createContext(undefined, undefined)
export const ThemeStateContext = React.createContext(undefined, undefined)
const ThemeDispatchContext = React.createContext(undefined, undefined)

function ThemeProvider ({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [actions] = React.useState({
    getTheme: getTheme(dispatch),
    getGreenhousePollResult: getGreenhousePollResult(dispatch),

  })

  const { loading } = useRequest(getTheme(dispatch))

  return (
    <ThemeActionsContext.Provider value={actions}>
      <ThemeStateContext.Provider value={state}>
        <ThemeDispatchContext.Provider value={dispatch}>
          {!loading && children}
        </ThemeDispatchContext.Provider>
      </ThemeStateContext.Provider>
    </ThemeActionsContext.Provider>
  )
}

/** hooks */
function useThemeActions () {
  const context = React.useContext(ThemeActionsContext)
  if (context === undefined) {
    throw new Error('useThemeActions must be used within a ThemeProvider')
  }
  return context
}

function useThemeState () {
  const context = React.useContext(ThemeStateContext)
  if (context === undefined) {
    throw new Error('useThemeState must be used within a ThemeProvider')
  }
  return context
}

function useThemeDispatch () {
  const context = React.useContext(ThemeDispatchContext)
  if (context === undefined) {
    throw new Error('useThemeDispatch must be used within a ThemeProvider')
  }
  return context
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { ThemeProvider, useThemeActions, useThemeState, useThemeDispatch }
