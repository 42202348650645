import React from 'react'
import { Row, Input, Col } from 'antd'
import { PropTypes } from 'prop-types'
// Local Files
import FiltersLayout from '../component/FiltersLayout'
import TagGroup from '../../../components/TagGroup'
// Helper Component
import AddMore from '../component/AddMore'
import HandleInputTags from '../../../helpers/HandleInputTagsHelpers'
// Api Helpers
import { UpdateActiveCandidateData } from '../../../helpers/QueryHelpers'
// Style Sheet
import './sections.css'
import isAnyField from '../helpers/isAnyField'
import { setIsOpen } from '../helpers/actionHelpers'
import FiltersCount from '../component/FiltersCount'
import { setSearchApiObj, useQuerysDispatch, useQuerysState } from '../../../CandidateContext/SearchQueryContext'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'

const Skills = ({
  contextData, existedSkills, setExistedFilters,
  match, existedFilters, setSearchThroughAdvanceFilter,
  setSearchApiVal
}) => {
  const [values, setValues] = React.useState({
    skillsValue: '',
    excludeSkillsValue: '',
    skills: [],
    excludeSkills: [],
    isExcludeSkills: false,
    isOpen: false
  })
  const { t } = useTranslation()
  const dispatch = useQuerysDispatch()
  const { searchApiObj } = useQuerysState()
  const [advancedSearchFilter, setAdvancedSearchFilter] = React.useState(false)

  const { candidatesFilters, api, matchingResults } = contextData

  const handleInputChange = e => setValues({ ...values, [e.target.name]: e.target.value })

  const handleInpurConfirm = React.useCallback(async (activeInputValue, activeInput) => {
    setAdvancedSearchFilter(true)
    const tags = HandleInputTags(activeInputValue, activeInput, values, setValues)
    if (tags.length > 0 && values[activeInputValue]) {
      setValues({
        ...values,
        [activeInput]: tags,
        [activeInputValue]: '',
        isExcludeSkills: false
      })
      updateJobApi(activeInput, tags)
    }
  }, [api, setValues, values, candidatesFilters])

  React.useEffect(() => {
    setValues({
      ...values,
      skills: existedSkills?.shouldHave || [],
      excludeSkills: existedSkills?.mustNotHave || []
    })
  }, [existedSkills])

  React.useEffect(() => {
    if (match && advancedSearchFilter) {
      setExistedFilters({
        ...existedFilters,
        skills: {
          ...existedFilters.skills,
          expertise: {
            mustHave: [],
            mustNotHave: values?.excludeSkills || [],
            shouldHave: values?.skills || []
          }
        }
      })
      setSearchThroughAdvanceFilter(true)
      setAdvancedSearchFilter(false)
      setSearchApiVal(true)
    }
  }, [advancedSearchFilter, values])

  const updateJobApi = React.useCallback((activeInput, tags) => {
    const skills = activeInput === 'skills' ? tags : values.skills
    UpdateActiveCandidateData({
      shouldHave: { expertise: skills },
      mustNotHave: { expertise: activeInput === 'excludeSkills' ? tags : values.excludeSkills }
    },
    candidatesFilters,
    api,
    { skills },
    matchingResults,
    match
    )
  }, [values])

  const handleDelete = async (tags, activeTagsType) => {
    const filteredTags = { ...values, [activeTagsType]: tags }
    setValues(filteredTags)
    if (match) {
      setAdvancedSearchFilter(true)
    }
    updateJobApi(activeTagsType, tags)
  }

  const clearIncludeFilters = () => {
    if (match) {
      dispatch(setSearchApiObj(
        {
          ...searchApiObj,
          shouldHave: { ...searchApiObj.shouldHave, expertise: [] }
        }
      ))
      setValues({
        ...values,
        skills: []
      })
      setAdvancedSearchFilter(true)
    }

    UpdateActiveCandidateData({
      shouldHave: { expertise: [] }
    },
    candidatesFilters,
    api,
    { skills: [] },
    matchingResults,
    match
    )
  }

  const clearExcludeFilters = () => {
    if (match) {
      dispatch(setSearchApiObj(
        {
          ...searchApiObj,
          mustNotHave: { ...searchApiObj.mustNotHave, expertise: [] }
        }
      ))
      setValues({
        ...values,
        excludeSkills: []
      })
      setAdvancedSearchFilter(true)
    }

    UpdateActiveCandidateData({
      mustNotHave: { expertise: [] }
    },
    candidatesFilters,
    api,
    { skills_exclude: [] },
    matchingResults,
    match
    )
  }

  return (
    <FiltersLayout title={t('SKILLS')}
      activeFilters={isAnyField({ skills: values.skills })} clearAllFilters={clearIncludeFilters}
      setIsOpen={() => setIsOpen(setValues, values)}
      isOpen={values.isOpen}
    >
      <Row className='filters-tags' style={values.skills.length ? { marginTop: '16px' } : undefined} >
        <TagGroup
          tags={values.skills}
          activeTagsType="skills"
          onDelete={handleDelete}
        />
      </Row>
      {values.isOpen && <Row className='search-skill-row'>
        <Input
          name='skillsValue'
          placeholder={t(allConstants.ADD_SKILLS_TEXT)}
          value={values.skillsValue}
          onChange={handleInputChange}
          onBlur={!match ? () => handleInpurConfirm('skillsValue', 'skills') : null}
          onPressEnter={() => handleInpurConfirm('skillsValue', 'skills')}
        />

      </Row>}

      <Col className={values.excludeSkills.length || values.isOpen ? 'exclude-tags-area' : ''} >
        {(values.excludeSkills.length > 0 || values.isExcludeSkills) &&
          <FiltersCount
          ExcludeField={values.excludeSkills}
          clearExcludeFilters={clearExcludeFilters}
          title={t(allConstants.EXCLUDE_SKILLS)}
          />
         }

        <Row className='filters-tags' >
          <TagGroup
            tags={values.excludeSkills}
            isExlude={values.excludeSkills && true}
            onDelete={handleDelete}
            activeTagsType="excludeSkills" />
        </Row>

        {values.isExcludeSkills && values.isOpen && <Row>
          <Input
            name='excludeSkillsValue'
            placeholder={t(allConstants.EXCLUDE_SKILLS)}
            value={values.excludeSkillsValue}
            onChange={handleInputChange}
            onBlur={!match ? (e) => handleInpurConfirm('excludeSkillsValue', 'excludeSkills') : null}
            onPressEnter={(e) => handleInpurConfirm('excludeSkillsValue', 'excludeSkills')}
          />
        </Row>}
      </Col>

      {!values.isExcludeSkills && values.isOpen &&
        <AddMore
          title={t(allConstants.EXCLUDE_SKILLS)}
          onClick={() => setValues({ ...values, isExcludeSkills: true })} />}
    </FiltersLayout>
  )
}

Skills.propTypes = {
  contextData: PropTypes.object.isRequired,
  existedSkills: PropTypes.object,
  setExistedFilters: PropTypes.func,
  match: PropTypes.object,
  existedFilters: PropTypes.object,
  setSearchThroughAdvanceFilter: PropTypes.func,
  setSearchApiVal: PropTypes.func
}

export default Skills
