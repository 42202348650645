/* eslint-disable no-debugger */

import { PropTypes } from 'prop-types'
import { DatePicker, Radio, Select, Button, Form, Col, Row } from 'antd'
import React, { useRef, useState } from 'react'
import {
  useReportActions,
  useReportState
} from '../../context/ReportContext'
import { dateOptions, getDateBeforeAndAfterRange } from '../../utils'
import { handleCsvDownload } from '../../../../helper'
import { TransactionRangeStyle } from './style'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'
const { RangePicker } = DatePicker
const { TODAY, STANDARD_DATE, CUSTOM_DATE } = allConstants
function RangeComponent ({ exportFileName }) {
  const [form] = Form.useForm()
  const today = getDateBeforeAndAfterRange('today')
  const year = getDateBeforeAndAfterRange('year')
  const { t } = useTranslation()
  const [dateRange, setDateRange] = useState({ customDate: { startDate: year.startDate, endDate: year.endDate }, dateRangeSelector: { startDate: today.startDate, endDate: today.endDate } })
  const [dateSelector, setDateSelector] = useState('standard')
  const actions = useReportActions()
  const DownloadRef = useRef()
  const { resumeReport } = useReportState()
  const handleChange = (days) => {
    const { startDate, endDate } = getDateBeforeAndAfterRange(days)
    setDateRange(prev => ({ ...prev, dateRangeSelector: { startDate, endDate } }))
    onFinish(startDate, endDate)
  }
  const customDateHandler = (val) => {
    if (val) {
      const startDate = val[0].format().substring(0, 19)
      const endDate = val[1].format().substring(0, 19)
      setDateRange(prev => ({ ...prev, customDate: { startDate, endDate } }))
      onFinish(startDate, endDate)
    }
  }

  const handleDateSelector = (val) => {
    setDateSelector(val)
  }

  const exportData = async () => {
    handleCsvDownload(
      resumeReport,
      DownloadRef,
      exportFileName
    )
  }
  const onFinish = (startDate, endDate) => {
    actions.getResumeReport({
      startDate,
      endDate,
      sortColumn: 'NAME',
      sortDirection: 'ASC'
    })
  }
  return (
    <TransactionRangeStyle>
      <a href="#" ref={DownloadRef} />
      <Form
      className='form'
        onFinish={onFinish}
        form={form}
        initialValues={{ standard: t(TODAY), custom: [year?.startDate, year?.endDate] }}
      >
        <Row gutter={[16, 8]}>
          <Col>
            <label style={{ cursor: 'pointer' }}>
              <Radio
                checked={dateSelector === 'standard'}
                value="standard"
                onChange={(e) => {
                  handleDateSelector(e.target.value)
                  const { dateRangeSelector } = dateRange
                  onFinish(dateRangeSelector.startDate, dateRangeSelector.endDate)
                }}
              />{' '}
             {t(STANDARD_DATE)}
            </label>
             <div className="standard_date" >
              <Form.Item
                style={{
                  padding: 0
                }}
                name="standard"
              >
                <Select
                  size="large"
                  style={{
                    width: 255
                  }}
                  onChange={handleChange}
                  options={dateOptions(t)}
                  disabled={dateSelector === 'custom'}
                />
              </Form.Item>
             </div>
          </Col>
          <Col>
            <div>
              <label style={{ cursor: 'pointer' }}>
                <Radio
                  checked={dateSelector === 'custom'}
                  value="custom"
                  onChange={(e) => {
                    handleDateSelector(e.target.value)
                    const { customDate } = dateRange
                    onFinish(customDate?.startDate, customDate?.endDate)
                  }}
                />
                {t(CUSTOM_DATE)}
              </label>
              <div className="custom_date">
                <Form.Item name="custom">
                  <RangePicker
                    size="large"
                    onChange={customDateHandler}
                    disabled={dateSelector === 'standard'}
                    dropdownClassName="range_picker"
                  />
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
        <div className='export_btn' style={{ display: 'none' }}>
          <Button type="primary" onClick={exportData}>
            Export
          </Button>
        </div>
      </Form>
    </TransactionRangeStyle>
  )
}

RangeComponent.propTypes = {
  exportFileName: PropTypes.string.isRequired
}

export default RangeComponent
