import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Divider, Space, Spin, Table } from 'antd'
import { columns } from './columns'
import { setFilter, setSelectedCandidatesIds, useJobRequisitionsDispatch, useJobRequisitionsState } from '../../context/ApplicantsContextProvider'
import ExportButton from '../ExportButton'
import { Container, QualifiedModal } from './style'
import JobDescription from '../JobDescription/JobDescription'
import ApplicantTableHeader from './Component/ApplicantTableHeader'
import { useJobsState } from '../../../jobs/context/JobsContext'
import { useHistory } from 'react-router'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

const ApplicantsTable = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const [qualifiedInfoModalVal, setQualifiedInfoModal] = React.useState(false)
  // const { jobExtId } = useParams()
  const [pageAndSize, setPageAndSize] = useState({
    page: 1,
    size: 10
  })
  const [filterState, setFilterState] = useState(['Direct Applicants'])
  const [filteredInfo, setFilteredInfo] = useState(null)
  const state = useJobRequisitionsState()
  const dispatch = useJobRequisitionsDispatch()
  const { candidatesIds, filter, indirectOnly, passiveLoading } = state
  const { list, loading, resultSetId } = state[filter]
  const qualifiedModalCount = JSON.parse(localStorage.getItem('qualifiedModalCount'))
  const { isLoading, passive } = useJobsState()

  useEffect(() => {
    dispatch(setSelectedCandidatesIds([]))
  }, [dispatch, filter])

  const dataSource = useMemo(() => {
    return (indirectOnly && filter === 'qualified')
      ? list.filter(applicant => (!applicant.isDirect && applicant.originTabCode === 'INDIRECT')).map((applicant, rank) => ({ ...applicant, rank }))
      : list
  }, [filter, indirectOnly, list])

  const rowSelection = useMemo(() => (
    {
      selectedRowKeys: candidatesIds,
      selection: candidatesIds,
      onSelect: (candidate) => {
        const checkCandidate = () => {
          if (candidatesIds.includes(candidate.id)) {
            return candidatesIds.filter(id => id !== candidate.id)
          }
          return [...(candidatesIds || []), candidate.id]
        }
        dispatch(setSelectedCandidatesIds(checkCandidate()))
      },
      onSelectAll: () => {
        if (candidatesIds.length === dataSource.length) {
          dispatch(setSelectedCandidatesIds([]))
        } else {
          dispatch(setSelectedCandidatesIds(dataSource.map(item => item.id)))
        }
      },
      preserveSelectedRowKeys: true
    }
  ), [candidatesIds, dataSource, dispatch])

  const ApplicationTableTitle = () => {
    const from = ((pageAndSize.page - 1) * pageAndSize.size) + 1
    const { t } = useTranslation()
    let isPageSizeOutOfOrder = null
    if (pageAndSize.page > 1) {
      const total = list.length / (pageAndSize.size)
      if (total % 10 !== 0) {
        const isFloattotal = total % 1 !== 0
        if (isFloattotal) {
          try {
            const getPageSizeFromListTotal = total.toString().split('.')
            if (pageAndSize.page > getPageSizeFromListTotal[0]) {
              isPageSizeOutOfOrder = parseInt(getPageSizeFromListTotal[getPageSizeFromListTotal.length - 1])
            }
          } catch (e) {
            console.log(e.message)
          }
        }
      }
    }

    return (
      <>
        <ApplicantTableHeader />
        <Divider className='table-title-divider' />
        {<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {list.length > 0
            ? `${filter === 'passive' ? t(allConstants.MATCHES_JOB_DESCRIPTION) : ''} ${t('Showing')} ${from} - ${list.length < 10
              ? (list.length)
              : (isPageSizeOutOfOrder
                ? ((from + isPageSizeOutOfOrder) - 1)
                : (pageAndSize.page * pageAndSize.size))
            } ${t('of')} ${list.length} ${t('Results')} ${filter === 'qualified' ? t(`(${allConstants.QUALIFIED_APPLICANTS_ONLY})`) : filter === 'direct' ? t(`${allConstants.DIRECT_APPLICANTS_ONLY}`) : filter === 'indirect' ? t(`${allConstants.DIRECT_APPLICANTS_ONLY}`) : ''}`
            : filter === 'qualified' ? t(`${allConstants.NO_QUALIFIED_APPLICANTS}`) : filter === 'direct' ? t(allConstants.NO_DIRECT_APPLICANTS) : filter === 'indirect' && t(allConstants.NO_INDIRECT_APPLICANTS)}
          <Space>
            {/* {filter === 'passive' */}
            {false &&
              <span style={{
                color: 'rgba(0, 0, 0, 0.85)',
                fontSize: '16px',
                fontWeight: '500'
              }}>{t(allConstants.DISCOVER_CANDIDATES)}
                <span
                  onClick={() => history.push('/candidates')}
                  className='click-here'
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                    textDecoration: 'underline',
                    color: '#00b7c0',
                    cursor: 'pointer'
                  }}
                >
                  {t(allConstants.CLICK_HERE)}.
                </span>
              </span>}
            <ExportButton
              selectedIds={candidatesIds}
              resultSetId={resultSetId}
            />
          </Space>
        </div>}
      </>)
  }

  useEffect(() => {
    setPageAndSize({ ...pageAndSize, size: 10, page: 1 })
  }, [filter])

  const handleFilterState = (filters) => {
    if (filter !== 'passive') {
      if (!filters?.ApplicantName) {
        setFilterState(['Qualified Applicants'])
        dispatch(setFilter('qualified'))
        if (!qualifiedModalCount) setQualifiedInfoModal(true)
      } else if (filters?.ApplicantName[0] === 'Direct Applicants') {
        setFilterState(filters?.ApplicantName)
        dispatch(setFilter('direct'))
      } else if (filters?.ApplicantName[0] === 'Indirect Applicants') {
        setFilterState(filters?.ApplicantName)
        dispatch(setFilter('indirect'))
      } else {
        setFilterState(filters?.ApplicantName)
        dispatch(setFilter('qualified'))
        if (!qualifiedModalCount) setQualifiedInfoModal(true)
      }
    }
  }

  return (
    <Container >
      <Spin spinning={loading || isLoading || passive || passiveLoading} >
        <div style={{ display: 'flex', margin: '0px 0px 10px 0px', width: '100%' }}>
          <JobDescription />
          <Card
            style={{ width: '79.5%' }}
            // extra={renderExtraActions()}
            title={ApplicationTableTitle()}
            bordered={false}>
            <Table
              pagination={{
                position: ['topRight', 'bottomRight'],
                pageSize: pageAndSize.size,
                defaultCurrent: 1,
                current: pageAndSize.page
              }}
              scroll={{ y: 'calc(100vh - 190px)' }}
              rowSelection={rowSelection}
              dataSource={dataSource}
              columns={columns(filterState, filteredInfo, filter, t)}
              rowKey={'id'}
              onChange={(pagination, filters) => {
                setFilteredInfo(filters)
                handleFilterState(filters)
                if (pagination?.current === 0) {
                  setPageAndSize({ ...pageAndSize, size: pagination.pageSize, page: pagination.current + 1 })
                  window.analytics.track('Page change', {
                    page: pagination?.current
                  })
                } else {
                  setPageAndSize({ ...pageAndSize, size: pagination.pageSize, page: pagination.current })
                  window.analytics.track('Page change', {
                    page: pagination?.current
                  })
                }
              }}
            />
          </Card>
        </div>
      </Spin>
      <QualifiedModal
        width={542}
        closable={false}
        visible={qualifiedInfoModalVal}
        centered
        footer={[
          <Button
            onClick={() => { localStorage.setItem('qualifiedModalCount', 1); setQualifiedInfoModal(false) }}
            type='primary'
            key={1}>
            {t(allConstants.GOT_IT)}!
          </Button>
        ]}
      >
        <div className='title'>
          <span className='title-info'>{t(allConstants.QUALIFIED_APPLICANT_INFO)}</span>
          <InfoCircleOutlined />
        </div>
        <ul>
          <li>{t(allConstants.QUALIFIED_CANDIDATE_INFO_TEXT_1)}</li>
          <li>{t(allConstants.QUALIFIED_CANDIDATE_INFO_TEXT_2)}</li>
        </ul>
      </QualifiedModal>
    </Container>
  )
}

export default ApplicantsTable
