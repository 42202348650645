import axios from 'axios'

const config = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-type': 'application/json; charset=utf-8',
    Accept: 'application/json; charset=utf-8'
  }
}

/**
 * GET
 * /twg/api/v1/circa/login
 * getCircaLogin
 */
export const getCircaLogin = () => {
  return axios.get('/twg/api/v1/circa/login', config)
}

/**
 * GET
 * /twg/api/v1/oidc/token/${tokenId}
 * getOidcToken
 */
export const getOidcToken = (token) => {
  return axios.get(`/twg/api/v1/oidc/token/${token}`, config)
}
