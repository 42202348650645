import React from 'react'
import { Row, Form, Input, Col } from 'antd'
import PropTypes from 'prop-types'
// Local Files
import FiltersLayout from '../component/FiltersLayout'
// Api helper
import { UpdateActiveCandidateData } from '../../../helpers/QueryHelpers'
import TagGroup from '../../../components/TagGroup'
import AddMore from '../component/AddMore'
import HandleInputTags from '../../../helpers/HandleInputTagsHelpers'
import FiltersCount from '../component/FiltersCount'
import { setIsOpen } from '../helpers/actionHelpers'
import { setSearchApiObj, useQuerysDispatch, useQuerysState } from '../../../CandidateContext/SearchQueryContext'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'

export default function Company ({
  contextData, existedCompany, setExistedFilters,
  match, existedFilters, setSearchThroughAdvanceFilter,
  setSearchApiVal
}) {
  const [values, setValues] = React.useState({
    company: existedCompany?.company?.mustHave || [],
    industry: existedCompany?.industry?.mustHave || [],
    excludeCompanyValue: '',
    excludeIndustryValue: '',
    companyValue: '',
    industryValue: '',
    excludedCompany: existedCompany?.company?.mustNotHave || [],
    excludedIndustry: existedCompany?.industry?.mustNotHave || [],
    isExcludedIndustry: false,
    isExcludedCompany: false,
    isOpen: false
  })
  const dispatch = useQuerysDispatch()
  const { searchApiObj } = useQuerysState()
  const [advancedSearchFilter, setAdvancedSearchFilter] = React.useState(false)
  const { t } = useTranslation()
  const { candidatesFilters, api, matchingResults } = contextData

  const handleInputChange = e => setValues({ ...values, [e.target.name]: e.target.value })

  const handleInpurConfirm = async (activeInputValue, activeInput) => {
    setAdvancedSearchFilter(true)
    const tags = HandleInputTags(activeInputValue, activeInput, values, setValues)
    if (tags.length > 0 && values[activeInputValue]) {
      setValues({
        ...values,
        [activeInput]: tags,
        [activeInputValue]: '',
        isExcludedIndustry: false,
        isExcludedCompany: false
      })
      updateJobApi(activeInput, tags)
    }
  }

  const handleDelete = async (tags, activeTagsType) => {
    const filteredTags = { ...values, [activeTagsType]: tags }
    setValues(filteredTags)
    if (match) {
      setAdvancedSearchFilter(true)
    }
    updateJobApi(activeTagsType, tags)
  }

  const updateJobApi = React.useCallback((activeInput, tags) => {
    const company = activeInput === 'company' ? tags : values.company
    const industry = activeInput === 'industry' ? tags : values.industry

    const excludedCompany = activeInput === 'excludedCompany' ? tags : values.excludedCompany
    const excludedIndustry = activeInput === 'excludedIndustry' ? tags : values.excludedIndustry
    UpdateActiveCandidateData({
      shouldHave: {
        current_company: company,
        current_industry: industry
      },
      mustNotHave: {
        current_company: excludedCompany,
        current_industry: excludedIndustry
      }
    }, candidatesFilters, api,
    activeInput === 'company' || activeInput === 'excludedCompany'
      ? {
          company,
          company_exclude: excludedCompany
        }
      : {
          industry: industry,
          industry_exclude: excludedIndustry

        },
    matchingResults,
    match

    )
  }, [values])

  React.useEffect(() => {
    setValues({
      ...values,
      company: existedCompany?.company?.shouldHave || [],
      excludedCompany: existedCompany?.company?.mustNotHave || [],
      industry: existedCompany?.industry?.shouldHave || [],
      excludedIndustry: existedCompany?.industry?.mustNotHave || []
    })
  }, [existedCompany])

  React.useEffect(() => {
    if (match && advancedSearchFilter) {
      setExistedFilters({
        ...existedFilters,
        current_company: {
          ...existedFilters.company,
          current_company: {
            mustHave: [],
            mustNotHave: values?.excludedCompany || [],
            shouldHave: values?.company || []
          },
          current_industry: {
            mustHave: [],
            mustNotHave: values?.excludedIndustry || [],
            shouldHave: values?.industry || []
          }

        }
      })
      setSearchThroughAdvanceFilter(true)
      setAdvancedSearchFilter(false)
      setSearchApiVal(true)
    }
  }, [advancedSearchFilter, values])

  const clearAllFilters = () => {
    // if (match) {
    //   dispatch(setSearchApiObj(
    //     {
    //       ...searchApiObj,
    //       shouldHave: { ...searchApiObj.shouldHave, company: [], industry: [] },
    //       mustNotHave: {
    //         ...searchApiObj.mustNotHave,
    //         excludedCompany: [],
    //         excludedIndustry: []
    //       }

    //     }
    //   ))
    //   setValues({
    //     ...values,
    //     company: [],
    //     industry: [],
    //     excludedCompany: [],
    //     excludedIndustry: []
    //   })
    //   setAdvancedSearchFilter(true)
    // }
    UpdateActiveCandidateData({
      shouldHave: {
        current_company: [],
        current_industry: []
      },
      shouldNotHave: {
        current_company: [],
        current_industry: []
      }
    }, candidatesFilters, api,
    {
      company: [],
      company_exclude: [],
      industry: [],
      industry_exclude: []
    },
    matchingResults,
    match
    )
  }

  const handleCompanyIncludeFilter = () => {
    dispatch(setSearchApiObj(
      {
        ...searchApiObj,
        shouldHave: { ...searchApiObj.shouldHave, company: [] }
      }
    ))
    setValues({
      ...values,
      company: []
    })
    setAdvancedSearchFilter(true)
  }

  const handleCompanyExcludeFilter = () => {
    dispatch(setSearchApiObj(
      {
        ...searchApiObj,
        mustNotHave: { ...searchApiObj.mustNotHave, excludedCompany: [] }
      }
    ))
    setValues({
      ...values,
      excludedCompany: []
    })
    setAdvancedSearchFilter(true)
  }

  const handleIndustryIncludeFilter = () => {
    dispatch(setSearchApiObj(
      {
        ...searchApiObj,
        shouldHave: { ...searchApiObj.shouldHave, industry: [] }
      }
    ))
    setValues({
      ...values,
      industry: []
    })
    setAdvancedSearchFilter(true)
  }

  const handleIndustryExcludeFilter = () => {
    dispatch(setSearchApiObj(
      {
        ...searchApiObj,
        mustNotHave: { ...searchApiObj.mustNotHave, excludedIndustry: [] }
      }
    ))
    setValues({
      ...values,
      excludedIndustry: []
    })
    setAdvancedSearchFilter(true)
  }

  return (
    <FiltersLayout title={t(allConstants.COMPANY)}
      clearAllFilters={clearAllFilters}
      setIsOpen={() => setIsOpen(setValues, values)}
      isOpen={values.isOpen}
    >
      <Row >
        <Form className='block-level-form' >
         {(values.company.length > 0 || values.isOpen) && <FiltersCount
            title={`${t(allConstants.COMPANY_TEXT)}:`}
            ExcludeField={values.company}
            clearExcludeFilters={match
              ? () => handleCompanyIncludeFilter()
              : () => UpdateActiveCandidateData({ shouldHave: { current_company: [] } }, candidatesFilters, api, { company: [], company_exclude: values.excludedCompany }, matchingResults)}
            classNames={'filters-sub-heading'}
          />}
          <Row className='tags-label filters-tags'>
            <TagGroup
              tags={values.company}
              onDelete={handleDelete}
              activeTagsType="company"
            />
          </Row>
          {values.isOpen && <Form.Item>

            <Input
              name="companyValue"
              placeholder={t(allConstants.ADD_COMPANY)}
              value={values.companyValue}
              onChange={handleInputChange}
              onBlur={!match ? () => handleInpurConfirm('companyValue', 'company') : null}
              onPressEnter={() => handleInpurConfirm('companyValue', 'company')}
            />
          </Form.Item>}
        </Form>
      </Row>

      <Col>
        {(values.excludedCompany.length > 0 || values.isExcludedCompany) &&
          <FiltersCount
            classNames={'comp-excl-title'}
            title={`${t(allConstants.EXCLUDE_COMPANY)}:`}
            ExcludeField={values.excludedCompany}
            clearExcludeFilters={match
              ? () => handleCompanyExcludeFilter()
              : () => UpdateActiveCandidateData({ mustNotHave: { current_company: [] } }, candidatesFilters, api, { company: values.company, company_exclude: [] }, matchingResults)}
          />}

        <Row >
          <TagGroup
            tags={values.excludedCompany}
            isExlude={values.excludedCompany && true}
            onDelete={handleDelete}
            activeTagsType="excludedCompany" />
        </Row>

        {values.isExcludedCompany && <Row>
          <Input
            name='excludeCompanyValue'
            placeholder={t(allConstants.EXCLUDE_COMPANY)}
            value={values.excludeCompanyValue}
            onChange={handleInputChange}
            onBlur={!match ? () => handleInpurConfirm('excludeCompanyValue', 'excludedCompany') : null}
            onPressEnter={() => handleInpurConfirm('excludeCompanyValue', 'excludedCompany')}
            className='filtered-exclude-input'
          />
        </Row>}
      </Col>

      {!values.isExcludedCompany && values.isOpen &&
        <AddMore
          title={t(allConstants.EXCLUDE_COMPANY)}
          onClick={() => setValues({ ...values, isExcludedCompany: true })} />}

      {/* Industry */}

      <Row className={(values.industry.length > 0 || values.isOpen) ? 'margin-top-row' : ''}>
        <Form className='block-level-form' >
          {(values.industry.length > 0 || values.isOpen) && <FiltersCount
            title={t(allConstants.INDUSTRY)}
            ExcludeField={values.industry}
            clearExcludeFilters={match
              ? () => handleIndustryIncludeFilter()
              : () => UpdateActiveCandidateData({ shouldHave: { current_industry: [] } }, candidatesFilters, api, { industry: [], industry_exclude: values.excludedIndustry }, matchingResults)}
            classNames={'filters-sub-heading'}
          />}
          <Row className='tags-label filters-tags'>
            <TagGroup
              tags={values.industry}
              onDelete={handleDelete}
              activeTagsType="industry"
            />
          </Row>
          {values.isOpen && <Form.Item>
            <Input
              name="industryValue"
              placeholder={t(allConstants.ADD_INDUSTRY)}
              value={values.industryValue}
              onChange={handleInputChange}
              onBlur={!match ? () => handleInpurConfirm('industryValue', 'industry') : null}
              onPressEnter={() => handleInpurConfirm('industryValue', 'industry')}
            />
          </Form.Item>}
        </Form>
      </Row>

      <Col>
        {(values.excludedIndustry.length > 0 || values.isExcludedIndustry) &&
          <FiltersCount
            classNames={'comp-excl-title'}
            title={`${t(allConstants.EXCLUDE_INDUSTRY)}:`}
            ExcludeField={values.excludedIndustry}
            clearExcludeFilters={match
              ? () => handleIndustryExcludeFilter()
              : () => UpdateActiveCandidateData({ mustNotHave: { current_industry: [] } }, candidatesFilters, api, { industry: values.industry, industry_exclude: [] }, matchingResults)}
          />}

        <Row >
          <TagGroup
            tags={values.excludedIndustry}
            isExlude={values.excludedIndustry && true}
            onDelete={handleDelete}
            activeTagsType="excludedIndustry" />
        </Row>

        {(values.isExcludedIndustry && values.isOpen) && <Row>
          <Input
            name='excludeIndustryValue'
            placeholder={t(allConstants.EXCLUDE_INDUSTRY)}
            value={values.excludeIndustryValue}
            onChange={handleInputChange}
            onBlur={!match ? () => handleInpurConfirm('excludeIndustryValue', 'excludedIndustry') : null}
            onPressEnter={() => handleInpurConfirm('excludeIndustryValue', 'excludedIndustry')}
            className='filtered-exclude-input'
          />
        </Row>}
      </Col>

      {!values.isExcludedIndustry && values.isOpen &&
        <AddMore
          title={t(allConstants.EXCLUDE_INDUSTRY)}
          onClick={() => setValues({ ...values, isExcludedIndustry: true })} />}

    </FiltersLayout>
  )
}

Company.propTypes = {
  contextData: PropTypes.object.isRequired,
  existedCompany: PropTypes.object,
  setExistedFilters: PropTypes.func,
  match: PropTypes.object,
  existedFilters: PropTypes.object,
  setSearchThroughAdvanceFilter: PropTypes.func,
  setSearchApiVal: PropTypes.func
}
