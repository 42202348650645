import { DownloadOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Card, Col, Row, Space, Spin } from 'antd'
import React from 'react'
import { ButtonsWrapper } from '../../components/theme-components'
import { useAuthState } from '../../contexts/AuthContext'
import PageHeaderLayoutWithHeader from '../../layouts/PageHeaderLayoutWithHeader'
import ComponentToPrint from './valuecalculator/Components/ComponentToPrint'
import ValueCalculator from './valuecalculator/ValueCalculator'
import { allConstants } from '../../constants/constants'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

export const ValueCalculatorPage = () => {
  const { user } = useAuthState()
  const [pdfTableData, setPdfTableData] = React.useState({})
  const [prospect, setProspect] = React.useState({
    name: '',
    company: ''
  })
  const { t } = useTranslation()
  const formulasVal = pdfTableData?.formulas && Object.values(pdfTableData?.formulas)
  const printCondition = !!(formulasVal?.every(val => val !== 0) && prospect.name && prospect.company)
  const date = dayjs(new Date()).format('YYMMDD')
  return (
    <PageHeaderLayoutWithHeader
    title={<Trans id={t(allConstants.TWG_CALCULATOR)}>Value Calculator</Trans>}
    extra={
      printCondition
        ? <PDFDownloadLink document={<ComponentToPrint pdfTableData={pdfTableData}/>} fileName={`TWG_ROI_${prospect?.company}_${date}`}>
          {({ loading }) => (!loading && (<ButtonsWrapper><DownloadOutlined />{t(allConstants.DOWNLOAD_PDF)}</ButtonsWrapper>))}
        </PDFDownloadLink>
        : (<ButtonsWrapper disabled={true}>
        <DownloadOutlined />{t(allConstants.DOWNLOAD_PDF)}
       </ButtonsWrapper>)
    }
  >
    <Row gutter={[8, 8]}>
    <Col span={24}>
      <Card title={''} bordered={false} id='print' >
        <Space>
          <Spin tip={`${t(allConstants.LOADING)}...`} spinning={!user} size='large'>
            <ValueCalculator prospect={prospect} setProspect={setProspect} setPdfTableData={setPdfTableData} pdfTableData={pdfTableData}/>
          </Spin>
        </Space>
      </Card>
    </Col>
  </Row>
  </PageHeaderLayoutWithHeader>
  )
}
