import { Checkbox } from 'antd'
import styled from 'styled-components'

export const TableWrapper = styled.div`

`
export const TitleDiv = styled.div`
    color: #00b7c0;
    text-decoration: underline;
    text-align: justify-all;
    
    &:hover{
        cursor:pointer;
    }
`

export const CustomCheckBox = styled(Checkbox)`
& .ant-checkbox-inner {
  border: 1px solid #d9d9d9;
  border-radius: 1px !important;
}
`