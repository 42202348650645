import { Button, Progress } from 'antd'
import { useThemeState } from '../contexts/WhiteLabelContext'
import styled from 'styled-components'
import React from 'react'
import { rgba } from 'polished'

export const ButtonsWrapper = styled(Button)`
  display:flex;
  justify-content:center;
  align-items:center;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1.5px ${({ theme }) => theme.primary_color || '#00b7c0'};
  background-color:#fff;
  & span {
  color: ${({ theme }) => theme.primary_color || '#00b7c0'} !important;
  }
`

export const ThemeContainer = styled.div`

  .ant-input:focus, .ant-input-focused {
    border-color: ${({ theme }) => theme.primary_color || 'none'} ;
    border-right-width: none !important;
    outline: none;
    box-shadow: none;
  }
  .ant-input:hover {
    border-color:  ${({ theme }) => theme.primary_color || '#00b7c0'} ;
  }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: ${({ theme }) => theme.primary_color || 'none'} ;
    border-right-width: none !important;
    outline: none;
    box-shadow: none;
  } 
  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover, .ant-menu-submenu-title:hover span *, .ant-menu-submenu-open  span * {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'} ;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: ${({ theme }) => rgba(theme.primary_color || '#00b7c0', 0.2)};
      color: ${({ theme }) => theme.primary_color || '#00b7c0'} ;
  }
  .ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
    /* height: 40px; */
    margin-top: 4px;
    margin-bottom: 4px;
    /* padding: 0 16px; */
    overflow: hidden;
    line-height: 28px;
    text-overflow: ellipsis;
  }
  .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 33px;
    line-height: 33px;
}
  .ant-input-affix-wrapper:hover {
    border-color:  ${({ theme }) => theme.primary_color || '#00b7c0'} ;
  }
  .ant-btn:hover:not(.ant-btn-primary), .ant-btn:focus:not(.ant-btn-primary) {
      color:  ${({ theme }) => theme.primary_color || '#00b7c0'} ;
      background: #fff;
      border-color:  ${({ theme }) => theme.primary_color || '#00b7c0'} ;
  }
  .ant-btn-primary:enabled:not(.ant-btn-dangerous) {
    background: ${({ theme }) => theme.primary_color || '#00b7c0'} ;
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'}       
  }
  .ant-btn-dashed:enabled {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'}
  }
  .ant-btn-link:enabled {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    border-bottom: 2px solid ${({ theme }) => theme.primary_color || '#00b7c0'};
    a {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    }
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover  {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    border-bottom: 2px solid ${({ theme }) => theme.primary_color || '#00b7c0'};
    a {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    }
  }
  .ant-menu-submenu-title:hover, .ant-menu-submenu:hover  {
    border-bottom: 2px solid ${({ theme }) => theme.primary_color || '#00b7c0'};
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    a {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    }
  }
  .ant-pagination-item-active {
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    a {
      color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${({ theme }) => theme.primary_color || '#00b7c0'} ;
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
}
.ant-radio-inner::after{
  background-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
}
  .ant-switch-checked {
    background-color: ${({ theme }) => theme.primary_color || '#00b7c0'}
  }
  
  .ant-page-header {
    background: linear-gradient(
      270deg,
      ${({ theme }) => theme.barColor?.[0] || '#76e1e6'},
      ${({ theme }) => theme.barColor?.[1] || '#00b7c0'}
    );
    border-radius: 5px;
    span {
      color: white
    }
  }
 
  
  & .bread-crumb {
    color: ${({ theme }) => theme.primary_color || '#00b7c0'};
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
}
`

export const TWGProgress = (props) => {
  const { barColor } = useThemeState()
  return (
    <Progress
      format={percent => `${percent}%`}
      type='dashboard'
      width={70}
      strokeColor={{ '0%': barColor?.[1], '100%': barColor?.[0] }}
      {...props}
    />
  )
}
