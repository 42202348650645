import { UserAddOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import { matchPath, useHistory, useLocation } from 'react-router'
import { useJobsState } from '../../../../jobs/context/JobsContext'
import DrawerUploadCandidates from '../../../DrawerUploadCandidates'
import { PageTitle } from '../style'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'

const ApplicantTableHeader = () => {
  const { selectedJob, page } = useJobsState()
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const matchUpload = matchPath(location.pathname, {
    path: '/score/:jobExtId/search/:jobPostExtId/applicants/upload',
    exact: true,
    strict: false
  })
  const renderDrawerUploadCandidate = () => <DrawerUploadCandidates />

  return (
    <>
  <PageTitle>
    <div className='title-container'>
      <span className='table-title' style={{ marginTop: '10px' }}>
      {page === false ? t(allConstants.VIEW_SUBMIT_RESUME) : t(allConstants.VIEW_INSTANT_RESULT)}
      </span>
    </div>
    <div>
      <Button
        type="primary"
        onClick={() => {
          window.analytics.track('add candidates', {
            job_id: selectedJob?.requestId,
            job_title: selectedJob?.title?.toLowerCase(),
            location: selectedJob?.location?.toLowerCase(),
            applicants: selectedJob?.applicantCount
          })
          const urlParts = location.pathname.split('/')
          urlParts.push('upload')
          history.push(urlParts.join('/'))
        }}
        disabled={matchUpload}
      >
      <UserAddOutlined /> {t(allConstants.UPLOAD_APPLICANT_RESUME)}
      </Button>
    </div>
  </PageTitle>
  {renderDrawerUploadCandidate()}

  </>
  )
}

export default ApplicantTableHeader