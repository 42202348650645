/* eslint-disable no-debugger */
import { Trans } from '@lingui/react'
import React, { useEffect } from 'react'
import { Route } from 'react-router'
import PageHeaderLayoutWithHeader from '../../layouts/PageHeaderLayoutWithHeader'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { allConstants } from '../../constants/constants'
import TransactionReport from './components/ReportPages/TransactionReport'
import UsageReport from './components/ReportPages/UsageReport'
import { defaultDate, reportTabs } from './utils'
import { useReportActions } from './context/ReportContext'
import PageSubHeader from '../../layouts/PageSubHeader'
import { CustomCard } from './style'
import ResumeReport from './components/ReportPages/ResumeReport'

export const Report = () => {
  const history = useHistory()
  const actions = useReportActions()
  const { t } = useTranslation()

  const changeTab = (key) => {
    const modifyKey = key.includes('resume-report') ? 'resume-report/ingest-report' : key
    history.push(`/reports/${modifyKey}`)
  }
  useEffect(() => {
    actions.getTransactionReport(defaultDate(30))
  }, [])
  return (
    <PageHeaderLayoutWithHeader
      title={
        <Trans id={t(allConstants.REPORTS)}>{t(allConstants.REPORTS)}</Trans>
      }
      onTabChange={(key) => history.push(`/reports/${key}`)}
      subHeaderTabs={reportTabs(t)}
    >
      <CustomCard bordered={false} >
        <PageSubHeader
        onTabChange={(key) => { changeTab(key) }}
      subHeaderTabs={reportTabs} />
      <Route
        path={['/reports', '/reports/transaction-report']}
        component={TransactionReport}
        exact
      />
      <Route path={'/reports/usage-report'} component={UsageReport} />

      </CustomCard>
      <Route path={['/reports/resume-report']} component={ResumeReport} />
    </PageHeaderLayoutWithHeader>
  )
}
