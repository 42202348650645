import n from 'normalize-data'

/**
 * Used at createJob, getJob, updateJob and closeJob
 * @param {*} job
 */
export const job = (job) => n.normalize(job, [
  ['applicantCount', 'applicantCount', (applicantCount) => applicantCount || 0]
], true)

/**
 * searchByJobDescription, searchByQueryRequest, searchByJobPosting,
 * searchByQueryRequestNoPosting, searchByQueryRequestNoJobNoPosting
 * @param {*} jobRequisition
 */
export const jobRequisition = jobRequisition => {
  return n.normalize(jobRequisition, [
    ['source', 'source', (source) => source.toLowerCase() === 'not set' ? '-' : source] // 'string',
  ], true)
}
