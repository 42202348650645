import axios from '../../axios-config'
/**
 * POST
 * /discover/api/v1/discoverjob
 */
export const createJob = payload => {
  return axios.post('/discover/api/v1/discoverjob', payload)
}

export const getDiscoverJobs = payload => {
  return axios.get('/discover/api/v1/discoverjob/me')
}

export const getDiscoverJob = payload => {
  return axios.get(`/discover/api/v1/discoverjob/${payload}`)
}

export const updateJob = payload => {
  return axios.put(`/discover/api/v1/discoverjob/${payload.jobKey}`, payload)
}

export const deleteJob = (payload) => {
  return axios.delete(`/discover/api/v1/discoverjob/${payload.jobKey}`, { data: payload })
}

export const duplicateJob = (payload) => {
  return axios.delete(`/discover/api/v1/discoverjob/${payload.jobKey}`, { data: payload })
}

export const discoverJobStats = (jobId) => {
  return axios.get(`discover/api/v1/preview/discoverjob/${jobId}`)
}

// search now post api
export const getCandidatesFromSearch = (payload) => {
  return axios.post('discover/api/v1/searchnow', payload)
}