import { useRequest } from 'ahooks'
// import { message } from 'antd'
import React from 'react'
import { useHistory, useParams } from 'react-router'
import { useAuthActions } from '../../../../contexts/AuthContext'
import AuthLayout from '../../../../layouts/AuthLayout'

const KeycloakSuccessPage = () => {
  const history = useHistory()
  const params = useParams()
  const actions = useAuthActions()

  const { loading } = useRequest(() => actions.getOidcToken(params.token), {
    onSuccess: () => {
      // message.success('You are logged in!')
      history.push('/score')
    }
  })

  return (
      <AuthLayout right={null}>
        {loading && <h1>Loading ...</h1>}
      </AuthLayout>
  )
}

export default KeycloakSuccessPage
