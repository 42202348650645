import React from 'react'
// import { Typography } from 'antd'
// Files
import HeaderLayout from '../../../../layouts/HeaderLayout'
import ZendeskCards from '../../Component/HomePage/ZendeskCards/index'
import ZendeskBanner from '../../Component/HomePage/ZendeskBanner/ZendeskBanner'
// import Footer from '../../Component//Footer/Footer'
// import { DownOutlined } from '@ant-design/icons'
// import Activities from '../../Component/HomePage/Activities/Activities'
// style
import './ZendeskHomePage.css'

// const { Text } = Typography

// const ViewMore = () => {
//   return (
//     <Row className='view-more' >
//       <Text> View more <DownOutlined /> </Text>
//     </Row>
//   )
// }

function ZendeskHomePage () {
  return (
    <HeaderLayout isZendesk={true} >
      <ZendeskBanner />

      <ZendeskCards />

      {/* <ViewMore /> */}

      {/* <Activities /> */}

      {/* <Footer /> */}
    </HeaderLayout>
  )
}

export default ZendeskHomePage
