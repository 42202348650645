import React from 'react'
import { ButtonsWrapper } from '../../../../../../components/theme-components'
import { Button } from 'antd'
import { allConstants } from '../../../../../../constants/constants'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { useHomeActions } from '../../../../../HomePage/context/HomeContext'

const FirstTimeModalFooter = ({ slideNumber, slider, setSlideNumber, setPopUpValue, totalSlides }) => {
  const actions = useHomeActions()
  const { t } = useTranslation()

  const handleReset = () => {
    setSlideNumber(1)
    setPopUpValue(false)
  }

  const handleNext = async () => {
    if (slideNumber === totalSlides) {
      handleReset()
      const response = await actions.updateFirstTimeLoginValue({ firstLogin: false })
      if (response) setPopUpValue(false)
    } else {
      slider.current.goTo(slideNumber + 1)
      setSlideNumber(slideNumber + 1)
    }
  }

  return (
    <div className={slideNumber ? 'slide-buttons' : 'first-slide-button'}>
      {!slideNumber
        ? (
        <Button
          className="popup-buttons"
          type="primary"
          key="continue"
          onClick={() => {
            slider.current.goTo(1)
            setSlideNumber(1)
          }}
        >
          {t(allConstants.CONTINUE)}
        </Button>
          )
        : (
        <>
          <ButtonsWrapper
            className="popup-buttons"
            key="previous"
            onClick={() => {
              slider.current.goTo(slideNumber - 1)
              setSlideNumber((prevSlideNumber) => prevSlideNumber - 1)
            }}
          >
            {t(allConstants.PREVIOUS)}
          </ButtonsWrapper>
          <Button
            className="popup-buttons"
            key="next"
            onClick={handleNext}
            type="primary"
          >
            {slideNumber === totalSlides ? t(allConstants.FINISH) : t(allConstants.NEXT)}
          </Button>
        </>
          )}
    </div>
  )
}

FirstTimeModalFooter.propTypes = {
  slideNumber: PropTypes.number,
  slider: PropTypes.any,
  setSlideNumber: PropTypes.func,
  setPopUpValue: PropTypes.any,
  totalSlides: PropTypes.number
}

export default FirstTimeModalFooter
