/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout, message, Row, Typography, Button, notification } from 'antd'
import { EditOutlined, ExportOutlined, SaveOutlined } from '@ant-design/icons'
import { ReactComponent as ExportIcon } from '../../../assets/export.svg'
import Filters from './Filters'
import FilteredData from './FilteredData'
import HeaderLayout from '../../../layouts/HeaderLayout'
import ModalLayout from '../components/ModalLayout'
import { QUERY_ACTIONS } from '../components/Constants'
import { useAuthState } from '../../../contexts/AuthContext'
import {
  useQueryActions,
  useQuerysState,
  useQuerysDispatch,
  setIsLoading,
  setFields,
  setSavedSearchVal
} from '../CandidateContext/SearchQueryContext'
// Styles
import './searchedCandidates.css'
import classNames from 'classnames'
import { ButtonsWrapper } from '../../../components/theme-components'
import { matchPath } from 'react-router'
import SimpleSearchFilter from './SimpleSearchFilter/SimpleSearchFilter'
import getSearchFilters from './Filters/helpers/getSearchFilters'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../constants/constants'
import { fileDownloadDate } from '../../../helper'
import { useParams } from 'react-router'

const { Title } = Typography
export default function FilteredCandidates ({ match }) {
  // State
  const [existedFilters, setExistedFilters] = React.useState(undefined)
  const [isOpen, setIsOpen] = React.useState(false)
  const [updateModal, setUpdateModal] = React.useState(false)
  // const [isSave, setIsSave] = React.useState(false)
  const [advancedSearchVal, setAdvanceSearchVal] = React.useState(false)
  const { jobkey } = match.params
 const {t}=useTranslation()
  // Context
  const actions = useQueryActions()
  const dispatch = useQuerysDispatch()
  const {searchApiObj} = useQuerysState()
  const { user } =  useAuthState()
  const { title} = useParams()
  const { candidatesFilters, SelectedCandidates, isLoading, searchedCandidates } = useQuerysState()
  const { name: jobTitle } = candidatesFilters || {name:title}
  // Refs
  const DownloadRef = React.useRef()

  const simpleSearchPath = matchPath(location.pathname, {
    path: '/scoreui/simplesearch/:title/:skills/:location',
    exact: true,
    strict: false
  })
  const savedSearchPath = matchPath(location.pathname, {
    path: '/scoreui/candidates/:jobkey',
    exact: true,
    strict: false
  })
  const getActiveSearchQuery = async () => {
    try {
      await actions.jobPreview(jobkey)
      await actions.getSearchedCandidates(jobkey)
    } catch (e) {
      console.log(e)
    }
  }

  const HeaderTitle = (isOpen, setIsOpen, candidatesFilters) => {
    const updateSearch = () => {
      // setIsOpen(!isOpen)
      setUpdateModal(!updateModal)
    }
    const handleOk = (name, desc) => {
      const reqHeader = candidatesFilters
      if (reqHeader) {
        const annotations = JSON.parse(reqHeader.searchJson)
        reqHeader.annotations = annotations
        reqHeader.description = desc || 'Description'
        reqHeader.name = name
        actions.updateJob(reqHeader)
      }
      setIsOpen(false)
      setUpdateModal(!updateModal)
    }
    return (
      <Row>
        {candidatesFilters?.name
          ? <Title level={4} className='filteredDataTitle' >
            {candidatesFilters?.name}
            <EditOutlined onClick={updateSearch}style={{marginLeft:'6px'}}/>
          </Title>
          : ''}
        {updateModal &&
          <ModalLayout
            isOpen={true}
            handleOk={handleOk}
            setIsopen={setUpdateModal}
            isDescription={false}
            isLoading={isLoading}
            modalBody={QUERY_ACTIONS.EDIT}
            updateSearch={candidatesFilters}
          />
        }
      </Row>
    )
  }


  

  const handleExportCandidates = async (e) => {
    dispatch(setIsLoading(true))
    let exportLoader
    try {
      const payload = {
        resultIds: SelectedCandidates,
        resultSetId: searchedCandidates?.resultSetId
      }
      exportLoader = message.loading(`${t(allConstants.CREATING_CSV)}..`, 0)
      setTimeout(exportLoader, 1000)
      const exportCandidates = await actions.exportCandidates(payload) // Export Api
      const blob = new Blob([exportCandidates.data], { type: 'octet/stream' })
      const url = window.URL.createObjectURL(blob)
      DownloadRef.current.href = url
      DownloadRef.current.download = `saved-search-exports-for-${jobTitle.toLowerCase().replace(/\s/g, '_')}-${fileDownloadDate()}.csv`
      await DownloadRef.current.click()
      const exportedLoader = message.success(`${SelectedCandidates.length} ${t(allConstants.ROWS_DOWNLOADED)}`, 0)
      setTimeout(exportedLoader, 2000)
      dispatch(setIsLoading(false))
      dispatch(setFields({ SelectedCandidates: [], setIsLoading: false }))
      window.analytics.track('exported selected', {
        search_id: jobkey
      })
    } catch (e) {
      notification.error({ placement: 'topLeft', message: t(allConstants.CREDIT_NOT_AVAILABLE) })
    } finally {
      dispatch(setIsLoading(false))
    }
  }

  const handleOk = () => {
    setIsOpen(false)
    handleExportCandidates()
  }
  const exportCandidate = () => {
    const exportCandidateModal = () => {
      if (SelectedCandidates.length < 1 || isLoading) return setTimeout(message.warning(`${t(allConstants.SELECT_ROW_TO_EXPORT)}...`, 0), 1000)
      else if(user?.showConsumptionPopups) {
        setIsOpen(true)
      }
      else {
        handleExportCandidates()
      }

    }

    return (
      <>
        <a href="#" ref={DownloadRef} />
        <Button
          type='primary' 
          id='export-selected' 
          icon={<ExportOutlined/>}
          onClick={exportCandidateModal}
        >
          {t(allConstants.EXPORT_SELECTED)}
        </Button>
      </>
    )
  }

 
  const FilteredDataStats = () => {
    return (
      <Row className={classNames(isLoading ? 'disabled-stats candidate-stats' : 'candidate-stats')} >
        <Row>
          <span>Very Qualified</span>
          <span> {searchedCandidates?.group1} </span>
        </Row>
        <span className='stat-divider' />
        <Row>
          <span> Qualified</span>
          <span> {searchedCandidates?.group2} </span>
        </Row>
        <span className='stat-divider' />
        <Row>
          <span> Semi Qualified</span>
          <span> {searchedCandidates?.group3} </span>
        </Row>
        <span className='stat-divider' />
          <Row>
          <span> Least Qualified</span>
          <span> {searchedCandidates?.group4} </span>
        </Row>
      </Row>
    )
  }
  React.useEffect(() => {
    setAdvanceSearchVal(false)
    if(!simpleSearchPath){
       getActiveSearchQuery()
      }
  }, [])

  return (
    <HeaderLayout title={simpleSearchPath 
      ? 
        !advancedSearchVal 
        ? t(allConstants.SEARCH_RESULTS)
        : <div className='saving-search-container'>
            <span>{t(allConstants.SEARCH_RESULTS)}</span>
            <div className={isLoading ? 'disable-save-search' :'saving-search'}  onClick={()=>dispatch(setSavedSearchVal(true))}>
              <SaveOutlined style={{marginRight:'8px'}}/>
              <span>{t(allConstants.SAVE_THIS_SEARCH)}</span>
            </div>
          </div>
      : HeaderTitle(isOpen, setIsOpen, candidatesFilters)} 
      // extra={exportCandidate()} 
      isFooterHide={true} 
    >
      <Layout id='searchedCandidatesLayout' >
        {savedSearchPath && <Filters />}
        {(simpleSearchPath && !advancedSearchVal ) 
          ? <SimpleSearchFilter setAdvanceSearchVal={setAdvanceSearchVal}/> 
          :  !savedSearchPath && <Filters/>
        }
        <FilteredData extra={exportCandidate()} advancedSearchVal={advancedSearchVal} />
{/*        <FilteredDataStats  /> */}
      </Layout>

      {isOpen &&
        <ModalLayout
          isOpen={true}
          handleOk={handleOk}
          setIsopen={setIsOpen}
          isLoading={isLoading}
          modalBody={QUERY_ACTIONS.EXPORT_CANDIDATES}
        />}
    </HeaderLayout >
  )
}

FilteredCandidates.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobkey: PropTypes.string
    })
  })
}
