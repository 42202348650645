/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */

import React from 'react'
import { PropTypes } from 'prop-types'
import { TableContainer, TableContainerWrapper } from './style'
import { Input, InputNumber } from 'antd'
import { DollarOutlined, PercentageOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

const ValueCalculatorTable = React.forwardRef((props, ref) => {
  const {
    prospect,
    setProspect,
    userInputValue,
    value,
    formulas,
    setUserInput
  } = props

  const {
    numberOfRecruitingPlatforms,
    MonthlyCostOfRecruitngPlatform,
    logInTimeMinutes,
    numberOfJobs,
    lookTimeMinutesPerJob,
    numberOfCandidatesPerJob,
    qualityTimeMinutesPerCandidate,
    hoursPerWeek,
    percentageTimeSpentSourcing,
    jobPostSimilarityPercentage,
    twgMonthlyCostOfRecruitngPlatform,
    newHireCost,
    timeSpentRankQualified
  } = userInputValue
  const {
    twgNumberOfRecruitingPlatforms,
    twgLookTimeMinutesPerJob,
    twgQualityTimeMinutesPerCandidate,
    twgHoursPerWeek,
    twgParnterNetworkValues,
    twgCandidateJobLookTime
  } = value

  const {
    twgTotalTimeNeededHoursPerWeek,
    userTotalTimeNeededHoursPerWeek,
    twgHoursDifference,
    userHoursDifference,
    twgTotalNeededRecruiters
  } = formulas
  const [percentValue, setPercentValue] = React.useState(percentageTimeSpentSourcing)
  const [jobPostSimilarity, setJobPostSimilarity] = React.useState(jobPostSimilarityPercentage)
  const { t } = useTranslation()

  const handleNumberChange = (val, name) => {
    if (val?.target?.name === 'percentageTimeSpentSourcing' || val?.target?.name === 'jobPostSimilarityPercentage' || val?.target?.name === 'MonthlyCostOfRecruitngPlatform' || val?.target?.name === 'twgMonthlyCostOfRecruitngPlatform') {
      if (val?.target?.name === 'percentageTimeSpentSourcing') {
        const { name, value } = val.target
        const integer = value ? parseInt(value) : ''
        setPercentValue(integer)
        const valueInPercentage = integer !== '' ? integer / 100 : integer
        setUserInput({
          ...userInputValue, [name]: valueInPercentage
        })
      } else if (val?.target?.name === 'jobPostSimilarityPercentage') {
        const { name, value } = val.target
        const integer = value ? parseInt(value) : ''
        setJobPostSimilarity(integer)
        const valueInPercentage = integer !== '' ? integer / 100 : integer
        setUserInput({
          ...userInputValue, [name]: valueInPercentage
        })
      } else {
        const { name, value } = val.target
        const integer = value ? parseInt(value) : ''
        setUserInput({
          ...userInputValue, [name]: integer
        })
      }
    } else {
      setUserInput({
        ...userInputValue, [name]: val
      })
    }
  }

  const handleProspectChange = (e) => {
    const { name, value } = e.target
    setProspect({ ...prospect, [name]: value })
  }

  return (
        <TableContainerWrapper ref={ref} >
            <div>
                <div style={{ textAlign: 'left' }}>
                    <div style={{
                      color: 'rgba(0,0,0,0.85)',
                      fontSize: '15px',
                      fontWeight: 'bold'
                    }}
                    >

                        {t(allConstants.PREPARED_FOR)}
                        <Input
                            name='name'
                            className='prospect-name-company'
                            onChange={(e) => handleProspectChange(e)}
                            placeholder={t(allConstants.prospect_name)}
                        />
                        , {t(allConstants.FROM)}
                        <Input
                            name='company'
                            className='prospect-name-company'
                            onChange={(e) => handleProspectChange(e)}
                            placeholder={t(allConstants.PROSPECT_EMPLOYER)}
                        />
                    </div>
                </div>
                <TableContainer>
                    <thead>
                        <tr className='headings'>
                            <th>{t(allConstants.PLATFORM_USAGE)}</th>
                            <th>{t(allConstants.CURRENT_TOOL)}</th>
                            <th>{t(allConstants.AI4J_VALUES_CALCULATE)}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t(allConstants.RECRUITING_PLATFORM_COUNT)}</td>
                            <td>
                                <InputNumber min={0} max={10000} value={numberOfRecruitingPlatforms} onChange={(val) => handleNumberChange(val, 'numberOfRecruitingPlatforms')} />
                            </td>
                            <td style={{ backgroundColor: 'rgba(240, 240, 240, 0.5)' }}>{twgNumberOfRecruitingPlatforms} {t(allConstants.NETWORKS)}</td>
                        </tr>
                        <tr>
                            <td>{t(allConstants.MONTHLY_RECRUITING_COST)}</td>
                            <td>
                                <Input type='number' name='MonthlyCostOfRecruitngPlatform' value={MonthlyCostOfRecruitngPlatform}
                                    onChange={(e) => { handleNumberChange(e) }} addonAfter={<DollarOutlined />} />
                            </td>
                            <td>
                                <Input style={{ padding: '0px 20px' }} type='number' name='twgMonthlyCostOfRecruitngPlatform' value={twgMonthlyCostOfRecruitngPlatform}
                                    onChange={(e) => { handleNumberChange(e) }} addonAfter={<DollarOutlined />} />
                            </td>
                        </tr>
                        <tr>
                            <td>{t(allConstants.LOG_TIME)}</td>
                            <td>
                                <InputNumber min={0} max={1000000000} value={logInTimeMinutes} onChange={(val) => handleNumberChange(val, 'logInTimeMinutes')} />
                            </td>
                            <td>{logInTimeMinutes}</td>
                        </tr>
                        <tr>
                            <td>{t(allConstants.NO_OF_JOBS)}</td>
                            <td>
                                <InputNumber min={0} max={100000} value={numberOfJobs} onChange={(val) => handleNumberChange(val, 'numberOfJobs')} />
                            </td>
                            <td>{numberOfJobs}</td>
                        </tr>
                        <tr>
                            <td>{t('Job Look Up Time (Minutes/Job)')}</td>
                            <td>
                                <InputNumber min={0} max={10000} value={lookTimeMinutesPerJob} onChange={(val) => handleNumberChange(val, 'lookTimeMinutesPerJob')} />
                            </td>
                            <td>{twgLookTimeMinutesPerJob}</td>
                        </tr>
                        <tr>
                            <td>{t(allConstants.NUMBER_OF_CANDIDATES)}</td>
                            <td>
                                <InputNumber min={0} max={100000} value={numberOfCandidatesPerJob} onChange={(val) => handleNumberChange(val, 'numberOfCandidatesPerJob')} />
                            </td>
                            <td>{numberOfCandidatesPerJob}</td>
                        </tr>
                        <tr>
                            <td>{t('Review Resume Time (Minutes/Candidate)')}</td>
                            <td>
                                <InputNumber min={0} max={1000000} value={qualityTimeMinutesPerCandidate} onChange={(val) => handleNumberChange(val, 'qualityTimeMinutesPerCandidate')} />
                            </td>
                            <td>{twgQualityTimeMinutesPerCandidate}</td>
                        </tr>
                        <tr>

                            <td>{t('Time Spent to Stack Rank Qualified Resumes (Minutes)')}</td>

                            <td>
                                <InputNumber min={0} max={1000000} value={timeSpentRankQualified} onChange={(val) => handleNumberChange(val, 'timeSpentRankQualified')} />
                            </td>
                            <td>0.1</td>
                        </tr>
                        <tr>
                            <td className='data-value'>{t('Note about resume ranking')}</td>
                            <td style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                Often subjective
                            </td>

                            <td style={{ fontWeight: 'bold' }}>Algorithmically based</td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td className='data-value'>{t('Job Post Similarity (%)')}</td>*/}
                        {/*    <td>*/}
                        {/*        <Input type='number' name='jobPostSimilarityPercentage' value={jobPostSimilarity}*/}
                        {/*               onChange={(e) => { handleNumberChange(e) }} addonAfter={<PercentageOutlined />} />*/}
                        {/*    </td>*/}

                        {/*    <td>{Number((jobPostSimilarityPercentage * 100)?.toFixed(2))}%</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td className='data-value'>{t('Time spent to qualify 1 resume to another Job Posts (Minutes)')}</td>*/}
                        {/*    <td className='text-center'>*/}
                        {/*        {qualityTimeMinutesPerCandidate}*/}
                        {/*    </td>*/}

                        {/*    <td>0.1</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}

                        {/*    <td className='data-value'>{t('Time to qualify all resumes to all other Job Posts (Minutes)')}</td>*/}
                        {/*    <td className='text-center' >*/}
                        {/*        {candidateJobLookTime?.toFixed(2)}*/}
                        {/*    </td>*/}

                        {/*    <td>{twgCandidateJobLookTime}</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}

                        {/*    <td className='data-value'>{t('Stack Rank Time to other Job Posts (Minutes)')}</td>*/}
                        {/*    <td className='text-center' >*/}
                        {/*        {(jobPostSimilarityPercentage * numberOfJobs * timeSpentRankQualified * numberOfCandidatesPerJob).toFixed(2)}*/}
                        {/*    </td>*/}

                        {/*    <td>0.1</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}

                        {/*    <td className='data-value'>{t('Stack Rank Time across all Job Posts (Minutes)')}</td>*/}
                        {/*    <td className='text-center'>*/}
                        {/*        {parseInt((jobPostSimilarityPercentage * numberOfJobs * timeSpentRankQualified * numberOfCandidatesPerJob).toFixed(2)) + parseInt(timeSpentRankQualified)}*/}
                        {/*    </td>*/}

                        {/*    <td>0.1</td>*/}
                        {/*</tr>*/}
                        <tr className='highlight'>
                            <td>{t('Total Time Needed To complete Sourcing (Hours/Week)')}</td>
                            <td className='data-value second-column-value'><span className='user-value'>{Number(userTotalTimeNeededHoursPerWeek?.toFixed(2))}</span></td>
                            <td>{Number(twgTotalTimeNeededHoursPerWeek?.toFixed(2))}</td>
                        </tr>
                        <tr>
                            <td>Normal Work Hours/Week</td>
                            <td>
                                <InputNumber min={0} max={100000} value={hoursPerWeek} onChange={(val) => handleNumberChange(val, 'hoursPerWeek')} />
                            </td>
                            <td>{hoursPerWeek}</td>
                        </tr>
                        <tr>
                            <td>Percent Time of Work week Spent Sourcing</td>
                            <td>
                                <Input type='number' name='percentageTimeSpentSourcing' value={percentValue}
                                    onChange={(e) => { handleNumberChange(e) }} addonAfter={<PercentageOutlined />} />
                            </td>
                            <td>{Number((percentageTimeSpentSourcing * 100)?.toFixed(2))}%</td>
                        </tr>
                        <tr>
                            <td className='data-value'>Total Time Currently Spent Sourcing (Hours)</td>
                            <td className='data-value second-column-value'>{(percentValue / 100 * hoursPerWeek).toFixed(2)}</td>
                            <td>{(percentValue / 100 * hoursPerWeek).toFixed(2)}</td>
                        </tr>
                        <tr className={'dimmed'}>
                            <td/>
                            <td/>
                            <td/>
                        </tr>
                        <tr>
                            <td >Workload Delta (+/- Hours)  </td>
                            <td className='data-value second-column-value'><span className='user-value'>{Number(userHoursDifference?.toFixed(2))}</span></td>
                            <td >{Number(twgHoursDifference?.toFixed(2))}</td>
                        </tr>
                        <tr>
                            <td className='data-value'>Weekly Workload Assessment (Surplus/Deficit) </td>
                            <td className='field-bold data-value second-column-value' style={{ textAlign: 'center' }} >
                                <span className='user-value' >
                                    {userHoursDifference ? (userHoursDifference > 0 ? t(allConstants.SURPLUS) : t(allConstants.DEFICIT)) : ''}
                                </span>
                            </td>
                            <td className='field-bold'>
                                {twgHoursDifference ? (twgHoursDifference > 0 ? t(allConstants.SURPLUS) : t(allConstants.DEFICIT)) : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className='data-value'>Total Time Spent Sourcing using Ai4J (Hours)</td>
                            <td className='data-value second-column-value'>{Number(userTotalTimeNeededHoursPerWeek?.toFixed(2))}</td>
                            <td>{Number(twgTotalTimeNeededHoursPerWeek?.toFixed(2))}</td>
                        </tr>
                        <tr>
                            <td className='data-value'>Weekly Time Savings using Ai4J (Hours)</td>
                            <td className='dimmed'></td>
                            <td>{(Number(userTotalTimeNeededHoursPerWeek?.toFixed(2)) - Number(twgTotalTimeNeededHoursPerWeek?.toFixed(2))).toFixed(2)}</td>
                        </tr>
                        <tr className={'highlight'}>
                            <td className='field-bold'>Summary</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Average Annual Salary for a Recruiter ($K/Yr)</td>
                            <td>
                                <InputNumber min={0} max={100000000} value={newHireCost} onChange={(val) => handleNumberChange(val, 'newHireCost')} />
                            </td>
                            <td>{newHireCost}</td>
                        </tr>
                        <tr>
                            <td>{t('Weekly Labor Cost Savings to Source Candidates using Ai4J')}</td>
                            {/*<td className='data-value second-column-value'><span className='user-value'>${Number(userCostToSourceCandidatesWeekly?.toFixed(2)).toLocaleString()}</span></td>*/}
                            <td className={'dimmed'}></td>
                            {/*<td>${(Number(twgCostToSourceCandidatesWeekly?.toFixed(2)).toLocaleString() * (Number(userTotalTimeNeededHoursPerWeek?.toFixed(2)) - Number(twgTotalTimeNeededHoursPerWeek?.toFixed(2)))).toFixed(2)}</td>*/}
                        <td>${((newHireCost / 2) * (Number(userTotalTimeNeededHoursPerWeek) - Number(twgTotalTimeNeededHoursPerWeek))).toFixed(0)}</td>
                        </tr>
                        <tr>
                            <td className='data-value'>Needed recruiters to complete workload</td>
                            <td className='data-value second-column-value'>{!isNaN(twgTotalNeededRecruiters) ? twgTotalNeededRecruiters : 0}</td>
                            <td>{!isNaN(twgTotalNeededRecruiters) ? twgTotalNeededRecruiters : 0}</td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <th className='data-value'>{t(allConstants.SOURCE_VALUE_CAPTURED)}</th>*/}
                        {/*    <th></th>*/}
                        {/*    <th></th>*/}
                        {/*</tr>*/}
                        <tr>
                            <td className='data-value'>{t(allConstants.WEEKLY_VALUE_GAINED)}</td>
                            <td className='dimmed'></td>
                            <td >${((newHireCost / 2) * (Number(userTotalTimeNeededHoursPerWeek) - Number(twgTotalTimeNeededHoursPerWeek))).toFixed(0)}</td>
                        </tr>
                        <tr>
                            <td className='data-value'>{t(allConstants.MONTHLY_VALUE_GAINED)}</td>
                            <td className='dimmed'></td>
                            <td>${(4.3333 * ((newHireCost / 2) * (Number(userTotalTimeNeededHoursPerWeek) - Number(twgTotalTimeNeededHoursPerWeek)))).toFixed(0)}</td>
                        </tr>
                        <tr>
                            <td className='data-value'>{t(allConstants.ANNUAL_VALUE_GAINED)}</td>
                            <td className='dimmed'></td>
                            <td>${(52 * ((newHireCost / 2) * (Number(userTotalTimeNeededHoursPerWeek) - Number(twgTotalTimeNeededHoursPerWeek)))).toFixed(0)}</td>
                        </tr>
                        {/*<tr className='highlight'>*/}
                        {/*    <td className='field-bold'>{t('Projected Contract Length  (Years) ')}</td>*/}
                        {/*    <td>*/}
                        {/*        <InputNumber*/}
                        {/*            name='startYear'*/}
                        {/*            className='prospect-name-company'*/}
                        {/*            onChange={(e) => handleNumberChange(e, 'startYear')}*/}
                        {/*            placeholder={'Starting Year'}*/}
                        {/*            min={0}*/}
                        {/*            max={5}*/}
                        {/*            value={userInputValue.startYear}*/}
                        {/*        />*/}
                        {/*    </td>*/}
                        {/*    <td>*/}
                        {/*        {userInputValue.startYear}*/}
                        {/*    </td>*/}
                        {/*</tr>*/}
                        {/*<tr className='highlight'>*/}
                        {/*    <td className='field-bold data-value'>{t(allConstants.ESTIMATED_GAIN)}</td>*/}
                        {/*    <td></td>*/}
                        {/*    <td className='field-bold'>${Number(estimateValueGain?.toFixed(2)).toLocaleString()}</td>*/}
                        {/*</tr>*/}
                        {/*<tr className='headings'>*/}
                        {/*    <th className='data-value' style={{ textAlign: 'left', paddingLeft: '25px' }}>{*/}
                        {/*        t('Stack Rank Time across all Job Posts (Minutes)')}</th>*/}
                        {/*    <th style={{ padding: '0px 40px', textAlign: 'center' }}>{t(allConstants.CURRENT_TOOL)}</th>*/}
                        {/*    <th style={{ padding: '0px 40px', textAlign: 'center' }}>AI4J</th>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td className='data-value'>{t('Monthly Platform Cost Comparison')}</td>*/}
                        {/*    <td style={{ textAlign: 'center' }}>${monthlyPlatformCosts?.toLocaleString()}</td>*/}
                        {/*    <td>${twgMonthlyCostOfRecruitngPlatform?.toLocaleString()}</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                        {/*    <td className='data-value'>{t('Annual Platform Cost Comparison')}</td>*/}
                        {/*    <td style={{ textAlign: 'center' }}>${annualPlatformCosts?.toLocaleString()}</td>*/}
                        {/*    <td>${twgAnnualPlatformCosts?.toLocaleString()}</td>*/}
                        {/*</tr>*/}
                        {/*<tr className='highlight top'>*/}
                        {/*    <td className='data-value'>{t('TWG Value Captured Annually')}</td>*/}
                        {/*    <td className='dimmed'></td>*/}
                        {/*    <td>${twgPortalValueCapturedAnnually?.toLocaleString()}</td>*/}
                        {/*</tr>*/}
                        {/*<tr className='highlight'>*/}
                        {/*    <td className='data-value' >{t('Partner Network Value ($10/Partner)')}</td>*/}
                        {/*    <td className='dimmed'></td>*/}
                        {/*    <td>${twgParnterNetworkValues?.toLocaleString()}</td>*/}
                        {/*</tr>*/}
                        {/*<tr className='highlight equivalent-network-value bottom'>*/}
                        {/*    <td className='field-bold data-value'>{t(allConstants.EQUIVALENT_NETWORK)}</td>*/}
                        {/*    <td className='dimmed'></td>*/}
                        {/*    <td className='field-bold'>${equivalentNetworkValue?.toLocaleString()}</td>*/}
                        {/*</tr>*/}
                    </tbody>

                </TableContainer>
                <div style={{ fontWeight: 'bold', textAlign: 'center', margin: '8px', fontSize: 'large' }}>Spend your time looking at the right ones and not for the right ones!</div>
            </div>
            {/* <div className='platform-value-captured-container'> */}
            {/* <TableContainer> */}
            {/* <thead>
                    <tr className='headings'>
                        <th style={{ textAlign: 'left', paddingLeft: '25px' }}>Platform Value Captured</th>
                        <th>{'Current Tool(s)'}</th>
                        <th style={{ padding: '0px 40px' }}>AI4J</th>
                    </tr>
                </thead> */}
            {/* <tbody className='platform-value-captured-body'> */}
            {/* <tr>
                        <th>Platform Value Captured</th>
                        <th></th>
                        <th></th>
                    </tr> */}
            {/* <tr>
                        <td>Monthly Platform Cost</td>
                        <td style={{ textAlign: 'center' }}>${monthlyPlatformCosts?.toLocaleString()}</td>
                        <td>${twgMonthlyCostOfRecruitngPlatform?.toLocaleString()}</td>
                    </tr>
                    <tr>
                        <td>Annual Platform Cost</td>
                        <td style={{ textAlign: 'center' }}>${annualPlatformCosts?.toLocaleString()}</td>
                        <td>${twgAnnualPlatformCosts?.toLocaleString()}</td>
                    </tr>
                    <tr className='highlight top'>
                        <td>Portal Value Captured Annually</td>
                        <td className='dimmed'></td>
                        <td>${twgPortalValueCapturedAnnually?.toLocaleString()}</td>
                    </tr>
                    <tr className='highlight'>
                        <td>{'Partner Network Value ($10/Partner)'}</td>
                        <td className='dimmed'></td>
                        <td>${twgParnterNetworkValues?.toLocaleString()}</td>
                    </tr>
                    <tr className='highlight equivalent-network-value bottom'>
                        <td className='field-bold'>Equivalent Network value</td>
                        <td className='dimmed'></td>
                        <td className='field-bold'>${equivalentNetworkValue?.toLocaleString()}</td>
                    </tr> */}
            {/* </tbody> */}
            {/* </TableContainer> */}
            {/* // </div> */}
        </TableContainerWrapper>
  )
})
ValueCalculatorTable.displayName = 'ValueCalculatorTable'
ValueCalculatorTable.propTypes = {
  prospect: PropTypes.object,
  setProspect: PropTypes.func,
  userInputValue: PropTypes.object,
  value: PropTypes.object,
  formulas: PropTypes.object,
  setUserInput: PropTypes.func
}
export default ValueCalculatorTable
