import React from 'react'
import { Row, InputNumber, Col, Typography, message } from 'antd'

// import { useDebounce } from 'ahooks'
import PropTypes from 'prop-types'
// Local Files
import FiltersLayout from '../component/FiltersLayout'
// Api helper
import { UpdateActiveCandidateData } from '../../../helpers/QueryHelpers'
import StringFilter from '../helpers/filterString'
// Style Sheet
import './sections.css'
import { setIsOpen } from '../helpers/actionHelpers'
import TagGroup from '../../../components/TagGroup'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'
// import { useDebounce } from 'ahooks'

const { Title } = Typography

export default function Education ({
  contextData, existedExperience, setExistedFilters,
  match, existedFilters, setSearchThroughAdvanceFilter,
  setSearchApiVal
}) {
  const [values, setValues] = React.useState({
    CountMinInputValue: 1,
    CountMaxInputValue: 3,
    ExperienceMinInputValue: 1,
    ExperienceMaxInputValue: 3,
    isOpen: false
  })
  const { t } = useTranslation()

  const [advancedSearchFilter, setAdvancedSearchFilter] = React.useState(false)

  const onChange = (type, value) => {
    setValues({
      ...values,
      [type]: value || 0
    })
  }

  const { candidatesFilters, api, matchingResults } = contextData

  const handleConfirm = React.useCallback((activeInput) => {
    setAdvancedSearchFilter(true)
    const { ExperienceMinInputValue, ExperienceMaxInputValue, CountMinInputValue, CountMaxInputValue } = values
    if (parseInt(ExperienceMaxInputValue) < 1 || parseInt(CountMaxInputValue) < 1) return updateJobApi(activeInput)
    if (parseInt(ExperienceMinInputValue) > parseInt(ExperienceMaxInputValue)) {
      return message.error(t(allConstants.MIN_YEAR_EXPERIENCE))
    } else if (parseInt(ExperienceMaxInputValue) > 100) {
      return message.error(t(allConstants.MAX_YEAR_EXPERIENCE))
    } else if (parseInt(CountMaxInputValue) > 100) {
      return message.error(t(allConstants.MAX_JOB_COUNT))
    } else if (parseInt(CountMinInputValue) > parseInt(CountMaxInputValue)) {
      return message.error(t(allConstants.MIN_JOB_COUNT))
    }
    updateJobApi(activeInput)
  }, [values])

  React.useEffect(() => {
    if (!existedExperience?.count?.mustHave) {
      setValues({
        ...values,
        CountMinInputValue: 1,
        CountMaxInputValue: 3,
        ExperienceMinInputValue: 1,
        ExperienceMaxInputValue: 3
      })
    } else {
      const cnt = StringFilter(existedExperience?.count?.mustHave)
      const yrs = StringFilter(existedExperience?.years?.mustHave)
      setValues({
        ...values,
        CountMinInputValue: cnt?.GTE,
        CountMaxInputValue: cnt?.LTE,
        ExperienceMinInputValue: yrs?.GTE,
        ExperienceMaxInputValue: yrs?.LTE
      })
    }
  }, [existedExperience])

  const updateJobApi = React.useCallback((activeInput, tags) => {
    const maxExperience = values.ExperienceMaxInputValue === 0 ? 100 : values.ExperienceMaxInputValue
    const maxCount = values.CountMaxInputValue === 0 ? 100 : values.CountMaxInputValue
    UpdateActiveCandidateData({
      mustHave: {
        career_length: `GTE ${values.ExperienceMinInputValue}:LTE ${maxExperience}`,
        career_count: `GTE ${values.CountMinInputValue}:LTE ${maxCount}`
      }
    },
    candidatesFilters,
    api,
    activeInput === 'experience'
      ? {
          experience: `${values.ExperienceMinInputValue}-${maxExperience}`
        }
      : {
          job_count: `${values.CountMinInputValue}-${maxCount}`

        },
    matchingResults,
    match

    )
  }, [values])

  React.useEffect(() => {
    if (match) {
      setAdvancedSearchFilter(true)
      setSearchThroughAdvanceFilter(true)
    }
  }, [])

  React.useEffect(() => {
    if (match && advancedSearchFilter) {
      setExistedFilters({
        ...existedFilters,
        experience: {
          ...existedFilters.experience,
          count: {
            mustHave: `GTE ${values?.CountMinInputValue}:LTE ${values?.CountMaxInputValue}` || 'GTE 0:LTE 100',
            mustNotHave: []
          },
          years: {
            mustHave: `GTE ${values?.ExperienceMinInputValue}:LTE ${values?.ExperienceMaxInputValue}` || 'GTE 0:LTE 100',
            mustNotHave: []
          }
        }
      })
      setAdvancedSearchFilter(false)
      setSearchApiVal(true)
    }
  }, [advancedSearchFilter, values])

  const handleKeyDown = (e) => {
    if (e.key === '.') return e.preventDefault()
  }

  return (
    <FiltersLayout
      title={t(allConstants.EXPERIENCE)}
      setIsOpen={() => setIsOpen(setValues, values)}
      isOpen={values.isOpen}
    >
     <>
        {/* Years of Experience */}
        <Row className='exp-typo-row' >
          <Title level={5} >{t(allConstants.EXPERIENCE_COUNT)}</Title>
        </Row>
        {!values.isOpen
          ? <Row>
            {/* <Col span={3} style={{ color: '#343434' }} >Min</Col> */}
            <Col className={'exp-tags'}>
               <TagGroup
               tags={[`${values.ExperienceMinInputValue} - ${values.ExperienceMaxInputValue}`]}
               HideCloseIcon={true}
               />
            </Col>
          </Row>
          : <Row >
          <Row className='hide-input-number-arrows'>
            <Col span={12}>
              <Row align='middle'>
                <Col span={4}>{t(allConstants.MIN)}</Col>
                <Col span={2} >
                  <InputNumber
                    min={0}
                    max={100}
                    style={{ margin: '0 16px' }}
                    step={1}
                    value={values.ExperienceMinInputValue}
                    onChange={(e) => onChange('ExperienceMinInputValue', e)}
                    onPressEnter={() => handleConfirm('experience')}
                    onKeyDown={handleKeyDown}
                    onBlur={!match ? () => handleConfirm('experience') : null}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row align='middle'>
                <Col span={4}>{t(allConstants.MAX)}</Col>
                <Col span={4}>
                  <InputNumber
                    min={0}
                    max={100}
                    style={{ margin: '0 16px' }}
                    step={1}
                    value={values.ExperienceMaxInputValue}
                    onChange={(e) => onChange('ExperienceMaxInputValue', e)}
                    onPressEnter={() => handleConfirm('experience')}
                    onKeyDown={handleKeyDown}
                    onBlur={!match ? () => handleConfirm('experience') : null}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>}

        {/* Job Count */}
        <Row className='job-count exp-typo-row' >
          <Title level={5} >{t(allConstants.JOB_COUNT)}</Title>
        </Row>
        {/* <Row > */}
          {!values.isOpen
            ? <Row>
             <Col className={'exp-tags'} >
               <TagGroup
               tags={[`${values.CountMinInputValue} - ${values.CountMaxInputValue}`]}
               HideCloseIcon={true}
               />
            </Col>
           </Row>
            : <Row >
            <Col span={12}>
              <Row align='middle' >
                <Col span={4} >{t(allConstants.MIN)}</Col>
                <Col span={2}>
                  <InputNumber
                    min={0}
                    max={100}
                    style={{ margin: '0 16px' }}
                    step={1}
                    value={values.CountMinInputValue}
                    onChange={(e) => onChange('CountMinInputValue', e)}
                    onPressEnter={() => handleConfirm('job_count')}
                    onKeyDown={handleKeyDown}
                    onBlur={!match ? () => handleConfirm('job_count') : null}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row align='middle'>
                <Col span={4}>{t(allConstants.MAX)}</Col>
                <Col span={4}>
                  <InputNumber
                    min={0}
                    max={100}
                    style={{ margin: '0 16px' }}
                    step={1}
                    value={values.CountMaxInputValue}
                    onChange={(e) => onChange('CountMaxInputValue', e)}
                    onPressEnter={() => handleConfirm('job_count')}
                    onKeyDown={handleKeyDown}
                    onBlur={!match ? () => handleConfirm('job_count') : null}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          }
          {/* Slider may be add later so don't be remove */}

          {/* <Col span={24}>
          <Slider
            range
            defaultValue={[values.CountMinInputValue, values.CountMaxInputValue]}
            min={0}
            max={20}
            tipFormatter={null}
            // value={[values.MinInputValue, values.MaxInputValue]}
            onChange={(range) => handleRange('count', range)} />
        </Col> */}
        {/* </Row> */}
      </>
    </FiltersLayout>
  )
}

Education.propTypes = {
  contextData: PropTypes.object.isRequired,
  existedExperience: PropTypes.object,
  setExistedFilters: PropTypes.func,
  match: PropTypes.object,
  existedFilters: PropTypes.object,
  setSearchThroughAdvanceFilter: PropTypes.func,
  setSearchApiVal: PropTypes.func

}
