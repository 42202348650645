import React from 'react'
import { Input, Card, Button, Tag, Tooltip } from 'antd'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'
import HeaderLayout from '../../../layouts/HeaderLayout'
import { InputContainer, SavedSearchLinkContainer, SearchContainer, TagsContainer, ViewSavedSearchLink } from './style'
import Candidates from '../SearchCandidate'
import { useQuerysDispatch, setSearchBarValue, useQuerysState, setSearchApiObj, useQueryActions } from '../CandidateContext/SearchQueryContext'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../constants/constants'

export default function SearchBar () {
  const [searchBarField, setSearchBarField] = React.useState({})
  const { t } = useTranslation()
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useQuerysDispatch()
  const action = useQueryActions()
  const { searchBarValues, searchApiObj } = useQuerysState()
  const [savedSearch, setSavedSearch] = React.useState(false)
  const [tagsVal, setTagsVal] = React.useState([])
  const [skillsInputVal, setSkillsInputVal] = React.useState('')
  const [hoverState, setHoverState] = React.useState(false)

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && skillsInputVal !== '') {
      const arr = [...tagsVal]
      arr.push(skillsInputVal)
      setTagsVal([...arr])
      dispatch(setSearchApiObj(
        { ...searchApiObj, shouldHave: { ...searchApiObj.shouldHave, expertise: [...arr] } }
      ))
      dispatch(setSearchBarValue({ ...searchBarValues, skills: arr }))
      setSearchBarField({ ...searchBarField, skills: arr })
      setSkillsInputVal('')
    } else if (e.key === 'Backspace' && tagsVal.length !== 0 && skillsInputVal === '') {
      const arr = [...tagsVal]
      arr.pop()
      dispatch(setSearchApiObj(
        { ...searchApiObj, shouldHave: { ...searchApiObj.shouldHave, expertise: [...arr] } }
      ))
      dispatch(setSearchBarValue({ ...searchBarValues, skills: arr }))
      setSearchBarField({ ...searchBarField, skills: arr })
      setTagsVal([...arr])
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    if (name !== 'skills') {
      if (name === 'location') {
        dispatch(setSearchApiObj({ ...searchApiObj, mustHave: { ...searchApiObj.mustHave, location: value } }))
        dispatch(setSearchBarValue({ ...searchBarValues, [name]: value }))
        setSearchBarField({ ...searchBarField, [name]: value })
      } else {
        dispatch(setSearchApiObj(
          { ...searchApiObj, shouldHave: { ...searchApiObj.shouldHave, current_title: [value] } }
        ))
        dispatch(setSearchBarValue({ ...searchBarValues, [name]: value }))
        setSearchBarField({ ...searchBarField, [name]: value })
      }
    } else {
      setSkillsInputVal(value)
    }
  }

  const handleSearch = () => {
    try {
      action.getCandidatesFromSearch({ annotations: searchApiObj })
    } catch (e) {
      console.log(e)
    } finally {
      const { title, skills, location } = searchBarValues
      const string = skills.join(',')
      history.push(`/simplesearch/${encodeURIComponent(title) || 'no_title'}/${encodeURIComponent(string)}/${location || 'no_location'}`)
    }
  }
  const tooltipStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '26px',
    width: '76px',
    objectFit: 'contain',
    borderRadius: '3px',
    border: 'solid 1px #d9d9d9',
    backgroundColor: '#f5f5f5',
    color: '#000',
    marginBottom: '5px',
    paddingLeft: '5px'
  }
  const tooltipEllipsis = {
    whiteSpace: ' nowrap',
    overflow: ' hidden',
    textOverflow: ' ellipsis'
  }

  const disableButtonConditionArray = Object.values(searchBarField)

  React.useEffect(() => {
    setSearchBarField({ title: '', skills: [], location: '' })
    dispatch((setSearchBarValue({ title: '', location: '' })))
  }, [])

  return (
    <HeaderLayout title={t(allConstants.SEARCH_CANDIDATE)}>
        <Card style={{ height: 'calc(100vh - 200px)' }}>
            <SearchContainer>
              <div>
                  <img src={theme.logo || 'https://score.ai4jobs.info/logo.png'}/>
              </div>
              <div>
                  <Input
                      name='title'
                      className='title-input'
                      placeholder={t(allConstants.SEARCH_BY_JOB_TITLE)}
                      onChange={(e) => handleChange(e)}
                      allowClear
                  />
              </div>

              <div className='input-container'>
                  <InputContainer>
                      <TagsContainer className='tags-wrappers'>
                          {tagsVal.length !== 0 && tagsVal.filter((item, index) => index < 1)
                            .map((ele, ind) =>
                              ele.length > 7
                                ? <Tooltip key={ind} title={ele}>
                                  <Tag key={ind}>
                                    <div className='tags-ellipsis' >{ele}</div>
                                  </Tag>
                                  </Tooltip>
                                : <Tag key={ind}>
                                  <div className='tags-ellipsis' >{ele}</div>
                                  </Tag>
                            )
                          }
                          {tagsVal.length !== 0 && tagsVal.length > 1 &&
                          <Tag
                            onMouseOver={() => setHoverState(true)}
                            onMouseLeave={() => setHoverState(false)}
                            className='more-tag'
                          >
                              {hoverState
                                ? <Tooltip
                                    overlayInnerStyle={{
                                      width: '95px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      flexWrap: 'wrap'
                                    }}
                                    placement="bottomLeft"
                                    title={
                                      tagsVal.filter((item, index) => index > 0)
                                        .map((ele, ind) =>
                                        <div style={tooltipStyle} key={ind}>
                                          <div name={ele}style={tooltipEllipsis}>{ele}</div>
                                        </div>
                                        )
                                    }
                                  >
                                    <div >{`+${tagsVal.length - 1} ${t(allConstants.MORE)}`}</div>
                                  </Tooltip>
                                : <div >{`+${tagsVal.length - 1} ${t(allConstants.MORE)}`}</div>
                              }
                          </Tag>
                          }
                      </TagsContainer>
                      <Input
                          type='text'
                          value={skillsInputVal}
                          name='skills'
                          onChange={(e) => handleChange(e)}
                          className='other-input'
                          placeholder={t(allConstants.ADD_SKILLS)}
                          onKeyDown={(e) => handleKeyPress(e)}
                          allowClear
                      />
                  </InputContainer>
                  <Input
                      type='text'
                      name='location'
                      onChange={(e) => handleChange(e)}
                      className='other-input'
                      placeholder={t(allConstants.ADD_LOCATION_IF_NOT_REMOTE)}
                      allowClear
                      value={searchBarValues.location === 'no_location' ? '' : searchBarValues.location }
                  />
              </div>
              <div className={'tags-helper'}>
                <span style={{ width: '240px' }}>{t(allConstants.ENTER_AFTER_ENTRY)}</span>
                <span style={{ width: '235px' }}>{t(allConstants.CITY_STATE_FORMAT)}</span>
              </div>
              <div>
                  <Button
                      onClick={() => handleSearch()}
                      className='search-button'
                      type='primary'
                      disabled={ !disableButtonConditionArray[0] || !disableButtonConditionArray[1]?.length }
                  >
                      {t(allConstants.SEARCH_ONLY_CANDIDATES)}
                  </Button>
              </div>
                <SavedSearchLinkContainer>
                <ViewSavedSearchLink onClick={() => setSavedSearch(true)}>
                {t(allConstants.VIEW_SAVED_SEARCHES)}
                </ViewSavedSearchLink>
                </SavedSearchLinkContainer>
            </SearchContainer>
              <Candidates setSavedSearch={setSavedSearch} savedSearch={savedSearch}/>
        </Card>
    </HeaderLayout>
  )
}
