import React from 'react'
import { useParams } from 'react-router'
import HeaderLayout from '../../layouts/HeaderLayout'
import ApplicantsTable from './components/ApplicantsTable'
import { ButtonContainer, Container } from './style'
import { Button, Space } from 'antd'
import FilterCards from './components/cards/FilterCards'
// import DrawerApplicantProfile from './DrawerApplicantProfile'

import DrawerUploadCandidates from './DrawerUploadCandidates'
import DrawerEditJob from './DrawerEditJob'
import { useRequest } from 'ahooks'
import NewDrawerApplicantProfile from './NewDrawerApplicantProfile'

import { useJobsState, useJobsActions, useJobsDispatch, setPassive, setPage } from '../jobs/context/JobsContext'
import { useJobRequisitionsDispatch, useJobRequisitionsState, setFilter } from './context/ApplicantsContextProvider'
import { allConstants } from '../../constants/constants'
import { useTranslation } from 'react-i18next'

export default function ApplicantPage () {
  const { selectedJob, page, passive, isLoading } = useJobsState()
  const { jobExtId } = useParams()
  const actions = useJobsActions()
  const jobsDispatch = useJobsDispatch()
  const dispatch = useJobRequisitionsDispatch()
  const { t } = useTranslation()
  const {
    // filter,
    passive: { list }, qualified: { loading }
  } = useJobRequisitionsState()

  useRequest(async () => jobExtId !== 'new' && actions.getJob(jobExtId), { refreshDeps: [jobExtId] })

  // const renderJobDrawer = () => <DrawerApplicantProfile />
  const renderJobDrawer = () => <NewDrawerApplicantProfile/>
  const renderDrawerUploadCandidate = () => <DrawerUploadCandidates />
  const renderDrawerEditJob = () => <DrawerEditJob />
  const renderCardsIndo = () => <FilterCards/>
  const renderApplicantsTable = () => <ApplicantsTable />
  const handlePassiveButton = () => {
    if (list.length === 0) return jobsDispatch(setPassive(true))
    else { dispatch(setFilter('passive')); jobsDispatch(setPage(!page)) }
  }

  const renderActions = () => (
    <Space>
      <ButtonContainer>
        {!page
          ? <Button
              style={loading || isLoading ? { height: '42px', width: '218px', border: '1.5px solid #d9d9d9' } : {}}
              disabled={loading || isLoading}
              loading={passive}
              onClick={() => handlePassiveButton() }>
              <span
              style={loading || isLoading ? { color: '#00000040' } : {}}
              >

                {t(allConstants.INSTANT_CANDIDATES_SEE)}
              </span>
            </Button>
          : <Button onClick={() => { dispatch(setFilter('direct')); jobsDispatch(setPage(!page)); jobsDispatch(setPassive(false)) }}>{t(allConstants.SEE_ACTIVE_APPLICANTS)}</Button>
        }
      </ButtonContainer>
    </Space>
  )

  React.useEffect(() => {
    window.zE('webWidget', 'helpCenter:setSuggestions', { search: 'ticket' })
  }, [])
  return (
    <HeaderLayout title={selectedJob?.title || '-'} extra={renderActions()}>
      <Container>
        {renderCardsIndo()}
        {renderApplicantsTable()}
      </Container>
      {renderJobDrawer()}
      {renderDrawerUploadCandidate()}
      {renderDrawerEditJob()}
    </HeaderLayout>
  )
}
