import React from 'react'
import {
  // CalculatorOutlined,
  DollarCircleOutlined, TranslationOutlined
  // ReadOutlined
} from '@ant-design/icons'
import { Route, Switch, useHistory, useLocation } from 'react-router'
import PageHeaderLayoutWithHeader from '../../layouts/PageHeaderLayoutWithHeader'
import BalancesPage from './balances/BalancesPage'
// import PreferencesPage from './preferences/PreferencesPage'
import { Trans } from '@lingui/macro'
import ChangeLanguagePage from './language/ChangeLanguagePage'
// import { Report } from './reports/Report'
// import { valueCalculator } from './valuecalculator/valueCalculator'
import { allConstants } from '../../constants/constants'
import { useTranslation } from 'react-i18next'

const SettingsPage = () => {
  const history = useHistory()
  const location = useLocation()
  const url = location.pathname
  const { t } = useTranslation()
  const menus = [
    { tab: t(allConstants.CREDITS), key: '/settings/balances', icon: <DollarCircleOutlined /> },
    { tab: t(allConstants.LANGUAGE), key: '/settings/change-language', icon: <TranslationOutlined /> }

  ]
  return (
    <PageHeaderLayoutWithHeader
      title={<Trans id="Account Info">{t(allConstants.ACCOUNT_INFO)}</Trans>}
      menus={menus}
      onTabChange={(key) => history.push(key)}
      defaultActiveKey={url}
    >
      <Switch>
        <Route path={'/settings/balances'} component={BalancesPage} />
        <Route path={'/settings/change-language'} component={ChangeLanguagePage} />
        {/* <Route path={'/settings/preferences'} component={PreferencesPage} /> */}
        {/* <Route path={'/settings/reports'} component={Report}/> */}
        {/* <Route path={'/settings/valueCalculator'} component={valueCalculator}/> */}
      </Switch>
    </PageHeaderLayoutWithHeader>
  )
}

export default SettingsPage
