import React from 'react'
import PropTypes from 'prop-types'
import { Row, Empty } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
// functions
import { handleOnChange, handleSearchClick, handleKeyEnter } from '../../SearchBar/utils'
// Styles
import './ZendeskArticleListing.css'
// files
import ZendeskTicketPageWrapper from '../../../ZendeskPages/ZendeskTicketPage/ZendeskTicketPageWrapper/ZendeskTicketPageWrapper'
import SearchBar from '../../SearchBar/SearchBar'
import { useZendeskActions, useZendesksState } from '../../../ZendeskContext'
import { ArticleName } from '../../HomePage/ZendeskBanner/ZendeskBannerStyles'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'

const ZendeskArticleListing = () => {
  const actions = useZendeskActions()
  const { articlesPerSection, categories } = useZendesksState()
  const location = useLocation()
  const articleSectionId = location.pathname.split('/')[3]
  const categoryName = location.pathname.split('/')[2]
  const history = useHistory()
  const [inputValue, setInputValue] = React.useState('')
  const [searchValue, setSearchValue] = React.useState('')

  React.useEffect(() => {
    try {
      actions.getZendeskArticlesPerSection(articleSectionId, { characters: 90 })
      actions.getZendeskAllCategories()
    } catch (e) {
      console.log(e)
    }
  }, [actions, articleSectionId])
  const { t } = useTranslation()

  const headerTitle = () => {
    return (
            <Row className='preview-title' >
                {`${t(categoryName)} `}
            </Row>
    )
  }
  const Breadcrumb = () => {
    return (
            <div className='bread-crumb-container'>
            <span className='bread-crumb' onClick={() => history.push('/help-center')}>{t(allConstants.HELP_CENTER)}</span>
            <span style={{ margin: '0px 5px' }}>&gt;</span>
            <span> {t(categoryName)} </span>
            </div>
    )
  }

  const categoriesName = () => {
    return categories.find(item => `${item.sectionId}` === `${articleSectionId}` && item.name)
  }

  const handleClick = (id) => {
    history.push(`/${categoriesName()?.name}/article/${id}`)
  }

  const styles = {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  }

  return (
        <ZendeskTicketPageWrapper
            headerTitle={headerTitle()}
            extra={<SearchBar
              onChange={(e) => handleOnChange(e, setInputValue, setSearchValue)}
              onClick={() => handleSearchClick(inputValue, history)}
              onKeyPress={(e) => handleKeyEnter(e, inputValue, history)}
              placeholder={t(allConstants.QUERY_SEARCH)}
              value={searchValue}
              />
            }
            withFooter={true}
            Breadcrumb={Breadcrumb}
        >
          <div className={'article-list-container'}>
            <div style={styles} >
            {articlesPerSection.length === 0 && <div className='article-no-data'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>}
            {articlesPerSection.map((item, index) => {
              return (

                      <div className='article-list-card' key={item.id} >
                          <ArticleName className='article-name' onClick={() => handleClick(item.id)}>{item.title}</ArticleName>
                          <div className='zendesk-article-description'>
                            <p dangerouslySetInnerHTML={{ __html: item.preview }}/>
                            <div>
                            <span style={{ fontSize: '17px', color: '#343434' }}>...</span>
                            <span className='zendesk-article-readmore' onClick={() => handleClick(item.id)}>{t(allConstants.READ_MORE)}</span>
                            </div>
                          </div>
                      </div>
              )
            })}
            </div>
          </div>

        </ZendeskTicketPageWrapper>
  )
}
ZendeskArticleListing.propTypes = {
  categoryName: PropTypes.string,
  categoryDescription: PropTypes.string,
  fetchingCategories: PropTypes.func
}
export default ZendeskArticleListing