import axios from '../../axios-config'

/**
 * POST
 * /reveal/reveal/api/v1/estimate/atskey/{atsApiKey}
 */
export const getEstimate = (data, body) => {
  return axios
    .post(`/reveal/api/v1/estimate/atskey/${data?.atsApiKey}`, body)
}

/**
 * POST
 * /reveal/reveal/api/v1/estimate/atskey/{atsApiKey}/job{jobId}
 */
export const getEstimateForJob = (data, body) => {
  return axios
    .post(`/reveal/api/v1/estimate/atskey/${data?.atsApiKey}/job/${data?.JobId}`, body)
}

/**
 * POST
 * /reveal/reveal/api/v1/estimate/atskey/{atsApiKey}/job{jobId}
 */
export const startSingleJobBackFill = (data) => {
  return axios
    .post(`/reveal/api/v1/preview/start/atskey/${data.atsApiKey}`, data)
}

/**
 * GET
 * /reveal/reveal/api/v1/estimate/atskey/{atsApiKey}/job{jobId}
 */
// export const startAllJobBackFill = (data) => {
//   return axios
//     .get('/reveal/api/v1/backfill/start/client/43008146-63f2-44a6-a801-24f51d8ab574')
// }

/**
 * GET
 * /reveal/reveal/api/v1/estimate/atskey/{atsApiKey}/job{jobId}
 */
export const startAllJobBackFill = (clientKey, trackingId) => {
  return axios
    .get(`/reveal/api/v1/preview/status/client/${clientKey}/tracking/${trackingId}`)
}
