import styled from 'styled-components'

export const FormWrapper = styled.div`
       display:flex;
    width:100% !important;
   & .table_row{
    display: flex;
    justify-content: flex-start;
    
    /* gap: 1rem; */
    /* border: 1px solid blue; */
   
   }
   
   & .ant-col {
    min-width:255px;
   }
    & .field-name {
        /* margin-right:10px; */
        padding: 10px 24px;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.85);
        /* border:1px solid yellow; */
        /* width:300px !important */
    }
    & input {
        width: 220px;
        height: 32px;
        padding: 0px 0px 0px 10px;
        border-radius: 2px;
        border: 1px solid rgb(217, 217, 217);
        background-color: rgb(255, 255, 255);
        outline:none;
        margin-bottom:10px;
    }
    & .button-wrapper {
        text-align:right;
    }
    & .submit-button {
        margin:10px 0px;
        padding:0 !important;
        width:100px;
        height: 32px;
        border-radius: 2px;
        border: transparent;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
        background-color: ${({ theme }) => theme.primary_color || '#00b7c0'};
        font-size: 14px;
        line-height: 1.57;
        color: #fff;

        &:hover {
            cursor:pointer;
        }
    }
    & select {
        width: 220px;
        height: 32px;
        padding: 0px 0px 0px 10px;
        border-radius: 2px;
        border: 1px solid rgb(217, 217, 217);
        background-color: rgb(255, 255, 255);
        outline:none;
        margin-bottom:10px;
    }
`