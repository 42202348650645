export const columns = (t) => {
  return [
    {
      title: t('Name'),
      dataIndex: 'shortName',
      key: 'shortName'
    },
    {
      title: t('Available Balance'),
      dataIndex: 'balanceAvailable',
      align: 'center',
      key: 'balanceAvailable'
    }
  ]
}