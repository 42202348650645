import { useRequest } from 'ahooks'
import { Card, Col, Row, Skeleton, Table } from 'antd'
import React from 'react'
import { getAccounts } from '../../../api-twg/twg/banking-controller'
import { columns } from './columns'
import { useAuthState } from '../../../contexts/AuthContext'
import { allConstants } from '../../../constants/constants'
import { useTranslation } from 'react-i18next'

const BalancesPage = () => {
  const { loading, data } = useRequest(getAccounts)
  const { user } = useAuthState()
  const { t } = useTranslation()
  const accountsCollection = data?.data?.filter((item) =>
    ['AJE', 'DIS'].includes(item.code)
  ) || []

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Card
          title={
            user?.subscriber
              ? t(allConstants.SUBSCRIPTION_ACCOUNT)
              : t(allConstants.CONSUMPTION_ACCOUNT)
          }
          bordered={false}
        >
          {loading && <Skeleton active />}
          {!loading && (
            <Table
              columns={columns(t)}
              dataSource={accountsCollection}
              rowKey={(e) => {
                return e.extid + e.code
              }}
              pagination={accountsCollection.length > 10 }
            />
          )}
        </Card>
      </Col>
    </Row>
  )
}

export default BalancesPage
