import { message, Spin } from 'antd'
import React from 'react'
import { Switch } from 'react-router'
import './zendeskContext.css'
// APi
import * as zendeskController from '../../../api-twg/zendesk'
// Routes
import PrivateRoute from '../../../components/PrivateRoute'
import ZendeskHomePage from '../ZendeskPages/ZendeskHomePage/ZendeskHomePage'
import ZendeskCategory from '../Component/ZendeskCategory/ZendeskCategory'
import ZendeskTicketPreview from '../ZendeskPages/ZendeskTicketPreview/ZendeskTicketPreview'
import ZendeskTickets from '../ZendeskPages/ZendeskTickets/ZendeskTickets'
import ZendeskArticle from '../Component/ZendeskCategory/ZendeskArticles/ZendeskArticle'
import ZendeskSearchedArticleList from '../Component/ZendeskSearchedArticlesList/ZendeskSearchedArticlesList'
// Local Files
// import WelcomeGreenhouse from '../WelcomeGreenhousePage'
// Controller

// Context Api instance
const ZendeskActionsContext = React.createContext(undefined, undefined)
const ZendeskStateContext = React.createContext(undefined, undefined)
const ZendeskDispatchContext = React.createContext(undefined, undefined)

// Actions Types
export const IS_LOADING = 'IS_LOADING'
export const TICKET_COMMENTS = 'TICKET_COMMENTS'
export const CATEGORIES = 'CATEGORIES'
export const ARTICLES = 'ARTICLES'
export const ARTICLESPERSECTION = 'ARTICLESPERSECTION'
export const ARTICLE = 'ARTICLE'
export const SEARCHARTICLE = 'SEARCHARTICLE'
export const SET_ZENDESK_TICKET_DATA = 'SET_ZENDESK_TICKET_DATA'

// Actions Creators
export const setIsLoading = (bool) => ({ type: IS_LOADING, bool })
export const setTicketComments = (comment) => ({ type: TICKET_COMMENTS, comment })
export const setCategories = (categories) => ({ type: CATEGORIES, categories })
export const setArticles = (articles) => ({ type: ARTICLES, articles })
export const setArticlesPerSection = (articlesPerSection) => ({ type: ARTICLESPERSECTION, articlesPerSection })
export const setArticle = (article) => ({ type: ARTICLE, article })
export const setSearchArticle = (searchArticle) => ({ type: SEARCHARTICLE, searchArticle })
export const setZendeskTicketData = (zendeskTicketData) => ({ type: SET_ZENDESK_TICKET_DATA, zendeskTicketData })

const initialState = {
  // Commons
  isLoading: false,
  ticketComments: undefined,
  categories: [],
  articles: [],
  articlesPerSection: [],
  article: {},
  searchArticle: [],
  zendeskTicketData: {}
}

export function reducer (state, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.bool
      }
    case TICKET_COMMENTS:
      return {
        ...state,
        ticketComments: action.comment
      }
    case CATEGORIES:
      return {
        ...state,
        categories: action.categories
      }
    case ARTICLES:
      return {
        ...state,
        articles: action.articles
      }
    case ARTICLESPERSECTION:
      return {
        ...state,
        articlesPerSection: action.articlesPerSection
      }
    case ARTICLE:
      return {
        ...state,
        article: action.article
      }
    case SEARCHARTICLE:
      return {
        ...state,
        searchArticle: action.searchArticle
      }
    case SET_ZENDESK_TICKET_DATA:
      return {
        ...state,
        zendeskTicketData: action.zendeskTicketData
      }
    default:
      return state
  }
}

export const createZendeskTicket = (dispatch) => async (payload) => {
  try {
    const response = await zendeskController.postCreateTicket(payload)
    const { data } = response
    return data
    // return
  } catch (e) {
    // message.error('Something went wrong with create Zendesk')
    console.error(e.message)
    throw Error('Something went wrong')
  }
}

export const postCreateTicketAttachment = (dispatch) => async (payload) => {
  try {
    const response = await zendeskController.postCreateTicketAttachment(payload)
    const { data } = response
    return data
    // return
  } catch (e) {
    // message.error('Something went wrong with create Zendesk')
    console.error(e.message)
    throw Error('Something went wrong')
  }
}

export const getZendeskAllCategories = dispatch => async (payload) => {
  try {
    const response = await zendeskController.getZendeskAllCategories()
    const { data } = response
    dispatch(setCategories(data))
    return data
  } catch (e) {
    console.log(e.message)
    throw Error('Something went wrong')
  }
}

export const getZendeskArticlesPerSection = dispatch => async (payload, body) => {
  dispatch(setIsLoading(true))
  try {
    const response = await zendeskController.getZendeskArticlesPerSection(payload, body)
    const { data } = response
    dispatch(setArticlesPerSection(data))
    return data
  } catch (e) {
    console.log(e)
    throw Error('Something went wrong')
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const getZendeskAllArticles = dispatch => async (payload) => {
  setIsLoading(true)
  try {
    const response = await zendeskController.getZendeskAllArticles()
    const { data } = response
    dispatch(setArticles(data))
    return data
  } catch (e) {
    console.log(e)
    throw Error('Something went wrong')
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const getZendeskArticle = dispatch => async (payload) => {
  dispatch(setIsLoading(true))
  try {
    const response = await zendeskController.getZenedeskArticle(payload)
    const { data } = response
    dispatch(setArticle(data))
    return data
  } catch (e) {
    console.log(e)
    throw Error('Something went wrong')
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const searchingArticle = dispatch => async (payload) => {
  dispatch(setIsLoading(true))
  try {
    const response = await zendeskController.searchingArticle(payload)
    const { data } = response
    dispatch(setSearchArticle(data))
    return data
  } catch (e) {
    console.log(e.message)
    throw Error('Something went wrong')
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const getZendeskAllUsers = (dispatch) => async (payload) => {
  try {
    const response = await zendeskController.getZendeskAllUsers()
    // const { data } = response
    // dispatch(setGreenhouseEstimator(data))
    // const data = 'res'
    console.log(response)
    return response
    // return
  } catch (e) {
    // message.error('Something went wrong with create Zendesk')
    console.error(e.message)
  }
}

export const getZendeskUsers = (dispatch) => async (payload) => {
  try {
    const response = await zendeskController.getZendeskUser()
    // const { data } = response
    // dispatch(setGreenhouseEstimator(data))
    // const data = 'res'
    console.log(response)
    return response
    // return
  } catch (e) {
    // message.error('Something went wrong with create Zendesk')
    console.error(e.message)
  }
}

export const getZendeskTicket = (dispatch) => async (_id) => {
  dispatch(setIsLoading(true))
  try {
    const response = await zendeskController.getZendeskTicket(_id)
    const { data } = response
    // dispatch(setGreenhouseEstimator(data))
    // const data = 'res'
    // console.log(data)
    dispatch(setZendeskTicketData(data))
    return response
    // return
  } catch (e) {
    // message.error('Something went wrong with create Zendesk')
    console.error(e.message)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const getZendeskComments = (dispatch) => async (_id) => {
  dispatch(setIsLoading(true))
  try {
    const response = await zendeskController.getComments(_id)
    const { data } = response
    dispatch(setTicketComments(data))
    // const data = 'res'
    return response
    // return
  } catch (e) {
    // message.error('Something went wrong with create Zendesk')
    console.error(e.message)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const getTicketSummaryForUser = (dispatch) => async (email) => {
  dispatch(setIsLoading(true))
  try {
    const response = await zendeskController.getTicketSummaryForUser(email)
    const { data } = response
    dispatch(setTicketComments(data))
    // const data = 'res'
    return data
    // return
  } catch (e) {
    // message.error('Something went wrong with create Zendesk')
    console.error(e.message)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const postCreateComment = (dispatch) => async (id, payload) => {
  dispatch(setIsLoading(true))
  try {
    const response = await zendeskController.postCreateComment(id, payload)
    const { data } = response
    dispatch(setTicketComments(data))
    return data
    // return
  } catch (e) {
    message.error('Something went wrong')
    console.error(e.message)
    throw Error('Something went wrong')
  } finally {
    dispatch(setIsLoading(false))
  }
}

function ZendseskContextProvider () {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [actions] = React.useState({
    // Commons
    // getEstimate: getEstimate(dispatch),
    createZendeskTicket: createZendeskTicket(dispatch),
    getZendeskAllUsers: getZendeskAllUsers(dispatch),
    getZendeskTicket: getZendeskTicket(dispatch),
    getZendeskUsers: getZendeskUsers(dispatch),
    getZendeskComments: getZendeskComments(dispatch),
    getTicketSummaryForUser: getTicketSummaryForUser(dispatch),
    postCreateComment: postCreateComment(dispatch),
    postCreateTicketAttachment: postCreateTicketAttachment(dispatch),
    getZendeskAllCategories: getZendeskAllCategories(dispatch),
    getZendeskAllArticles: getZendeskAllArticles(dispatch),
    getZendeskArticlesPerSection: getZendeskArticlesPerSection(dispatch),
    getZendeskArticle: getZendeskArticle(dispatch),
    searchingArticle: searchingArticle(dispatch)
  })

  // const location = useLocation()
  // useRequest(async () => actions.getDiscoverJobs())
  // console.log(state, location.pathname === '/help-center')

  return (
    <ZendeskActionsContext.Provider value={actions}>
      <ZendeskStateContext.Provider value={state}>
        <ZendeskDispatchContext.Provider value={dispatch}>
          <Spin spinning={state.isLoading} >
          <Switch>
            <PrivateRoute
              path={'/categories/:name/:id'}
              strict={false}
              component={ZendeskCategory}
            />
            <PrivateRoute
              exact
              path={'/:name/article/:id'}
              strict={false}
              component={ZendeskArticle}
            />
            <PrivateRoute
            exact
              path={'/article/search/:id'}
              strict={false}
              component={ZendeskSearchedArticleList}
            />
            <PrivateRoute
              path={'/help-center'}
              strict={false}
              component={ZendeskHomePage}
            />
            <PrivateRoute
              path={'/tickets'}
              strict={false}
              component={ZendeskTickets}
            />
             <PrivateRoute
              path={'/ticket-preview/:id'}
              strict={false}
              component={ZendeskTicketPreview}
            />
          </Switch>
          </Spin>
        </ZendeskDispatchContext.Provider>
      </ZendeskStateContext.Provider>
    </ZendeskActionsContext.Provider>
  )
}

// Hooks
function useZendeskActions () {
  const context = React.useContext(ZendeskActionsContext)
  if (context === undefined) {
    throw new Error('useZendeskActions must be used within a ZendeskContextProvider')
  }
  return context
}

function useZendesksState () {
  const context = React.useContext(ZendeskStateContext)
  if (context === undefined) {
    throw new Error('useZendeskState must be used within a ZendeskContextProvider')
  }
  return context
}

function useZendesksDispatch () {
  const context = React.useContext(ZendeskDispatchContext)
  if (context === undefined) {
    throw new Error('useZendeskDispatch must be used within a ZendeskContextProvider')
  }
  return context
}

export { ZendseskContextProvider, useZendeskActions, useZendesksState, useZendesksDispatch }
