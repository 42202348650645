import axios from '../../axios-config'

import dayjs from 'dayjs'
import { createJobToApi } from './normalize'

import {
  normalizeDoExport,
  normalizeCreateJob,
  normalizeGetJob,
  normalizeUpdateJob,
  normalizeCloseJob,
  normalizeUpdateCandidateWithCv,
  normalizeCreateCandidatesWithCv,
  normalizeGetAllCountries,
  normalizeGetAllCountriesForRegions,
  normalizeGetAllLocations,
  normalizeGetAllLocationsForRegionsAndCountries,
  normalizeGetAllRegions,
  normalizeSearchByJobDescription,
  normalizeSearchByQueryRequest,
  normalizeSearchByJobPosting,
  normalizeSearchByQueryRequestNoPosting,
  normalizeSearchByQueryRequestNoJobNoPosting
} from './normalize2'
/**
 * POST
 * /score/api/v1/export
 * doExport
 */
export const doExport = (params, data) => {
  return axios
    .post('/score/api/v1/export', data, { params })
    .then(normalizeDoExport)
}

/**
 * POST
 * /score/api/v1/job
 * createJob
 */
export const createJob = (data) => {
  const date = new Date()
  const cleanDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS')
  const update = cleanDate + 'Z'
  const dateType = new Date(update)
  const newData = {
    ...data,
    externalJobId: data.externalJobId,
    region: 'North America',
    createdAt: dateType
  }

  return axios
    .post('/score/api/v1/job', createJobToApi(newData))
    .then(normalizeCreateJob)
}
/**
 * GET
 * /score/api/v1/job/${jobExtId}
 * getJob
 */
export const getJob = (jobExtId) => {
  return axios
    .get(`/score/api/v1/job/${jobExtId}`)
    .then(normalizeGetJob)
}
/**
 * PUT
 * /score/api/v1/job/${jobExtId}
 * updateJob
 */
export const updateJob = (jobExtId, data) => {
  return axios
    .put(`/score/api/v1/job/${jobExtId}`, data)
    .then(normalizeUpdateJob)
}
/**
 * DELETE
 * /score/api/v1/job/${jobExtId}
 * closeJob
 */
export const closeJob = (jobExtId) => {
  return axios
    .delete(`/score/api/v1/job/${jobExtId}`)
    .then(normalizeCloseJob)
}
/**
 * GET
 * /score/api/v1/job/${jobExtId}/candidate
 * getAllJobCandidates
 */
export const getAllJobCandidates = (jobExtId) => {
  return axios.get(`/score/api/v1/job/${jobExtId}/candidate`)
}
/**
 * GET
 * /score/api/v1/job/${jobExtId}/candidate/{candidateExtId}
 * getCandidate
 */
export const getCandidate = (jobExtId, candidateExtId) => {
  return axios.get(`/score/api/v1/job/${jobExtId}/candidate/${candidateExtId}`)
}
/**
 * PUT
 * /score/api/v1/job/${jobExtId}/candidate/{candidateExtId}
 * updateCandidateWithCv
 */
export const updateCandidateWithCv = (jobExtId, candidateExtId, data) => {
  return axios
    .put(`/score/api/v1/job/${jobExtId}/candidate/${candidateExtId}`, data)
    .then(normalizeUpdateCandidateWithCv)
}
/**
 * DELETE
 * /score/api/v1/job/${jobExtId}/candidate/{candidateExtId}
 * removeCandidate
 */
export const removeCandidate = (jobExtId, candidateExtId) => {
  return axios.delete(`/score/api/v1/job/${jobExtId}/candidate/${candidateExtId}`)
}
/**
 * POST
 * /score/api/v1/job/${jobExtId}/candidates
 * createCandidatesWithCv
 */
export const createCandidatesWithCv = (jobExtId, data) => {
  return axios
    .post(`/score/api/v1/job/${jobExtId}/candidates`, data)
    .then(normalizeCreateCandidatesWithCv)
}
/**
 * GET
 * /score/api/v1/job/${jobExtId}/posting
 * getAllJobPostings
 */
export const getAllJobPostings = (jobExtId) => {
  if (!jobExtId) return Promise.resolve([])
  return axios.get(`/score/api/v1/job/${jobExtId}/posting`)
}

/**
 * POST
 * /score/api/v1/job/${jobExtId}/posting
 * createJobPosting
 */
export const createJobPosting = (jobExtId, data) => {
  return axios.post(`/score/api/v1/job/${jobExtId}/posting`, data)
}
/**
 * GET
 * /score/api/v1/job/${jobExtId}/posting/${jobPostExtId}
 * getJobPosting
 */
export const getJobPosting = (jobExtId, jobPostExtId) => {
  return axios.get(`/score/api/v1/job/${jobExtId}/posting/${jobPostExtId}`)
}
/**
 * PUT
 * /score/api/v1/job/${jobExtId}/posting/${jobPostExtId}
 * updateJobPosting
 */
export const updateJobPosting = (jobExtId, jobPostExtId, data) => {
  return axios.put(`/score/api/v1/job/${jobExtId}/posting/${jobPostExtId}`, data)
}
/**
 * DELETE
 * /score/api/v1/job/${jobExtId}/posting/${jobPostExtId}
 * deactivateJobPosting
 */
export const deactivateJobPosting = (jobExtId, jobPostExtId) => {
  return axios.delete(`/score/api/v1/job/${jobExtId}/posting/${jobPostExtId}`)
}

/**
 * DELETE
 * /score/api/v1/job/attract/${attractJobExtId}
 * closeAttractJob
 */
export const closeAttractJob = (attractJobExtId) => {
  return axios.delete(`/score/api/v1/job/attract/${attractJobExtId}`)
}

/**
 * POST
 * /score/api/v1/jobandpost
 * createJobAndPost
 */
export const createJobAndPost = (data) => {
  return axios.post('/score/api/v1/jobandpost', data)
}
/**
 * GET
 * /score/api/v1/jobs/country
 * getAllCountries
 */
export const getAllCountries = () => {
  return axios
    .get('/score/api/v1/jobs/country')
    .then(normalizeGetAllCountries)
}
/**
 * POST
 * /score/api/v1/jobs/country
 * getAllCountriesForRegions
 */
export const getAllCountriesForRegions = (data) => {
  return axios
    .post('/score/api/v1/jobs/country', data)
    .then(normalizeGetAllCountriesForRegions)
}
/**
 * POST
 * /score/api/v1/jobs/job
 * getOpenJobs
 */
export const getOpenJobs = (data, page, size) => {
  return axios.post(`score/api/v2/jobs/job?page=${page || 0}&size=${size || 10}`, data)
}
/**
 * POST
 * /score/api/v1/jobs/job/${jobExtId}/posting
 * getJobPostings
 */
export const getJobPostings = (jobExtId, data) => {
  return axios.post(`/score/api/v1/jobs/job/${jobExtId}/posting`, data)
}
/**
 * POST
 * /score/api/v1/jobs/job/${jobExtId}/posting
 * getJobPosting2
 */
export const getJobPosting2 = (jobExtId, jobPostExtId, data) => {
  return axios.post(`/score/api/v1/jobs/job/${jobExtId}/posting/${jobPostExtId}`, data)
}
/**
 * GET
 * /score/api/v1/jobs/location
 * getAllLocations
 */
export const getAllLocations = () => {
  return axios
    .get('/score/api/v1/jobs/location')
    .then(normalizeGetAllLocations)
}
/**
 * POST
 * /score/api/v1/jobs/location
 * getAllLocationsForRegionsAndCountries
 */
export const getAllLocationsForRegionsAndCountries = (data) => {
  return axios
    .post('/score/api/v1/jobs/location', data)
    .then(normalizeGetAllLocationsForRegionsAndCountries)
}
/**
 * GET
 * /score/api/v1/jobs/region
 * getAllRegions
 */
export const getAllRegions = () => {
  return axios
    .get('/score/api/v1/jobs/region')
    .then(normalizeGetAllRegions)
}
/**
 * POST
 * /score/api/v1/search/job/${jobExtId}/posting/${jobPostExtId}/jobreq
 * searchByJobDescription
 */
export const searchByJobDescription = (jobExtId, jobPostExtId, data) => {
  return axios
    .post(`/score/api/v1/search/job/${jobExtId}/posting/${jobPostExtId}/jobreq`, data)
    .then(normalizeSearchByJobDescription)
}
/**
 * POST
 * /score/api/v1/search/job/${jobExtId}/posting/${jobPostExtId}/query
 * searchByQueryRequest
 */
export const searchByQueryRequest = (jobExtId, jobPostExtId, data) => {
  return axios
    .post(`/score/api/v1/search/job/${jobExtId}/posting/${jobPostExtId}/query`, data)
    .then(normalizeSearchByQueryRequest)
}
/**
 * POST
 * /score/api/v1/search/job/${jobExtId}/posting/${jobPostExtId}/search
 * searchByJobPosting
 */
export const searchByJobPosting = (jobExtId, jobPostExtId, params, data) => {
  return axios
    .post(`/score/api/v1/search/job/${jobExtId}/posting/${jobPostExtId}/search`, data, { params })
    .then(normalizeSearchByJobPosting)
}
/**
 * POST
 * /score/api/v1/search/job/${jobExtId}/query
 * searchByQueryRequestNoPosting
 */
export const searchByQueryRequestNoPosting = (jobExtId, data) => {
  return axios
    .post(`/score/api/v1/search/job/${jobExtId}/query`, data)
    .then(normalizeSearchByQueryRequestNoPosting)
}
/**
 * POST
 * /score/api/v1/search/query
 * searchByQueryRequestNoJobNoPosting
 */
export const searchByQueryRequestNoJobNoPosting = (data) => {
  return axios
    .post('/score/api/v1/search/query', data)
    .then(normalizeSearchByQueryRequestNoJobNoPosting)
}
