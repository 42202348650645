import React from 'react'
import AuthLayout from '../../../layouts/AuthLayout'
import { LeftHeader, Row, BackLoginPageButton, FullWidthButton } from './style'
import { Form, Input, message } from 'antd'
import { useHistory } from 'react-router-dom'
import * as passwordApi from '../../../api-twg/twg/password-change-controller'
import { allConstants } from '../../../constants/constants'
import { useTranslation } from 'react-i18next'
const ResetPasswordPage = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const onFinish = (values) => {
    passwordApi.doPasswordReset(values.email)
      .then(() => {
        message.success(`Please check ${values.email} for reset link`)
        history.push('/')
      })
      .catch(() => message.loading(`Checking for ${values.email}`))
  }

  const renderRightSideTitle = () => (
    <>
      <Row>
        <LeftHeader>{t(allConstants.HELLO)},</LeftHeader>
      </Row>
      <Row>
        <LeftHeader>{t(allConstants.CHANGE_PASSWORD)}</LeftHeader>
      </Row>
    </>
  )

  const handleBackLoginClick = () => {
    history.push('/')
  }

  return (
    <AuthLayout right={renderRightSideTitle()}>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: t(allConstants.EMAIL_ERROR)
            }
          ]}
        >
          <Input placeholder={t(allConstants.EMAIL_ADDRESS)} />
        </Form.Item>

        <Form.Item>
          <FullWidthButton type="primary" htmlType="submit">

            {t(allConstants.RESET_PASSWORD)}
          </FullWidthButton>
        </Form.Item>
        <Row justify="center">
          <BackLoginPageButton type="link" onClick={handleBackLoginClick}>
            {t(allConstants.LOGIN_ACCOUNT)}
          </BackLoginPageButton>
        </Row>
      </Form>
    </AuthLayout>
  )
}

export default ResetPasswordPage
