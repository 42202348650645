import React from 'react'
import PropTypes from 'prop-types'
import { Col, Layout, Row, Typography } from 'antd'
// import { MoneyCollectOutlined } from '@ant-design/icons'
// Style Sheet
import './modalBody.css'
import { DollarCircleTwoTone } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

const { Title, Text } = Typography

export default function EditModal ({ handleSubmit }) {
  const { t } = useTranslation()
  return (
        <Layout className='edit-modal-layout' >
            <Row className='export-candidate-body' >
                <Col span={2} >
                    <DollarCircleTwoTone />
                </Col>
                <Col span={22}>
                    <Typography>
                        <Title level={5}>
                        {t(allConstants.EXPORT_SELECTED_CANDIDATES)}
                        </Title>
                    </Typography>

                    <Typography>
                        <Text>
                        This feature requires use of a credit.
                        </Text>
                    </Typography>
                </Col>
            </Row>
        </Layout>
  )
}

EditModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}
