import React from 'react'
import { Card, Row, Typography, Button, Col } from 'antd'
// import HeaderLayout from '../../../layouts/HeaderLayout'
// context
import { useQuerysDispatch, useQuerysState, setGreenhouseResult, setAccountType } from '../GreenhouseEstimatorContext'
// styling
import './estimatorResult.css'

const { Title } = Typography

export default function ThirtyMinutes () {
  const dispatch = useQuerysDispatch()
  const { greenhouseEstimate } = useQuerysState()

  return (
        < >

            <Row className='estimatorResultCards' >
                <Card bordered={false} className="jobStatCard">
                    <Title>{greenhouseEstimate?.jobs}</Title>
                    <p>Job Count</p>
                </Card>
                <Card bordered={false} className="jobStatCard">
                <Title>{greenhouseEstimate?.posts}</Title>
                    <p>Job Posts</p>

                </Card>
            </Row>
            <Row className='estimatorResultCards' >
                <Card bordered={false} className="jobStatCard">
                    <Title>{greenhouseEstimate?.candidates}</Title>
                    <p>Application Count</p>
                </Card>
                <Card bordered={false} className="jobStatCard">
                <Title>{greenhouseEstimate?.estimatedTimeSec}</Title>
                    <p>Seconds</p>
                </Card>
            </Row>
            <Row className='estimatorResultCards' >
                <Col span={12} >
                    <Button
                    onClick={() => {
                      dispatch(setGreenhouseResult(false))
                      dispatch(setAccountType(''))
                    }
                    }
                    className='job-stat-btn' >Start Another Estimate</Button>
                </Col>
            </Row>
        </>

  )
}
