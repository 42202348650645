/* eslint-disable react/prop-types */
import React from 'react'
import { Form, Button, Input, Space } from 'antd'
import { useJobsActions, useJobsState } from '../../context/JobsContext'
import { useHistory, useLocation, useParams } from 'react-router'
import { RichEditor } from '../../../../components/RichEditor'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

function CreateNewJobDescription ({ toggleFullScreen }) {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const actions = useJobsActions()
  const { selectedJob } = useJobsState()
  const history = useHistory()
  const location = useLocation()
  const params = useParams()

  const { state } = location
  const { jobExtId, descriptionId } = useParams()

  const onFinish = (values) => {
    if (params.descriptionId && params.descriptionId !== 'new') {
      actions.updateJobDescription(params.descriptionId, selectedJob, values)
    } else {
      actions.createJobDescription(selectedJob, values)
    }
    location.pathname ===
      `/score/${jobExtId}/search/${jobExtId}/applicants/new/descriptions` ||
    location.pathname ===
      `/score/${jobExtId}/search/${jobExtId}/applicants/${descriptionId}/edit`
      ? history.push(`/score/${jobExtId}/search/${jobExtId}/applicants/edit`)
      : history.push(`/score/${selectedJob.externalJobId}/descriptions`)
  }
  const handleCancelButtonClick = () => {
    if (
      location.pathname ===
        `/score/${jobExtId}/search/${jobExtId}/applicants/new/descriptions` ||
      location.pathname ===
        `/score/${jobExtId}/search/${jobExtId}/applicants/${descriptionId}/edit`
    ) {
      toggleFullScreen(true)
      history.push(`/score/${jobExtId}/search/${jobExtId}/applicants/edit`)
    } else {
      toggleFullScreen(true)
      history.push(`/score/${selectedJob.externalJobId}/descriptions`)
    }
  }
  const editDescCondition = location.pathname.split('/')[7]
  return (
    <Form
      preserve
      form={form}
      initialValues={
        state !== undefined
          ? state?.description
          : editDescCondition === 'edit'
            ? selectedJob?.descriptions[0]
            : form.getFieldsValue() || {}
      }
      onFinish={onFinish}
      layout="vertical"
      requiredMark="optional"
      style={{ width: '100%' }}
    >
      <Form.Item
        label={t(allConstants.JOB_POST_TITLE)}
        name="title"
        rules={[
          { required: true, message: t(allConstants.JOB_POST_TITLE_REQUIRED) }
        ]}
      >
        <Input placeholder={t(allConstants.UNIQUE_JOB_POST_TITLE)} />
      </Form.Item>
      <Form.Item
        label={t(allConstants.JOB_POST_DESCRIPTION)}
        name="content"
        rules={[
          { required: true, message: t(allConstants.JOB_DESCRIPTION_REQUIRED) }
        ]}
      >
        <RichEditor />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit">
            {t(allConstants.SAVE)}
          </Button>
          <Button onClick={handleCancelButtonClick}>
            {t(allConstants.CANCEL)}
          </Button>
          <Button onClick={() => toggleFullScreen()}>
            {t(allConstants.FULL_SCREEN)}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

export default CreateNewJobDescription
