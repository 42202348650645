export const allConstants = {
  HELLO: 'Hello',
  WELCOME: 'WelcomeBack',
  PASSWORD_ERROR: 'PasswordInput',
  EMAIL_ERROR: 'EmailInput',
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'ForgotPassword',
  POWERED_BY: 'PoweredBy',
  WELCOME_TO: 'WelcomeTo',
  EMAIL_ADDRESS: 'EmailAddress',
  PASSWORD: 'Password',
  EMAIL_PASSWORD_ERROR: 'CheckEmailPassword',
  START_JOURNEY: 'StartJourney',
  JOB_POSTING: 'ActiveJobPostings',
  SEE_JOB_POSTING: 'SeeActiveJobPosts',
  VIEW_APPLICANT_RESUME: 'ViewApplicantResumes',
  VIEW_CANDIDATE_PROFILE: 'ViewCandidateProfiles',
  LAUNCH_JOB_POST: 'LaunchJobPosts',
  SEARCH_CANDIDATE: 'SearchPassiveCandidate',
  SOURCE_FUNNEL_CANDIDATE: 'DiverseCandidateFunnel',
  FILTER_SKILLS: 'FilterCandidates',
  EXPORT_CANDIDATE: 'ExportTopCandidates',
  SEARCH_CANDIDATE_IN_DATABASE: 'SearchDatabase',
  QUICK_PRODUCT_DEMO: 'QuickProductDemo',
  WHATS_NEW: 'WhatsNew',
  TICKET_CENTER: 'TicketCenter',
  HELP_CENTER: 'HelpCenter',
  REPORTS: 'Reports',
  ACTIVE_JOBS: 'ActiveJobs',
  GREENHOUSE_ESTIMATOR: 'GreenhouseEstimator',
  GREENHOUSE_DEMO: 'GreenhouseDemo',
  TWG_CALCULATOR: 'TWGValueCalculator',
  ACCOUNT_INFO: 'AccountInfo',
  CREATE_CUSTOMER: 'CreateNewCustomer',
  ABOUT_US: 'AboutUs',
  LOGOUT: 'Logout',
  CREATE_JOB: 'CreateNewJob',
  JOB_REQUISITIONS: 'YourActiveJobRequisitions',
  SHOWING: 'Showing',
  RESULTS: 'Results',
  VIEW: 'View',
  STOP_ATTRACTIVE_APPLICANT: 'StopAttractActiveApplicants',
  ACTIVE_ATTRACT_APPLICANT: 'AttractActiveApplicants',
  REQUIRE_CREDIT: 'FeatureRequiresCredit',
  JOB_ID: 'JobID',
  JOB_TITLE: 'JobTitle',
  LOCATION: 'Location',
  REMOTE: 'Remote',
  APPLICANTS: 'Applicants',
  ATTRACT_STATUS: 'AttractStatus',
  GROUP_BY_ATTRACT_STATUS: 'GroupByAttractStatus',
  INSTANT_CANDIDATES: 'InstantCandidates',
  MANAGE_JOB: 'ManageJob',
  CANDIDATES_CREATED: 'CandidatesBeingCreated',
  CANDIDATES_UPLOAD_MESSAGE: 'CandidatesAppearShortly',
  JOB_DESCRIPTION: 'JobDescription',
  DELETE_JOB: 'DeleteJob',
  COUNTRY: 'Country',
  STATE: 'State',
  CITY: 'City',
  APPLICANTS_NOT_SORT_WARNING: 'LocationCriteria',
  ON_SITE: 'OnSite',
  INSTANT_CANDIDATES_SEE: 'SeeInstantCandidates',

  PASSIVE_CANDIDATES_FETCHED: 'PassiveCandidatesFetched',
  NOT_PAID: 'NotPaid',
  PAID: 'PAID',
  PASSIVE_CANDIDATES_PARTNER_NETWORK_MESSAGE: 'PassiveCandidatesDescription',
  // ON_SITE: 'On Site',

  APPLICANTS_FOR_ROLE: 'BestSuitedApplicants',

  MATCHES_JOB_DESCRIPTION: 'MatchesBasedOnDescription',
  QUALIFIED_APPLICANTS_ONLY: 'QualifiedApplicantsOnly',
  QUALIFIED_APPLICANTS_MESSAGE: 'QualifiedCandidiateInfo',
  VIEW_PROFILE: 'ViewProfile',
  SOURCE: 'Source',
  SCORE: 'Score',
  Score: 'Score',

  INDIRECT_APPLICANTS: 'IndirectApplicants',
  RANK: 'Rank',
  QUALIFIED_APPLICANTS: 'QualifiedApplicants',
  DIRECT_APPLICANTS_ONLY: 'directApplicantsOnly',
  NO_QUALIFIED_APPLICANTS: 'noQualifiedApplicants',
  NO_DIRECT_APPLICANTS: 'noDirectApplicants',
  NO_INDIRECT_APPLICANTS: 'noInDirectApplicants',
  QUALIFIED_APPLICANT_INFO: 'QualifiedApplicantsInfo',
  QUALIFIED_CANDIDATE_INFO_TEXT_1: 'QualifiedCandidateInfoText1',
  QUALIFIED_CANDIDATE_INFO_TEXT_2: 'QualifiedCandidateInfoText2',
  INDIRECT_APPLICANTS_ONLY: 'indirectApplicantsOnly',
  DIRECT_APPLICANTS: 'DirectApplicants',
  GOT_IT: 'Got_It',
  APPLICANT: 'Applicant',
  _CANDIDATE: 'Candidate',
  DOCUMENT_EXPIRED: 'DocumentExpired',
  PREVIOUS_CANDIDATE: 'PreviousCandidate',
  NEXT_CANDIDATE: 'NextCandidate',
  DOWNLOAD_RESUME: 'DownloadResume',
  SELECT_CANDIDATE: 'SelectCandidate',
  PREVIOUS_TITLE: 'PreviousTitle',
  CURRENT_TITLE: 'CurrentTitle',
  CURRENT_COMPANY: 'CurrentCompany',
  CURRENT_INDUSTRY: 'CurrentIndustry',
  DEGREE: 'Degree',
  PHONE: 'PhoneNumber',
  EMAIL: 'EmailAddressShort',
  MATCH_PROCESSING_TIME: 'ProcessingTimeDescription',
  UPLOAD: 'Upload',
  UPLOADING: 'Uploading',
  TASKS_EXAMPLES: 'socialExamples',
  SOURCE_LABEL: 'SourceLabel',
  LABEL_ORIGINATING_SOURCE: 'AddLabelFiles',
  FILE_VALIDATION: 'FileRequiredMessage',
  REDIRECT_LOGIN: 'RedirectToLoginPage',
  ERROR: 'Error',
  LOADING: 'Loading',
  CHANGE_PASSWORD: 'ChangePassword',
  LOGIN_ACCOUNT: 'LoginToAccount',
  // EMAIL_ERROR: 'Please input your valid email address!',
  RESET_PASSWORD_ERROR: 'Your password was reset. Try login again.',
  RESET_PASSWORD_AGAIN_ERROR:
    'Password reset error. Try to reset again from login page.',
  RESET_YOUR_PASSWORD: 'ResetPassword',
  RESET_PASSWORD: 'Reset Password',
  GO_TO_LOGIN: 'BackToLoginPage',
  CONFIRM_NEW_PASSWORD: 'ConfirmNewPassword',
  REPEAT_PASSWORD: 'RepeatPassword',
  PASSWORD_NOT_MATCH: 'PasswordMismatch',
  CONFIRM_PASSWORD: 'ConfirmPassword',
  DOWNLOAD_CANDIDATE_DATA: 'DownloadingCandidatesData',
  PAGE_NOT_FOUND: 'PageNotFound',

  CONSUMPTION_ACCOUNT: 'AccountTypeConsumption',
  SUBSCRIPTION_ACCOUNT: 'AccountTypeSubscription',
  LANGUAGE: 'Language',
  CREDITS: 'Credits',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  CHANGE_LANGUAGE: 'ChangeLanguage',
  ACTIVE_REVEAL_CANDIDATES: 'TotalActiveCandidates',
  TOTAL_REVEAL_CANDIDATES: 'TotalCandidates',
  APPLICANT_STATISTICS: 'ApplicantStatistics',
  TOTAL_OPEN_ATTRACT_JOBS: 'TotalOpenAttractJobs',
  TOTAL_ATTRACT_JOBS: 'TotalAttractJobs',
  TOTAL_JOB_PROCESSED: 'TotalJobsProcessed',
  TOTAL_JOBS_CLOSED: 'TotalClosedJobs',
  TOTAL_OPEN: 'TotalOpenListed',
  NOT_AUTHORIZED: 'UnauthorizedAccess',
  CIRCA_ACCOUNT_MANAGER: 'CircaAccountManager',
  ENTER_CIRCA_MANAGER: 'EnterCircaManager',
  ENTER_PRIMARY_EMAIL: 'EnterPrimaryEmail',
  PRIMARY_EMAIL: 'PrimaryContactEmail',
  PRIMARY_CONTACT: 'PrimaryContact',
  ATTRACT_CREDIT_AMOUNT: 'EnterAttractCredit',
  ENTER_DISCOVER_CREDIT_AMOUNT: 'EnterDiscoverCredit',
  DISCOVER_CREDIT_AMOUNT: 'DiscoverCredit',
  CONTRACT_END_DATE: 'ContractEndDate',
  ENTER_CONTRACT_END_DATE: 'EnterContractEndDate',
  ENTER_CONTRACT_START_DATE: 'EnterContractStartDate',
  CONTRACT_START_DATE: 'ContractStartDate',
  CONSUMPTION: 'Consumption',
  SUBSCRIPTION: 'Subscription',
  PRICING_MODEL: 'pricing_model',
  COMPANY_NAME: 'EnterCompanyName',
  UPSELL: 'Upsell',
  RENEWAL: 'Renewal',
  NEW_ACCOUNT: 'NewAccount',
  NONE: 'None',
  CASE_TYPE: 'CaseType',
  NEW_CUSTOMER_REQUEST: 'CreateNewCustomerRequest',
  LAUNCH_UI_DEMO: 'LaunchUIDemo',
  YOUTUBE_VIDEO: 'YouTubePlayer',
  WATCH_PRODUCT_DEMO: 'WatchProductDemo',
  THISWAY_VERSION: 'ThisWayV2.0UI',
  WHAT_NEW: 'WhatsNew',
  ANNOUNCEMENT: 'NewUIAnnouncement',
  EQUIVALENT_NETWORK: 'EquivalentNetworkValue',
  PARTNER_NETWORK_VALUE: 'PartnerNetworkValue',
  PORTAL_VALUE_CAPTURED: 'PortalValueCaptured',
  ANNUAL_PLATFORM_COST: 'AnnualPlatformCost',
  MONTHLY_PLATFORM_COST: 'MonthlyPlatformCost',
  AI4J: 'AI4J',
  CURRENT_TOOL: 'CurrentTools',
  PLATFORM_VALUE_CAPTURED: 'PlatformValueCaptured',
  END_DATE: 'EndDate',
  START_DATE: 'StartDate',
  ANNUAL_VALUE_GAINED: 'EquivalentAnnualValueGained',
  MONTHLY_VALUE_GAINED: 'EquivalentMonthlyValueGained',
  WEEKLY_VALUE_GAINED: 'EquivalentWeeklyValueGained',
  SOURCE_VALUE_CAPTURED: 'SourcingValueCaptured',
  CANDIDATE_WEEKLY_SOURCE_COST: 'CostToSourceCandidatesWeekly',
  DEFICIT: 'Deficit',
  SURPLUS: 'Surplus',
  TIME_ASSESSMENT: 'TimeAssessment',
  HOURS_DIFFERENCE: 'HoursDifference',
  PERCENT_TIME_SPENT: 'PercentTimeSpentSourcing',
  TOTAL_TIME_NEEDED: 'TotalTimeNeeded',
  HRS_WEEK: 'HoursPerWeek',
  QUALIFY_TIME: 'QualifyTimePerCandidate',
  NUMBER_OF_CANDIDATES: 'CandidatesPerJob',
  LOOK_TIME: 'LookTimePerJob',
  DOWNLOAD_PDF: 'DownloadAsPDF',
  VALUE_CALCULATOR: 'ValueCalculator',
  INSTANT_CANDIDATE: 'InstantCandidates',
  GROUP_ATTRACT_STATUS: 'GroupByAttractStatus',

  ATTRACT_ACTIVE_APPLICATION: 'AttractStatus',
  ATTRACT_DECLINED: 'AttractDeclined',
  ATTRACT_DISACTIVATED: 'AttractDisactivated',
  STOP_ATTRACT_ACTIVE_APPLICANT: 'StopAttractActiveApplicants',
  VIEW_RESULTS: 'ViewResults',
  ACTIVE_JOB_REQUISITION: 'YourActiveJobRequisitionsShowing',
  SEARCH_BY_ATTRIBUTES: 'SearchByTitleOrLocation',
  REVEAL_MATCHING_SCORE: 'RevealMatchingScores',

  CREATING_JOB_ATS_SYSTEM_MESSAGE: 'CreateJobNote',
  JOB_DESCRIPTION_TO_RANK: 'FindRankByJobDescription',
  UNITED_STATES: 'UnitedStates',
  SORT_NOT_WORK_WARNING: 'LocationCriteria',
  JOB_LOCATION: 'JobLocation',
  TYPE_HERE: 'TypeHere',
  CREATE_NEW_JOB: 'CreateNewJob',
  FULL_SCREEN: 'FullScreen',
  JOB_DESCRIPTION_REQUIRED: 'JobPostDescriptionRequired',
  JOB_POST_DESCRIPTION: 'JobPostDescription',
  UNIQUE_JOB_POST_TITLE: 'UniqueJobPostTitle',
  JOB_POST_TITLE_REQUIRED: 'JobPostTitleRequired',
  JOB_POST_TITLE: 'JobPostTitle',
  CANDIDATE_CONTEXT_PROVIDER: 'CandidateContextProvider',
  DUPLICATE: 'Duplicate',
  SHARE: 'Share',
  SAVE_THIS_SEARCH: 'SaveSearch',
  SEARCH_RESULTS: 'SearchResults',
  LEAST_QUALIFIED: 'LeastQualified',
  SEMI_QUALIFIED: 'SemiQualified',
  VERY_QUALIFIED: 'VeryQualified',
  EXPORT_SELECTED: 'ExportSelected',
  SELECT_ROW_TO_EXPORT: 'SelectRowToExport',
  CREDIT_NOT_AVAILABLE: 'NoCreditsRemaining',
  DESCRIPTION: 'Description',
  VIEW_SAVED_SEARCHES: 'ViewSavedSearches',
  CITY_STATE_FORMAT: 'UseCityStateFormat',
  ENTER_AFTER_ENTRY: 'PressEnterAfterEntry',
  ADD_LOCATION_IF_NOT_REMOTE: 'AddLocationIfNotRemote',
  SEARCH_BY_JOB_TITLE: 'SearchByJobTitle',
  GREEN_HOUSE_ESTIMATOR: 'GreenHouseEstimator',
  COLLECTING_GREENHOUSE_DATA: 'CollectingGreenhouseData',
  ONBEHALF_ID: 'OnBehalfID',
  GREENHOUSE_JOB_ID: 'GreenhouseJobID',
  GREENHOUSE_API_KEY: 'GreenhouseAPIKey',
  ESTIMATE: 'Estimate',
  YEAR: 'Year',
  DAYS_BACK_ESTIMATE: 'EstimateDaysBack',
  FILL_INFO_REGARDING_API_KEY: 'FillGreenhouseInfo',
  SUCCESS: 'Success',
  GREENHOUSE_INTEGRATION_SUCCESSFUL: 'IntegrationSuccessful',
  FULL_ACCOUNT: 'FullAccount',
  ERROR_CONTACT_ENGINEERING: 'SomethingWentWrongContact',
  START_ESTIMATE: 'StartAnotherEstimate',
  SECONDS: 'Seconds',
  APPLICATION_COUNT: 'ApplicationCount',
  JOB_POSTS: 'JobPosts',
  WELCOME_GREENHOUSE_ESTIMATOR: 'WelcomeToGreenhouseEstimator',
  VIEW_TICKETS: 'ViewAllTickets',
  CLOSED: 'ClosedStatus',
  SOLVED: 'SolvedStatus',
  PENDING: 'PendingStatus',
  OPEN: 'OpenStatus',
  NEW: 'NewStatus',
  STATUS: 'TicketStatus',
  UPDATE: 'Update',
  OPENED: 'Opened',
  SUBJECT: 'Subject',
  REMOVE: 'Remove',
  FILE_UPLOAD_ERROR: 'SystemSupportsAllFileTypes',
  BROWSE: 'Browse',
  DROP: 'Drop',
  DRAG_FILE: 'DragFileHereOr',
  UPLOAD_FILES: 'UploadDescription',
  STEPS_FOR_VIEW_RESULT: 'DescribeStepsTaken',
  DESCRIBE_ACTION_ATTEMPTING: 'DescribeActionTaken',
  DESCRIBE_TASK_ATTEMPTED: 'DescribeTaskAttempted',
  TICKET_SUBJECT: 'TicketSubject',
  CREATE_NEW_TICKET: 'CreateNewTicket',
  SOMETHING_WENT_WRONG: 'SomethingWentWrong',
  TICKET_CREATED: 'TicketSuccessfullyCreated',
  Name: 'Name',

  // NEW ADDED WORDS
  QUANTITY: 'quantity',
  ATTRACT_CAMPAIGN_JOBS: 'attractCampaignJobs',
  OPEN_JOBS: 'OpenJobs',
  SEE_ACTIVE_APPLICANTS: 'SeeActiveApplicants',
  SUBMIT: 'submit',
  LAUNCH: 'Launch',
  CONCLUDED: 'Concluded',
  YES: 'yes',
  NO: 'no',
  UPLOAD_CV_TO_SCORE: 'uploadApplicantResumesForJob',
  FILE_LIMIT_MESSAGE: 'clickOrDragFilesHere',
  LOADED_APPLICANT_MATCH_MESSAGE: 'LoadedApplicantScoreJob',
  ALLOWED_FILE_EXTENSIONS: 'allowedFileExtensions',
  EDIT: 'Edit',
  CONFIRM_DELETE: 'confirmDeleteOperation',
  CREATE_JOB_DESCRIPTION: 'createNewJobDescription',
  EDIT_JOB: 'editJobDescription',
  VIEW_SUBMIT_RESUME: 'viewingApplicantsSubmittedResume',
  VIEW_INSTANT_RESULT: 'viewingInstantCandidateResults',
  UPLOAD_APPLICANT_RESUME: 'uploadApplicantResumes',
  EXPORT: 'export',
  EXPORT_SELECTED_CANDIDATES: 'exportSelectedPassiveCandidates',
  RECIEVE_EMAIL_LINK: 'exportDataEmailNotice',
  INCLUDE_RESUME: 'includeResumes',
  EXPORT_REQUEST_PROCESSED:
    'Please wait a moment while your export request is being processed.',
  EXPORTING_CANDIDATES: 'exportingCandidates',
  CLICK_HERE: 'clickHereLink',
  CLICK_HERE_CAPITAL: 'clickHere',
  DISCOVER_CANDIDATES: 'discoverPassiveCandidates',
  SEARCH: 'Search',
  QUERY_SEARCH: 'searchQuery',
  // WELCOME_TWG: 'welcomeToThisWayGlobalKnowledgebase',

  WELCOME_TWG: 'welcomeToThisWayGlobal',
  READ_MORE: 'readMore',
  VIEW_TICKET: 'viewTickets',
  SUPPORT_TICKET: 'createSupportTicket',
  LOOKING_FOR: 'cannotFindWhatYouAreLookingFor',
  MY_SUPPORT_TICKET: 'mySupportTickets',
  CREATE_TICKET: 'createNewTicket',
  TICKET: 'Ticket',
  ADD_COMMENTS: 'addComments',
  UPLOAD_ATTACHMENT: 'uploadAttachment',
  CONTINUE: 'continue',
  PREVIOUS: 'previous',
  NEXT: 'next',
  FINISH: 'finish',
  WHATS_NEW_WALKTHROUGH_AGAIN: 'walkthroughUpdateNotice',
  WE_ARE_DONE: 'weAreDone',
  DISCOVER_CANDIDATES_WITH_CRITERIA: 'discoverPassiveCandidatesCriteria',
  CANDIDATE_SEARCH_WAY: 'filterSortDirectIndirectApplicants',
  JOB_POSTING_SCORE: 'matchScoreApplicants',
  MATCH_SCORE: 'matchScoreYourApplicants',
  PRODUCT_OVERVIEW: 'productOverviewWalkthrough',
  ADD_SKILLS: 'addFourOrMoreSkills',
  SEARCH_ONLY_CANDIDATES: 'searchCandidates',
  MORE: 'more',
  ADVANCED_SEARCH: 'advancedSearch',
  SKILLS: 'Skills',
  SAVED_SEARCH: 'searchSaved',
  PASSIVE_CANDIDATES: 'passiveCandidates',
  MATCHING_RESULT: 'matchingResult',
  FULL_NAME: 'fullName',
  PERSONAL_INFO: 'personalInfo',
  EXPERTISE: 'expertise',
  WORK_HISTORY: 'workHistory',
  CREATING_CSV: 'creatingCSV',
  ROWS_DOWNLOADED: 'rowsDownloaded',
  SAVED_SEARCHES: 'savedSearches',
  FIND_SEARCH_BY_TITLE: 'findSavedSearchByTitle',
  DELETE: 'delete',
  ADD_SKILLS_TEXT: 'addSkills',
  EXCLUDE_SKILLS: 'excludeSkills',
  POSTCODE: 'Postcode',
  ZIP: 'zip',
  ADD_POSTAL_CODE: 'addPostalCode',
  POST_CODE: 'postCode',
  ADD: 'Add',
  LOCATION_TEXT: 'DefaultDistance',
  ADD_DISTANCE: 'addDistance',
  COMPANY: 'COMPANY',
  COMPANY_TEXT: 'company',
  ADD_COMPANY: 'addCompany',
  EXCLUDE_COMPANY: 'excludeCompany',
  ADD_INDUSTRY: 'addIndustry',
  INDUSTRY: 'Industry',
  EXCLUDE_INDUSTRY: 'excludeIndustry',
  EXCLUDE_TITLE: 'excludeTitle',
  ADD_TITLE: 'addTitle',
  TITLE: 'title',
  ADD_LANGUAGE: 'addLanguage',
  LANGUAGE_CAPITAL: 'language',
  JOB_COUNT: 'jobCount',
  MAX: 'max',
  MIN: 'min',
  EXPERIENCE_COUNT: 'yearsOfExperience',
  ADD_CERTIFICATION: 'addCertification',
  EXCLUDED_CERTIFICATION: 'excludedCertifications',
  EXCLUDED_DEGREE: 'excludeDegree',
  ADD_DEGREE: 'addDegree',
  EDUCATION: 'education',
  DISTANCE_UNIT: 'distanceMiles',
  EXPERIENCE: 'EXPERIENCE',
  MIN_JOB_COUNT: 'minimumJobCount',
  MAX_JOB_COUNT: 'maximumJobCount',
  MAX_YEAR_EXPERIENCE: 'maximumExperienceYears',
  MIN_YEAR_EXPERIENCE: 'minimumExperienceYears',
  CREATE: 'create',
  estimateForJobOrAccount: 'estimateForJobOrAccount',
  SINGLE_JOB: 'singleJob',
  DAYS: 'days',
  MONTHS: 'months',
  ABOUT_US_MODAL: 'talentPoolDiversityPlatform',
  RIGHTS_RESERVED: 'allRightsReserved',
  PRIVACY_POLICY: 'privacyPolicy',
  SERVICE_TERMS: 'termsOfService',
  RELEASE_DATE: 'releaseDate',
  CLOSE: 'closeAction',
  VERSION: 'version',
  PREPARED_FOR: 'preparedFor',
  FROM: 'from',
  AI4J_VALUES_CALCULATE: 'calculatedValuesUsingAI4J',
  PLATFORM_USAGE: 'platformUsageEstimates',
  RECRUITING_PLATFORM_COUNT: 'numberOfRecruitingPlatforms',
  MONTHLY_RECRUITING_COST: 'monthlyCostRecruitingPlatform',
  LOG_TIME: 'logInTimeMinutes',
  NO_OF_JOBS: 'numberOfJobs',
  NEW_HIRE: 'newHire',
  PROSPECT_EMPLOYER: 'enterProspectsEmployer',
  ESTIMATED_GAIN: 'estimatedValueToGain',
  NETWORKS: 'Networks',
  PROJECTED_RANGE: 'projectedDateRange',
  PREVIOUS_COMPANY: 'Previous_Company',
  ticketSubject: 'TicketSubject',
  CLEAR: 'clearAction',
  ActiveJobs: 'ActiveJobs',
  QuickDemo: 'QuickDemo',
  TicketCenter: 'TicketCenter',
  HelpCenter: 'HelpCenter',
  Reports: 'Reports',
  SearchPassiveCandidates: 'SearchPassiveCandidates',
  Locations: 'Locations',
  JobID: 'Job ID',
  AttractStatus: 'AttractStatus',
  GroupByAttractStatus: 'GroupByAttractStatus',
  InstantCandidates: 'InstantCandidates',
  LOCATIONS: 'Locations',
  attracting: 'Attracting',
  editViewJob: 'editViewJob',
  uploadApplicantResumes: 'uploadApplicantResumes',
  DeleteJob: 'DeleteJob',
  confirmDeleteOperation: 'confirmDeleteOperation',
  launches: 'launches',
  partners: 'partners',
  diversity_service: 'diversity_service',
  dont_show_again: 'dont_show_again',
  searchTitle: 'searchTitle',
  dateCreated: 'dateCreated',
  manageSearch: 'manageSearch',
  confirmDelete: 'confirmDelete',
  delete_search: 'delete_search',
  SomethingWentWrong: 'Something went wrong',
  back_button: 'Back',
  nameYourSearch: 'nameYourSearch',
  attractCampaignJobs: 'attractCampaignJobs',
  allTickets: 'All Tickets',
  ticket: 'ticket',
  tickets: 'tickets',
  describeStepsTakenResult: 'describeStepsTakenResult',
  candidateName: 'candidateName',
  create_request: 'create_request',
  pricing_model: 'pricing_model',
  company_name: 'company_name',
  consumption: 'consumption',
  contract_start_date: 'contract_start_date',
  contract_end_date: 'contract_end_date',
  discover_credit_amount: 'discover_credit_amount',
  attract_credit_amount: 'attract_credit_amount',
  primary_contact: 'primary_contact',
  primary_contact_email: 'primary_contact_email',
  circa_account_manager: 'circa_account_manager',
  not_authorized: 'not_authorized',
  prospect_name: 'prospect_name',
  LocationSmText: 'Location',
  enter_primary_contact: 'enter_primary_contact',
  optional: 'optional',
  software_engineer: 'software_engineer',
  loadingJobs: 'Loading Jobs',
  PreviousCandidate: 'PreviousCandidate',
  FILL_ZIP_CITY: 'fillZipOrCity',
  CERTIFICATION: 'Certification',
  STANDARD_DATE: 'standardDate',
  CUSTOM_DATE: 'customDate',
  RESET: 'reset',
  TRANSACTION_REPORT: 'transactionReport',
  TOTAL_USAGE: 'totalUsage',
  TRANSACTIONS: 'transactions',
  CREDIT_SPENT: 'creditsSpent',
  CURRNECY_NAME: 'currencyName',
  CURRENCY_CODE: 'currencyCode',
  DATE_USED: 'dateUsed',
  ASSOCIATION: 'association',
  ACCOUNT_EXPIRED: 'accountExpired',
  RENEWAL_NEEDED: 'renewalNeeded',
  RESUME_REPORTS: 'resumeReports',
  INGEST_REPORT: 'ingestReport',
  FAILURE_REPORT: 'failureReport',
  FILE_NAME: 'fileName',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  INGEST_REPORT_DATE: 'ingestReportDate',
  DATE_OF_ATTEMPT: 'dateOfAttempt',
  TODAY: 'today',
  NEED_HELP: 'needHelp',
  NEED_HELP_TEXT: 'needHelpText',
  WHATS_NEW_TEXT: 'whatsNewText',
  TRANSACTION_REPORT_TEXT: 'transactionReportText',
  INGEST_REPORT_TEXT: 'ingestReportText',
  FAILURE_REPORT_TEXT: 'failureReportText',
  REMOTE_SERACH_TEXT: 'remoteSearchText',
  VIDEO_TEXT: 'videoText'

}
