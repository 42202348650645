import { Col, Row, Typography } from 'antd'
import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import SearchBar from '../../SearchBar/SearchBar'
import ZendeskTicketPageWrapper from '../../../ZendeskPages/ZendeskTicketPage/ZendeskTicketPageWrapper/ZendeskTicketPageWrapper'
// functions
import { handleOnChange, handleSearchClick, handleKeyEnter } from '../../SearchBar/utils'
// styles
import './ZendeskArticle.css'
import { useZendeskActions, useZendesksState } from '../../../ZendeskContext'
import { allConstants } from '../../../../../constants/constants'
import { useTranslation } from 'react-i18next'

const { Title, Text } = Typography

function ZendeskArticle () {
  const actions = useZendeskActions()
  const location = useLocation()
  const categoryName = location.pathname.split('/')[1]
  const articleId = location.pathname.split('/')[3]
  const history = useHistory()
  const { article } = useZendesksState()
  const [inputValue, setInputValue] = React.useState('')
  const [searchValue, setSearchValue] = React.useState('')
  const [articleUpdateDate, setArticleUpdateDate] = React.useState(null)
  const [articleUpdateDateArray, setArticleUpdateDateArray] = React.useState([])
  const { t } = useTranslation()
  React.useEffect(() => {
    try {
      actions.getZendeskArticle(articleId)
    } catch (e) {
      console.log(e)
    } finally {
      setArticleUpdateDate(article.updated_at)
    }
    (articleUpdateDate !== null && articleUpdateDate !== undefined) && setArticleUpdateDateArray(articleUpdateDate.split(' '))
  }, [articleId, actions, article.updated_at, articleUpdateDate])
  const headerTitle = () => {
    return (
          <Row className='preview-title' >
            {categoryName}
          </Row>
    )
  }
  const Breadcrumb = () => {
    return (
            <div className='bread-crumb-container'>
            <span className='bread-crumb' onClick={() => history.push('/help-center')}>{t(allConstants.HELP_CENTER)}</span>
            <span style={{ margin: '0px 5px' }}>&gt;</span>
            <span className='bread-crumb' onClick={() => history.push(`/categories/${categoryName}/${article.section_id}`)}> {categoryName} </span>
            <span style={{ margin: '0px 5px' }}>&gt;</span>
            <span> {article.title} </span>
            </div>
    )
  }
  return (
        <ZendeskTicketPageWrapper
            headerTitle={headerTitle()}
            extra={<SearchBar
              onChange={(e) => handleOnChange(e, setInputValue, setSearchValue)}
              onClick={() => handleSearchClick(inputValue, history)}
              onKeyPress={(e) => handleKeyEnter(e, inputValue, history)}
              placeholder='Search for the query'
              value={searchValue}
            />}
            withFooter={true}
            Breadcrumb={Breadcrumb}
        >
            <Row className='zendesk-ticket-body' >
                <Title className='detail-desc-title'> {article.title} </Title>
                <Text className='update-container'>Last Updated On: {`${articleUpdateDateArray[0]} ${articleUpdateDateArray[1]} ${articleUpdateDateArray[2]}`}</Text>
                <Row className='article-body'>
                  <Col className='desc-body' >
                      <Text className='detail-desc-text' >
                        <div dangerouslySetInnerHTML={{ __html: article.body }}/>
                      </Text>
                  </Col>
                  {/* <Col span={4.1} className='ticket-stats'>
                      <Row>
                          <Title className='stats-title' >Updated</Title>
                          <Text className='stat-text'>{article.updated_at}</Text>
                      </Row>

                      <Row>
                          <Title className='stats-title'>Viewed</Title>
                          <Text className='stats-text'>------</Text>
                      </Row>
                  </Col> */}
                </Row>

            </Row>
        </ZendeskTicketPageWrapper>
  )
}

export default ZendeskArticle
