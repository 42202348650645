import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Row, Typography } from 'antd'
// Style
import './Footer.css'
import CreateModal from '../CreateTicket/CreateModal/CreateModal'
import { ZendeskText } from '../HomePage/ZendeskBanner/ZendeskBannerStyles'
import { allConstants } from '../../../../constants/constants'
import { useTranslation } from 'react-i18next'

const { Text } = Typography

function Footer () {
  const [IsModal, setIsModal] = useState(false)

  const history = useHistory()
  const { t } = useTranslation()
  return (
        <Row className='homepage-footer' >
            <Text className='looking-for' >{t(allConstants.LOOKING_FOR)}
                <ZendeskText className='view-ticket' onClick={() => setIsModal(true) } >{t(allConstants.SUPPORT_TICKET)}</ZendeskText>
                <CreateModal IsModal={IsModal} setIsModal={setIsModal} />
            </Text>

            <ZendeskText className='view-ticket' onClick={() => history.push('/tickets') } >{t(allConstants.VIEW_TICKET)}</ZendeskText>
        </Row>
  )
}

export default Footer
