import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
// import PropTypes from 'prop-types'
// import { MoreOutlined } from '@ant-design/icons'
import { Row, Typography } from 'antd'
import classNames from 'classnames'
// Local Files
import ZendeskTicketChatBody from '../../Component/ZendeskTicketChatBody/ZendeskTicketChatBody'
import ZendeskTicketPageWrapper from '../ZendeskTicketPage/ZendeskTicketPageWrapper/ZendeskTicketPageWrapper'
// Styles
import './ZendeskTicketPreview.css'
import { useZendeskActions, useZendesksState } from '../../ZendeskContext'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../constants/constants'

const { Text } = Typography
function ZendeskTicketPreview () {
  // const [isCloseTicket, setisCloseTicket] = useState(false)
  const params = useParams()
  const history = useHistory()
  const actions = useZendeskActions()
  const { zendeskTicketData } = useZendesksState()
  const { t } = useTranslation()
  const headerTitle = (t) => {
    return (
      <Row className="preview-title">
        {t(allConstants.ticket)} # {params?.id}
        <Text className={classNames('preview-status', 'Open')}>
         {t(zendeskTicketData?.status?.toUpperCase())}
        </Text>
      </Row>
    )
  }

  // const handleCloseTicketModal = () => setisCloseTicket(!isCloseTicket)

  // const menu = (
  //   <Menu >
  //     <Menu.Item key="1" className='close-ticket' onClick={handleCloseTicketModal} > Close Ticket </Menu.Item>

  //     {/* {isCloseTicket && */}

  //   </Menu>
  // )

  // const MoreOption = () => {
  // return <></>
  // return (
  //   <Row className='preview-more' >
  //     <Dropdown overlay={menu} trigger={['click']}>
  //       <Row>
  //         <MoreOutlined />
  //       </Row>
  //     </Dropdown>
  //     {/* Modal for close ticket */}
  //     {isCloseTicket && <Modal
  //       title="Ticket Close Confirmation"
  //       visible={isCloseTicket}
  //       onOk={handleCloseTicketModal}
  //       onCancel={handleCloseTicketModal}
  //       okText="Close"
  //       cancelText="Cancel">
  //       <Title className='close-ticket-text'> Are you sure you want to close the ticket? </Title>
  //       <Text className='close-ticket-text'>
  //         Ones the ticket be closed it will not re-open again, instead you have
  //         to create a new ticket.
  //       </Text>
  //     </Modal>}
  //   </Row>
  // )
  // }

  const getZendeskTicketData = async () => {
    await actions.getZendeskTicket(params?.id)
  }

  const getZendeskComment = async () => {
    await actions.getZendeskComments(params?.id)
  }

  const Breadcrumb = () => {
    return (
      <>
        <span
          className="bread-crumb"
          onClick={() => history.push('/tickets')}
          style={{ cursor: 'pointer', marginRight: 5 }}
        >
          {t(allConstants.tickets)}
        </span>
        &gt; {t(allConstants.ticket)} # {params?.id}
      </>
    )
  }

  useEffect(() => {
    getZendeskComment()
    getZendeskTicketData()
  }, [])

  return (
    <ZendeskTicketPageWrapper
      headerTitle={headerTitle(t)}
      Breadcrumb={Breadcrumb}
    >
      <ZendeskTicketChatBody />
    </ZendeskTicketPageWrapper>
  )
}

ZendeskTicketPreview.propTypes = {
  // match: PropTypes.object
}

export default ZendeskTicketPreview
