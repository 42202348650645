import { SearchOutlined } from '@ant-design/icons'
import { Input, Row } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { SearchButton } from '../HomePage/ZendeskBanner/ZendeskBannerStyles'
// style
import './SearchBar.css'
import { allConstants } from '../../../../constants/constants'
import { useTranslation } from 'react-i18next'

function SearchBar ({ placeholder, onClick, onChange, onKeyPress, value }) {
  const { t } = useTranslation()
  return (
    <Row className="search-bar">
      <SearchOutlined style={{ fontSize: 18 }} />

      <Input
        className="search-input"
        placeholder={placeholder}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
      />

      <SearchButton className="search-btn" onClick={onClick}>
        {t(allConstants.SEARCH)}
      </SearchButton>
    </Row>
  )
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  value: PropTypes.string
}
export default SearchBar
