import React from 'react'
import JobsTable from './JobsTable/JobsTable'
import { Col, Row } from 'antd'
import { setPassive, useJobsDispatch } from '../../context/JobsContext'

const JobRequisitionsSection = () => {
  const dispatch = useJobsDispatch()
  function renderJobsTable () {
    return <JobsTable />
  }

  React.useEffect(() => { dispatch(setPassive(false)) }, [])
  return (
    <>
      <Row gutter={8}>
        <Col span={24}>
          {renderJobsTable()}
        </Col>
      </Row>
    </>
  )
}

export default JobRequisitionsSection
