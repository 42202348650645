/* eslint-disable no-debugger */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import {
  Button,
  Checkbox,
  // Tag,
  Modal,
  message
} from 'antd'

import './column.css'
import { useHistory } from 'react-router'
import { getAllJobPostings } from '../../../../../api-twg/score/score-controller'
import Actions from './Actions'
import { useJobsActions, useJobsState } from '../../../context/JobsContext'
import { useLocalStorageState, useRequest, useToggle } from 'ahooks'
import { CustomCheckBox, TitleDiv } from './styles'
import { CheckCircleOutlined, DollarCircleTwoTone, SyncOutlined, PlayCircleOutlined, RetweetOutlined } from '@ant-design/icons'
import { useAuthState } from '../../../../../contexts/AuthContext'
import PopupLogo from '../../../../../assets/popup_logo.png'
import { useHomeActions, useHomeState } from '../../../../HomePage/context/HomeContext'
import PassiveCandidateViewButton from './components/PassiveCandidateViewButton'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'

const { confirm } = Modal
// eslint-disable-next-line react/prop-types
function ViewResultsLink ({ job }) {
  const history = useHistory()
  const { t } = useTranslation()
  const { run } = useRequest(() => getAllJobPostings(job.requestId), {
    manual: true
  })

  const handleViewResultClick = async () => {
    try {
      const { data } = await run()
      if (data?.length) {
        history.push(
          `/score/${job.requestId}/search/${data?.[0].externalJobPostingId}/applicants`,
          job
        )
        window.analytics.track('View Results', {
          job_id: job.requestId,
          job_title: job?.title?.toLowerCase(),
          location: job?.location?.toLowerCase(),
          applicants: job?.applicantCount,
          attract: job?.isAttractJob ? 'on' : 'off'
        })
      } else {
        message.warning('no job postings')
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <TitleDiv onClick={handleViewResultClick}>
      {`${t(allConstants.VIEW)} ${job.applicantCount} ${t(allConstants.RESULTS)}`}
    </TitleDiv>
  )
}

const ConfirmAttractButton = ({ job, attractDaysRemaining }) => {
  const { t } = useTranslation()
  const { user } = useAuthState()
  const homeActions = useHomeActions()
  const { ShowPopupAgain } = useHomeState()
  const actions = useJobsActions()
  const { pageSize, filters } = useJobsState()
  const [isAttractJob, { toggle: toggleIsAttract }] = useToggle(
    job?.isAttractJob
  )
  const [wasAttractJob, { toggle: toggleWasAttract }] = useToggle(
    job?.wasAttractJob
  )
  const [messagingPreference] = useLocalStorageState('messaging', true)
  // const { loading: loadingCloseAttractJob, run: runCloseAttractJob } =
  //   useRequest(() => actions.closeAttractJob(job.jobExtId), { manual: true })
  const { loading: loadingUpdateJob, run: runUpdateJob } = useRequest(
    () =>
      actions.updateJob(
        { ...job, externalJobId: job.requestId },
        { isAttractJob: !job.isAttractJob }
      ),
    { manual: true }
  )

  const segmentTrack = (string) =>
    window.analytics.track(string, {
      job_id: job.requestId,
      job_title: job?.title?.toLowerCase(),
      location: job?.location?.toLowerCase(),
      applicants: job?.applicantCount,
      attract: job?.isAttractJob ? 'off' : 'on'
    })

  const handleAttractButtonTypes = () => {
    if (isAttractJob && !wasAttractJob) {
      if (attractDaysRemaining < 7) return t(allConstants.CONCLUDED)
      else {
        return `${t(allConstants.attracting)} ${attractDaysRemaining} ${t(allConstants.DAYS).toLowerCase()}`
      }
    } else if (wasAttractJob) return t(allConstants.CONCLUDED)
    else return t(allConstants.LAUNCH)
  }

  const handleAttractButtonClick = async () => {
    try {
      if (isAttractJob) {
        if (messagingPreference) {
          window.analytics.track('Attract Window')
          confirm({
            width: '520px',
            title: t(allConstants.STOP_ATTRACTIVE_APPLICANT),
            okText: t(allConstants.YES),
            cancelText: t(allConstants.NO),
            icon: <DollarCircleTwoTone />,
            onOk: async () => {
            // const response = await runCloseAttractJob()
              const response = await runUpdateJob()
              console.log(response, 'job response 1')
              toggleIsAttract(response?.isAttractJob)
              toggleWasAttract(response?.wasAttractJob)
              segmentTrack('Attract Disactivated')
            },
            onCancel: () => {
              segmentTrack('Attract Declined')
            }
          })
        } else {
        // const response = await runCloseAttractJob()
          const response = await runUpdateJob()
          console.log(response, 'job response 1')
          segmentTrack('Attract Disactivated')
          toggleIsAttract(response?.isAttractJob)
          toggleWasAttract(response?.wasAttractJob)
        }
      } else {
        if (ShowPopupAgain === 'true') {
          return attractLaunchPopUp()
        }
        attractJobRun()
      }
    } catch (err) {
      console.log('err', err)
    }
  }
  const attractJobRun = async () => {
    if (user?.showConsumptionPopups) {
      confirm({
        width: '520px',
        title: t(allConstants.ATTRACT_ACTIVE_APPLICATION),
        okText: t(allConstants.YES),
        cancelText: t(allConstants.NO),
        icon: <DollarCircleTwoTone />,
        content: t(allConstants.REQUIRE_CREDIT),
        onOk: async () => {
          try {
            await runUpdateJob()
          } catch (e) {
            console.log(e)
          } finally {
            await actions.getOpenJobs(
              filters,
              pageSize.page - 1,
              pageSize.size
            )
            toggleIsAttract(!isAttractJob)
            segmentTrack('Attract Activated')
          }
        },
        onCancel: () => {}
      })
    } else {
      await runUpdateJob()
      await actions.getOpenJobs(filters, pageSize.page - 1, pageSize.size)
      toggleIsAttract(!isAttractJob)
      segmentTrack('Attract Activated')
    }
  }
  const attractLaunchPopUp = () => {
    let isPopupChecked = false
    confirm({
      width: '750px',

      title: <img src={PopupLogo}></img>,
      okText: t(allConstants.CONTINUE),
      cancelText: t(allConstants.CANCEL),

      icon: '',
      wrapClassName: 'popup_modal',
      className: 'popup_show',
      content: (
        <div>
          <p
            style={{
              marginTop: '12px',
              color: '#343434',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: '400'
            }}
          >
           {t(allConstants.launches)}
            <span
              style={{ color: '#00B7C0', fontSize: '16px', fontWeight: '600', padding: '0 4px' }}
            >
               8,500
            </span>
             {t(allConstants.partners)}<br/>
             {t(allConstants.diversity_service)}
          </p>
          <CustomCheckBox
            id="dontShowPopup"
            onChange={(e) => {
              isPopupChecked = e.target.checked
            }}
          />
          <label
            htmlFor="dontShowPopup"
            style={{
              marginLeft: '8px',
              fontSize: '14px',
              fontWeight: '400',
              color: '#000'
            }}
          >
           {t(allConstants.dont_show_again)}
          </label>
          <div
            style={{
              backgroundColor: '#F0F0F0',
              height: '1px',
              marginTop: '16px'
            }}
          ></div>
        </div>
      ),

      onOk: async () => {
        try {
          // updateUserMetadata()
          homeActions.updatePopupValue({ ShowPopupAgain: `${!isPopupChecked}` })
          attractJobRun()
        } catch (err) {
          console.log('err')
        }
      },

      okButtonProps: {
        style: {
          marginTop: '-8px',
          borderRadius: '2px',
          boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
          fontSize: '14px',
          fontWeight: '400',
          width: '89px'
        }
      },
      cancelButtonProps: {
        style: {
          marginTop: '-8px',
          borderRadius: '2px',
          boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
          fontSize: '14px',
          fontWeight: '400',
          width: '89px'

        }
      }
    })
  }

  return (
    <Button
      style={{ width: '175px', fontSize: '12px' }}
      // loading={loadingCloseAttractJob || loadingUpdateJob}
      className={!isAttractJob && !wasAttractJob && 'table-dashed-button'}
      loading={loadingUpdateJob}
      type="dashed"
      disabled={wasAttractJob || (isAttractJob && attractDaysRemaining < 7)}
      onClick={handleAttractButtonClick}
      icon={
        isAttractJob && !wasAttractJob
          ? (
          <SyncOutlined />
            )
          : wasAttractJob
            ? (
          <CheckCircleOutlined />
              )
            : (
          <PlayCircleOutlined />
              )
      }
    >
      {handleAttractButtonTypes()}
      {/* {(isAttractJob && !wasAttractJob) ? `Attracting : ${attractDaysRemaining} Days Left` : wasAttractJob ? 'Concluded' : 'Launch'} */}
    </Button>
  )
}

export const columns = (locations, countries, t) => {
  return [
    {
      title: t(allConstants.JobID),
      dataIndex: 'name',
      key: 'id',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: true,
      width: '20%'
    },
    {
      title: t(allConstants.JOB_TITLE),
      // dataIndex: 'title',
      key: 'title',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: true,
      render: (row) => <div>{row.title}</div>,
      width: '20%'
    },
    // {
    //   title: 'Job Title',
    //   dataIndex: 'title',
    //   key: 'title',
    //   render: (text) => <JobTitleLink text={text}/>
    // },
    {
      title: t(allConstants.LOCATIONS),
      dataIndex: 'location',
      key: 'location',
      render: (location) => (location ? <>{location}</> : <>Remote</>),
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: true,
      width: '15%'
    },
    {
      title: t(allConstants.APPLICANTS),
      // dataIndex: 'applicantCount',
      align: 'left',
      key: 'applicants',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (row) => (
        <div>
          <ViewResultsLink job={row} />
        </div>
      ),
      sorter: true,
      width: '10%'
    },
    {
      // title: () => <Tag color="orange">STATUS</Tag>,
      title: () => (
        <div>
          {t(allConstants.AttractStatus)}{' '}
          <RetweetOutlined className={'attract-status-icon'} />
        </div>
      ),
      dataIndex: 'isAttractJob',
      key: 'isAttractJob',
      align: 'left',
      render: (isAttractJob, record) => (
        <ConfirmAttractButton
          attractDaysRemaining={record?.attractDaysRemaining}
          isAttractJob={isAttractJob}
          job={record}
        />
      ),
      width: '10%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: true,
      showSorterTooltip: {
        title: t(allConstants.GroupByAttractStatus)
      },
      className: 'attract-status'
    },
    {
      title: t(allConstants.InstantCandidates),
      key: 'passiveCandidates',
      align: 'center',
      render: (row) => <PassiveCandidateViewButton job={row} />,
      width: '15%'
    },
    {
      title: t(allConstants.MANAGE_JOB),
      key: 'actions',
      align: 'center',
      render: (row) => <Actions job={row} />,
      width: '15%'
    }
  ]
}