import React, { useEffect } from 'react'
import { TabWrapperStyle } from '../style'
import { Spin, Table } from 'antd'
import { failureReportColumn } from '../../table-utils/column'
import { useReportActions, useReportState } from '../../../context/ReportContext'
import RangeComponent from '../../RangeComponent'
import { defaultDate } from '../../../utils'
import { useTranslation } from 'react-i18next'

function FailureReport () {
  const action = useReportActions()
  const { t } = useTranslation()
  const { resumeReport, reportLoading } = useReportState()
  const { txDateFrom: startDate, txDateTo: endDate } = defaultDate('1')
  useEffect(() => {
    action.getResumeReport({
      startDate,
      endDate,
      sortColumn: 'NAME',
      sortDirection: 'ASC'
    })
  }, [])
  return (
    <TabWrapperStyle>
      <RangeComponent exportFileName="Resume Failure Report" />
      <Spin spinning={reportLoading}>
        <Table
          rowKey="resumeFilename"
          columns={failureReportColumn(t)}
          dataSource={resumeReport.filter(val => val.parseFailure)}

        />
      </Spin>
    </TabWrapperStyle>
  )
}

export default FailureReport
