import React from 'react'
import { Typography } from 'antd'
import PropTypes from 'prop-types'
// Files
// Styles
import './ZendeskHeader.css'
import { ZendeskBannerBody } from '../HomePage/ZendeskBanner/ZendeskBannerStyles'

const { Title } = Typography

function ZendeskHeader ({ title, extra }) {
  return (
            <ZendeskBannerBody className='zendesk-header' >
                <Title className='zendesk-header-title' > {title} </Title>
                {extra && extra}
            </ZendeskBannerBody>
  )
}

ZendeskHeader.propTypes = {
  title: PropTypes.object,
  extra: PropTypes.node
}

export default ZendeskHeader
