/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-debugger */
import React, { useEffect, useReducer, useState } from 'react'
import { PropTypes } from 'prop-types'
import * as userApi from '../../../api-twg/twg/user-controller'
import i18n from 'i18next'
import { languageFormatFunc } from '../../../helper'
/** action types */
export const FIRST_TIME_LOGIN_VALUE = 'FIRST_TIME_LOGIN_VALUE'
export const SHOW_POPUP_AGAIN = 'SHOW_POPUP_AGAIN'
export const SET_LANGUAGE = 'SET_LANGUAGE'

/** action creators */
export const setFirstTimeLoginValue = (firstTimeLoginValue) => ({
  type: FIRST_TIME_LOGIN_VALUE,
  firstTimeLoginValue
})
export const setShowPopupAgainValue = (showPopupAgainValue) => ({
  type: SHOW_POPUP_AGAIN,
  showPopupAgainValue
})
export const setLanguage = (language) => ({ type: SET_LANGUAGE, language })
/** async actions */

const createFirstTimeLoginValue = (dispatch) => async (value) => {
  try {
    const { data } = await userApi.createUserMetadata(value)
    return data
  } catch (e) {
    console.log(e)
  } finally {
    dispatch(getFirstTimeLoginValue())
  }
}

const getShowPopupRun = (dispatch) => async () => {
  try {
    const response = await userApi.getUserMetadata()
    dispatch(setShowPopupAgainValue(response?.data?.ShowPopupAgain))
  } catch (e) {
    console.log(e)
  }
}
const createMetaData = (dispatch) => async (value) => {
  try {
    const { data } = await userApi.updateUserMetadata(value)
    dispatch(setLanguage(data?.language))
    return data
  } catch (e) {
    console.log(e)
  }
}
const getMetaValues = (dispatch) => async () => {
  try {
    const response = await userApi.getUserMetadata()
    if (response?.data?.language) {
      dispatch(setLanguage(response.data.language))
      i18n.changeLanguage(response.data.language)
    }
  } catch (e) {
    console.log(e)
  }
}
const getFirstTimeLoginValue = (dispatch) => async () => {
  try {
    const response = await userApi.getUserMetadata()
    dispatch(setLanguage(response?.data?.language))
    dispatch(setFirstTimeLoginValue(response?.data))
    dispatch(setShowPopupAgainValue(response?.data?.ShowPopupAgain))
    if (response) {
      const { data } = response
      if (!Object?.entries(data)?.length) {
        const promise1 = await userApi.createUserMetadata({ firstLogin: true })
        const promise2 = await userApi.getUserMetadata()
        Promise.all([promise1, promise2]).then((values) => {
          dispatch(setFirstTimeLoginValue(values[1]?.data))
        })
      }

      return data
    }
  } catch (e) {
    console.log(e)
  }
}

const updateFirstTimeLoginValue = (dispatch) => async (value) => {
  try {
    const { data } = await userApi.updateUserMetadata(value)
    dispatch(setFirstTimeLoginValue(data?.firstTimeLogin))
    dispatch(setShowPopupAgainValue(data?.ShowPopupAgain))
    return data
  } catch (e) {
    console.log(e)
  }
}

const updatePopupValue = (dispatch) => async (value) => {
  try {
    const { data } = await userApi.updateUserMetadata(value)
    dispatch(setShowPopupAgainValue(data?.ShowPopupAgain))
    return data
  } catch (e) {
    console.log(e)
  }
}

// dispatch actions
export const changeLanguage = async (language, dispatch) => {
  try {
    i18n.changeLanguage(language)
    return await createMetaData(dispatch)({ language })
  } catch (err) {
    console.log(err)
  }
}
/** initial value */
const userLanguage = (navigator.language || navigator.userLanguage).slice(0, 2)
const initialState = {
  firstTimeLoginValue: false,
  ShowPopupAgain: 'true',
  language: userLanguage
}

export function reducer (state, action) {
  switch (action.type) {
    case FIRST_TIME_LOGIN_VALUE:
      return {
        ...state,
        firstTimeLoginValue: action.firstTimeLoginValue
      }
    case SHOW_POPUP_AGAIN:
      return {
        ...state,
        ShowPopupAgain: action?.showPopupAgainValue ?? 'true'
      }
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language ?? userLanguage
      }
  }
}

const HomeActionsContext = React.createContext(undefined, undefined)
const HomeStateContext = React.createContext(undefined, undefined)
const HomeDispatchContext = React.createContext(undefined, undefined)

function HomeContextProvider ({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const [actions] = useState({
    getFirstTimeLoginValue: getFirstTimeLoginValue(dispatch),
    updateFirstTimeLoginValue: updateFirstTimeLoginValue(dispatch),
    createFirstTimeLoginValue: createFirstTimeLoginValue(dispatch),
    updatePopupValue: updatePopupValue(dispatch),
    getShowPopupRun: getShowPopupRun(dispatch),
    createMetaData: createMetaData(dispatch),
    getMetaValues: getMetaValues(dispatch)
  })
  const languageFormat = languageFormatFunc(state.language)
  const pathName = window.location.pathname.replaceAll('/', '')
  useEffect(() => {
    if (pathName !== 'scoreui') {
      actions.getMetaValues()
    } else {
      i18n.changeLanguage(languageFormat.code)
    }
  }, [])

  useEffect(() => {
    getFirstTimeLoginValue()
  }, [getFirstTimeLoginValue])

  return (
    <HomeActionsContext.Provider value={actions}>
      <HomeStateContext.Provider value={state}>
        <HomeDispatchContext.Provider value={dispatch}>
          {children}
        </HomeDispatchContext.Provider>
      </HomeStateContext.Provider>
    </HomeActionsContext.Provider>
  )
}

function useHomeActions () {
  const context = React.useContext(HomeActionsContext)
  if (context === undefined) {
    throw new Error('useHomeActions must be used within a HomeContextProvider')
  }
  return context
}

function useHomeState () {
  const context = React.useContext(HomeStateContext)
  if (context === undefined) {
    throw new Error('useHomeState must be used within a HomeContextProvider')
  }
  return context
}

function useHomeDispatch () {
  const context = React.useContext(HomeDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useHomeDispatch must be used within a HomeContextProvider'
    )
  }
  return context
}

HomeContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { HomeContextProvider, useHomeActions, useHomeState, useHomeDispatch }
