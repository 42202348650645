import Background from '../../assets/login/background.png'
import styled from 'styled-components'
import { Col, Row } from 'antd'

export const Logo = styled.img`
  width: 100%;
`

export const LogoWithTag = styled.div`
  width: ${({ theme }) => theme.logo ? '283px' : 'auto'};
  height: ${({ theme }) => theme.logo ? '100px' : 'auto'};
  margin-top:${({ theme }) => theme.logo ? '7rem' : '9rem'};
  margin-bottom: 2rem;
  position: relative;
  img{
    width:100%;
  }
`

export const FlexRow = styled(Row)`
  height: 100vh;
  flex: 1;
`
export const FlexColumn = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 400rem;
`
export const RightColumn = styled(Col)`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
export const LeftColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-left: 4rem !important;
  height: 100vh;
  justify-content: center;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 4rem;
  background-image: url(${Background});
`
