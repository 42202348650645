import React from 'react'
import { Typography } from 'antd'
import PropTypes from 'prop-types'
// Files
import HeaderLayout from '../../../../../layouts/HeaderLayout'
import ZendeskHeader from '../../../Component/ZendeskHeader/ZendeskHeader'
import Footer from '../../../Component/Footer/Footer'
// Styles
import './ZendeskTicketPage.css'

const { Text } = Typography

function ZendeskTicketPageWrapper ({ headerTitle, extra, withFooter, Breadcrumb, children }) {
  return (
        <HeaderLayout isZendesk={true}>
            <ZendeskHeader title={headerTitle} extra={extra && extra}
            />

            <Text className='desc-title' > {Breadcrumb && <Breadcrumb/>} </Text>

            {children}

           {withFooter && <Footer/>}
        </HeaderLayout>
  )
}

ZendeskTicketPageWrapper.propTypes = {
  children: PropTypes.node,
  headerTitle: PropTypes.object,
  withSearchbox: PropTypes.bool,
  withFooter: PropTypes.bool,
  extra: PropTypes.node,
  Breadcrumb: PropTypes.func
}

export default ZendeskTicketPageWrapper
