import React, { useState } from 'react'
import { Drawer, Space } from 'antd'
import { matchPath, useHistory, useLocation } from 'react-router'
import NewJobDescriptionDrawer from '../jobs/components/JobDrawer/NewJobDescriptionDrawer/NewJobDescriptionDrawer'

const DrawerEditJob = () => {
  const [editDrawer, setEditDrawer] = useState(false)
  const location = useLocation()
  const history = useHistory()

  const match = matchPath(location.pathname, {
    path: '/score/:jobExtId/search/:jobPostExtId/applicants/edit',
    exact: true,
    strict: false
  })

  const handleOnClose = () => {
    const urlParts = location.pathname.split('/')
    urlParts.pop()
    history.push(urlParts.join('/'))
  }

  React.useEffect(() => {
    match && setEditDrawer(true)
  }, [match])

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={handleOnClose}
      width={720}
      visible={!!match}
    >
      <Space direction="vertical" size="middle" >
        <NewJobDescriptionDrawer
        editDrawer={editDrawer}
        setEditDrawer={setEditDrawer}
        />
      </Space>
    </Drawer>
  )
}

export default DrawerEditJob
