import axios from '../../axios-config'

/**
 * POST
 * /score/api/v2/search/job/{jobExtId}/posting/{jobPostExtId}/search
 * searchByJobPosting
 */
export const searchByJobPosting = (jobExtId, jobPostExtId, params, data) => {
  return axios
    .post(`/score/api/v2/search/job/${jobExtId}/posting/${jobPostExtId}/search`, data, { params })
}
