import MatchScoreImg from '../../../assets/firstJourneyPopupImages/slide1.png'
import CandidateSearchImg from '../../../assets/firstJourneyPopupImages/slide2.png'
import DiscoverCandidatesImg from '../../../assets/firstJourneyPopupImages/slide3.png'
import ActiveJobsImg from '../../../assets/firstJourneyPopupImages/active-jobs.png'
import TransactionReportImg from '../../../assets/firstJourneyPopupImages/transaction-report.png'
import MenuImg from '../../../assets/firstJourneyPopupImages/menu.png'
import FailedReportImg from '../../../assets/firstJourneyPopupImages/failed-report.png'
import IngestReportImg from '../../../assets/firstJourneyPopupImages/ingest-report.png'
import { allConstants } from '../../../constants/constants'
import thumbnail from '../../../assets/thumbnail.png'
import video from '../../../assets/demo_video.mp4'
const {
  NEED_HELP_TEXT,
  NEED_HELP,
  REMOTE_SERACH_TEXT,
  TRANSACTION_REPORT_TEXT,
  INGEST_REPORT_TEXT,
  FAILURE_REPORT_TEXT,
  TRANSACTION_REPORT,
  FAILURE_REPORT,
  INGEST_REPORT,
  JOB_POSTING_SCORE,
  MATCH_SCORE,
  WHATS_NEW_WALKTHROUGH_AGAIN,
  DISCOVER_CANDIDATES_WITH_CRITERIA,
  DISCOVER_CANDIDATES,
  CANDIDATE_SEARCH_WAY,
  ACTIVE_JOBS,
  VIDEO_TEXT
} = allConstants

export const demoSlidesItems = [
  { title: MATCH_SCORE, description: JOB_POSTING_SCORE, slide: MatchScoreImg },
  {
    title: ACTIVE_JOBS,
    description: CANDIDATE_SEARCH_WAY,
    slide: CandidateSearchImg
  },
  {
    title: DISCOVER_CANDIDATES,
    description: DISCOVER_CANDIDATES_WITH_CRITERIA,
    slide: DiscoverCandidatesImg
  },
  {
    title: VIDEO_TEXT,
    description: WHATS_NEW_WALKTHROUGH_AGAIN,
    slide: video,
    video: true
  }
]

export const WhatsNewSlidesitem = [
  { title: NEED_HELP, description: NEED_HELP_TEXT, slide: MenuImg },
  { title: ACTIVE_JOBS, description: REMOTE_SERACH_TEXT, slide: ActiveJobsImg },
  {
    title: TRANSACTION_REPORT,
    description: TRANSACTION_REPORT_TEXT,
    slide: TransactionReportImg
  },
  {
    title: FAILURE_REPORT,
    description: FAILURE_REPORT_TEXT,
    slide: FailedReportImg
  },
  {
    title: INGEST_REPORT,
    description: INGEST_REPORT_TEXT,
    slide: IngestReportImg
  }
]

export const contentText1 =
  'Explore our dropdown realm for swift updates and easy product demos. Seek HelpCenter support or submit tickets seamlessly. Our remote location feature simplifies global hiring for recruiters.'
export const contentText2 =
  'Discover the latest updates and demo products effortlessly in our dropdown realm. Access HelpCenter or submit tickets for seamless support. Our remote location feature streamlines global talent acquisition.'
export const items = [
  {
    title: 'ThisWay Version 2.2',
    content: contentText2,
    newPost: true,
    video,
    id: 2,
    WhatsNewSlidesitem,
    version: '2.2'
  },
  {
    title: 'ThisWay Version 2.1',
    content: contentText1,
    thumbnail,
    newPost: false,
    video,
    id: 1,
    WhatsNewSlidesitem: demoSlidesItems,
    version: '2.1'
  }
]
