/* eslint-disable no-debugger */
import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import PoweredByLogo from '../../assets/power.png'
import { FlexColumn, FlexRow, LeftColumn, RightColumn, LogoWithTag } from './style'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getLogoAndText } from '../../helper/index'

const AuthLayout = ({ children, right }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <FlexRow type="flex" align="middle">
        <LeftColumn span={8}>{right}</LeftColumn>
        <RightColumn span={16}>
          <FlexColumn span={10}>
            <Row justify="center" style={{ position: 'relative' }}>
              <LogoWithTag>
                <img src={getLogoAndText(theme.logo).logo} style={{ objectFit: 'contain' }}/>
              </LogoWithTag>
            </Row>
            <>{children}</>
            <Row justify="center" align="bottom" style={{ paddingTop: '4rem' }}>
              <Col>
                <Row justify="center">
                  <span>{t('Powered by')}</span>
                </Row>
                <Row>
                  <img
                    src={PoweredByLogo}
                    style={{ maxWidth: 125 }}
                    alt="this way global"
                  />
                </Row>
              </Col>
            </Row>
          </FlexColumn>
        </RightColumn>
      </FlexRow>
    </React.Fragment>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node,
  right: PropTypes.node
}

export default AuthLayout
