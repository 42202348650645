import React from 'react'
import { Col, Row, Typography } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const { Title, Text } = Typography

export default function FiltersCount ({ clearExcludeFilters, ExcludeField, title, classNames, children }) {
  return (
        <Row className={classnames('excl-typo-row', classNames)} >
            <Col span={16} >
                <Typography>
                    <Title level={5} >
                        {children || title}
                    </Title>
                </Typography>
            </Col>
            <Col span={4} style={{ textAlign: 'right', marginBottom: '14px' }} >
                {ExcludeField?.length > 0 &&
                    <Row className='filters-count' >
                        <Text> {ExcludeField?.length} </Text>
                        <CloseOutlined onClick={clearExcludeFilters} />
                    </Row>}
            </Col>
        </Row>
  )
}

FiltersCount.propTypes = {
  clearExcludeFilters: PropTypes.func,
  ExcludeField: PropTypes.array,
  title: PropTypes.string,
  classNames: PropTypes.string,
  isCityOrPostCode: PropTypes.bool,
  values: PropTypes.object,
  setValues: PropTypes.func,
  updateJobApi: PropTypes.func,
  checked: PropTypes.bool,
  children: PropTypes.node

}
