import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Input, Row, Image, Button, message, Tooltip } from 'antd'
// Style
import './WriteMessage.css'
// Svgs
import Attachement from '../../../Assets/Attachement.svg'
// import { ReactComponent as SendMessage } from '../../../Assets/SendMessage.svg'
import { useZendeskActions, useZendesksState, useZendesksDispatch, setIsLoading } from '../../../ZendeskContext'
import { DeleteOutlined, SendOutlined } from '@ant-design/icons'
import FileIcon from '../../../Assets/FileIcon.png'
import { getPreviewFiles } from '../../../Helpers/getPreviewFiles'
import { validateFileSize } from '../../../Helpers/validations'
import { ZendeskWriteMessage } from '../../HomePage/ZendeskBanner/ZendeskBannerStyles'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'

function WriteMessage () {
  const [filesData, setFilesData] = useState({
    fileList: [],
    previewVisible: false,
    previewFiles: [],
    previewTitle: '',
    isAttachement: false,
    body: ''
  })

  const { isLoading } = useZendesksState()
  const dispatch = useZendesksDispatch()
  const { t } = useTranslation()

  const params = useParams()
  const actions = useZendeskActions()
  const UPLOAD_REF = useRef(null)

  // const handleChange = ({ fileList }) => setFilesData({ ...filesData, fileList })

  const onChange = e => {
    if (isLoading) return
    setFilesData({ ...filesData, [e.target.name]: e.target.value })
  }

  const handleAttachement = () => {
    setFilesData({ ...filesData, isAttachement: true })
    UPLOAD_REF.current.click()
  }

  const handleSubmit = async () => {
    if (!filesData.body) return message.error('Comment body is empty')
    if (isLoading) return
    dispatch(setIsLoading(true))
    try {
      const tokens = []
      // get ticket attachment tokens
      for (let i = 0; i < filesData.fileList.length; i++) {
        const formData = new FormData()
        formData.append('file', filesData.fileList[i])
        const token = await actions.postCreateTicketAttachment(formData)
        tokens.push(token)
      }
      await actions.postCreateComment(params?.id, {
        comment: filesData.body,
        uploadTokens: tokens
      })
      await actions.getZendeskComments(params?.id)
    } catch (err) {
      console.error(err.message)
    } finally {
      setFilesData({
        fileList: [],
        previewVisible: false,
        previewFiles: [],
        previewTitle: '',
        isAttachement: false,
        body: ''
      })
      dispatch(setIsLoading(false))
    }
  }

  const handleSetAttachment = (e) => {
    const ValidateFileSize = validateFileSize(e.target.files)
    if (ValidateFileSize !== 'success') return message.error(ValidateFileSize)
    const existedFiles = filesData.fileList
    const fileList = [...filesData.fileList, ...e.target.files]
    const previewFiles = getPreviewFiles(fileList)
    setFilesData({ ...filesData, fileList: [...existedFiles, ...e.target.files], previewFiles })
    // console.log(oFReader)
    // srcc = oFReader.result
  }

  const onRemovePreviewFile = index => {
    const newPreviewFiles = [...filesData.previewFiles]
    const newFilelist = [...filesData.fileList]
    newPreviewFiles.splice(index, 1)
    newFilelist.splice(index, 1)
    setFilesData({ ...filesData, fileList: newFilelist, previewFiles: newPreviewFiles })
  }
  return (
    <Row className='write-Message-body' >
      <Row className='preview-files' >
        {filesData.previewFiles.length > 0 &&
          filesData.previewFiles.map((src, index) => (
            <div key={index} className='preview-image-container' onClick={() => onRemovePreviewFile(index)} >
              {typeof src === 'string'
                ? <Image className='preview-file' preview={false} src={src} width={80} height={80} />
                : <Row className='general-file' >
                    <Image className='preview-file' preview={false} src={FileIcon} width={50} height={50} />
                    <span className='file-name' > {src.name} </span>
                  </Row>
              }
              <span className='close-image' style={{ padding: '1rem' }} ><DeleteOutlined color='red' /> {t(allConstants.REMOVE)} </span>
            </div>
          ))
        }
      </Row>

      <input
        type='file'
        onChange={handleSetAttachment}
        multiple
        name='file'
        value=""
        accept="*"
        className='input-file-hide'
        ref={UPLOAD_REF}
      />

      <ZendeskWriteMessage >
        <Row className='form-row' >
          <Form className='message-form' >
            <Input
              className='message-input'
              placeholder={t(allConstants.ADD_COMMENTS)}
              onChange={onChange}
              name='body'
              value={filesData.body}
              onPressEnter={handleSubmit}
            />
          </Form>
          <Tooltip
          overlayInnerStyle={{ width: '12vw' }}
          title={t(allConstants.FILE_UPLOAD_ERROR)} >
            <Image className='attachment-file' preview={false} src={Attachement} onClick={handleAttachement} />
          </Tooltip>

          <Button
            disabled={(!filesData.body) || isLoading}
            icon={<SendOutlined/>}
            onClick={handleSubmit}
          />
        </Row>
      </ZendeskWriteMessage >

    </Row >

  )
}

export default WriteMessage
