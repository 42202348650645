import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Image, Input, message, Modal, Row, Typography } from 'antd'
import { DeleteOutlined, InfoCircleFilled } from '@ant-design/icons'
import FileIcon from '../../../Assets/FileIcon.png'
import PropTypes from 'prop-types'
// Styles
import './CreateModal.css'
import { useZendeskActions, useZendesksDispatch, setIsLoading } from '../../../ZendeskContext'
import { getPreviewFiles } from '../../../Helpers/getPreviewFiles'
import { validateFileSize } from '../../../Helpers/validations'
import { BrowseImage, UploadImage, ZendeskModalBody } from '../../HomePage/ZendeskBanner/ZendeskBannerStyles'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../../../constants/constants'
const { Title, Text } = Typography
const { TextArea } = Input

// function getBase64 (file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = error => reject(error)
//   })
// }

function CreateModal ({ IsModal, setIsModal }) {
  const dispatch = useZendesksDispatch()
  const actions = useZendeskActions()
  const { t } = useTranslation()
  const closeModal = () => setIsModal(false)
  const [zendeskValues, setZendeskValues] = useState({
    subject: '',
    body: '',
    fileList: [],
    previewTitle: undefined,
    isAttachement: false,
    isDragDrop: false,
    previewFiles: []
  })
  const UPLOAD_REF = useRef(null)
  const history = useHistory()

  const handleAttachement = () => {
    setZendeskValues({ ...zendeskValues, isAttachement: true })
    UPLOAD_REF.current.click()
  }

  const clearStateValues = () => {
    setZendeskValues({
      subject: '',
      body: '',
      fileList: [],
      previewFiles: []
    })
  }

  const onChange = e => setZendeskValues({ ...zendeskValues, [e.target.name]: e.target.value })

  const handleCreate = async () => {
    dispatch(setIsLoading(true))
    try {
      if (!zendeskValues.subject || !zendeskValues.body) return
      const tokens = []
      // get ticket attachment tokens
      for (let i = 0; i < zendeskValues.fileList.length; i++) {
        const formData = new FormData()
        formData.append('file', zendeskValues.fileList[i])
        const token = await actions.postCreateTicketAttachment(formData)
        tokens.push(token)
      }
      // create ticket
      const ticket = await actions.createZendeskTicket({
        subject: zendeskValues.subject,
        body: zendeskValues.body,
        uploadTokens: tokens
      })
      message.success(t(allConstants.TICKET_CREATED))
      history.push(`/ticket-preview/${ticket.id}`)
      closeModal()
    } catch (err) {
      message.error(t(allConstants.SOMETHING_WENT_WRONG))
    } finally {
      setIsLoading(false)
    }
  }

  const handleCloseModal = () => {
    clearStateValues()
    closeModal()
  }

  const handleSetAttchment = async e => {
    const ValidateFileSize = validateFileSize(e.target.files)
    console.log(ValidateFileSize)
    if (ValidateFileSize !== 'success') return message.error(ValidateFileSize)
    const fileList = [...zendeskValues.fileList, ...e.target.files]
    const previewFiles = getPreviewFiles(fileList)
    setZendeskValues({ ...zendeskValues, fileList, previewFiles })
  }

  const handleDragOver = (e) => {
    e.stopPropagation()
    e.preventDefault()
    e.dataTransfer.dropEffect = 'copy'
    setZendeskValues({ ...zendeskValues, isDragDrop: true })
  }

  const handleDrop = e => {
    e.stopPropagation()
    e.preventDefault()
    const ValidateFileSize = validateFileSize(e.dataTransfer.files)
    if (ValidateFileSize !== 'success') return message.error(ValidateFileSize)
    const fileList = [...zendeskValues.fileList, ...e.dataTransfer.files]
    const previewFiles = getPreviewFiles(fileList)
    setZendeskValues({ ...zendeskValues, fileList, previewFiles, isDragDrop: false })
  }

  const onRemovePreviewFile = index => {
    const newPreviewFiles = [...zendeskValues.previewFiles]
    const newFilelist = [...zendeskValues.fileList]
    newPreviewFiles.splice(index, 1)
    newFilelist.splice(index, 1)
    setZendeskValues({ ...zendeskValues, fileList: newFilelist, previewFiles: newPreviewFiles })
  }

  return (
    <Modal
      title={t(allConstants.CREATE_NEW_TICKET)}
      visible={IsModal}
      onOk={handleCreate}
      onCancel={handleCloseModal}
      okText={t(allConstants.CREATE)}
      cancelText={t(allConstants.CANCEL)}
      bodyStyle={{ padding: 0 }}
      className='create-ticket-modal'
      width={650}
      okButtonProps={{ disabled: (!zendeskValues.subject || !zendeskValues.body) }}
    >
      <input
        type='file'
        onChange={handleSetAttchment}
        multiple
        name='file'
        // value={zendeskValues?.previewTitle}
        value=""
        accept="*"
        className='input-file-hide'
        ref={UPLOAD_REF}
      />
      <Row className='header-divider' />
      <ZendeskModalBody >
        {/* Subject */}
        <Title className='ticket-title'>{t(allConstants.ticketSubject)}</Title>
        <Input className='ticket-subject-desc' name='subject' required onChange={onChange} value={zendeskValues.subject} placeholder={t(allConstants.DESCRIBE_TASK_ATTEMPTED)} />

        {/* Description */}
        <Title className='ticket-title' >{t(allConstants.DESCRIBE_ACTION_ATTEMPTING)}</Title>
        <TextArea autoSize={{ minRows: 5 }} className='ticket-subject-desc' name='body' onChange={onChange} value={zendeskValues.body} required placeholder={t(allConstants.describeStepsTakenResult)}></TextArea>

        {/* Upload Image */}
        <Title className='ticket-title' >{t(allConstants.UPLOAD_ATTACHMENT)} </Title>
        <help>{t(allConstants.UPLOAD_FILES)}</help>
        <div
          onClick={handleAttachement}
          className='drag-img'
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >

          <UploadImage >
            <Text className='drag-file' > {zendeskValues.isDragDrop ? t(allConstants.DROP) : t(allConstants.DRAG_FILE)}
              <BrowseImage>{t(allConstants.BROWSE)}</BrowseImage>
            </Text>
          </UploadImage>
        </div>
        <div className='zendesk-file-warning'>
        <InfoCircleFilled style= {{ color: 'rgba(0, 0, 0, 0.65)' }}/>
        <div>{t(allConstants.FILE_UPLOAD_ERROR)}</div>
        </div>

        <Row className='preview-files' >
          {zendeskValues.previewFiles.length > 0 &&
            zendeskValues.previewFiles.map((src, index) => (
              <div key={index} className='preview-image-container' onClick={() => onRemovePreviewFile(index)} >
                {typeof src === 'string'
                  ? <Image className='preview-file' preview={false} src={src} width={80} height={80} />
                  : <Row className='general-file' >
                      <Image className='preview-file' preview={false} src={FileIcon} width={50} height={50} />
                      <span className='file-name' > {src.name} </span>
                    </Row>
                }
                <span className='close-image' ><DeleteOutlined color='red' /> {t(allConstants.REMOVE)} </span>
              </div>
            ))
          }
        </Row>
      </ZendeskModalBody>
    </Modal >
  )
}

CreateModal.propTypes = {
  IsModal: PropTypes.bool,
  setIsModal: PropTypes.func
}

export default CreateModal
