import { Alert, Card } from 'antd'
import styled from 'styled-components'

export const UserOption = styled.div`
    display:flex;
    align-items:center;
    justify-content: center;
    width: 660px;
    min-height: 162px;
    margin-bottom:16px;
    padding: 0px 8px 0px 24px;
    border-radius: 8px;
    border: solid 1.7px #d9d9d9;
    background-color: #fff;
    

    & .text-container {
        margin:16px 0px 16px 16px;

        & .text-heading {
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: normal;
            color: #343434;
        }

        & .text-para {
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: normal;
            color: #343434;
        }
    }

    & .arrow {
        margin:0px 24px 0px 32px;
        color:#898989;
        display:flex;
        justify-content:center;
        align-items:center;
        padding:12px;
        object-fit: contain;
        border-radius:50%;
        background-color: #fff;
    }

    
`

export const OptionsContainer = styled.div`
    & :hover {
        cursor:pointer;
    }

    & .hover-user-options:hover {
        border-radius: 8px;
        border: solid 1.7px #00b7c0;
        background-color: rgba(0, 183, 192, 0.08);   
        
        & .arrow {
            display:flex;
            justify-content:center;
            align-items:center;
            padding:12px;
            object-fit: contain;
            border-radius:100%;
            background-color: rgba(0, 183, 192, 0.6);
            color: #fff;
        }
    }


`

export const JourneyHeading = styled.div`
    margin:32px 0px 26px 0px;    
    display:flex;
    align-items:flex-end;
    & span {
        color:#343434;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    & img {
        margin-left:15px;
    }
`

export const ViewTicket = styled.div`
    color: #00b7c0;
    text-align: center;
    margin-top: 80px;
  & span {
    font-size: 17px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }
`

export const CustomCard = styled(Card)`
   display: flex;
   justify-content: center;
   min-height: calc(100vh - 340px);
   padding-bottom: 40px;
`
export const CustomAlert = styled(Alert)`
    width: 100%;
    margin: auto auto;
    background: #fcf1ef;
    font-size: 14px;
    & .info_icon{
        font-size: 14px;
        color: #c91c00;
    }
`