import styled from 'styled-components'

export const JobDescriptionsDrawer = styled.div`
  width: 695px;
  .fields-container {
    display: flex;
    flex-wrap: wrap;
  }

  .desc-divider {
    margin: 0px;
  }
`
export const JobDescriptionsTitle = styled.div`
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #343434;
  }

  .job-desc-drawer-button {
    .edit-button {
      margin-right: 12px;
    }
  }
  .close-button {
    color: #00b7c0;
    font-weight: 500;
    margin-right: 12px;
  }
`
export const DescDrawersFields = styled.div`
  display: flex;
  align-items: center;
  margin: 21px 0px 0px 0px;

  .field {
    font-size: 16px;
    font-weight: 600;
    color: #343434;
    margin-right: 10px;
  }
  .value {
    margin-top: 2px;
    font-size: 14px;
    color: #343434;

    .field-value-input {
      width: 250px;
    }
  }
  .id-ellipsis {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const DescContainer = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 4px;
  margin-top: 21px;
  .job-title {
    font-size: 16px;
    font-weight: 600;
    color: #343434;
    padding: 16px 0px 0px 16px;

    & .title-ellipsis {
      width: 640px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .desc-divider {
    margin: 15px 0px;
    border: 1px thin #d9d9d9;
  }
`
export const SpinContainer = styled.div`
  width: 695px;
  height: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const DescDrawerWrapper = styled.div`
  padding: 0px 12px 0px 12px;
  width: 695px;
  .fields-container {
    display: flex;
    flex-wrap: wrap;
  }

  .desc-divider {
    margin: 0px;
    border: 0.5px solid #d9d9d9;
  }
  .sub_header_btn{
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  .sub_header_btn_edit {
  
    padding-right: 12px;
  }
  .sub_header_btn_save {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding-right: 21px;
  }
`
