import { useRequest } from 'ahooks'
import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router'
import { fetchExportedFile } from '../../../api-twg/score/document-controller'
import './ExportDownloadPage.css'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../constants/constants'

export default function ExportDownloadPage () {
  const [isDownload, setisDownload] = useState(null)
  const { ticketId } = useParams()
  // const history = useHistory()
  const { t } = useTranslation()
  const { run } = useRequest(async () => fetchExportedFile(ticketId), {
    manual: true,
    onSuccess: (response) => {
      setisDownload(true)
      const filename = response?.headers?.['content-disposition']?.split('"')?.[1] || 'file'
      const url = window.URL.createObjectURL(new Blob([response?.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename) // or any other extension
      document.body.appendChild(link)
      link.click()
      // history.push('/score')
      setTimeout(() => {
        window.open('about:blank', '_self')
        window.close()
      }, 100)
    },
    onError: () => {
      setisDownload(false)
      message.error(t(allConstants.DOCUMENT_EXPIRED))
      // history.push('/score')
    }
  })

  useEffect(() => {
    message.success(t(allConstants.DOWNLOAD_CANDIDATE_DATA))
    ticketId && run()
  }, [run, ticketId])

  if (!ticketId) {
    return <Redirect to="/score" />
  }

  return isDownload === false && (
    <div className='download-fail-page'>
      {/* {ticketId} */}
      <h1>404</h1>
      <p>{t(allConstants.PAGE_NOT_FOUND)}</p>
    </div>
  )
}
