import React from 'react'
import { useHistory } from 'react-router'
import { Typography } from 'antd'
// import { SearchOutlined } from '@ant-design/icons'
import classNames from 'classnames'
// Local Files
import { DateFormat } from '../../../../helper'
// Style
import './ZendeskTickets.css'
import moment from 'moment'
import { ZendeskText } from '../../Component/HomePage/ZendeskBanner/ZendeskBannerStyles'
import { allConstants } from '../../../../constants/constants'

const { Text } = Typography

export default function Columns (filterState, filteredInfo, t) {
  const history = useHistory()

  const columns = [
    {
      key: 'subject',
      title: t(allConstants.SUBJECT),
      dataIndex: 'subject'

    },
    {
      key: 'created_at',
      title: t(allConstants.OPENED),
      dataIndex: 'created_at',
      render: function UpdatedAt (cell, row) {
        return (
          <time dateTime={row.created_at} className='ticket-dates' >{DateFormat(row.created_at)}</time>
        )
      }
    },
    {
      key: 'updated_at',

      title: t(allConstants.UPDATE),
      dataIndex: 'updated_at',
      sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
      render: function UpdatedAt (cell, row) {
        return (
          <time dateTime={row.updated_at} className='ticket-dates' >{DateFormat(row.updated_at)}</time>
        )
      }
    },
    {
      key: 'status',
      title: t(allConstants.STATUS),
      dataIndex: 'status',
      filters: [
        {
          text: t(allConstants.NEW),
          value: 'NEW'

        },
        {
          text: t(allConstants.OPEN),
          value: 'OPEN'

        },
        {
          text: t(allConstants.PENDING),
          value: 'PENDING'
        },
        {
          text: t(allConstants.SOLVED),
          value: 'SOLVED'
        },
        {
          text: t(allConstants.CLOSED),
          value: 'CLOSED'

        },
        {
          text: t(allConstants.allTickets),
          value: 'ALL'

        }
      ],
      filterMultiple: false,
      filterResetToDefaultFilteredValue: true,
      filteredValue: filteredInfo === null || filteredInfo.status === null ? ['ALL'] : filteredInfo?.status,
      onFilter: (value, record) => value === 'ALL' ? record : record?.status?.includes(value),
      filterIcon: (filtered) => `${t('Showing')}: ${t(filterState)}`,
      render: function Action (value) {
        const status = value[0] + value.slice(1).toLowerCase()
        return (
          <Typography>
            <Text className={classNames('status', value)} >{t(status).toUpperCase()}</Text>
          </Typography>
        )
      },
      width: '350px'
    },
    {
      key: 'Action',
      title: '',
      dataIndex: 'Action',
      render: function Action (record, value) {
        const getTicket = () => {
          history.push(`/ticket-preview/${value.id}`)
        }
        return (
          <Typography>
            <ZendeskText className='actions' onClick={getTicket} >{t('View')}</ZendeskText>
          </Typography>
        )
      }

    }
  ]

  return {
    columns
  }
}
