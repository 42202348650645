import React from 'react'
import PropTypes from 'prop-types'
import { Empty, Row } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'

// files
import ZendeskTicketPageWrapper from '../../ZendeskPages/ZendeskTicketPage/ZendeskTicketPageWrapper/ZendeskTicketPageWrapper'
import SearchBar from '../SearchBar/SearchBar'
import { useZendesksState, useZendeskActions } from '../../ZendeskContext'
// functions
import { handleOnChange, handleSearchClick, handleKeyEnter } from '../SearchBar/utils'
import { ArticleName } from '../HomePage/ZendeskBanner/ZendeskBannerStyles'
import { allConstants } from '../../../../constants/constants'
import { useTranslation } from 'react-i18next'

const ZendeskSearchedArticleList = () => {
  const location = useLocation()
  const history = useHistory()
  const queryValue = location.pathname.split('/')[3]
  const [inputValue, setInputValue] = React.useState('')
  const [searchValue, setSearchValue] = React.useState(`${queryValue}`)
  const actions = useZendeskActions()
  const { searchArticle, categories } = useZendesksState()
  const { t } = useTranslation()
  React.useEffect(() => {
    try {
      actions.searchingArticle({ terms: `${queryValue}` })
      actions.getZendeskAllCategories()
    } catch (e) {
      console.log(e)
    }
  }, [actions, queryValue])

  const headerTitle = () => {
    return (
            <Row className='preview-title' >
                {'Search Articles '}
            </Row>
    )
  }
  const Breadcrumb = () => {
    return (
            <div className='bread-crumb-container'>
              <span className='bread-crumb' onClick={() => history.push('/help-center')}>{t(allConstants.HELP_CENTER)}</span>
              <span style={{ margin: '0px 5px' }}>&gt;</span>
              <span> {'Articles'} </span>
            </div>
    )
  }

  const categoriesName = (secId) => {
    return categories.find(item => `${item.sectionId}` === `${secId}` && item.name)
  }

  const handleClick = (id, secId) => {
    history.push(`/${categoriesName(secId)?.name}/article/${id}`)
  }

  const styles = {
    display: 'flex',
    alignItems: 'center',
    width: '95vw',
    flexWrap: 'wrap'
  }
  return (
        <ZendeskTicketPageWrapper
            headerTitle={headerTitle()}
            extra={<SearchBar
                   onChange={(e) => handleOnChange(e, setInputValue, setSearchValue)}
                   onClick={() => handleSearchClick(inputValue, history)}
                   onKeyPress={(e) => handleKeyEnter(e, inputValue, history)}
                   placeholder='Search for the query'
                   value={searchValue}
            />}
            withFooter={true}
            Breadcrumb={Breadcrumb}
        >
        <div className={'article-list-container'}>
          <div style={styles} >
          {searchArticle.length === 0
            ? <div className='article-no-data'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
            : searchArticle.map(item => {
              return (
                    <div className='article-list-card' key={item.id} >
                      <ArticleName className='article-name' onClick={() => handleClick(item.id, item.section_id)}>{item.title}</ArticleName>
                      {item.preview && <div className='zendesk-article-description'>
                            <p dangerouslySetInnerHTML={{ __html: item.preview }}/>
                            <span style={{ fontSize: '17px', color: '#343434' }}>...</span>
                            <span className='zendesk-article-readmore' onClick={() => handleClick(item.id)}>... ReadMore</span>
                      </div>}
                    </div>
              )
            })
              }
            </div>
          </div>
        </ZendeskTicketPageWrapper>
  )
}
ZendeskSearchedArticleList.propTypes = {
  categoryName: PropTypes.string,
  categoryDescription: PropTypes.string
}
export default ZendeskSearchedArticleList