import React from 'react'
import { Layout } from 'antd'
import PropTypes from 'prop-types'
// Filters Section
import Skills from './Sections/Skills'
import Education from './Sections/Education'
import Company from './Sections/Company'
import Experience from './Sections/Experience'
import Location from './Sections/Location'
import Language from './Sections/Language'
import SearchByTitle from './Sections/SearchByTitle'
// helpers
import getSearchFilters from './helpers/getSearchFilters'
// Context
import {
  useQuerysState,
  useQueryActions,
  useQuerysDispatch,
  setFields,
  setSearchApiObj,
  setSearch,
  setIsLoading
} from '../../CandidateContext/SearchQueryContext'
// Style
import './Filters.css'
import { matchPath, useLocation, useParams } from 'react-router'
import { advancedSearchForCandidates } from '../../helpers/QueryHelpers'

const { Sider } = Layout

export default function Filters ({ match }) {
  const [existedFilters, setExistedFilters] = React.useState(undefined)
  const [searchThroughAdvcanceFilter, setSearchThroughAdvanceFilter] = React.useState(false)
  const [searchApiVal, setSearchApiVal] = React.useState(false)
  const { candidatesFilters, searchedCandidates, isLoading, searchBarValues, searchApiObj } = useQuerysState()
  const actions = useQueryActions()
  const dispatch = useQuerysDispatch()
  const location = useLocation()
  const { jobkey } = useParams()

  const getActiveSearchQuery = async () => {
    try {
      await actions.jobPreview(jobkey)
      await actions.getSearchedCandidates(jobkey)
    } catch (e) {
      console.log(e)
    }
  }

  const simpleSearchPath = matchPath(location.pathname, {
    path: '/simplesearch/:title/:skills/:location',
    exact: true,
    strict: false
  })

  React.useEffect(() => {
    if (jobkey) {
      getActiveSearchQuery()
    }
  }, [jobkey])

  React.useEffect(() => {
    const filters = getSearchFilters(candidatesFilters?.searchJson)
    setExistedFilters(filters)
    dispatch(setFields({ selectedFields: filters }))
  }, [candidatesFilters])

  React.useEffect(() => {
    if (Object.entries(searchBarValues).length !== 0) {
      setExistedFilters(
        {
          ...existedFilters,
          location: {
            ...existedFilters?.location,
            location: {
              ...existedFilters?.location?.location,
              mustHave: searchBarValues.location !== 'no_location' ? searchBarValues.location : ''
            }
          },
          title: {
            ...existedFilters?.title,
            current_title: {
              // eslint-disable-next-line
              ...existedFilters?.title?.current_title, 
              shouldHave: searchBarValues.title !== 'no_title' ? [searchBarValues.title] : []
            }
          },
          skills: {
            ...existedFilters?.skills,
            expertise: {
              ...existedFilters?.skills?.expertise,
              shouldHave: searchBarValues.skills
            }
          }

        }
      )
    }
  }, [])

  React.useEffect(() => {
    if (searchThroughAdvcanceFilter) {
      const arrayOfExistedFilters = Object.entries(existedFilters)
      // console.log(arrayOfExistedFilters)
      advancedSearchForCandidates(
        arrayOfExistedFilters,
        actions,
        searchApiObj,
        dispatch,
        setSearchApiObj,
        searchApiVal,
        setSearchApiVal,
        setSearch,
        setIsLoading
      )
    }
  }, [existedFilters])

  return (
      <Sider className='Filters-body' style={isLoading ? { pointerEvents: 'none' } : undefined} >

        <Skills
          match={simpleSearchPath}
          contextData={ { api: actions, candidatesFilters, matchingResults: searchedCandidates?.results } }
          existedSkills={existedFilters?.skills?.expertise}
          setExistedFilters={setExistedFilters}
          existedFilters={existedFilters}
          setSearchThroughAdvanceFilter={setSearchThroughAdvanceFilter}
          setSearchApiVal={setSearchApiVal}
        />

        <Location
          match={simpleSearchPath}
          contextData={{ api: actions, candidatesFilters, matchingResults: searchedCandidates?.results }}
          existedFilters={existedFilters}
          setExistedFilters={setExistedFilters}
          setSearchThroughAdvanceFilter={setSearchThroughAdvanceFilter}
          existedLocation={existedFilters?.location}
          setSearchApiVal={setSearchApiVal}
        />

        <SearchByTitle
          match={simpleSearchPath}
          contextData={ { api: actions, candidatesFilters, matchingResults: searchedCandidates?.results } }
          existedFilters={existedFilters}
          existedTitles={existedFilters?.title}
          setSearchThroughAdvanceFilter={setSearchThroughAdvanceFilter}
          setExistedFilters={setExistedFilters}
          setSearchApiVal={setSearchApiVal}
        />

        <Experience
          match={simpleSearchPath}
          contextData={{ api: actions, candidatesFilters, matchingResults: searchedCandidates?.results }}
          existedFilters={existedFilters}
          existedExperience={existedFilters?.experience}
          setSearchThroughAdvanceFilter={setSearchThroughAdvanceFilter}
          setExistedFilters={setExistedFilters}
          setSearchApiVal={setSearchApiVal}
        />

        <Education
          match={simpleSearchPath}
          contextData={ { api: actions, candidatesFilters, matchingResults: searchedCandidates?.results }}
          setExistedFilters={setExistedFilters}
          existedFilters={existedFilters}
          setSearchThroughAdvanceFilter={setSearchThroughAdvanceFilter}
          existedEducation={existedFilters?.education}
          setSearchApiVal={setSearchApiVal}
        />

        <Company
          match={simpleSearchPath}
          contextData={ { api: actions, candidatesFilters, matchingResults: searchedCandidates?.results }}
          setExistedFilters={setExistedFilters}
          existedFilters={existedFilters}
          setSearchThroughAdvanceFilter={setSearchThroughAdvanceFilter}
          existedCompany={existedFilters?.company }
          setSearchApiVal={setSearchApiVal}
        />

        <Language
          match={simpleSearchPath}
          contextData={{ api: actions, candidatesFilters, matchingResults: searchedCandidates?.results }}
          setExistedFilters={setExistedFilters}
          existedFilters={existedFilters}
          setSearchThroughAdvanceFilter={setSearchThroughAdvanceFilter}
          existedLanguage={existedFilters?.languages}
          setSearchApiVal={setSearchApiVal}
        />

      </Sider>
  )
}

Filters.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      queryId: PropTypes.string.isRequired,
      workboodId: PropTypes.string.isRequired
    })
  })
}
