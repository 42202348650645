/* eslint-disable react/prop-types */
import React from 'react'
// import { InfoCircleOutlined } from '@ant-design/icons'
import { Spin, Col } from 'antd'
import { SpinContainer, CardContent, SelectableCard, Title } from './style'
import { setFilter, setSelectedCandidatesIds, useJobRequisitionsDispatch, useJobRequisitionsState } from '../../../context/ApplicantsContextProvider'
import { useJobsDispatch, setPassive } from '../../../../jobs/context/JobsContext'

function FilterCard ({ title, tooltip, value, loading, name, onClick, trackcard }) {
  const dispatch = useJobRequisitionsDispatch()
  const { filter } = useJobRequisitionsState()
  const jobsDispatch = useJobsDispatch()

  const handleCardClick = () => {
    jobsDispatch(setPassive(false))
    dispatch(setFilter(name))
    dispatch(setSelectedCandidatesIds([]))
  }

  const selected = name === filter

  const handleClick = () => {
    if (!selected) {
      if (onClick) {
        onClick?.()
      } else {
        handleCardClick()
      }
      window.analytics.track(trackcard)
    }
  }

  const renderCard = () => (
    <SelectableCard
      selected={selected}
      onClick={handleClick}
      hoverable={!selected}
    >
      <CardContent>
        <Title>{title}</Title>
        {/* <Tooltip
          title={tooltip}
          placement="right"
          style={{ fontSize: 20 }}
          onVisibleChange={(visible) => {
            window.analytics.track('Information icon', {
              placement: title
            })
          }}
        >
          <InfoCircleOutlined />
        </Tooltip> */}
      </CardContent>
      {/* <Value>{value}</Value> */}
    </SelectableCard>
  )

  return (
    <Col span={12} key={title} style={{
      paddingLeft: '4px',
      paddingRight: '4px',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'stretch',
      width: '100%'
    }}>
      <SpinContainer>
        <Spin spinning={loading}>
          {renderCard()}
        </Spin>
      </SpinContainer>
    </Col>
  )
}

export default FilterCard
