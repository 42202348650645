import { Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import AuthLayout from '../../../../layouts/AuthLayout'

const KeycloakErrorPage = () => {
  return (
    <AuthLayout>
      <Row align='middle' justify="center">
        <Col style={{ textAlign: 'center' }}>
          <h1>Error</h1>
          <Link to="/">Redirect to login page</Link>
        </Col>

      </Row>
    </AuthLayout>
  )
}

export default KeycloakErrorPage
