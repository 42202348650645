/* eslint-disable no-debugger */
import React from 'react'
import { Tabs } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { ResumeReportStyle } from './style'
import { Route } from 'react-router-dom/cjs/react-router-dom.min'
import IngestReport from '../tabs/IngestReport'
import FailureReport from '../tabs/FailureReport'
import { allConstants } from '../../../../constants/constants'
import { useTranslation } from 'react-i18next'

const { FAILURE_REPORT, INGEST_REPORT } = allConstants
function ResumeReport () {
  const location = useLocation()
  const defaultKey = location.pathname.replace('/reports/resume-report/', '')
  const { t } = useTranslation()
  const tabs = [
    {
      title: t(INGEST_REPORT),
      key: 'ingest-report',
      component: (
        <Route
          path={[
            '/reports/resume-report/ingest-report'
          ]}
          component={IngestReport}
        />
      )
    },
    {
      title: t(FAILURE_REPORT),
      key: 'failure-report',
      component: (
        <Route
          exact
          path={['/reports/resume-report/failure-report']}
          component={FailureReport}
        />
      )
    }
  ]

  const history = useHistory()

  return (
    <ResumeReportStyle>
      <Tabs
        onChange={(key) => {
          history.push(`/reports/resume-report/${key}`)
        }}
        defaultActiveKey={defaultKey}
        activeKey={defaultKey}
      >
        {tabs.map(({ title, key, component }) => (
          <Tabs.TabPane tab={title} key={key}>
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </ResumeReportStyle>
  )
}

export default ResumeReport
