import React, { useState } from 'react'
import { Modal, Form } from 'antd'
import PropTypes from 'prop-types'
// Context
import {
  useQuerysDispatch,
  useQuerysState,
  setIsLoading
} from '../CandidateContext/SearchQueryContext'
// Modal Body Component
import DeleteModal from './modalBodyComponent/DeleteModal'
import ShareModal from './modalBodyComponent/ShareModal'
import DuplicateModal from './modalBodyComponent/DuplicateModal'
import EditModal from './modalBodyComponent/EditModal'
import CreateModal from './modalBodyComponent/CreateModal'
import ExportCandidateModal from './modalBodyComponent/ExportCandidateModal'
// Constants
import { QUERY_ACTIONS } from './Constants'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../../constants/constants'

const ModalLayout = ({ updateSearch, isOpen, handleOk, modalBody, setIsopen }) => {
  // Context

  const dispatch = useQuerysDispatch()
  const { isLoading, searchApiObj } = useQuerysState()
  const { t } = useTranslation()
  const [values, setValues] = useState({
    inputVisible: isOpen,
    name: updateSearch?.name || null,
    description: updateSearch?.description || null
  })

  const handleInputChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const handleCancel = () => {
    setValues({ ...values, inputVisible: false })
    setIsopen(false)
  }

  const handleSubmit = (isExportCandidate) => {
    if (isExportCandidate) {
      // console.log(isExportCandidate)
      dispatch(setIsLoading(true))
      handleOk()
      // console.log(values.name)
      return
    }
    if (!values.name) return
    dispatch(setIsLoading(true))
    handleOk(values.name, values.description, modalBody)
  }

  const ModalContent = () => {
    switch (modalBody) {
      case QUERY_ACTIONS.DELETE:
        return {
          SubmitText: t(allConstants.DELETE),
          ModalBody: <DeleteModal searchName={updateSearch?.name} />
        }
      case QUERY_ACTIONS.SHARE:
        return {
          SubmitText: t(allConstants.SHARE),
          ModalBody: <ShareModal />
        }
      case QUERY_ACTIONS.DUPLICATE:
        return {
          SubmitText: t(allConstants.DUPLICATE),
          ModalBody: <DuplicateModal searchName={updateSearch?.name} handleSubmit={handleSubmit} handleInputChange={handleInputChange} />
        }
      case QUERY_ACTIONS.EDIT:
        return {
          SubmitText: t(allConstants.SAVE),
          ModalBody: <EditModal values={values} handleSubmit={handleSubmit} handleInputChange={handleInputChange} updateSearch={updateSearch} />
        }
      case QUERY_ACTIONS.CREATE:
        return {
          SubmitText: t(allConstants.SAVE),
          isExportCandidate: false,
          ModalBody: <CreateModal values={values} handleSubmit={handleSubmit} handleInputChange={handleInputChange} />
        }
      case QUERY_ACTIONS.EXPORT_CANDIDATES:
        return {
          SubmitText: t(allConstants.YES),
          isExportCandidate: true,
          ModalBody: <ExportCandidateModal handleSubmit={handleSubmit} />
        }
      default:
        return {
          SubmitText: t(allConstants.back_button),
          ModalBody: <> {t(allConstants.SomethingWentWrong)}  </>
        }
    }
  }

  const { SubmitText, ModalBody, isExportCandidate } = React.useMemo(() => ModalContent(), [values])
  React.useEffect(() => { }, [searchApiObj])

  return (
    <Modal
      visible={values.inputVisible}
      onOk={ () => !isLoading && handleSubmit(isExportCandidate)}
      onCancel={handleCancel}
      centered
      closable={false}
      okText={SubmitText}
      cancelText={t(allConstants.CANCEL)}
      okButtonProps={{ loading: isLoading, disabled: !isExportCandidate && !values.name }}
      className='discover-modal'
    >
      <Form>

        {ModalBody}

      </Form>
    </Modal>
  )
}

ModalLayout.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  updateSearch: PropTypes.object,
  handleOk: PropTypes.func.isRequired,
  setIsopen: PropTypes.func,
  modalBody: PropTypes.string
}

export default ModalLayout
