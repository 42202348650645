import React from 'react'
import { Button, Descriptions, Drawer, notification } from 'antd'
import { matchPath, useHistory, useLocation } from 'react-router'
import UploadCandidates from '../../components/UploadCandidates'
import { LeftOutlined } from '@ant-design/icons'
import { SnackBar } from './style'
import LightBulb from '../../assets/lightbulb.png'
import { useTranslation } from 'react-i18next'
import { allConstants } from '../../constants/constants'

const DrawerUploadCandidates = () => {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const match = matchPath(location.pathname, {
    path: '/score/:jobExtId/search/:jobPostExtId/applicants/upload',
    exact: true,
    strict: false
  })

  const handleOnClose = () => {
    const urlParts = location.pathname.split('/')
    urlParts.pop()
    history.push(urlParts.join('/'))
  }

  const onFinishUploadCandidate = async ({ upload: { fileList }, Source }) => {
    handleOnClose()
    notification.success({
      message: t(allConstants.CANDIDATES_CREATED),
      description: t(allConstants.CANDIDATES_UPLOAD_MESSAGE),
      placement: 'topLeft'
    })
  }

  return (
    <Drawer
      // title="Upload Applicant Resumes/CVs to Score against this Job "
      placement="right"
      closable
      onClose={handleOnClose}
      width={720}
      visible={!!match}
      bodyStyle={{ paddingBottom: 80 }}
    >
      {/* <UploadCandidates onFinish={onFinishUploadCandidate}/> */}
      <>
          <Button
            type="text"
            icon={<LeftOutlined />} shape="circle" onClick={() => history.goBack()} />
          <SnackBar>
            <img src={LightBulb}/>
            <span>{t(allConstants.REVEAL_MATCHING_SCORE)}</span>
          </SnackBar>
          <Descriptions title={t(allConstants.UPLOAD_CV_TO_SCORE)}>
            <Descriptions.Item>
              <UploadCandidates onFinish={onFinishUploadCandidate}/>
            </Descriptions.Item>
          </Descriptions>
      </>
    </Drawer>
  )
}

export default DrawerUploadCandidates
