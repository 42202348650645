import { Row as AntRow } from 'antd'
import styled from 'styled-components'

export const Row = styled(AntRow)`
  padding-bottom: 1rem;
`
export const LeftHeader = styled.span`
  font-size: 3rem;
  font-weight: 600;
`
