import axios, { BASE_URL } from '../../axios-config'
import Axios from 'axios'

/**
 * GET
 * /score/api/v1/document/{ticketId}
 * getDocument
 */
export const getDocument = (ticketId) => {
  return axios
    .get(`/score/api/v1/document/${ticketId}`, {
      responseType: 'blob' // important
    })
}

/** GET
 * /score/api/v1/export/document/{ticketId}
 * fetchExportedFile
 */
export const fetchExportedFile = (ticketId) => {
  return Axios({
    baseURL: BASE_URL,
    method: 'get',
    url: `/score/api/v1/export/document/${ticketId}`,
    responseType: 'blob'
  })
}
